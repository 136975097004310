import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import TextInput from "../../../../components/TextInput/TextInput";
import { formatErrorEmail } from "../../../../utils";
import Button from "../../../../components/Button/Button";
import { sendMailPassWord } from "../../../../core/services/httpRequest/axios";
import handleRequestResponse from "../../../../utils/responsesHandler";

const Step1 = () => {
   const { t } = useTranslation();
   const history = useHistory();

   const {
      handleSubmit,
      control,
      formState: { errors },
   } = useForm({ mode: "onBlur", reValidateMode: "onChange" });

   function submit(data) {
      sendMailPassWord(data.email).then((res) => {
         handleRequestResponse(
            res,
            () => {
               history.push("/password/mail-sent");
            },
            () => {
               return;
            }
         );
      });
   }

   return (
      <div className="container_step">
         <h1>{t("password.step1")}</h1>
         <p className="blue">{t("password.step1sub")}</p>
         <div className="container_form">
            <form
               name="resetForm"
               onSubmit={handleSubmit(submit)}
               noValidate
               autoComplete="off"
            >
               <Controller
                  control={control}
                  name="email"
                  rules={{
                     required: t("error.required"),
                     validate: formatErrorEmail,
                  }}
                  render={({ field: { value, onChange, onBlur } }) => (
                     <TextInput
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label={t("auth.email")}
                        placeholder={t("auth.email")}
                        variant="outlined"
                        type="email"
                        error={errors.email !== undefined}
                        helperText={errors.email?.message}
                        required
                     />
                  )}
               />
               <div className="container_btn">
                  <Button
                     className="green outline "
                     onClick={() => history.push("/auth")}
                  >
                     {t("password.back")}
                  </Button>
                  <Button type="submit" className="green ">
                     {t("password.send")}
                  </Button>
               </div>
            </form>
         </div>
      </div>
   );
};

export default Step1;
