import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./core/i18n/i18n";
import { Provider } from "react-redux";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import { stores } from "./core/stores/stores";
import { persistStore } from "reduxjs-toolkit-persist";
import ScrollToTop from "./components/ScrollToTop/ScrollTotop";
import Loading from "./components/Loading/Loading";
// import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");
const root = createRoot(container);
const persistor = persistStore(stores);

document.ENVIRONNEMENTS = !!document.ENVIRONNEMENTS
  ? document.ENVIRONNEMENTS
  : {
      API_BASE_PATH: "/test",
    };

root.render(
  <BrowserRouter>
    <Provider store={stores}>
      <PersistGate
        loading={<Loading />}
        persistor={persistor}
        onBeforeLift={() => new Promise((resolve) => setTimeout(resolve, 0.5))}
      >
        <ScrollToTop />
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
