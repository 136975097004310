import React from "react";

const Next = ({ fill }) => {
   return (
      <svg
         width="8"
         height="9"
         viewBox="0 0 8 9"
         fill={fill}
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M6.65851 5.58494C7.30284 5.19603 7.30284 4.26158 6.65851 3.87267L1.97828 1.04771C1.3118 0.645434 0.461521 1.12538 0.461521 1.90385L0.461521 7.55377C0.461521 8.33224 1.3118 8.81218 1.97828 8.4099L6.65851 5.58494Z"
            fill={fill}
         />
      </svg>
   );
};

export default Next;
