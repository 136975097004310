import React from "react";
import Button from "../../../Button/Button";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import "./styles/block.scss";

const Block = ({ children }) => {
   const history = useHistory();
   const { t } = useTranslation();
   return (
      <div className="error-block">
         {children}
         <Button className="green btn" onClick={() => history.push("/accueil")}>
            {t("error.backhome")}
         </Button>
      </div>
   );
};

export default Block;
