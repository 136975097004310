import React, { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import "./comboBox.scss";

const ComboBoxObject = ({
  list,
  label,
  value,
  onChange,
  error,
  helperText,
  fullWidth = false,
  isbool = false,
  required = false,
  canBeCanceled,
  ...props
}) => {
  const [selected, setSelected] = useState(undefined);

  const handleChange = (e) => {
    setSelected(e.target.value);
    onChange(e.target.value);
  };

  function toggle(e) {
    if (e.target.selected === true) {
      setSelected(undefined);
      onChange(undefined);
    }
  }

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <div className="container_combobox">
      <FormControl
        sx={{ m: 1, minWidth: props.minWidth || 280 }}
        size="small"
        fullWidth={fullWidth}
      >
        <InputLabel id="select-small" error={error} required={required}>
          {label}
        </InputLabel>
        <Select
          labelId="select-small"
          label={label}
          value={selected || value || ""}
          onChange={handleChange}
          defaultValue={selected}
          autoWidth={!fullWidth}
          placeholder={label}
          error={error}
          {...props}
        >
          {list?.map((item) => {
            if (!!item) {
              if (canBeCanceled) {
                return (
                  <MenuItem
                    value={item.value}
                    key={item.value}
                    onClick={(e) => toggle(e)}
                  >
                    {item.name}
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem value={item?.value} key={item?.value}>
                    {item?.name}
                  </MenuItem>
                );
              }
            }
          })}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default ComboBoxObject;
