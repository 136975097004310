import React, { useEffect } from "react";
import Bloc from "../Bloc/Bloc";
import AddressDisplay from "../Address/AddressDisplay/AddressDisplay";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import Logo from "../../core/assets/picto/logoPrimeo.svg";
import { ReactComponent as CheckSvg } from "../../core/assets/picto/check.svg";
import {
   replacePointByCommaInDecimals,
   safeEval,
   trueOrFalseDo,
} from "../../utils";
import moment from "moment";
// scss
import "./styles/recapBloc.scss";
import ConfirmSub from "../ConfirmSub/ConfirmSub";
import { useHistory } from "react-router-dom";
import _ from "lodash";
moment.locale("fr");

const RecapBloc = () => {
   const { t } = useTranslation();

   const broker = useSelector((state) => state.auth.broker);
   const sale = useSelector((state) => state.sale);
   const params = useSelector((state) => state.params);

   const history = useHistory();

   function getPrixKwh(values) {
      // booléen pour savoir quel tarif display si valeur TTC > 0
      let base = values?.consoPartVariableBaseTTC > 0;
      let hphc =
         values?.consoPartVariableHcTTC > 0 &&
         values?.consoPartVariableHpTTC > 0;
      let hpehphhcehch =
         values?.consoPartVariableHceTTC > 0 &&
         values?.consoPartVariableHchTTC > 0 &&
         values?.consoPartVariableHpeTTC > 0 &&
         values?.consoPartVariableHphTTC > 0;

      if (base && !hphc && !hpehphhcehch)
         return (
            <div key={"consoPartVariableBaseTTC"} className="details">
               <p>
                  <CheckSvg />
                  {t("souscription.offre.consoPartVariableBaseTTC")}
               </p>

               <p style={{ marginBottom: 0 }}>
                  {replacePointByCommaInDecimals(
                     (values?.consoPartVariableBaseTTC / 100000).toFixed(4)
                  )}{" "}
                  {t("souscription.offre.unitTarif")}
               </p>
            </div>
         );
      if (hphc && !base && !hpehphhcehch)
         return (
            <>
               <div key={"consoPartVariableHcTTC"} className="details">
                  <p>
                     <CheckSvg />
                     {t("souscription.offre.consoPartVariableHcTTC")}
                  </p>

                  <p style={{ marginBottom: 0 }}>
                     {replacePointByCommaInDecimals(
                        (values?.consoPartVariableHcTTC / 100000).toFixed(4)
                     )}{" "}
                     {t("souscription.offre.unitTarif")}
                  </p>
               </div>
               <div key={"consoPartVariableHpTTC"} className="details">
                  <p>
                     <CheckSvg />
                     {t("souscription.offre.consoPartVariableHpTTC")}
                  </p>

                  <p style={{ marginBottom: 0 }}>
                     {replacePointByCommaInDecimals(
                        (values?.consoPartVariableHpTTC / 100000).toFixed(4)
                     )}{" "}
                     {t("souscription.offre.unitTarif")}
                  </p>
               </div>
            </>
         );
      if (hpehphhcehch && !base && !hphc)
         return (
            <>
               <div key={"consoPartVariableHceTTC"} className="details">
                  <p>
                     <CheckSvg />
                     {t("souscription.offre.consoPartVariableHceTTC")}
                  </p>

                  <p style={{ marginBottom: 0 }}>
                     {replacePointByCommaInDecimals(
                        (values?.consoPartVariableHceTTC / 100000).toFixed(4)
                     )}{" "}
                     {t("souscription.offre.unitTarif")}
                  </p>
               </div>
               <div key={"consoPartVariableHchTTC"} className="details">
                  <p>
                     <CheckSvg />
                     {t("souscription.offre.consoPartVariableHchTTC")}
                  </p>

                  <p style={{ marginBottom: 0 }}>
                     {replacePointByCommaInDecimals(
                        (values?.consoPartVariableHchTTC / 100000).toFixed(4)
                     )}{" "}
                     {t("souscription.offre.unitTarif")}
                  </p>
               </div>
               <div key={"consoPartVariableHpeTTC"} className="details">
                  <p>
                     <CheckSvg />
                     {t("souscription.offre.consoPartVariableHpeTTC")}
                  </p>

                  <p style={{ marginBottom: 0 }}>
                     {replacePointByCommaInDecimals(
                        (values?.consoPartVariableHpeTTC / 100000).toFixed(4)
                     )}{" "}
                     {t("souscription.offre.unitTarif")}
                  </p>
               </div>
               <div key={"consoPartVariableHphTTC"} className="details">
                  <p>
                     <CheckSvg />
                     {t("souscription.offre.consoPartVariableHphTTC")}
                  </p>

                  <p style={{ marginBottom: 0 }}>
                     {replacePointByCommaInDecimals(
                        (values?.consoPartVariableHphTTC / 100000).toFixed(4)
                     )}{" "}
                     {t("souscription.offre.unitTarif")}
                  </p>
               </div>
            </>
         );
      else {
         if (values)
            for (const [key, value] of Object?.entries(values)) {
               let current = key?.split("");
               let currentEnd = current?.slice(-3, current?.length)?.join("");
               let currentStart = current?.slice(0, 17)?.join("");
               let clefStart = "consoPartVariable";
               let clefEnd = "TTC";
               if (
                  currentStart === clefStart &&
                  currentEnd === clefEnd &&
                  value > 0
               ) {
                  let libelle =
                     _?.replace(
                        _?.replace(current?.join(""), clefStart, ""),
                        clefEnd,
                        ""
                     ) || "";
                  return (
                     <div key={current?.join("")} className="details">
                        <p style={{ marginBottom: 0 }}>
                           <CheckSvg />
                           {t(
                              "souscription.offre.consoPartVariableUnknownTTC"
                           )}{" "}
                           {libelle}
                        </p>
                        <p style={{ marginBottom: 0 }}>
                           {replacePointByCommaInDecimals(
                              (values?.[current?.join("")] / 100000).toFixed(4)
                           )}{" "}
                           {t("souscription.offre.unitTarif")}
                        </p>
                     </div>
                  );
               }
            }
      }
   }

   function getPrixAbonnement(values) {
      if (values?.aboPartFixeMensuelTTC > 0)
         return (
            <div className="details">
               <p>
                  <CheckSvg />
                  {t("souscription.offre.titleAbonnement")}
               </p>
               <p style={{ marginBottom: 0 }}>
                  {replacePointByCommaInDecimals(
                     (values?.aboPartFixeMensuelTTC / 100)?.toFixed(2)
                  )}{" "}
                  € TTC/mois
               </p>
            </div>
         );
      else return null;
   }

   function getOptionsPrix(option, nbBundleName) {
      // je dois aller chercher le bon prix dans l'option en f° de la puissanceSouscrite et du nbBundleName
      //   let nbBundleName = sale?.optionStorage?.length;
      let prices = [...option?.productsOfferingPrices["0"]?.priceAlterations];
      prices.push(option?.productsOfferingPrices["0"]?.price);
      if (nbBundleName < 2) {
         return (
            <div className="details" key={option?.productSpecification?.name}>
               <p>
                  <CheckSvg />
                  {option?.productSpecification?.name}
               </p>
               <p style={{ margin: 0 }}>
                  {replacePointByCommaInDecimals(
                     (
                        prices.find((p) => p?.priceCondition === undefined)
                           ?.taxIncludedAmount /
                        100 /
                        12
                     ).toFixed(2)
                  )}
                  {t("souscription.offre.unitOption")}
               </p>
            </div>
         );
      } else {
         return (
            <div className="details">
               <p>
                  <CheckSvg />
                  {option?.productSpecification?.name}
               </p>
               <p>
                  <span
                     style={{
                        textDecoration: "line-through",
                        margin: "0 8px 0 0",
                     }}
                  >
                     {replacePointByCommaInDecimals(
                        (
                           prices.find((p) => p?.priceCondition === undefined)
                              ?.taxIncludedAmount /
                           100 /
                           12
                        ).toFixed(2)
                     )}
                     {t("souscription.offre.EUR")}
                  </span>
                  <span
                     className="green"
                     style={{ margin: "0", fontWeight: "bold" }}
                  >
                     {replacePointByCommaInDecimals(
                        (
                           prices.find(
                              (p) =>
                                 p?.priceCondition !== undefined &&
                                 safeEval(
                                    p.priceCondition.split(" "),
                                    nbBundleName
                                 )
                           )?.price?.taxIncludedAmount /
                           100 /
                           12
                        ).toFixed(2)
                     )}{" "}
                     {t("souscription.offre.EUR")}
                  </span>
                  <span style={{ margin: 0 }}>
                     {t("souscription.offre.unitOptionRecap")}
                  </span>
               </p>
            </div>
         );
      }
   }

   // function getNameOptionTarifaire() {
   //   let currentOffer = params?.offers?.find(
   //     (of) => of?.name === sale?.currentOffer?.selectOffer
   //   );

   //   let currentTarif = currentOffer?.tarifications?.find(
   //     (of) => of?.tarification?.name === sale?.currentOffer?.selectTarif
   //   );

   //   return currentTarif?.tarification?.productSpecification?.name;
   // }

   // function getNameOffre() {
   //   let currentOffer = params?.offers?.find(
   //     (of) => of?.name === sale?.currentOffer?.selectOffer
   //   );
   //   return currentOffer?.offerDetail["0"]?.productSpecification?.name;
   //   //  return currentOffer?.
   // }

   // Return home if store is empty
   useEffect(() => {
      if (sale === undefined) {
         history.push("/home");
      }
   }, []);

   return (
      <div className="wrapper">
         <Bloc
            title={t("recapPage.title")}
            open={true}
            icon={"recap"}
            isFix
            className="bloc_recapPage"
            note={
               params?.noteAdmin?.filter(
                  (v) => v.word === "SUBSCRIPTION_VALIDATION"
               )[0]?.value
            }
         >
            <Grid container spacing={3}>
               <Grid item xs={12}>
                  {t("recapPage.messageItem1")}
                  {sale?.timeStampToString}
                  {", "}
                  {broker?.fullName}
                  {t("recapPage.messageItem2")}
                  <br />
                  {t("recapPage.messageItem3")}
               </Grid>

               <Grid item xs={12} className="container_option">
                  <>
                     <h3>{sale?.offerStorage?.name}</h3>

                     {getPrixAbonnement(sale?.monthlyAmountDetailed)}
                  </>

                  {getPrixKwh(sale?.monthlyAmountDetailed)}

                  {sale?.optionStorage?.map((option) =>
                     getOptionsPrix(option, sale?.optionStorage?.length)
                  )}

                  { sale?.voltalis && 
                     <div className="details">
                                 <p>
                                    <CheckSvg />
                                    {t("souscription.offre.optionVoltalis")}
                                 </p>
                                 <p style={{ marginBottom: 0 }}>
                                    {replacePointByCommaInDecimals(
                                       (0)?.toFixed(2)
                                    )}{" "}
                                    € TTC/mois
                                 </p>
                        </div>
                  }

                  <span className="line" />
               </Grid>

               <Grid item xs={6}>
                  <p className="green bold mleft">
                     {t("recapPage.mensualite")}
                  </p>
               </Grid>
               <Grid item xs={6}>
                  <h2 className="green bold" style={{ textAlign: "right" }}>
                     {sale?.PDLInfos?.estimatedCar?.carBase?.value > 0
                        ? replacePointByCommaInDecimals(
                           sale?.consoMode === "SCHEDULED"
                              ? (sale?.valueMonthSelected / 100)?.toFixed(2)
                              : (sale?.monthlyAmount / 100)?.toFixed(2)
                        )
                        : "--"}

                     <span className="green">
                        {" "}
                        {t("recapPage.mensualiteUnit")}
                     </span>
                  </h2>
               </Grid>

               {/* {sale?.consoMode === t("souscription.facturation.consoR") && (
            <> */}
               <Grid item xs={6} className="years">
                  <p className="green bold mleft">{t("recapPage.annuel")}</p>
               </Grid>
               <Grid item xs={6}>
                  <h2 className="green bold" style={{ textAlign: "right" }}>
                     {sale?.PDLInfos?.estimatedCar?.carBase?.value > 0
                        ? replacePointByCommaInDecimals(
                           sale?.consoMode === "SCHEDULED"
                              ? (
                                 (sale?.valueMonthSelected / 100) *
                                 12
                              )?.toFixed(2)
                              : ((sale?.monthlyAmount / 100) * 12)?.toFixed(2)
                        )
                        : "--"}
                     <span className="green"> {t("recapPage.annuelUnit")}</span>
                  </h2>
               </Grid>
               {/* </>
          )} */}

               <div className="fieldset">
                  <Grid container>
                     <Grid item xs={12}>
                        <img
                           src={Logo}
                           style={{ marginBottom: "16px", width: "185px" }}
                           alt="logo"
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <h3 className="green" style={{ marginBottom: "0" }}>
                           {t("recapPage.coord")}
                        </h3>
                     </Grid>

                     {sale?.["relative.0.givenName"] ? (
                        <>
                           <Grid item xs={5}>
                              <p
                                 style={{ marginBottom: "0" }}
                              >{`${sale?.gender} ${sale?.givenName} ${sale?.familyName}`}</p>
                           </Grid>
                           <Grid item xs={5}>
                              <p
                                 style={{ marginBottom: "0" }}
                              >{`${sale?.["relative.0.gender"]} ${sale?.["relative.0.givenName"]} ${sale?.["relative.0.familyName"]}`}</p>
                           </Grid>
                        </>
                     ) : (
                        <Grid item xs={12}>
                           <p
                              style={{ marginBottom: "0" }}
                           >{`${sale?.gender} ${sale?.givenName} ${sale?.familyName}`}</p>
                        </Grid>
                     )}
                     {sale?.["relative.1.givenName"] && (
                        <Grid item xs={5}>
                           <p
                              style={{ marginBottom: "0" }}
                           >{`${sale?.["relative.1.gender"]} ${sale?.["relative.1.givenName"]} ${sale?.["relative.1.familyName"]}`}</p>
                        </Grid>
                     )}
                     {sale?.["relative.2.givenName"] && (
                        <Grid item xs={5}>
                           <p
                              style={{ marginBottom: "0" }}
                           >{`${sale?.["relative.2.gender"]} ${sale?.["relative.2.givenName"]} ${sale?.["relative.2.familyName"]}`}</p>
                        </Grid>
                     )}

                     <Grid item xs={6}>
                        <p
                           style={{
                              wordBreak: "break-word",
                              paddingRight: "12px",
                           }}
                        >
                           {sale?.customerEmail}
                        </p>
                     </Grid>

                     <Grid item xs={6}>
                        <p>
                           {sale?.customerMobile
                              .replace(/(\d{2})/g, "$1 ")
                              .replace(/(^\s+|\s+$)/, "")}
                        </p>
                     </Grid>

                     <Grid item xs={12}>
                        <p className="smallingrey">
                           {t("recapPage.consoLocation")}
                        </p>
                        <AddressDisplay address={sale?.PDLInfos} isRecap />
                     </Grid>

                     <Grid item xs={12}>
                        <p className="smallingrey">
                           {t("recapPage.facturLocation")}
                        </p>
                        <AddressDisplay
                           address={sale?.billingAddress}
                           isRecap
                        />
                     </Grid>

                     <Grid item xs={12}>
                        <h3 className="green smallmarginbottom">
                           {t("recapPage.caracteristicsTilte")}
                        </h3>
                        <p className="small">
                           {t("recapPage.caracteristicsText")}
                        </p>
                     </Grid>

                     <Grid item xs={5}>
                        <p className="smallingrey">{t("recapPage.refPDL")}</p>
                        <p className="smallmargin marginbottom12">
                           {sale?.PDLnumber.replace(/(\d{2})/g, "$1 ").replace(
                              /(^\s+|\s+$)/,
                              ""
                           )}
                        </p>
                     </Grid>

                     <Grid item xs={5}>
                        <p className="smallingrey">{t("recapPage.PS")}</p>
                        <p className="smallmargin marginbottom12">
                           {sale?.PDLInfos?.contractPowerMax?.valeur}{" "}
                           {sale?.PDLInfos?.contractPowerMax?.unite === "K_VA"
                              ? "kVA"
                              : ""}
                        </p>
                     </Grid>

                     <Grid item xs={12}>
                        <p className="smallingrey">{t("recapPage.option")}</p>
                        <p className="smallmargin marginbottom12">
                           {sale?.optionTarifaireStorage?.name}
                        </p>
                     </Grid>

                     <Grid item xs={12}>
                        <p className="smallingrey">{t("recapPage.conso")}</p>

                        <p className="smallmargin marginbottom12">
                           {trueOrFalseDo(
                              sale?.PDLInfos?.estimatedCar?.carBase?.value,
                              () => {
                                 let conso =
                                    sale?.PDLInfos?.estimatedCar?.carBase
                                       ?.value;
                                 // eslint-disable-next-line no-lone-blocks
                                 {
                                    /* for (const [key, value] of Object?.entries(
                                    sale?.PDLInfos?.estimatedCar?.result
                                 )) {
                                    conso += value * 1;
                                 } */
                                 }
                                 return conso + " kWh";
                              },
                              () => {
                                 return "-- kWh";
                              }
                           )}
                        </p>
                     </Grid>

                     <Grid item xs={12}>
                        <h3 className="green marginbottom12">
                           {t("recapPage.contractTitle")}
                        </h3>
                        <p className="smallingrey">{t("recapPage.offer")}</p>

                        <p className="smallmargin marginbottom12">
                           {sale?.offerStorage?.name}
                        </p>
                        <p className="smallingrey">{t("recapPage.factur")}</p>
                        {sale?.consoMode === "SCHEDULED" ? (
                           <p className="smallmargin marginbottom12">
                              {t("recapPage.facturEstimee")}
                           </p>
                        ) : (
                           <p className="smallmargin marginbottom12">
                              {t("recapPage.facturReel")}
                           </p>
                        )}

                        <p className="smallingrey">{t("recapPage.paiement")}</p>
                        <p className="smallmargin marginbottom12">
                           {t("recapPage.paiementDefault")}
                        </p>

                        <p className="smallingrey">{t("recapPage.date")}</p>
                        <p className="smallmargin marginbottom12">
                           {moment(JSON.parse(sale?.debitingDate)).format("L")}
                        </p>
                     </Grid>

                     <Grid item xs={12}>
                        <h3 className="green marginbottom12">
                           {t("recapPage.sepaTitle")}
                        </h3>
                        <p className="smallingrey">{t("recapPage.creditor")}</p>
                        <p className="smallmargin marginbottom12">
                           {params?.creditor}
                        </p>
                        <p className="smallingrey">{t("recapPage.debtor")}</p>
                        <p className="smallmargin0bot">{`${sale?.givenHolderBank}`}</p>
                        <p className="smallmargin0top">
                           {t("recapPage.iban")} {sale?.iban}
                        </p>
                        <p className="small">{t("recapPage.ref")}</p>
                     </Grid>
                  </Grid>
               </div>
            </Grid>
         </Bloc>
         <ConfirmSub />
      </div>
   );
};

RecapBloc.propTypes = {};

export default RecapBloc;
