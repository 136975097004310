import React from "react";
import Button from "../Button/Button";
import { Grid } from "@mui/material";
import { ReactComponent as CrossSvg } from "../../core/assets/picto/cross.svg";
import "./deleteButton.scss";

const DeleteButton = ({ xs, onClick }) => {
  return (
    <Grid className="deleteButton-container" item xs={xs}>
      <Button className="deleteButton" onClick={() => onClick()}>
        <CrossSvg />
      </Button>
    </Grid>
  );
};

export default DeleteButton;
