import i18n from "i18next";
import { regularMobilePhoneToInternational, trueOrFalseDo } from ".";

export function preventUndefinedString(params) {
   return params !== undefined ? params : "";
}

export function createTripicaOrderWithSMS(preSMSorder, SMS) {
   // let CAR = trueOrFalseDo(
   //    sale?.PDLInfos?.estimatedCar?.result,
   //    () => {
   //       let conso = 0;
   //       for (const [key, value] of Object?.entries(
   //          sale?.PDLInfos?.estimatedCar?.result
   //       )) {
   //          conso += value * 1;
   //       }
   //       return conso;
   //    },
   //    () => {
   //       return 0;
   //    }
   // );
   // const gender =
   //    data.gender === i18n.t("souscription.id.MALE") ? "MALE" : "FEMALE";

   // function formatDate(date) {
   //    return new Date(date).getTime();
   // }

   // const street1 = sale?.billingAddress?.housenumberAndStreet
   //    .trim()
   //    .split(" ")[0];
   // const street2 = sale?.billingAddress?.housenumberAndStreet
   //    .trim()
   //    .replace(street1, "")
   //    .trim();

   return {
      ...preSMSorder,
      // state: "ACKNOWLEDGED",
      // planOrderComponent: {
      //    planName: sale.offerStorage.productNumber,
      // },
      // offerOrderComponent: {
      //    offerName: sale.offerStorage.productNumber,
      //    vendorType: "PRIMEO",
      //    vendorName: "PRIMEO",
      //    turpeIncluded: true,
      //    formuleTarifaireAcheminementCode:
      //       data.PDLInfos.formuleTarifaireAcheminement.code, // ???
      //    calendrierFrnCode: data.PDLInfos.calendrierFrn.code,
      //    puissanceMaximumSouscrite: data.PDLInfos.contractPowerMax.valeur,
      // },
      // offerOptionOrderComponent: {
      //    optionName: sale.optionTarifaireStorage.productNumber,
      //    indexName: data.currentOffer.selectTarif, // ???
      // },
      // loginOrderComponent: {
      //    email: data?.customerEmail, // ???
      // },
      // individualOrderComponent: {
      //    familyName: data.familyName,
      //    givenName: data.givenName,
      //    fullName: data.givenName + " " + data.familyName,
      //    gender: gender,
      //    birthdate: formatDate(data?.birthdate),
      //    nationality: "FRA",
      // },
      // contactNumberOrderComponent: {
      //    mobile: regularMobilePhoneToInternational(
      //       "+33",
      //       "0",
      //       data.customerMobile
      //    ),
      // },
      // contactAddressOrderComponent: {
      //    street1: sale?.billingAddress?.housenumberAndStreet.split(" ", 2)[0],
      //    street2: sale?.billingAddress?.housenumberAndStreet.split(" ", 2)[1],
      //    city: data.billingAddress.city,
      //    country: "France",
      //    postCode: data.billingAddress.zipCode,
      // },
      // pointDeLivraisonOrderComponent: {
      //    customerFullName: data.givenName + " " + data.familyName,
      //    deliveryAddress: {
      //       street:
      //          data.PDLInfos.deliveryAddress?.housenumber +
      //          " " +
      //          data.PDLInfos.deliveryAddress?.street,
      //       additionnalAddress:
      //          data.PDLInfos.deliveryAddress?.additionnalAddress,
      //       city: data.PDLInfos.deliveryAddress.city,
      //       inseeCode: data.PDLInfos.deliveryAddress.inseeCode,
      //       zipCode: data.PDLInfos.deliveryAddress.zipCode,
      //       additionnalAddress2:
      //          data.PDLInfos.deliveryAddress.additionnalAddress2 || "",
      //    },
      //    car: CAR,
      //    pdlId: data.PDLInfos.pdlId,
      //    autoReleveBase: "1000",
      //    autoReleveHP: "1000",
      //    autoReleveHC: "1000",
      //    activationDate: "1656626400000",
      //    pointDonneesGenerales: sale?.PDLInfos?.pointDonneesGenerales,
      // },
      // souscriptionOrderComponent: {
      //    denyRightOfWithdrawal: true,
      // },
      // bankAccountPaymentMeanOrderComponent: {
      //    bankName: data.payment?.bankName || "string",
      //    iban: data.iban,
      //    bic: data.payment?.bic || "string",
      //    holder: data.givenName + " " + data.familyName,
      //    country: data.iban.split("")[0] + data.iban.split("")[1],
      //    city: data.payment?.city || "string",
      //    postCode: data.payment?.postCode || "string",
      //    street: data.payment?.street || "string",
      // },
      characteristics: {
         validationCode: SMS,
      },
   };
}
