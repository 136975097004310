/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
   checkPDLValue,
   extractFirstNumbers,
   replaceFirstNumbers,
   trueOrFalseDo,
} from "../../utils";
import { Alert, Grid } from "@mui/material";
import Bloc from "../Bloc/Bloc";
import BarreButton from "../BarreButton/BarreButton";
import TextInput from "../TextInput/TextInput";
import Button from "../Button/Button";
import AddressDisplay from "../Address/AddressDisplay/AddressDisplay";
import * as saleStore from "../../core/stores/saleStore";
import * as paramsStore from "../../core/stores/paramsStore";
import {
   getCatalog,
   getParametrage,
   postDeliveryPoint,
} from "../../core/services/httpRequest/axios";
import moment from "moment";
import PDLSGTError from "../ErrorCodeList/PDLSGTError";
import { sendNotif } from "../../core/stores/utils";
import Notifaction from "../BOCO/Notification/Notifaction";
import Loader from "../Loader/Loader";

const PDLBloc = ({ open, setOpen, resetErrors }) => {
   const [checkPDL, setCheckPDL] = useState(false);
   const [showPDLAddress, setshowPDLAddress] = useState(false);
   const [CAR, setCAR] = useState(undefined);
   const [isPDLNumberactive, setIsPDLNumberactive] = useState(false);
   const [loading, setLoading] = useState(false);
   const [niveauOuvertureServices, setNiveauOuvertureServices] = useState(false);

   const sale = useSelector((state) => state.sale);
   const params = useSelector((state) => state.params);
   const broker = useSelector((state) => state.auth.broker);

   const { t } = useTranslation();
   const dispatch = useDispatch();

   const {
      watch,
      getValues,
      setValue,
      control,
      formState: { errors },
   } = useFormContext();

   function tooglePDLNumber() {
      return getValues("attestatOccupation") === t("souscription.pdl.yes")
         ? setIsPDLNumberactive(true)
         : setIsPDLNumberactive(false);
   }

   function preventPDLErrorsOnRefresh() {
      if (getValues("PDLnumber") === sale?.PDLInfos?.pdlId) return true;
      else {
         return false;
      }
   }

   function canSearchPDL(){
      let identityBlocIsComplete = sale?.identityCheck 
      return ((checkPDLValue(getValues("PDLnumber")) && identityBlocIsComplete))
   }

   function searchPDL() {
      resetErrors();
      setLoading(true);
      let pdlId = getValues("PDLnumber");

      postDeliveryPoint({
         pdlId : pdlId,
         nom: sale?.givenName,
         prenom: sale?.familyName,
         dateNaissance: sale?.birthdate,
         email : sale?.customerEmail,
         numeroTel: sale?.customerMobile,
         authCollectDonnes : true,
      })
         .then((res) => {
            // SGT ERROR CODE INTERCEPTOR
           
            if (res.status === 500) {
               dispatch(paramsStore.update(sendNotif(res.status)));
               dispatch(
                  paramsStore.update({
                     target: "SGT_CODE",
                     value: res.data.errorCodeList,
                  })
               );
            }
            setNiveauOuvertureServices(false)
            if (res.status === 406) {
               setNiveauOuvertureServices(true)
            }

            // END

            let data = res.data;


            let formated = {
               city: data?.deliveryAddress?.city,
               zipCode: data?.deliveryAddress?.zipCode,
               additionnalAddress: data?.deliveryAddress.additionnalAddress,
               additionnalAddress2: data?.deliveryAddress.additionnalAddress2,
               inseeCode: data?.deliveryAddress?.inseeCode,
               street: replaceFirstNumbers(
                  data?.deliveryAddress?.street
               ).trim(),
               housenumber: extractFirstNumbers(data?.deliveryAddress?.street),
               housenumberAndStreet: data?.deliveryAddress?.street,
            };

            data.deliveryAddress = formated;

            setCheckPDL(true);
            dispatch(saleStore.update({ target: "PDLCheck", value: true}));
            dispatch(
               saleStore.update({
                  target: "PDLInfos",
                  value: {
                     ...data,
                     deliveryAddress: formated,
                  },
               })
            );
            dispatch(
               saleStore.update({
                  target: "timeStamp",
                  value: JSON.stringify(new Date()),
               })
            );
            dispatch(
               saleStore.update({
                  target: "timeStampToString",
                  value:
                     moment().format("dddd") +
                     " " +
                     moment().format("LL") +
                     " à " +
                     moment().format("LT"),
               })
            );
            dispatch(
               saleStore.update({
                  target: "forceFacturationReele",
                  value: false,
               })
            );
            // ICI je vide la currentOffer et les data du form concernant l'offre sélectionnée
            dispatch(saleStore.update({ target: "currentOffer", value: {} }));
            dispatch(saleStore.update({ target: "offerStorage", value: {} }));
            dispatch(
               saleStore.update({
                  target: "optionTarifaireStorage",
                  value: {},
               })
            );
            dispatch(saleStore.update({ target: "optionStorage", value: [] }));
            paramsStore.update({
               target: "offers",
               value: [],
            });
            setValue("currentOffer.selectOffer", null);
            setValue("currentOffer.selectTarif", null);
            setValue("PDLInfos", data);

            const checkValueCarBaseType = data?.estimatedCar?.carBase;

            trueOrFalseDo(
               !!checkValueCarBaseType?.value > 0 &&
               !!checkValueCarBaseType?.type &&
               checkValueCarBaseType?.type !== "TABLE_REF",
               () => {
                  dispatch(
                     saleStore.update({
                        target: "forceFacturationReele",
                        value: false,
                     })
                  );
                  setCAR(data?.estimatedCar?.carBase?.value);
               },
               () => {
                  dispatch(
                     saleStore.update({
                        target: "forceFacturationReele",
                        value: true,
                     })
                  );
                  if (!!data?.estimatedCar?.carBase?.value > 0) {
                     return setCAR(data?.estimatedCar?.carBase?.value);
                  } else {
                     return setCAR("--");
                  }
               }
            );
            setshowPDLAddress(true);
         })
         .catch((err) => {
            // TODO
            setshowPDLAddress(false);
            setCAR(undefined);
         })
         .finally(() => {
            setLoading(false);
         });
   }

   useEffect(() => {
      // ICI, peut etre un trux à faire surle reload ?
      if (sale?.PDLCheck) {
         setCheckPDL(sale?.PDLCheck);
         setshowPDLAddress(true);

         trueOrFalseDo(
            sale?.PDLInfos?.estimatedCar?.carBase?.value,
            () => {
               let conso = 0;
               for (const [key, value] of Object?.entries(
                  sale?.PDLInfos?.estimatedCar?.carBase?.value
               )) {
                  conso += value * 1;
               }
               setCAR(conso);
            },
            () => {
               setCAR("--");
            }
         );
      }
   }, []);

   useEffect(() => {
      tooglePDLNumber();
      if (getValues("attestatOccupation") === t("souscription.pdl.no")) {
         setshowPDLAddress(false);
         setCheckPDL(false);
         setCAR(undefined);
      }
   }, [watch("attestatOccupation")]);

   useEffect(() => {
      tooglePDLNumber();
      if (!preventPDLErrorsOnRefresh()) {
         setshowPDLAddress(false);
         setCheckPDL(false);
         setCAR(undefined);
      }
   }, [watch("PDLnumber")]);

   useEffect(() => {
      if (!isNaN(CAR)) {
         // ICI get le catalogue
         getCatalog(CAR).then((res) => {
            let resp = res.data?.offers;
            return dispatch(
               paramsStore.update({
                  target: "offers",
                  value: resp,
               })
            );
         });
      } else if (isNaN(CAR)) {
         getCatalog(0).then((res) => {
            let resp = res.data?.offers;
            return dispatch(
               paramsStore.update({
                  target: "offers",
                  value: resp,
               })
            );
         });
      }
   }, [CAR]);

   //   if there's note admin

   useEffect(() => {
      if (broker?.idSocieteCourtage) {
         const fieldsToSet = [
            "PDL",
            "IDENTITY",
            "OFFER_AND_OPTIONS",
            "BILLING",
            "PAYMENT",
            "SUBSCRIPTION_VALIDATION",
         ];
         const note = [];
         getParametrage(broker?.idSocieteCourtage).then((res) => {
            fieldsToSet.forEach((field) => {
               note?.push({ word: field, value: res?.data?.[field] });
            });

            dispatch(
               paramsStore.update({
                  target: "noteAdmin",
                  value: note,
               })
            );
         });
      }
   }, [broker]);

   return (
      <Bloc
         icon={"pdl"}
         title={t("souscription.pdl.title")}
         note={params?.noteAdmin?.filter((v) => v.word === "PDL")[0]?.value}
         checked={checkPDL}
         id="pdl"
         info={
            !!checkPDL
               ? `${CAR || sale?.PDLInfos?.estimatedCar?.carBase?.value
               }${" "}kWh`
               : null
         }
         open={open}
         setIsOpen={setOpen}
      >
         <Notifaction />
         <p>{t("souscription.pdl.text")}</p>

         <Controller
            name="attestatOccupation"
            control={control}
            rules={{ required: t("error.required") }}
            render={({ field: { value, onChange, onBlur } }) => (
               <BarreButton
                  name="attestatOccupation"
                  value={value}
                  labelA={t("souscription.pdl.yes")}
                  labelB={t("souscription.pdl.no")}
                  onChange={(v) => {
                     onChange(v);
                     dispatch(
                        saleStore.update({
                           target: "attestatOccupation",
                           value: v,
                        })
                     );
                  }}
                  error={errors.attestatOccupation}
                  onBlur={(v) => {
                     dispatch(
                        saleStore.update({
                           target: "attestatOccupation",
                           value: value,
                        })
                     );
                     onBlur(v);
                  }}
               />
            )}
         />
         <Grid container spacing={3} style={{ marginTop: 0 }}>
            <Grid item xs={12} sm={8} md={8} lg={8}>
               <Controller
                  name="PDLnumber"
                  control={control}
                  rules={{
                     required: t("error.required"),
                     validate: checkPDLValue,
                  }}
                  render={({ field: { value, onChange, onBlur } }) => (
                     <TextInput
                        onFocus={() =>
                           dispatch(
                              paramsStore.update({
                                 target: "SGT_CODE",
                                 value: "",
                              })
                           )
                        }
                        value={value}
                        type="tel"
                        onChange={onChange}
                        label={t("souscription.pdl.placeholder")}
                        error={errors.PDLnumber !== undefined}
                        helperText={errors.PDLnumber?.message}
                        required
                        disabled={!isPDLNumberactive}
                        autoComplete="off"
                        maxLength={14}
                        onBlur={(v) => {
                           dispatch(
                              saleStore.update({
                                 target: "PDLnumber",
                                 value: value,
                              })
                           );
                           onBlur(v);
                        }}
                     />
                  )}
               />
            </Grid>
            <Grid
               item
               xs={12}
               sm={4}
               style={{
                  display: "flex",
                  champyContent: "end",
                  alignItems: "center",
               }}
            >
               <Button
                  disabled={!canSearchPDL()}
                  className={"blue"}
                  onClick={searchPDL}
               >
                  {t("actions.search")}
               </Button>
            </Grid>
            <Grid
               item
               xs={12}
               sm={12}
               style={{
                  display: "flex",
                  champyContent: "end",
                  alignItems: "center",
               }}
            >
               {niveauOuvertureServices && (<Alert style={{"width":"100%"}} severity="error">Ce PDL n'est pas éligible aux offres de Primeo Energie.</Alert>)}
            </Grid>
            <PDLSGTError errorCode={params?.SGT_CODE} />
         </Grid>

         {loading && (
            <div
               style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
               }}
            >
               <Loader />
            </div>
         )}
         {!loading && showPDLAddress && !niveauOuvertureServices && (
            <AddressDisplay address={sale?.PDLInfos} isPDL loading={loading} />
         )}
      </Bloc>
   );
};

export default PDLBloc;
