import React from "react";

const WaitingSvg = ({ fill }) => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"></svg>
        <path
          d="M8.12488 20.275H15.8749V17.325C15.8749 16.2083 15.504 15.2542 14.7624 14.4625C14.0207 13.6708 13.0999 13.275 11.9999 13.275C10.8999 13.275 9.97904 13.6708 9.23738 14.4625C8.49571 15.2542 8.12488 16.2083 8.12488 17.325V20.275ZM11.9999 10.725C13.0999 10.725 14.0207 10.3292 14.7624 9.53751C15.504 8.74584 15.8749 7.78334 15.8749 6.65001V3.72501H8.12488V6.65001C8.12488 7.78334 8.49571 8.74584 9.23738 9.53751C9.97904 10.3292 10.8999 10.725 11.9999 10.725ZM3.37488 22.625V20.275H5.77488V17.325C5.77488 16.2083 6.04154 15.1667 6.57488 14.2C7.10821 13.2333 7.86654 12.5 8.84988 12C7.86654 11.4833 7.10821 10.7417 6.57488 9.77501C6.04154 8.80834 5.77488 7.76667 5.77488 6.65001V3.72501H3.37488V1.35001H20.6499V3.72501H18.2499V6.65001C18.2499 7.76667 17.9749 8.80834 17.4249 9.77501C16.8749 10.7417 16.1249 11.4833 15.1749 12C16.1415 12.5 16.8957 13.2333 17.4374 14.2C17.979 15.1667 18.2499 16.2083 18.2499 17.325V20.275H20.6499V22.625H3.37488Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default WaitingSvg;
