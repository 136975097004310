/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "@mui/material";
import PopinAddUser from "../../../components/BOCO/PopinAddUser/PopinAddUser";
import PopinModifyUser from "../../../components/BOCO/PopinModifyUser/PopinModifyUser";
import Button from "../../../components/Button/Button";
import ComboBoxObject from "../../../components/ComboBox/ComboBoxObject";
import AuthorizationLayout from "../../../components/HOC/AuthorizationLayout/AuthorizationLayout";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  customRequest,
  getUserDetails,
  getUsers,
  putStatuts,
  putUser,
} from "../../../core/services/httpRequest/axios";
import UsersTable from "../../../components/BOCO/TableComponents/UsersTable/UsersTable";
import { ReactComponent as Modify } from "../../../core/assets/picto/modify.svg";
import { ReactComponent as Active } from "../../../core/assets/picto/active.svg";
import { ReactComponent as Inactive } from "../../../core/assets/picto/inactive.svg";
import { ReactComponent as Swap } from "../../../core/assets/picto/swap.svg";
import { ReactComponent as Save } from "../../../core/assets/picto/save.svg";
import { ReactComponent as Close } from "../../../core/assets/picto/close.svg";
import { ReactComponent as Dwnld } from "../../../core/assets/picto/dwnld.svg";
import ArrowDownSvg from "../../../components/SvgComponents/ArrowDownSvg";
import ArrowUpSvg from "../../../components/SvgComponents/ArrowUpSvg";
import FilterSvg from "../../../components/SvgComponents/FilterSvg";
import _ from "lodash";
import "./styles/utilisateurs.scss";
import PopinTransferRespo from "../../../components/BOCO/PopinTransferRespo/PopinTransferRespo";
import Notifaction from "../../../components/BOCO/Notification/Notifaction";
import { update } from "../../../core/stores/paramsStore";
import { sendNotif } from "../../../core/stores/utils";
import handleRequestResponse from "../../../utils/responsesHandler";
import { trueOrFalseDo } from "../../../utils";
import PermissionWrapper from "../../../components/HOC/PermissionWrapper";
import { download_csv } from "../../../components/BOCO/CSVContainers/utils";

const Utilisateurs = () => {
  const [authorization, setAuthorization] = useState("");
  const [showAddUser, setShowAddUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [listSelectedUser, setListSelectedUser] = useState([]);
  const [showModifyUser, setShowModifyUser] = useState(false);
  const [loadingOnModal, setloadingOnModal] = useState(false);
  const [toggleFilters, setToggleFilters] = useState(false);
  const [showToolTip, setshowToolTip] = useState(false);
  const [selectedSociete, setselectedSociete] = useState(undefined);
  const [selectedStatut, setselectedStatut] = useState(undefined);
  const [selectedTypeVendeur, setselectedTypeVendeur] = useState(undefined);
  const [rows, setRows] = useState([]);
  const [societe, setSociete] = useState([]);
  const [transferIsDisabled, settransferIsDisabled] = useState(false);
  const [openTransferMass, setOpenTransferMass] = useState(false);
  const [listTranferMass, setListTranferMass] = useState([]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const authorizations = useSelector((state) => state?.params?.authorizations);
  const currentUser = useSelector((state) => state.auth.broker);

  const notif = useSelector((state) => state?.params?.notif);

  const headCells = [
    {
      id: "nom",
      numeric: false,
      disablePadding: true,
      label: t("users.lastname"),
    },
    {
      id: "prenom",
      numeric: false,
      disablePadding: true,
      label: t("users.name"),
    },
    {
      id: "nomSociete",
      numeric: false,
      disablePadding: true,
      label: t("users.society"),
    },
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: t("users.email"),
    },
    {
      id: "nomResponsable",
      numeric: false,
      disablePadding: true,
      label: t("users.respo"),
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: t("users.type"),
    },
    {
      id: "statut",
      numeric: false,
      disablePadding: true,
      label: t("users.statut"),
    },
    {
      id: "statut",
      numeric: false,
      disablePadding: true,
      label: "",
    },
    {
      id: "statut",
      numeric: false,
      disablePadding: true,
      label: "",
    },
  ];

  useEffect(() => {
    setAuthorization(currentUser.type);
  }, [currentUser]);

  function transferVendeur(vendeurs, newRespo) {
    setloadingOnModal(true);
    let response = [];
    let names = vendeurs?.map((v) => v.prenom + " " + v.nom).join(", ");

    Promise.all(
      vendeurs?.map((v) => {
        let newUser = { ...v, idResponsable: newRespo };
        putUser(newUser).then((res) => {
          response.push(res?.status);
        });
      })
    ).finally(() => {
      setloadingOnModal(false);
    });

    return response?.every((r) => r === 200)
      ? dispatch(update(sendNotif("transferMass", names)))
      : dispatch(update(sendNotif(response.find((r) => r !== 200))));
  }

  function handleOpenTransfer() {
    setOpenTransferMass(true);
  }

  function resetFilters() {
    setselectedSociete(undefined);
    setselectedStatut(undefined);
    setselectedTypeVendeur(undefined);
    getUsers(undefined, undefined).then((res) => {
      setRows(res.data);
    });
  }

  function handleModifyUser(id) {
    getUserDetails(id)
      .then((res) => {
        let geo = !!res?.data?.exclusitiveGeo
          ? JSON.parse(res?.data?.exclusitiveGeo)
          : "";
        let tmpRes = {
          ...res?.data,
          exclusitiveGeo: geo,
        };

        setSelectedUser(tmpRes);
      })
      .catch((err) => console.log(err))
      .finally(() => setShowModifyUser(true));
  }

  function handleSuccess(resetFilters) {
    trueOrFalseDo(
      resetFilters,
      () => {
        setselectedSociete(undefined);
        setselectedStatut(undefined);

      },
      () => {
        return;
      }
    );

    setShowAddUser(false);
    setShowModifyUser(false);
    setListTranferMass([]);
    // reset table
    getUsers(undefined, undefined).then((res) => {
      setRows(res.data);
    });

  }

  useEffect(() => {
    if (notif?.action === "modifyUser") {
      setTimeout(() => {
        return applyFilters();
      }, 800);
    }
  }, [notif])


  function handleSuccessTransferPopin() {
    applyFilters();
  }

  function toogleToolTip(arg) {
    trueOrFalseDo(
      currentUser.type === "ADMIN_PRIMEO",
      () => {
        setshowToolTip(arg);
        const guard = () =>
          setTimeout(() => {
            return setshowToolTip(false);
          }, 5000);
        trueOrFalseDo(
          arg,
          () => {
            guard();
          },
          () => {
            clearTimeout(guard);
          }
        );
      },
      () => {
        return;
      }
    );
  }

  function exportUser () {
    customRequest.get("/users/csv").then((res) => {
      download_csv("user.csv", res.data);
    })
  }


  function modifyStatutMass(status) {
    let list = listSelectedUser?.map((u) => {
      return { idUtilisateur: u, statut: status };
    });
    let names = _.partition(rows, function (u) {
      return _.includes(listSelectedUser, u.idUtilisateur);
    })?.[0]
      ?.map((u) => u.prenom + " " + u.nom)
      ?.join(", ");
    let ordre = trueOrFalseDo(
      status === "ACTIF",
      () => {
        return "modifyMassUserStatusACTIF";
      },
      () => {
        return "modifyMassUserStatusINACTIF";
      }
    );

    putStatuts(_.uniq(list))
      .then((res) => {
        handleRequestResponse(
          res,
          () => {
            dispatch(update(sendNotif(ordre, names)));
          },
          () => {
            dispatch(update(sendNotif(res.data.status)));
          }
        );
      })
      .finally(() =>
        getUsers().then((res) => {
          return setRows(res.data);
        })
      );
  }

  function applyFilters() {
    getUsers(selectedSociete, selectedStatut, selectedTypeVendeur).then(
      (res) => {
        setRows(res.data);
      }
    );
  }



  useEffect(() => {
    getUsers().then((res) => {
      let businesses = _.uniqBy(res?.data, "idSocieteCourtage")
        ?.filter((val) => {
          return val.idSocieteCourtage;
        })
        ?.map((b) => {
          return {
            value: b.idSocieteCourtage,
            name: b.nomSociete,
          };
        });
      setSociete(businesses);
      return setRows(res?.data);
    });
  }, []);

  useEffect(() => {
    trueOrFalseDo(
      listSelectedUser?.length > 0,
      () => {
        let newList = [];
        rows?.forEach((r) => {
          if (_.includes(listSelectedUser, r.idUtilisateur)) {
            newList.push(r);
          }
        });

        trueOrFalseDo(
          newList?.every(
            (el) => el.idSocieteCourtage === newList[0].idSocieteCourtage
          ),
          () => {
            setListTranferMass(
              newList
                ?.filter((v) => v.type === "VENDEUR")
                ?.map((v) => {
                  return { ...v, isPicked: true };
                })
            );
          },
          () => {
            settransferIsDisabled(true);
            setListTranferMass([]);
          }
        );
      },
      () => {
        settransferIsDisabled(true);
        setListTranferMass([]);
      }
    );
  }, [listSelectedUser]);

  useEffect(() => {
    trueOrFalseDo(
      listTranferMass?.find((u) => u.type === "VENDEUR"),
      () => {
        settransferIsDisabled(false);
      },
      () => {
        settransferIsDisabled(true);
      }
    );
  }, [listTranferMass]);

  return (
    <>
      <Notifaction />
      <div className="utilisateurs_container">
        {/* Header */}
        <div className="utilisateurs_header">
          <div className="top">
            <div>
              <div className="left">
                <h2>{t("users.title")}</h2>
                <Button
                  className={
                    toggleFilters
                      ? "blue outline small"
                      : "blue outline small black"
                  }
                  onClick={() => setToggleFilters(!toggleFilters)}
                >
                  <FilterSvg fill={toggleFilters ? "#0ABBF0" : "#000"} />
                  <p>{t("users.filters")}</p>
                  {toggleFilters ? (
                    <ArrowUpSvg fill="black" />
                  ) : (
                    <ArrowDownSvg fill="black" />
                  )}
                </Button>
                <p
                  onClick={() => resetFilters()}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    margin: "0 12px 0 12px",
                  }}
                >
                  Effacer
                </p>
              </div>
            </div>
            <div className="cta">
              {/* modal modifier unitaire */}
              <PermissionWrapper requiredPermission={["UPDATE_SELLER"]}>
                <Modal
                  open={showModifyUser}
                  onClose={() => setShowModifyUser(false)}
                >
                  <>
                    <PopinModifyUser
                      onClose={() => setShowModifyUser(false)}
                      onSuccess={() => handleSuccess()}
                      onTransfer={transferVendeur}
                      onTransferSuccessPopin={() =>
                        handleSuccessTransferPopin()
                      }
                      selectedUser={selectedUser}
                      loading={loadingOnModal}
                      user={currentUser}
                      list={rows}
                    />
                  </>
                </Modal>
              </PermissionWrapper>
              {/* modal transfer mass */}
              <Modal
                open={openTransferMass}
                onClose={() => setOpenTransferMass(false)}
              >
                <>
                  <PopinTransferRespo
                    list={listTranferMass}
                    onClose={() => setOpenTransferMass(false)}
                    onTransfer={transferVendeur}
                    onSuccess={() => handleSuccess()}
                  />
                </>
              </Modal>
              <div className="button-list">
                <PermissionWrapper requiredPermission={["TOGGLE_SELLER"]}>
                  <Button
                    className="blue small square"
                    style={{ marginRight: "8px" }}
                    disabled={listSelectedUser.length <= 0}
                    onClick={() => modifyStatutMass("ACTIF")}
                    title="Activer"
                  >
                    <Active />
                  </Button>
                  <Button
                    className="blue small square"
                    style={{ marginRight: "8px" }}
                    disabled={listSelectedUser.length <= 0}
                    onClick={() => modifyStatutMass("INACTIF")}
                    title="Désactiver"
                  >
                    <Inactive />
                  </Button>
                </PermissionWrapper>
                <Button
                    className="blue small square"
                    style={{ marginRight: "8px" }}
                    onClick={() => exportUser()}
                    title="Export"
                  >
                    <Dwnld></Dwnld>
                </Button>
                <AuthorizationLayout
                  authorized={["ADMIN_PRIMEO", "ADMIN_COURTAGE"]}
                  current={currentUser?.type}
                >
                  <div
                    className="wrapper_tooltip"
                    onMouseEnter={() => toogleToolTip(true)}
                    onMouseLeave={() => toogleToolTip(false)}
                  >
                    <Button
                      className="blue small square"
                      style={{ marginRight: "8px" }}
                      disabled={transferIsDisabled}
                      onClick={() => handleOpenTransfer()}
                      title="Transférer"
                    >
                      <Swap />
                    </Button>
                    {showToolTip && transferIsDisabled && (
                      <div
                        className="wrapped_tooltip"
                        onMouseLeave={() => toogleToolTip(false)}
                      >
                        <p>{t("users.transferTooltip")}</p>
                      </div>
                    )}
                  </div>
                </AuthorizationLayout>
                {/* <Button
                           className="blue small square"
                           style={{ marginRight: "12px" }}
                           disabled={listSelectedUser.length <= 0}
                           onClick={() => console.log("Export")}
                           title="Export"
                        >
                           <Dwnld />
                        </Button> */}
              </div>
              {/* modal + cta ajouter */}
              <PermissionWrapper requiredPermission={["CREATE_SELLER"]}>
                <Button
                  onClick={() => setShowAddUser(true)}
                  className="green small"
                  style={{ marginLeft: "12px" }}
                >
                  {t("users.add")}
                </Button>
                <Modal open={showAddUser} onClose={() => setShowAddUser(false)}>
                  <>
                    <PopinAddUser
                      onClose={() => setShowAddUser(false)}
                      onSuccess={() => handleSuccess(true)}
                      user={currentUser}
                      list={rows}
                    />
                  </>
                </Modal>
              </PermissionWrapper>
            </div>
          </div>
          <p className="filter_infos">
            {t("users.users")} {t("users.active")} (
            {rows?.filter((r) => r.statut === "ACTIF")?.length}) -{" "}
            {t("users.inactive")} (
            {rows?.filter((r) => r.statut === "INACTIF")?.length})
          </p>
          {toggleFilters && (
            <div className="utilisateurs_header_filters">
              {authorization === authorizations[0] && (
                <div>
                  <ComboBoxObject
                    label={t("users.societies")}
                    list={societe}
                    value={selectedSociete}
                    canBeCanceled={true}
                    onChange={(e) => {
                      return setselectedSociete(e);
                    }}
                  />
                </div>
              )}

              <div>
                <ComboBoxObject
                  label={t("users.statut")}
                  canBeCanceled={true}
                  list={[
                    { value: "ACTIF", name: "Actif" },
                    { value: "INACTIF", name: "Inactif" },
                  ]}
                  value={selectedStatut}
                  onChange={(e) => {
                    setselectedStatut(e);
                  }}
                />
              </div>
              {
                currentUser?.type !== "CHARGE_CLIENT" &&
              <div>
                <ComboBoxObject
                  label={t("users.typeVendeur")}
                  canBeCanceled={true}
                  list={[
                    { value: "REMOTE", name: t("adduser.remote") },
                    {
                      value: "DOOR_TO_DOOR",
                      name: t("adduser.door2door"),
                    },
                  ]}
                  value={selectedTypeVendeur}
                  onChange={(e) => {
                    setselectedTypeVendeur(e);
                  }}
                />
              </div>
              }
              <div>
                <Button className="blue" onClick={() => applyFilters()}>
                  {t("users.filter")}
                </Button>
              </div>
            </div>
          )}
        </div>
        {/* Table */}
        <div
          className={`utilisateurs_content_wrapper ${toggleFilters && "filterShow"
            }`}
        >
          {/* <div className="scroller-h-wrapper">
          <div className="scroller-h-track">
            <div className="scroller-h-bar" />
          </div>
        </div> */}
          <div className="utilisateurs_content">
            <UsersTable
              headCells={headCells}
              rows={rows}
              actions={{
                modify: {
                  fnc: handleModifyUser,
                  component: (
                    <PermissionWrapper requiredPermission={["UPDATE_SELLER"]}>
                      <AuthorizationLayout
                        current={authorization}
                        authorized={useMemo(() => {
                          return [
                            authorizations[0],
                            authorizations[1],
                            authorizations[2],
                          ];
                        }, [authorizations])}
                      >
                        <Modify />
                      </AuthorizationLayout>
                    </PermissionWrapper>
                  ),
                },
                close: {
                  fnc: "x",
                  component: <Close />,
                },
                save: {
                  fnc: "x",
                  component: <Save />,
                },
              }}
              editInRow
              collect={(l) => setListSelectedUser(l)}
              onSuccess={() =>
                getUsers().then((res) => {
                  setselectedSociete(undefined);
                  setselectedStatut(undefined);
                  return setRows(res.data);
                })
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Utilisateurs;
