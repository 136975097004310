import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button/Button";

const Step2 = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="container_step">
      <h1>{t("password.step2")}</h1>
      <p className="blue">{t("password.step2sub")}</p>
      <p className="blue">{t("password.step1info1")}</p>
      <p className="blue">{t("password.step1info2")}</p>
      <div className="container_btn">
        <Button
          className="green outline"
          style={{ margin: "0 auto" }}
          onClick={() => history.push("/auth")}
        >
          {t("password.back")}
        </Button>
      </div>
    </div>
  );
};

export default Step2;
