import React, { useState } from "react";
import { useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Button from "../../../Button/Button";
import { menuUser } from "../../../../utils/BOCO/enums";

// Liste svg
import { ReactComponent as DecoSvg } from "../../../../core/assets/picto/deco.svg";
import { ReactComponent as MdpSvg } from "../../../../core/assets/picto/mdp.svg";
import { ReactComponent as UserSvg } from "../../../../core/assets/picto/user.svg";

import { useHistory } from "react-router-dom";

import "./menu.scss";

const MenuRight = () => {
   const history = useHistory();
   const [open, setOpen] = useState(null);
   const isOpen = Boolean(open);

   const currentUser = useSelector((state) => state.auth.broker);

   const handleClick = (event) => {
      setOpen(event.currentTarget);
   };

   const handleMenuItemClick = (event, link) => {
      if (!!link) {
         history.push(link);
         setOpen(null);
      }
   };
   const handleClose = () => {
      setOpen(null);
   };

   return (
      <div className="menu_right">
         <Button
            className={`green btn_userRight ${isOpen ? "open" : "closed"}`}
            onClick={handleClick}
         >
            <UserSvg />
         </Button>
         <Menu
            id="menuRight"
            MenuListProps={{
               "aria-labelledby": "long-button",
            }}
            anchorEl={open}
            open={isOpen}
            onClose={handleClose}
         >
            {menuUser.map((option) => (
               <MenuItem
                  key={option.label}
                  onClick={(event) => handleMenuItemClick(event, option?.link)}
               >
                  <span className="icon">
                     {(() => {
                        switch (option.icon) {
                           case "mdp":
                              return <MdpSvg />;
                           case "deco":
                              return <DecoSvg />;

                           default:
                              return null;
                        }
                     })()}
                  </span>
                  <p onClick={() => option?.action(currentUser.email)}>
                     {option.label}
                  </p>
               </MenuItem>
            ))}
         </Menu>
      </div>
   );
};

export default MenuRight;
