/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ComboBoxObject from "../../ComboBox/ComboBoxObject";
import TextInput from "../../TextInput/TextInput";
import Button from "../../Button/Button";
import { Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  getListSociete,
  getParametrage,
  getSocieteDetails,
  postParametrage,
  postParamsOfSociete,
} from "../../../core/services/httpRequest/axios";
import { autoCompleteAlea, checkEnterKeyDown } from "../../../utils";
import handleRequestResponse from "../../../utils/responsesHandler";
import { useDispatch, useSelector } from "react-redux";
import paramsStore, { setMaintenance, update } from "../../../core/stores/paramsStore";
import { sendNotif } from "../../../core/stores/utils";
import Notifaction from "../Notification/Notifaction";
import CheckboxButton from "../../Checkbox/CheckboxButton";

const ParametrageGlobal = ({}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useSelector((state) => state.params);

  const {
    handleSubmit,
    setValue,
    resetField,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
    // defaultValues: { ...selectedSociete },
  });

  async function submit(data) {
    dispatch(update(sendNotif("createAdminParam")));
    dispatch(setMaintenance(data.MAINTENANCE_TCO === true ? "MAINTENANCE" : "AVAILABLE"));
  }

  function reset() {
    setValue("MAINTENANCE_TCO", false);
  }

  useEffect(() => {
  }, []);

  return (
    <div>
      <h3 style={{ margin: "0 0 12px" }}>Parametrage global</h3>
      <form
        onSubmit={handleSubmit(submit)}
        onKeyDown={(e) => checkEnterKeyDown(e)}
        noValidate
        autoComplete="off"
        className="form_parametrage_global"
      >
        <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            <Controller
              control={control}
              name="MAINTENANCE_TCO"
              defaultValue={params.maintenanceStatus === "MAINTENANCE"}
              render={({ field: { onChange, value } })  => (
                <CheckboxButton
                text="Bloquer la création de ventes"
                //    className="gray"
                onChange={(e) => {
                  onChange(e);
                }}
               value={value}
                checked={value}
              />
              )}
            />
            </Grid>
          <div
            style={{
              width: "100%",
              margin: "20px auto 0",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              className={"green"}
              type="submit"
              style={{ marginRight: "6px" }}
            >
              {t("parametrage.submit")}
            </Button>
            <Button
              className={"red"}
              style={{ marginLeft: "6px" }}
              onClick={reset}
            >
              {t("parametrage.cancel")}
            </Button>
          </div>
        </Grid>
      </form>
    </div>
  );
};

export default ParametrageGlobal;
