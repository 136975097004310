import _ from "lodash";

const ERROR_CODE_LIST = [
   // check SMS
   "BAD_ORDER_VALIDATION_CODE",
   // PDL
   "PERIOD_NOT_SUFFICIENT",
   // check catalogue
   "WRONG_%%_VALUE",
   "NOT_EXPECTED_%%",
   "NO_%%",
   "NOT_AUTHORIZED_CHARAC_ORDER",
   "NOT_AUTHORIZED_%%_CHARAC_ORDER",
   "%1%_%2%_BAD_FORMATTED",
   "%%_LENGTH_OUT_OF_RANGE",
   "%%_VALUE_OUT_OF_RANGE",
   "BAD_FORMAT_%%_%%",
   "RETIRED_OFFER_%%",
   "DEPENDENCY_PB",
   "PRICE_PB",
   // checks core
   "EMAIL_ALREADY_USED",
   "DISABLED_LOGIN",
   "EMPTY_PRODUCT_ORDER",
   "MANY_TIME_SAME_PRODUCT_IN_ORDER",
   "NO_PASSWORD",
   "PASSWORD_STRENGTH_TOO_LOW",
   // check presence produit
   "NO_SUBSCRIPTION",
   "NO_PLAN",
   "NO_PRIMARY_RESOURCE",
   "NO_CAR",
   "MISSING_LOGIN_PRODUCT",
   // check PDL
   "PDL_ALREADY_IN_ACTIVATION",
   "ACTIVATION_DATE_NOT_AT_MIDNIGHT",
   "ACTIVATION_DATE_OUT_OF_RANGE",
   "ETAT_CONTRACTUEL_%%_NOT_VALID_FOR_EVENT_%%",
   "MISSING_URGENT",
   "MISSING_RELEVE_SPECIAL",
   "MISSING_CORRECTION",
   "MISSING_AUTORELEVE",
   "AUTORELEVE_FTA_INCONSISTENCY",
   "MISSING_MOTIF_CORRECTION_CODE",
   "WRONG_%%_ENEDIS",
   "NON_COMMUNICATING_METER",
   // check IBAN
   "UNAUTHORIZED_IBAN_COUNTRY_CODE",
   "WRONG_IBAN_LENGTH",
   "INVALID_IBAN_KEY",
   // check individu
   "MISSING_INDIVIDUAL_PRODUCT",
   "BAD_INDIVIDUAL_STATUS",
   // check RIB
   "MISSING_ADVANCE_PRODUCT",
   "ADVANCE_PRODUCT_PRICE_NOT_FOUND",
   // check mensualité
   "NEXT_RENEWAL_OUT_RANGE",
   "INVALID_MONTHLY_PAYMENT_AMOUNT"
];
const DYNAMIC_MASKS = ["%%", "%1%", "%2%"];

export function analyseErrorCodes(err) {
   let codesToTest = ERROR_CODE_LIST?.map((e) =>
      e?.split("_")?.filter((elt) => !_?.includes(DYNAMIC_MASKS, elt))
   );
   let output = codesToTest?.find(
      (error) =>
         JSON.stringify(_.intersection(error, err?.split("_"))) ===
         JSON.stringify(error)
   );
   if (!!output === true) {
      return output?.join("_");
   }
}
