/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import PopinAddSocietes from "../../../components/BOCO/PopinAddSociete/PopinAddSocietes";
import Button from "../../../components/Button/Button";
import { ReactComponent as Active } from "../../../core/assets/picto/active.svg";
import { ReactComponent as Inactive } from "../../../core/assets/picto/inactive.svg";
import { useTranslation } from "react-i18next";
import {
  getUsers,
  getListSociete,
  getSocieteDetails,
  putSociete,
} from "../../../core/services/httpRequest/axios";
import "./styles/societes.scss";
import SocieteTable from "../../../components/BOCO/TableComponents/SocieteTable/SocieteTable";
import * as addsocieteStore from "../../../core/stores/addSocieteStore";
import { useDispatch } from "react-redux";
import { update } from "../../../core/stores/paramsStore";
import { sendNotif } from "../../../core/stores/utils";
import Notifaction from "../../../components/BOCO/Notification/Notifaction";
import _ from "lodash";

const Societes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedSociete, setSelectedSociete] = useState({});
  const [showAddSociete, setShowAddSociete] = useState(false);
  const [showModifySociete, setShowModifySociete] = useState(false);
  const [rows, setRows] = useState([]);
  const [listSelectedSociete, setlistSelectedSociete] = useState([]);

  const [listAdminCourtage, setListAdminCourtage] = useState();

  function handleModifySociete(id) {
    getSocieteDetails(id)
      .then((res) => {
        let geo = JSON.parse(res?.data?.societe.exclusiviteGeo);

        let tmpRes = { ...res?.data.societe };
        tmpRes = { ...tmpRes, exclusiviteGeo: geo };

        for (let i = 0; i < res?.data.listRemunerationParProfil.length; i++) {
          let profil = {
            profil: res?.data.listRemunerationParProfil[i].profil,
            ratio: res?.data.listRemunerationParProfil[i].ratio,
          };
          tmpRes = { ...tmpRes, [`ratio-${profil.profil}`]: profil };
        }

        for (let i = 0; i < res?.data.listRemunerationParProduit.length; i++) {
          let produits = {
            produit: res?.data.listRemunerationParProduit[i].produit,
            remuneration: res?.data.listRemunerationParProduit[i].remuneration,
          };
          tmpRes = { ...tmpRes, [`produitRemuneration${i}`]: produits };
        }
        for (
          let i = 0;
          i < res?.data.listRemunerationParPuissance.length;
          i++
        ) {
          let label = res?.data.listRemunerationParPuissance[i].kva;
          let puissance = {
            kva: res?.data.listRemunerationParPuissance[i].kva,
            remuneration:
              res?.data.listRemunerationParPuissance[i].remuneration !== 0 || res?.data.listRemunerationParPuissance[i].remuneration !== undefined ? res?.data.listRemunerationParPuissance[i].remuneration : undefined,
          };
          tmpRes = {
            ...tmpRes,
            [`${label}`]: puissance,
          };
        }
        console.log(tmpRes)
        setSelectedSociete(tmpRes);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        dispatch(addsocieteStore.empty());
        setShowModifySociete(true);
      });
  }

  const headCells = [
    {
      id: "nomSociete",
      numeric: false,
      disablePadding: true,
      label: t("societe.nom"),
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: t("societe.type"),
    },
    {
      id: "statut",
      numeric: false,
      disablePadding: true,
      label: t("societe.statut"),
    },
  ];

  const handleSuccess = () => {
    setShowAddSociete(false);
    setShowModifySociete(false);
    getListSociete().then((res) => {
      return setRows(res?.data);
    });
  };

  function modifyStatutMass(status) {
    let names = _.partition(rows, function (u) {
      return _.includes(listSelectedSociete, u.idSociete);
    })?.[0]
      ?.map((u) => u.nomSociete)
      ?.join(", ");
    let ordre =
      status === "ACTIF"
        ? "modifyMassSocieteStatusACTIF"
        : "modifyMassSocieteStatusINACTIF";
    listSelectedSociete?.map((u) => {
      return getSocieteDetails(u)
        .then((res) => {
          let val = {
            ...res?.data,
            societe: { ...res?.data.societe, statut: status },
          };
          putSociete(val)
            .then((res) => {
              dispatch(update(sendNotif(ordre, names)));
            })
            .finally(() =>
              getListSociete().then((res) => {
                return setRows(res?.data);
              })
            );
        })
        .catch((err) => console.log(err));
    });
  }

  // init list admin courtage
  useEffect(() => {
    getListSociete().then((res) => {
      setRows(res?.data);
    });

    getUsers().then((res) => {
      setListAdminCourtage(
        res?.data?.filter((r) => {
          return r.type === "ADMIN_COURTAGE";
        })
      );
    });
  }, []);

  useEffect(() => {
    if (showModifySociete === false) dispatch(addsocieteStore.empty());
  }, [showModifySociete]);

  return (
    <>
      <Notifaction />

      <div className="societes_container">
        {/* modal + cta ajouter */}
        {/* Header */}
        <div className="utilisateurs_header">
          <div className="top">
            <div>
              <div className="left">
                <h2>{t("addsociete.listTitleSociete")}</h2>
              </div>
            </div>
            <div className="cta">
              {/* modal modifier unitaire */}

              <div className="button-list">
                <Button
                  className="blue small square"
                  style={{ marginRight: "8px" }}
                  disabled={listSelectedSociete.length <= 0}
                  onClick={() => modifyStatutMass("ACTIF")}
                  title="Activer"
                >
                  <Active />
                </Button>
                <Button
                  className="blue small square"
                  style={{ marginRight: "8px" }}
                  disabled={listSelectedSociete.length <= 0}
                  onClick={() => modifyStatutMass("INACTIF")}
                  title="Désactiver"
                >
                  <Inactive />
                </Button>
              </div>
              {/* modal + cta ajouter */}
              <Button
                onClick={() => setShowAddSociete(true)}
                className="green small"
                style={{ marginLeft: "12px" }}
              >
                {t("addsociete.add")}
              </Button>
              <Modal
                open={showAddSociete}
                onClose={() => setShowAddSociete(false)}
              >
                <>
                  <PopinAddSocietes
                    onClose={() => setShowAddSociete(false)}
                    onSuccess={() => handleSuccess()}
                    listAdmin={listAdminCourtage}
                  />
                </>
              </Modal>
              {/* modif */}
              <Modal
                open={showModifySociete}
                onClose={() => setShowModifySociete(false)}
              >
                <>
                  <PopinAddSocietes
                    onClose={() => setShowModifySociete(false)}
                    onSuccess={() => handleSuccess()}
                    listAdmin={listAdminCourtage}
                    selectedSociete={selectedSociete}
                  />
                </>
              </Modal>
            </div>
          </div>
          <p className="filter_infos">
            {t("societe.societies")} {t("common.ACTIF")} (
            {rows?.filter((r) => r.statut === "ACTIF")?.length}) -{" "}
            {t("common.INACTIVE")} (
            {rows?.filter((r) => r.statut === "INACTIF")?.length})
          </p>
        </div>
        {/* Table */}
        <div className="societes_content_wrapper">
          <div className="societes_content">
            <SocieteTable
              headCells={headCells}
              rows={rows}
              collect={(l) => setlistSelectedSociete(l)}
              actions={{
                modify: { fnc: (e) => handleModifySociete(e) },
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Societes;
