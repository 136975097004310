import moment from "moment";
import React from "react";
import { ReactComponent as Dwnld } from "../../../core/assets/picto/dwnld.svg";
import { getExportVentes } from "../../../core/services/httpRequest/axios";
import { download_csv } from "./utils";

const CSVVentes = ({ filters }) => {
  return (
    <Dwnld
      onClick={() =>
        getExportVentes(
          moment(filters.startDate).format("DD-MM-YYYY"),
          moment(filters.endDate).format("DD-MM-YYYY")
        ).then((res) => {
          download_csv("ventes_tableau_primeo.csv", res.data);
        })
      }
    />
  );
};

export default CSVVentes;
