import React from "react";

const ListDropDown = ({ blocState, onClick }) => {
  return (
    <svg
      width="32"
      height="28"
      viewBox="0 0 32 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M23.5294 12.5884C24.3117 12.5884 24.9412 13.2178 24.9412 14.0002C24.9412 14.7826 24.3117 15.412 23.5294 15.412H8.47057C7.68822 15.412 7.05881 14.7826 7.05881 14.0002C7.05881 13.2178 7.68822 12.5884 8.47057 12.5884H23.5294ZM23.5294 18.2355C24.3117 18.2355 24.9412 18.8649 24.9412 19.6473C24.9412 20.4296 24.3117 21.059 23.5294 21.059H8.47057C7.68822 21.059 7.05881 20.4296 7.05881 19.6473C7.05881 18.8649 7.68822 18.2355 8.47057 18.2355H23.5294ZM0.941162 4.58844C0.941162 2.50902 2.62646 0.82373 4.70587 0.82373H27.2941C29.3706 0.82373 31.0588 2.50902 31.0588 4.58844V23.412C31.0588 25.4884 29.3706 27.1767 27.2941 27.1767H4.70587C2.62646 27.1767 0.941162 25.4884 0.941162 23.412V4.58844ZM3.76469 10.2355V23.412C3.76469 23.9296 4.18587 24.3531 4.70587 24.3531H27.2941C27.8117 24.3531 28.2353 23.9296 28.2353 23.412V10.2355H3.76469ZM26.6294 4.58844H22.3117C21.8941 4.58844 21.6823 5.09432 21.9823 5.39432L24.1353 7.54726C24.3235 7.73549 24.6176 7.73549 24.8059 7.54726L26.9588 5.39432C27.2588 5.09432 27.047 4.58844 26.6294 4.58844Z"
        fill="#00644B"
        fillOpacity={blocState ? "1" : "0.25"}
      />
    </svg>
  );
};

export default ListDropDown;
