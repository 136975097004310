import React from "react";
import Next from "../../SvgComponents/Next";
import Previous from "../../SvgComponents/Previous";
import { useTranslation } from "react-i18next";
import "./styles/ButtonChart.scss";
import moment from "moment";

const ButtonChart = ({ name, onChange, date }) => {
   const { t } = useTranslation();

   function addTextForView() {
      if (name === "week") return t("dash.week") + " " + moment(date).week();
      if (name === "month") return t(`dash.${moment(date).month()}`);
      if (name === "year") return t("dash.year") + " " + moment(date).year();
   }

   function handleChange(direction) {
      let filters = {};

      let newDate = date;

      // function preventUndefined(direction, name) {

      if (name === "week" && direction === "PREV") {
         newDate = moment(date).subtract(1, "week");
         Object.defineProperty(filters, "week", {
            value: moment(date).subtract(1, "week").week(),
            writable: true,
         });
         Object.defineProperty(filters, "year", {
            value: moment(date).subtract(1, "week").year(),
            writable: true,
         });
      }

      if (
         name === "week" &&
         direction === "NEXT" &&
         moment(date).add(1, "week") <= moment()
      ) {
         newDate = moment(date).add(1, "week");

         Object.defineProperty(filters, "week", {
            value: moment(date).add(1, "week").week(),
            writable: true,
         });
         Object.defineProperty(filters, "year", {
            value: moment(date).add(1, "week").year(),
            writable: true,
         });
      }

      if (name === "month" && direction === "PREV") {
         newDate = moment(date).subtract(1, "month");
         Object.defineProperty(filters, "month", {
            value: moment(date).subtract(1, "month").month(),
            writable: true,
         });
         Object.defineProperty(filters, "year", {
            value: moment(date).subtract(1, "month").year(),
            writable: true,
         });
      }

      if (
         name === "month" &&
         direction === "NEXT" &&
         moment(date).add(1, "month") <= moment()
      ) {
         newDate = moment(date).add(1, "month");

         Object.defineProperty(filters, "month", {
            value: moment(date).add(1, "month").month(),
            writable: true,
         });
         Object.defineProperty(filters, "year", {
            value: moment(date).add(1, "month").year(),
            writable: true,
         });
      }

      if (name === "year" && direction === "PREV") {
         newDate = moment(date).subtract(1, "year");
         Object.defineProperty(filters, "year", {
            value: moment(date).subtract(1, "year").year(),
            writable: true,
         });
      }

      if (name === "year" && direction === "NEXT") {
         if (moment(date).add(1, "year").year() <= moment().year()) {
            newDate = moment(date).add(1, "year");

            Object.defineProperty(filters, "year", {
               value: moment(date).add(1, "year").year(),
               writable: true,
            });
         } else {
            Object.defineProperty(filters, "year", {
               value: moment(date).year(),
               writable: true,
            });
         }
      }

      // }

      // preventUndefined(direction, name);

      return onChange(filters, newDate);
   }

   return (
      <div className="buttonChart">
         <div style={{ margin: "0 auto", display: "flex", alignItems: "center" }} >
            <div className="icon" onClick={() => handleChange("PREV")}>
               <Previous />
            </div>
            <p style={{ textAlign: "center" }}>{addTextForView()}</p>
            <div className="icon" onClick={() => handleChange("NEXT")}>
               <Next />
            </div>
         </div>
      </div>
   );
};

export default ButtonChart;
