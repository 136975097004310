/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _, { set } from "lodash";
import "./styles/prospect.scss";
import Notifaction from "../../../components/BOCO/Notification/Notifaction";
import ProspectTable from "../../../components/BOCO/TableComponents/ProspectTable/ProspectTable";
import { customRequest } from "../../../core/services/httpRequest/axios";
import ComboBoxObject from "../../../components/ComboBox/ComboBoxObject";
import Button from "../../../components/Button/Button";
import { t } from "i18next";
import CustomDatePickerV1 from "../../../components/DatePicker/datePickerV1";
import moment from "moment";
import PaginationManager from "../../../components/BOCO/TableComponents/Pagination/PaginationManager";
import Loader from "../../../components/Loader/Loader";
import { CircularProgress } from "@mui/material";
import FilterSvg from "../../../components/SvgComponents/FilterSvg";
import ArrowUpSvg from "../../../components/SvgComponents/ArrowUpSvg";
import ArrowDownSvg from "../../../components/SvgComponents/ArrowDownSvg";
import { ReactComponent as Dwnld } from "../../../core/assets/picto/dwnld.svg";
import { fil } from "date-fns/locale";
import { download_csv } from "../../../components/BOCO/CSVContainers/utils";
const Prospect = () => {

  const headCellsDefault = [
    {
      id: "origine",
      idForTri: "origine",
      numeric: false,
      disablePadding: true,
      label: "Origine",
    },
    {
      id : "pdl",
      idForTri : "pdl",
      numeric : false,
      disablePadding : true,
      label : "PDL"
    },
    {
      id: "nom_prenom",
      idForTri: "nom_prenom",
      numeric: false,
      disablePadding: true,
      label: "Nom Prenom",
    },
    {
      id: "mail",
      idForTri: "mail",
      numeric: false,
      disablePadding: true,
      label: "Mail",
    },
    {
      id: "phone",
      idForTri: "phone",
      numeric: false,
      disablePadding: true,
      label: "Phone",
    },
    {
      id: "date",
      idForTri: "date",
      numeric: false,
      disablePadding: true,
      label: "Date",
    },
    {
      id: "hasSubscribed",
      idForTri: "hasSubscribed",
      numeric: false,
      disablePadding: true,
      label: "A souscrit",
    },
  ];

  const [headCells, setHeadCells] = useState(headCellsDefault);

  const [prospects, setProspects] = useState([]);
  const [loadingProspects, setLoadingProspects] = useState('IDLE');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [filter, setFilter] = useState({ dateDebut: undefined, dateFin: undefined });
  const [toggleFilters, setToggleFilters] = useState(false);
  const getProspect = async (filters) => {
    let dateDebut = filters.dateDebut ? "&dateBeginTimestamp=" + moment(filters.dateDebut).add(2, 'hours').valueOf() : "";
    let dateFin = filters.dateFin ? "&dateEndTimestamp=" + moment(filters.dateFin).add(2, 'hours').valueOf() : "";

    setLoadingProspects('LOADING');
    const res = customRequest.get(`/prospects?page=${page}&pageSize=${pageSize}` + dateDebut + dateFin).then((res) => {
      setLoadingProspects('SUCCESS');
      setPageNumber(res.data.pageNumber);
      setProspects(res.data.prospectDTOlist.map((prospect) => {
        return {
          origine: prospect.origine,
          pdl : prospect.numeroPDL,
          nom_prenom: prospect.nom + " " + prospect.prenom,
          mail: prospect.email,
          phone: prospect.numeroTel,
          date: prospect.dateDemande,
          hasSubscribed: prospect.estSouscrit,
        }
      }));
      return res;
    }).catch((err) => {
      setLoadingProspects('ERROR');
    })
  }

  const handleCsvExport = () => {
    let dateDebut = filter.dateDebut ? "&dateBeginTimestamp=" + moment(filter.dateDebut).add(2, 'hours').valueOf() : "";
    let dateFin = filter.dateFin ? "&dateEndTimestamp=" + moment(filter.dateFin).add(2, 'hours').valueOf() : "";

    customRequest.get("/prospects/csv?"+ dateDebut + dateFin).then((res) => {
      download_csv("prospects.csv", res.data);
    })
  }
  useEffect(() => {
    getProspect(filter);
  }, [undefined, page, pageSize]);

  return (
    <div className="vente_container" style={{ padding: "28px" }}>
      <Notifaction />
      <div style={{ display: "flex", alignItems: "center" }}>
        <h2 style={{ marginTop: "0px", marginBottom: "0px" }}>Liste des prospects</h2>
        <Button
          className={
            toggleFilters
              ? "blue outline small"
              : "blue outline small black"
          }
          style={{ marginLeft: "15px" }}
          onClick={() => { setToggleFilters(!toggleFilters) }}
        >
          <FilterSvg fill={toggleFilters ? "#0ABBF0" : "#000"} />
          <p>{t("users.filters")}</p>
          {toggleFilters ? (
            <ArrowUpSvg fill="black" />
          ) : (
            <ArrowDownSvg fill="black" />
          )}
        </Button>
        <p
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            margin: "0 0 0 12px",
          }}
          onClick={() => {
            let filter = { dateDebut: undefined, dateFin: undefined };
            setFilter(filter);
            getProspect(filter);
          }}
        >
          Effacer
        </p>
        <Button
          className="blue small square"
          style={{ marginLeft: "auto" }}
          disabled={filter.dateDebut === undefined || filter.dateFin === undefined}
          onClick={() => {handleCsvExport()}}
        >
          <Dwnld></Dwnld>
        </Button>
      </div>
      {toggleFilters &&
        <div className="utilisateurs_header_filters">
          <div className="datepicker">
            <CustomDatePickerV1
              value={filter.dateDebut}
              onChange={(e) => {
                setFilter({ ...filter, dateDebut: e });
              }}
              label={t("ventes.filterLabel.dateStart")}
              limitation={{
              }}
            />
          </div>
          <div className="datepicker">
            <CustomDatePickerV1
              value={filter.dateFin}
              onChange={(e) => {
                setFilter({ ...filter, dateFin: e });
              }}
              label={t("ventes.filterLabel.dateEnd")}
              limitation={{
              }}
            />
          </div>
          <div>
            <Button className="blue" onClick={() => { getProspect(filter) }}>
              {t("users.filter")}
            </Button>
          </div>
        </div>
      }
      {loadingProspects === 'SUCCESS' &&
        <>
          <ProspectTable
            headCells={headCells}
            rows={prospects}
            rowsPage={10}
            pagination={true}
            pageInit={0}
            actions={{
              open: {
                fnc: (v) => { },
              },
            }}
            collect={(l) => { }}
            actionRowTri={(isAsc, idForTri) => {
            }}
          ></ProspectTable>
          <div className="pagination_wrapper">
            <PaginationManager
              rows={prospects}
              pagination={true}
              onChange={(pageSeleted, rowPerPageSelected) => {
                setPage(pageSeleted);
                setPageSize(rowPerPageSelected);
              }}
              numbersPage={pageNumber}
              currentPage={page}
              size={pageSize}
            />
          </div>
        </>
      }
      {loadingProspects === 'LOADING' &&
        <div style={{ display: "flex" }}>
          <CircularProgress style={{ marginLeft: "auto", marginRight: "auto" }} />
        </div>
      }
    </div>
  );
};

export default Prospect;
