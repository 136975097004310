export function filterRespo(user, selectedUser, responsables) {
   switch (user) {
      case "ADMIN_PRIMEO":
         return responsables
            .filter((r) => r.statut === "Actif")
            ?.filter(
               (r) => r.idSocieteCourtage === selectedUser?.idSocieteCourtage
            );
      case "ADMIN_COURTAGE":
         return responsables
            .filter((r) => r.statut === "Actif")
            ?.filter(
               (r) => r.idSocieteCourtage === selectedUser.idSocieteCourtage
            );
      case "RESPONSABLE_VENDEUR":
         return responsables.filter(
            (r) => r.idUtilisateur === selectedUser.idUtilisateur
         );
      default:
         return responsables;
   }
}

export function handleRespoIdByType(type, respoID) {
   switch (type) {
      case "ADMIN_PRIMEO":
         return null;
      case "ADMIN_COURTAGE":
         return null;
      case "RESPONSABLE_VENDEUR":
         return null;
      case "VENDEUR":
         return respoID;
      default:
         return null;
   }
}
