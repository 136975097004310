/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import CustomTableHead from "../../CustomTableHead/CustomTableHead";
import ActiveSvg from "../../../SvgComponents/ActiveSvg";
import InactiveSvg from "../../../SvgComponents/InactiveSvg";
import { ReactComponent as Dot } from "../../../../core/assets/picto/dot.svg";
import { useTranslation } from "react-i18next";
import "./styles/societetable.scss";

const SocieteTable = ({
  headCells,
  rows,
  actions,
  pagination = false,
  editInRow = false,
  collect,
  onSuccess,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [openOrganize, setOpenOrganize] = useState(false);

  const { t } = useTranslation();

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function toggleOrganize() {
    setOpenOrganize(!openOrganize);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows?.map((n) => n.idSociete);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, idSociete) => {
    const selectedIndex = selected.indexOf(idSociete);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, idSociete);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (idSociete) => {
    return selected.indexOf(idSociete) !== -1;
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  useEffect(() => {
    if (pagination) {
      setRowsPerPage(6);
    } else {
      setRowsPerPage(rows?.length);
    }
  }, [rows]);

  useEffect(() => {
    collect(selected);
  }, [selected]);

  return (
    <Box className="table_container">
      <TableContainer>
        <Table
          // sx={{ minWidth: 750 }}
          padding={"none"}
          aria-labelledby="tableTitle"
          size="medium"
          stickyHeader={true}
        >
          <CustomTableHead
            headCells={headCells}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
            // action={{ icon: <Dot /> }}
          />
          {openOrganize && (
            <Modal
              className="organize_modal"
              open={openOrganize}
              onClose={() => setOpenOrganize(false)}
            >
              <p>MODAL</p>
            </Modal>
          )}
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, i) => {
                const isItemSelected = isSelected(row.idSociete);
                const labelId = `enhanced-table-checkbox-${row.idSociete}`;

                return (
                  <TableRow
                    key={row + i}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={1}
                    selected={isItemSelected}
                    className="custom-table-row "
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) => handleClick(event, row.idSociete)}
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        size="small"
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => actions.modify.fnc(row.idSociete)}
                    >
                      <p>{row.nomSociete}</p>
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => actions.modify.fnc(row.idSociete)}
                    >
                      <p>{row.type}</p>
                    </TableCell>
                    <TableCell
                      align="left"
                      className="custom-cell"
                      onClick={() => actions.modify.fnc(row.idSociete)}
                    >
                      <div>
                        {row.statut === "ACTIF" ? (
                          <ActiveSvg fill="#109F7B" />
                        ) : (
                          <InactiveSvg fill="#9D9D9D" />
                        )}
                        <p>{t("common." + row.statut)}</p>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 48 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

export default SocieteTable;
