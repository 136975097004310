import React from "react";

const ActiveSvg = ({ fill }) => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.475 15.925L16.825 8.57501L15.175 6.92501L9.475 12.625L6.7 9.85001L5.075 11.5L9.475 15.925ZM11.025 21.625C9.50833 21.625 8.1 21.3542 6.8 20.8125C5.5 20.2708 4.375 19.525 3.425 18.575C2.475 17.625 1.72917 16.5 1.1875 15.2C0.645833 13.9 0.375 12.4917 0.375 10.975C0.375 9.49167 0.645833 8.10417 1.1875 6.81251C1.72917 5.52084 2.475 4.39584 3.425 3.43751C4.375 2.47917 5.5 1.72501 6.8 1.17501C8.1 0.625006 9.50833 0.350006 11.025 0.350006C12.5083 0.350006 13.8958 0.625006 15.1875 1.17501C16.4792 1.72501 17.6042 2.47917 18.5625 3.43751C19.5208 4.39584 20.275 5.52084 20.825 6.81251C21.375 8.10417 21.65 9.50001 21.65 11C21.65 12.5 21.375 13.9 20.825 15.2C20.275 16.5 19.5208 17.625 18.5625 18.575C17.6042 19.525 16.4792 20.2708 15.1875 20.8125C13.8958 21.3542 12.5083 21.625 11.025 21.625ZM11 19.275C13.35 19.275 15.3167 18.4875 16.9 16.9125C18.4833 15.3375 19.275 13.3667 19.275 11C19.275 8.63334 18.4833 6.66251 16.9 5.08751C15.3167 3.51251 13.35 2.72501 11 2.72501C8.65 2.72501 6.68333 3.51251 5.1 5.08751C3.51667 6.66251 2.725 8.63334 2.725 11C2.725 13.3667 3.51667 15.3375 5.1 16.9125C6.68333 18.4875 8.65 19.275 11 19.275Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default ActiveSvg;
