/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import CustomTableHead from "../../CustomTableHead/CustomTableHead";

// styles
import "./styles/ProspectTable.scss";
import WaitingSvg from "../../../SvgComponents/WaitingSvg";
import ActiveSvg from "../../../SvgComponents/ActiveSvg";
import InactiveSvg from "../../../SvgComponents/InactiveSvg";
import { dateFormatL, sortDates } from "../../../../utils";
import _ from "lodash";
import { useSelector } from "react-redux";
import { puissanceObject } from "../../../../utils/BOCO/enums";
import moment from "moment";

const ProspectTable = ({
  headCells,
  rows,
  actions,
  pagination = false,
  collect,
  pageInit,
  rowsPage,
  actionRowTri,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(pageInit);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPage);

  const [authorization, setAuthorization] = useState("");
  const authorizations = useSelector((state) => state?.params?.authorizations);
  const currentUser = useSelector((state) => state.auth.broker);

  // init autorisation
  useEffect(() => {
    setAuthorization(currentUser.type);
  }, [currentUser]);

  useEffect(() => {
    setPage(pageInit);
  }, [pageInit]);

  useEffect(() => {
    setRowsPerPage(rowsPage);
  }, [rowsPage]);

  const handleRequestSort = (event, property, idForTri) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    actionRowTri(isAsc ? "desc" : "asc", idForTri);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked && selected.length > 0) {
      return setSelected([]);
    }
    if (event.target.checked) {
      let newSelecteds = [];
      newSelecteds = rows.flatMap((n) => [
        {
          ...newSelecteds,
          idVente: n.idVente,
          idMetier: n.idMetier,
          commissionVente: n.commissionVente,
          statut: sortDates(n)[0]?.statut,
        },
      ]);

      return setSelected(newSelecteds);
    }
    return setSelected([]);
  };

  const handleClick = (event, idVente, commissionVente, idMetier, statut) => {
    const selectedIndex = _.findIndex(selected, function (o) {
      return o.idVente === idVente;
    });
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(...selected, {
        idVente: idVente,
        idMetier: idMetier,
        commissionVente: commissionVente,
        statut: statut,
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (idVente) => {
    return (
      _.findIndex(selected, function (o) {
        return o.idVente === idVente;
      }) !== -1
    );
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  useEffect(() => {
    if (pagination) {
      setRowsPerPage(10);
    } else {
      setRowsPerPage(rows?.length);
    }
  }, [rows]);

  useEffect(() => {
    collect(selected);
  }, [selected]);

  return (
    <Box className="table_container">
      <TableContainer>
        <Table
          padding={"none"}
          aria-labelledby="tableTitle"
          size="medium"
          stickyHeader={true}
          className="prospect-table"
        >
          <CustomTableHead
            headCells={headCells}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
          // action={{ icon: <Dot /> }}
          />

          <TableBody>
            {rows?.map((row, i) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${row.id}`;

              const statut = sortDates(row)[0]?.statut;
              return (
                <TableRow
                  key={row + i}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={1}
                  selected={isItemSelected}
                  className="custom-table-row "
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      onClick={(event) => {
                        handleClick(
                          event,
                          row.id,
                          row.commissionVente,
                          row.idMetier,
                          statut
                        );
                      }}
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                      size="small"
                    />
                  </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => actions.open.fnc(row.id)}
                    >
                      <p>{row.origine}</p>
                    </TableCell>
                  <TableCell
                    align="left"
                    onClick={() => actions.open.fnc(row.id)}
                  >
                    <p>
                      {row.pdl}
                    </p>
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={() => actions.open.fnc(row.id)}
                  >
                    <p>
                      {row.nom_prenom}
                    </p>
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={() => actions.open.fnc(row.id)}
                  >
                    <p>{row.mail}</p>
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={() => actions.open.fnc(row.id)}
                  >
                    <p> { row.phone } </p>
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={() => actions.open.fnc(row.id)}
                  >
                    <p>{moment(row.date).format("DD/MM/YYYY HH:mm:ss")}</p>
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={() => actions.open.fnc(row.id)}
                  >
                    <p>{row.hasSubscribed ? "Oui" : "Non"}</p>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 48 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProspectTable;
