import React from "react";
import Logo from "../../core/assets/picto/logoPrimeo.svg";
import "./styles/header.scss";
import { useDispatch } from "react-redux";

const Header = ({ children, isHome }) => {
   return (
      <div className={isHome ? "home" : ""}>
         <div className={"header_container"}>
            <header>
               <img src={Logo} alt="Logo Primeo" className="logoPrimeo" />
               <div className="children">{children}</div>
            </header>
         </div>
      </div>
   );
};

export default Header;
