import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { trueOrFalseDo } from "../../../utils";

const AddressDisplay = ({
  address,
  isPDL = false,
  loading = false,
  isBillingBloc,
  isRecap,
  children,
}) => {
  const { t } = useTranslation();
  const [conso, setConso] = useState("--");

  useEffect(() => {
    if (
      isPDL &&
      !isBillingBloc &&
      address?.estimatedCar?.carBase?.value !== undefined
    ) {
      let tmpConso = address?.estimatedCar?.carBase?.value;
      // for (const [key, value] of Object.entries(
      //   address?.estimatedCar?.carBase?.value
      // )) {
      //   tmpConso += value * 1;
      // }
      if (tmpConso === 0) {
        setConso("--");
      } else {
        setConso(tmpConso);
      }
    }
    if (
      isPDL &&
      !isBillingBloc &&
      address?.estimatedCar?.carBase?.value === undefined
    ) {
      setConso("--");
    }
  }, [address]);

  const getAdress = () => {
    return (
      <>
          <h2 className="green smMargin">
          {address?.deliveryAddress?.additionnalAddress2}
        </h2>
        <h2 className="green smMargin">
          {address?.deliveryAddress?.housenumberAndStreet ||
            address?.housenumberAndStreet}
        </h2>
        <h2 className="green smMargin">
          {address?.additionnalAddress}
          {address?.deliveryAddress?.additionnalAddress}{" "}
        </h2>
        <h2 className="green smMargin">
          {address?.deliveryAddress?.zipCode}
          {address?.zipCode} {address?.deliveryAddress?.city}
          {address?.city}
        </h2>
      </>
    );
  };

  const getAdressRecap = () => {
    return (
      <>
        <p style={{ margin: "0" }}>
          {address?.deliveryAddress?.housenumberAndStreet ||
            address?.housenumberAndStreet}
          {/* {address?.housenumber} */}
          {/* {address?.deliveryAddress?.housenumber}{" "} */}
          {/* {address?.deliveryAddress?.street} */}
          {/* {address?.street || address?.name} */}
        </p>
        <p style={{ margin: "0" }}>
          {address?.additionnalAddress}
          {address?.deliveryAddress?.additionnalAddress}{" "}
        </p>

        <p style={{ marginTop: "4px" }}>
          {address?.zipCode}
          {address?.deliveryAddress?.zipCode} {address?.city}
          {address?.deliveryAddress?.city}
        </p>
      </>
    );
  };

  return (
    <>
      {isPDL === false && isBillingBloc && <>{children}</>}
      <Grid container spacing={3}>
        {" "}
        <Grid item xs={12} sm={7}>
          {isPDL && <h3>{t("souscription.pdl.address")}</h3>}

          {!isRecap && getAdress()}

          {isRecap && getAdressRecap()}
        </Grid>
        {isPDL && !isBillingBloc && (
          <>
            <Grid item xs={12} sm={6}>
              <h3>{t("souscription.pdl.conso")}</h3>
              <h2 className="green smMargin">
                {conso} <span className="green">kWh</span>
              </h2>
              <h2 className="green smMargin" style={{ fontSize: "16px" }}>
                {address?.estimatedCar?.errorCodeList[0]}
              </h2>
              {/* {trueOrFalseDo(
                              address?.estimatedCar.errorCodeList.length === 0,
                              () => (
                                 <h2 className="green smMargin">
                                    {conso} <span className="green">kWh</span>
                                 </h2>
                              ),
                              () => (
                                 <h2 className="green smMargin">
                                    {address?.estimatedCar.errorCodeList[0]}
                                 </h2>
                              )
                           )} */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <h3>{t("souscription.pdl.PS")}</h3>

              <h2 className="green smMargin">
                {address?.contractPowerMax?.valeur}{" "}
                <span className="green">
                  {trueOrFalseDo(
                    address?.contractPowerMax?.unite === "K_VA",
                    () => "kVA",
                    () => ""
                  )}
                </span>
              </h2>
            </Grid>
            <Grid item xs={12} sm={12}>
              <h3>{t("souscription.pdl.calendrier")}</h3>

              <h2 className="green smMargin">
                {trueOrFalseDo(
                  address?.libelleOffre === "Unknown",
                  () => address?.codeOffre,
                  () =>
                  address.optionTarifaireOffre
                    // trueOrFalseDo(
                    //   address?.libelleOffre === null || undefined,
                    //   () => address?.calendrierFrn?.libelle,
                    //   () => address?.libelleOffre
                    // )
                )}
              </h2>
            </Grid>
            <Grid item xs={12} sm={12}>
              <h3>FTA</h3>

              <h2 className="green smMargin">
                      {address.formuleTarifaireAcheminement.code}
              </h2>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default AddressDisplay;
