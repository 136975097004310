import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextInput from "../../../components/TextInput/TextInput";
import Button from "../../../components/Button/Button";
import CustomRadio from "../../../components/BOCO/CustomRadio/CustomRadio";
import ComboBox from "../../../components/ComboBox/ComboBox";
import BarreButton from "../../../components/BarreButton/BarreButton";
import {
   capitalizeFirstLetter,
   checkEnterKeyDown,
   formatErrorPhoneNumber,
   formatErrorNames,
   formatErrorEmail,
   autoCompleteAlea,
   check0to100,
   trueOrFalseDo,
} from "../../../utils";
import AuthorizationLayout from "../../HOC/AuthorizationLayout/AuthorizationLayout";
import "./styles/popinadduser.scss";
import { Grid } from "@mui/material";
import * as adduserStore from "../../../core/stores/adduserStore";
import ComboBoxObject from "../../ComboBox/ComboBoxObject";
import GeoExclu from "../GeoExclu/GeoExclu";
import {
   createUser,
   getListSociete,
   getSocieteDetails,
   getUsers,
} from "../../../core/services/httpRequest/axios";
import _ from "lodash";
import { ReactComponent as CrossSvg } from "../../../core/assets/picto/cross.svg";
import handleRequestResponse from "../../../utils/responsesHandler";
import { update } from "../../../core/stores/paramsStore";
import { sendNotif } from "../../../core/stores/utils";
import Notifaction from "../../../components/BOCO/Notification/Notifaction";

const PopinAddUser = ({ user, list, onClose, onSuccess }) => {
   const [newUserIsAdminPrimeo, setnewUserIsAdminPrimeo] = useState(true);
   const [newUserIsAdminCourtage, setnewUserIsAdminCourtage] = useState(true);
   const [newUserIsResponsableVendeur, setnewUserIsResponsableVendeur] =
      useState(true);
   const [newUserIsVendeur, setnewUserIsVendeur] = useState(true);
   const [newUserIsChargeClient, setnewUserIsChargeClient] = useState(true);
   const [responsables, setResponsables] = useState([]);
   const [respoInit, setrespoInit] = useState([]);
   const [societes, setSocietes] = useState([]);

   const [autoSelectSociete, setAutoSelectSociete] = useState(false);
   const [onChangeSociete, setOnChangeSociete] = useState(false);
   const [synchroGeoSociete, setSynchroGeoSociete] = useState([]);

   const { t } = useTranslation();
   const dispatch = useDispatch();

   const adduser = useSelector((state) => state.adduser);

   const statuts = [
      { value: "ACTIF", name: t("adduser.active") },
      { value: "INACTIF", name: t("adduser.inactive") },
   ];

   const {
      handleSubmit,
      getValues,
      setValue,
      watch,
      control,
      formState: { errors },
   } = useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      shouldFocusError: true,
      shouldUseNativeValidation: false,
      defaultValues: { ...adduser },
   });

   //
   function forceDefaultCheckTypeVendeur(userType) {
      if (userType === "RESPONSABLE_VENDEUR") {
         dispatch(
            adduserStore.update({
               target: "type",
               value: "VENDEUR",
            })
         );
         setValue("type", "VENDEUR");
      }
      if (userType === "CHARGE_CLIENT") {
         dispatch(
            adduserStore.update({
               target: "type",
               value: "CHARGE_CLIENT",
            })
         );
         setValue("type", "CHARGE_CLIENT");
      }
   }
   //
   function filterRespo(user) {
      switch (user) {
         case "ADMIN_PRIMEO":
            if (!!getValues("societeCourtage")) {
               return responsables.filter(
                  (r) =>
                     r.statut === "Actif" &&
                     r.idSocieteCourtage === getValues("idSocieteCourtage")
               );
            }
            return responsables.filter((r) => r.statut === "Actif");
         case "ADMIN_COURTAGE":
            return responsables.filter(
               (r) =>
                  r.idSocieteCourtage === user.idSocieteCourtage &&
                  r.statut === "Actif"
            );
         case "RESPONSABLE_VENDEUR":
            return responsables.filter(
               (r) => r.idUtilisateur === user.idUtilisateur
            );
         default:
            return responsables;
      }
   }

   function handleRespoIdByType(type, respoID) {
      switch (type) {
         case "ADMIN_PRIMEO":
            return null;
         case "ADMIN_COURTAGE":
            return null;
         case "RESPONSABLE_VENDEUR":
            return null;
         case "VENDEUR":
            return respoID;
         default:
            return null;
      }
   }

   async function submit(data) {
      var correspondingEnum = [];
      correspondingEnum[t("adduser.remote")] = ["REMOTE"];
      correspondingEnum[t("adduser.door2door")] = ["DOOR_TO_DOOR"];
      correspondingEnum[t("adduser.both")] = ["REMOTE", "DOOR_TO_DOOR"];

      let query = {
         type: data.type,
         nom: capitalizeFirstLetter(data.nom),
         prenom: capitalizeFirstLetter(data.prenom),
         email: data.email,
         telephone: data.telephone,
         exclusitiveGeo: JSON.stringify(data?.exclusitiveGeo),
         idResponsable: await handleRespoIdByType(
            data.type,
            data?.idResponsable
         ),
         pourcentageVendeurCommission: data?.commission,
         visuCommission: data?.visuCommission?.toUpperCase(),
         statut: data.statut.toUpperCase(),
         idSocieteCourtage: data?.idSocieteCourtage,
         typesVendeur: correspondingEnum[data?.typeVendeur],
      };

      dispatch(adduserStore.empty());
      createUser(query).then((res) => {
         onSuccess();
         handleRequestResponse(
            res,
            () => {
               onSuccess();
               dispatch(update(sendNotif("createUser")));
            },
            () => {
               dispatch(update(sendNotif("", res.data.details)));
            }
         );
      });
   }

   useEffect(() => {
      if (user?.type === "ADMIN_PRIMEO") {
         getListSociete().then((res) => {
            handleRequestResponse(
               res,
               () => {
                  let businesses = [];
                  res?.data?.forEach((s) => {
                     businesses.push({
                        idSocieteCourtage: s.idSociete,
                        name: s.nomSociete,
                     });
                  });

                  setSocietes(businesses);
               },
               () => {
                  return;
               }
            );
         });
      }

      if (user?.type === "ADMIN_COURTAGE") {
         dispatch(
            adduserStore.update({
               target: "idSocieteCourtage",
               value: user?.idSocieteCourtage,
            })
         );
         setValue("idSocieteCourtage", user?.idSocieteCourtage);
      }
      getUsers().then((res) => {
         let respos = res?.data?.filter((u) => {
            if (u?.type === "RESPONSABLE_VENDEUR" && u.statut === "ACTIF") {
               return {
                  name: u.nomResponsable,
                  idUtilisateur: u.idUtilisateur,
               };
            }
         });

         setResponsables(respos);
         setrespoInit(respos);
      });
   }, []);

   useEffect(() => {
      let newUserType = getValues("type");
      newUserType !== "ADMIN_PRIMEO"
         ? setnewUserIsAdminPrimeo(true)
         : setnewUserIsAdminPrimeo(false);
      newUserType !== "ADMIN_COURTAGE"
         ? setnewUserIsAdminCourtage(true)
         : setnewUserIsAdminCourtage(false);
      newUserType !== "RESPONSABLE_VENDEUR"
         ? setnewUserIsResponsableVendeur(true)
         : setnewUserIsResponsableVendeur(false);
      newUserType !== "VENDEUR"
         ? setnewUserIsVendeur(true)
         : setnewUserIsVendeur(false);
      newUserType !== "CHARGE_CLIENT"
         ? setnewUserIsChargeClient(true)
         : setnewUserIsChargeClient(false);
   }, [watch("type")]);

   useEffect(() => {
      // je choisis agnes vincent
      // je regarde SI societe selectionné est celle de agnes vincent
      // si oui je laisse, si non je met à undefined
      // JE NE FILTRE PAS LES SOCIETE
      let selectedSocieteID = getValues("idSocieteCourtage");
      let selectedResponsableID = getValues("idResponsable");
      let selectedResponsable = responsables.find(
         (r) => r.idUtilisateur === selectedResponsableID
      );

      if (
         !!getValues("idResponsable") &&
         selectedResponsable?.idSocieteCourtage !== selectedSocieteID
      ) {
         setValue("idSocieteCourtage", undefined);
      }
   }, [watch("idResponsable")]);

   useEffect(() => {
      // Je choisis une societe
      // si la respo sélectionné est dans la société alors je le laisse
      // SINON je met à undefined et je filtre les respo avec la societe sélectionnée
      let selectedSocieteID = getValues("idSocieteCourtage");
      let selectedResponsableID = getValues("idResponsable");
      let selectedResponsable = responsables.find(
         (r) => r.idUtilisateur === selectedResponsableID
      );

      if (
         !!getValues("idSocieteCourtage") &&
         selectedResponsable?.idSocieteCourtage !== selectedSocieteID
      ) {
         setValue("idResponsable", undefined);
         setResponsables(
            respoInit.filter((r) => r.idSocieteCourtage === selectedSocieteID)
         );
      }
      if (getValues("idSocieteCourtage")) {
         setResponsables(
            respoInit.filter((r) => r.idSocieteCourtage === selectedSocieteID)
         );
      }
   }, [watch("idSocieteCourtage")]);

   // Init select input if there's store data
   useEffect(() => {
      forceDefaultCheckTypeVendeur(user.type);
      // init select societe
      trueOrFalseDo(
         adduser.idSocieteCourtage,
         () => {
            setValue("idSocieteCourtage", adduser.idSocieteCourtage);
         },
         () => {
            return;
         }
      );
      // init select responsable
      trueOrFalseDo(
         adduser.idResponsable,
         () => {
            setValue("idResponsable", adduser.idResponsable);
         },
         () => {
            return;
         }
      );
   }, []);

   useEffect(() => {
      if (getValues("idSocieteCourtage"))
         setResponsables(
            respoInit.filter(
               (r) => r.idSocieteCourtage === getValues("idSocieteCourtage")
            )
         );
   }, [respoInit]);

   //   Auto select societé if select responsable before
   useEffect(() => {
      if (autoSelectSociete) {
         setValue("idSocieteCourtage", adduser.idSocieteCourtage);
         setAutoSelectSociete(!autoSelectSociete);
      }
   }, [autoSelectSociete]);

   // init commission value input
   useEffect(() => {
      if (getValues("idSocieteCourtage")) {
         getSocieteDetails(getValues("idSocieteCourtage"))
            .then((res) => {
               setSynchroGeoSociete(
                  JSON.parse(res?.data.societe.exclusiviteGeo)
               );

               if (!newUserIsVendeur) {
                  const comProfilVendeur =
                     res?.data?.listRemunerationParProfil?.filter(
                        (com) => com.profil === "VENDEUR"
                     );

                  setValue("commission", comProfilVendeur[0].ratio);
                  dispatch(
                     adduserStore.update({
                        target: "commission",
                        value: comProfilVendeur[0].ratio,
                     })
                  );
               }
               if (!newUserIsResponsableVendeur) {
                  const comProfilRespVendeur =
                     res?.data?.listRemunerationParProfil.filter(
                        (com) => com.profil === "RESPONSABLE_VENDEUR"
                     );

                  setValue("commission", comProfilRespVendeur[0].ratio);
                  dispatch(
                     adduserStore.update({
                        target: "commission",
                        value: comProfilRespVendeur[0].ratio,
                     })
                  );
               }
            })
            .catch((err) => {
               // console.log(err);
               setValue("commission", 100);
               dispatch(
                  adduserStore.update({
                     target: "commission",
                     value: 100,
                  })
               );
            });
      }
   }, [
      onChangeSociete,
      watch("idSocieteCourtage"),
      newUserIsResponsableVendeur,
      newUserIsVendeur,
   ]);

   // change list exclu geo if there's societe selected
   useEffect(() => {
      dispatch(
         adduserStore.update({
            target: "exclusitiveGeo",
            value: synchroGeoSociete,
         })
      );
      setValue("exclusitiveGeo", synchroGeoSociete);
      setOnChangeSociete(false);
   }, [synchroGeoSociete, newUserIsResponsableVendeur, newUserIsVendeur]);

   return (
      <>
         <Notifaction />

         <div className="popin popinaddUser">
            <div className="page_content_addduser">
               <div className="container" style={{ margin: "0 auto" }}>
                  <header className="popin_adduser_header">
                     <h2 className="title">{t("adduser.title")}</h2>
                     <CrossSvg className="cross" onClick={onClose} />
                  </header>
                  <div className="main_container_adduser">
                     <form
                        onSubmit={handleSubmit(submit)}
                        onKeyDown={(e) => checkEnterKeyDown(e)}
                        noValidate
                        autoComplete="off"
                        className="form_adduser"
                     >
                        <Grid container spacing={0}>
                           <Grid item xs={12}>
                              <Controller
                                 name="type"
                                 control={control}
                                 rules={{
                                    required: t("error.required"),
                                 }}
                                 render={({
                                    field: { value, onChange, onBlur },
                                 }) => (
                                    <>
                                       <div
                                          className={`radio_controller_container ${
                                             !!errors.type
                                                ? "error_wrapper"
                                                : null
                                          }`}
                                       >
                                          <div className="radio_group_container" style={{flexDirection : "column"}}>
                                          <div style={{display : "flex", justifyContent : "center"}}>
                                             <p id="label-radio-group-user-type" style={{margin : "0"}}>
                                                {t("adduser.type")}
                                             </p>
                                             <AuthorizationLayout
                                                authorized={["ADMIN_PRIMEO"]}
                                                current={user?.type}
                                             >
                                                <CustomRadio
                                                   value="ADMIN_PRIMEO"
                                                   controllerValue={value}
                                                   name={t("adduser.type1")}
                                                   target="type"
                                                   onChange={(e) => {
                                                      dispatch(
                                                         adduserStore.update({
                                                            target: "type",
                                                            value: e.target
                                                               .value,
                                                         })
                                                      );
                                                      onChange(e.target.value);
                                                   }}
                                                   onBlur={onBlur}
                                                />
                                             </AuthorizationLayout>
                                             <AuthorizationLayout
                                                authorized={["ADMIN_PRIMEO"]}
                                                current={user?.type}
                                             >
                                                <CustomRadio
                                                   value="ADMIN_COURTAGE"
                                                   controllerValue={value}
                                                   name={t("adduser.type2")}
                                                   target="type"
                                                   onChange={(e) => {
                                                      dispatch(
                                                         adduserStore.update({
                                                            target: "type",
                                                            value: e.target
                                                               .value,
                                                         })
                                                      );
                                                      onChange(e.target.value);
                                                   }}
                                                   onBlur={onBlur}
                                                />
                                             </AuthorizationLayout>
                                             <AuthorizationLayout
                                                authorized={[
                                                   "ADMIN_PRIMEO",
                                                   "ADMIN_COURTAGE",
                                                ]}
                                                current={user?.type}
                                             >
                                                <CustomRadio
                                                   value="RESPONSABLE_VENDEUR"
                                                   controllerValue={value}
                                                   name={t("adduser.type3")}
                                                   target="type"
                                                   onChange={(e) => {
                                                      dispatch(
                                                         adduserStore.update({
                                                            target: "type",
                                                            value: e.target
                                                               .value,
                                                         })
                                                      );
                                                      onChange(e.target.value);
                                                   }}
                                                   onBlur={onBlur}
                                                />
                                             </AuthorizationLayout>
                                             </div>
                                             <div style={{display : "flex", justifyContent : "center"}}>
                                                <AuthorizationLayout
                                                   authorized={[
                                                      "ADMIN_PRIMEO",
                                                      "ADMIN_COURTAGE",
                                                      "RESPONSABLE_VENDEUR",
                                                   ]}
                                                   current={user?.type}
                                                >
                                                   <CustomRadio
                                                      value="VENDEUR"
                                                      controllerValue={value}
                                                      name={t("adduser.type4")}
                                                      target="type"
                                                      onChange={(e) => {
                                                         dispatch(
                                                            adduserStore.update({
                                                               target: "type",
                                                               value: e.target
                                                                  .value,
                                                            })
                                                         );
                                                         onChange(e.target.value);
                                                      }}
                                                      onBlur={onBlur}
                                                   />
                                                </AuthorizationLayout>
                                                <AuthorizationLayout
                                                      authorized={[
                                                         "ADMIN_PRIMEO",
                                                         "CHARGE_CLIENT"
                                                      ]}
                                                      current={user?.type}
                                                   >
                                                      <CustomRadio
                                                         value="CHARGE_CLIENT"
                                                         controllerValue={value}
                                                         name={"Chargé client"}
                                                         target="type"
                                                         onChange={(e) => {
                                                            dispatch(
                                                               adduserStore.update({
                                                                  target: "type",
                                                                  value: e.target
                                                                     .value,
                                                               })
                                                            );
                                                            onChange(e.target.value);
                                                         }}
                                                         onBlur={onBlur}
                                                      />
                                                   </AuthorizationLayout>
                                             </div>
                                          </div>
                                       </div>
                                       {errors?.type ? (
                                          <p className="type_error">
                                             {errors.type.message}
                                          </p>
                                       ) : undefined}
                                    </>
                                 )}
                              />
                           </Grid>
                           <Grid item xs={12}>
                              {!newUserIsVendeur && (
                                 <Controller
                                    name="typeVendeur"
                                    control={control}
                                    rules={{
                                       required: t("error.required"),
                                    }}
                                    defaultValue={t("adduser.remote")}
                                    render={({
                                       field: { onChange, onBlur, value },
                                    }) => {
                                       return (
                                          <ComboBox
                                             label={t(
                                                "adduser.labelTypeVendeur"
                                             )}
                                             onChange={onChange}
                                             value={value}
                                             list={[
                                                t("adduser.remote"),
                                                t("adduser.door2door"),
                                                t("adduser.both"),
                                             ]}
                                             required
                                             helperText={t("error.required")}
                                             error={
                                                errors?.statut !== undefined
                                             }
                                             onBlur={(e) => {
                                                onBlur(e);
                                             }}
                                             autoComplete={autoCompleteAlea()}
                                             fullWidth={true}
                                          />
                                       );
                                    }}
                                 />
                              )}
                           </Grid>
                           <Grid item xs={12}>
                              <Controller
                                 name="nom"
                                 control={control}
                                 rules={{
                                    required: t("error.required"),
                                    validate: formatErrorNames,
                                 }}
                                 defaultValue=""
                                 render={({
                                    field: { value, onChange, onBlur },
                                 }) => (
                                    <TextInput
                                       className="adduser-input-text"
                                       value={value}
                                       label={t("adduser.labelFamilyName")}
                                       onChange={onChange}
                                       required
                                       error={errors.nom !== undefined}
                                       helperText={errors.nom?.message}
                                       onBlur={(e) => {
                                          dispatch(
                                             adduserStore.update({
                                                target: "nom",
                                                value: value,
                                             })
                                          );
                                          onBlur(e);
                                       }}
                                       autoComplete="off"
                                    />
                                 )}
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <Controller
                                 name="prenom"
                                 control={control}
                                 rules={{
                                    required: t("error.required"),
                                    validate: formatErrorNames,
                                 }}
                                 defaultValue=""
                                 render={({
                                    field: { value, onChange, onBlur },
                                 }) => (
                                    <TextInput
                                       value={value}
                                       label="Prénom"
                                       onChange={onChange}
                                       required
                                       error={errors.prenom !== undefined}
                                       helperText={errors.prenom?.message}
                                       onBlur={(e) => {
                                          dispatch(
                                             adduserStore.update({
                                                target: "prenom",
                                                value: value,
                                             })
                                          );
                                          onBlur(e);
                                       }}
                                       autoComplete={autoCompleteAlea()}
                                    />
                                 )}
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <Controller
                                 name="email"
                                 control={control}
                                 rules={{
                                    required: t("error.required"),
                                    validate: formatErrorEmail,
                                 }}
                                 render={({
                                    field: { onChange, value, onBlur },
                                 }) => (
                                    <TextInput
                                       value={value}
                                       type="email"
                                       label={"E-mail"}
                                       onChange={onChange}
                                       required={true}
                                       error={errors.email !== undefined}
                                       helperText={errors.email?.message}
                                       onBlur={(e) => {
                                          dispatch(
                                             adduserStore.update({
                                                target: "email",
                                                value: value,
                                             })
                                          );
                                          onBlur(e);
                                       }}
                                       autoComplete={autoCompleteAlea()}
                                    />
                                 )}
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <Controller
                                 name="telephone"
                                 control={control}
                                 rules={{
                                    required: t("error.required"),
                                    validate: formatErrorPhoneNumber,
                                 }}
                                 render={({
                                    field: { onChange, value, onBlur },
                                 }) => (
                                    <TextInput
                                       value={value}
                                       type="tel"
                                       label={"Mobile"}
                                       required={true}
                                       onChange={onChange}
                                       error={errors.telephone !== undefined}
                                       helperText={errors.telephone?.message}
                                       onBlur={(e) => {
                                          onBlur(e);
                                          dispatch(
                                             adduserStore.update({
                                                target: "telephone",
                                                value: value,
                                             })
                                          );
                                       }}
                                       autoComplete={autoCompleteAlea()}
                                       maxLength={10}
                                    />
                                 )}
                              />
                           </Grid>

                           <Grid item xs={12}>
                              {newUserIsAdminPrimeo && newUserIsChargeClient && (
                                 <AuthorizationLayout
                                    authorized={["ADMIN_PRIMEO"]}
                                    current={user?.type}
                                 >
                                    <Controller
                                       name="idSocieteCourtage"
                                       control={control}
                                       rules={{ required: true }}
                                       render={({
                                          field: { onChange, onBlur, value },
                                       }) => (
                                          <ComboBoxObject
                                             label={t("adduser.labelBusiness")}
                                             onChange={(e) => {
                                                onChange(e);
                                                setOnChangeSociete(true);
                                             }}
                                             value={value}
                                             list={societes.map((b) => {
                                                return {
                                                   value: b.idSocieteCourtage,
                                                   name: b.name,
                                                };
                                             })}
                                             required
                                             helperText={t("error.required")}
                                             error={
                                                errors?.idSocieteCourtage !==
                                                undefined
                                             }
                                             onBlur={(e) => {
                                                dispatch(
                                                   adduserStore.update({
                                                      target:
                                                         "idSocieteCourtage",
                                                      value: value,
                                                   })
                                                );
                                                onBlur(e);
                                             }}
                                             autoComplete={autoCompleteAlea()}
                                             fullWidth={true}
                                             synch={synchroGeoSociete}
                                          />
                                       )}
                                    />
                                 </AuthorizationLayout>
                              )}
                           </Grid>
                           <Grid item xs={12}>
                              {!newUserIsVendeur && (
                                 <Controller
                                    name="idResponsable"
                                    control={control}
                                    rules={{
                                       required: t("error.required"),
                                    }}
                                    render={({
                                       field: { onChange, onBlur, value },
                                    }) => (
                                       <ComboBoxObject
                                          required={
                                             getValues("type") === "VENDEUR"
                                                ? true
                                                : false
                                          }
                                          label={t("adduser.labelResp")}
                                          value={value}
                                          list={filterRespo(user)?.map((r) => {
                                             return {
                                                value: r.idUtilisateur,
                                                name: r.nom + " " + r.prenom,
                                             };
                                          })}
                                          helperText={t("error.required")}
                                          error={
                                             errors?.idResponsable !== undefined
                                          }
                                          onChange={(e) => {
                                             onChange(e);

                                             const userResponsable =
                                                responsables.find(
                                                   (v) => v.idUtilisateur === e
                                                );

                                             dispatch(
                                                adduserStore.update({
                                                   target: "idSocieteCourtage",
                                                   value: userResponsable.idSocieteCourtage,
                                                })
                                             );
                                             setAutoSelectSociete(true);
                                          }}
                                          onBlur={(e) => {
                                             dispatch(
                                                adduserStore.update({
                                                   target: "idResponsable",
                                                   value: value,
                                                })
                                             );
                                             onBlur(e);
                                          }}
                                          autoComplete={autoCompleteAlea()}
                                          fullWidth={true}
                                       />
                                    )}
                                 />
                              )}
                           </Grid>
                           <Grid item xs={12}>
                              {newUserIsAdminPrimeo === true && newUserIsChargeClient && (
                                 <AuthorizationLayout
                                    authorized={["ADMIN_PRIMEO"]}
                                    current={user?.type}
                                 >
                                    <Controller
                                       name="exclusitiveGeo"
                                       control={control}
                                       rules={{
                                          required: t("error.required"),
                                       }}
                                       render={({
                                          field: { onChange, onBlur, value },
                                       }) => (
                                          <GeoExclu
                                             required={
                                                getValues("type") === "VENDEUR"
                                                   ? true
                                                   : false
                                             }
                                             label={t("geoComp.label")}
                                             value={value}
                                             error={errors.exclusitiveGeo}
                                             synch={synchroGeoSociete}
                                             helperText={
                                                errors?.exclusitiveGeo
                                                   ? t("error.required")
                                                   : ""
                                             }
                                             onChange={(value) => {
                                                onChange(value);
                                             }}
                                          />
                                       )}
                                    />
                                 </AuthorizationLayout>
                              )}
                           </Grid>
                           <Grid item xs={12}>
                              {newUserIsAdminPrimeo && newUserIsAdminCourtage && newUserIsChargeClient && (
                                    <Controller
                                       name="commission"
                                       control={control}
                                       rules={{
                                          required: t("error.required"),
                                          validate: check0to100,
                                       }}
                                       defaultValue="100"
                                       render={({
                                          field: { onChange, value, onBlur },
                                       }) => (
                                          <TextInput
                                             value={value}
                                             type="number"
                                             label={t("adduser.labelComm")}
                                             required={true}
                                             onChange={(v) => {
                                                onChange(v);
                                             }}
                                             error={
                                                errors.commission !== undefined
                                             }
                                             helperText={
                                                errors.commission?.message
                                             }
                                             onBlur={(e) => {
                                                dispatch(
                                                   adduserStore.update({
                                                      target: "commission",
                                                      value: value,
                                                   })
                                                );
                                                onBlur(e);
                                             }}
                                             autoComplete={autoCompleteAlea()}
                                             maxLength={3}
                                             percentage
                                          />
                                       )}
                                    />
                                 )}
                           </Grid>
                           <Grid item xs={12}>
                              {newUserIsAdminPrimeo &&
                                 newUserIsAdminCourtage && newUserIsChargeClient && (
                                    <Controller
                                       name="visuCommission"
                                       control={control}
                                       rules={{
                                          required: t("error.required"),
                                       }}
                                       render={({
                                          field: { value, onChange, onBlur },
                                       }) => (
                                          <div className="visuCommission_container">
                                             <p
                                                style={{
                                                   marginRight: "20px",
                                                }}
                                             >
                                                {" "}
                                                {t(
                                                   "adduser.visuCommission"
                                                )}{" "}
                                             </p>
                                             <BarreButton
                                                name="visuCommission"
                                                value={value}
                                                labelA={t("adduser.yes")}
                                                labelB={t("adduser.no")}
                                                error={errors.visuCommission}
                                                onChange={onChange}
                                                onBlur={(v) => {
                                                   dispatch(
                                                      adduserStore.update({
                                                         target:
                                                            "visuCommission",
                                                         value: value,
                                                      })
                                                   );
                                                   onBlur(v);
                                                }}
                                             />
                                          </div>
                                       )}
                                    />
                                 )}
                           </Grid>
                           <Grid item xs={12}>
                              <Controller
                                 name="statut"
                                 control={control}
                                 rules={{
                                    required: t("error.required"),
                                 }}
                                 defaultValue={
                                    statuts.find((s) => s.value === "ACTIF")
                                       ?.name
                                 }
                                 render={({
                                    field: { onChange, onBlur, value },
                                 }) => {
                                    return (
                                       <ComboBox
                                          label={t("adduser.labelStatut")}
                                          isbool={true}
                                          onChange={onChange}
                                          value={value}
                                          list={statuts.map((s) => s.name)}
                                          required
                                          helperText={t("error.required")}
                                          error={errors?.statut !== undefined}
                                          onBlur={(e) => {
                                             dispatch(
                                                adduserStore.update({
                                                   target: "statut",
                                                   value: value,
                                                })
                                             );
                                             onBlur(e);
                                          }}
                                          autoComplete={autoCompleteAlea()}
                                          fullWidth={true}
                                       />
                                    );
                                 }}
                              />
                           </Grid>
                        </Grid>

                        <Button type="submit" className={`green save`}>
                           {t("adduser.save")}
                        </Button>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default PopinAddUser;
