import React from "react";
import "./lien.scss";

import { ReactComponent as ArrowDownSvg } from "../../core/assets/picto/arrow.svg";

const Lien = ({
  href,
  icon,
  onClick,
  className,
  children,
  disabled,
  ...props
}) => {
  return (
    <a
      href={href}
      onClick={onClick}
      className={`lien ${disabled ? "" : "disabled"} ${className}`}
      {...props}
    >
      {children} {icon && <ArrowDownSvg />}
    </a>
  );
};

export default Lien;
