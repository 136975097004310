import React from "react";

const List = ({ blocState, onClick }) => {
  return (
    <svg
      width="30"
      height="26"
      viewBox="0 0 30 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{ marginLeft: "30px" }}
    >
      <path
        d="M4.64716 0.764648C5.42951 0.764648 6.05893 1.397 6.05893 2.17641V4.99994C6.05893 5.7823 5.42951 6.41171 4.64716 6.41171H1.82363C1.04422 6.41171 0.411865 5.7823 0.411865 4.99994V2.17641C0.411865 1.397 1.04422 0.764648 1.82363 0.764648H4.64716ZM28.1766 2.17641C28.9589 2.17641 29.5883 2.80877 29.5883 3.58818C29.5883 4.37053 28.9589 4.99994 28.1766 4.99994H10.2942C9.51187 4.99994 8.88245 4.37053 8.88245 3.58818C8.88245 2.80877 9.51187 2.17641 10.2942 2.17641H28.1766ZM28.1766 11.5882C28.9589 11.5882 29.5883 12.2176 29.5883 12.9999C29.5883 13.7823 28.9589 14.4117 28.1766 14.4117H10.2942C9.51187 14.4117 8.88245 13.7823 8.88245 12.9999C8.88245 12.2176 9.51187 11.5882 10.2942 11.5882H28.1766ZM28.1766 20.9999C28.9589 20.9999 29.5883 21.6294 29.5883 22.4117C29.5883 23.1941 28.9589 23.8235 28.1766 23.8235H10.2942C9.51187 23.8235 8.88245 23.1941 8.88245 22.4117C8.88245 21.6294 9.51187 20.9999 10.2942 20.9999H28.1766ZM0.411865 11.5882C0.411865 10.8058 1.04422 10.1764 1.82363 10.1764H4.64716C5.42951 10.1764 6.05893 10.8058 6.05893 11.5882V14.4117C6.05893 15.1941 5.42951 15.8235 4.64716 15.8235H1.82363C1.04422 15.8235 0.411865 15.1941 0.411865 14.4117V11.5882ZM4.64716 19.5882C5.42951 19.5882 6.05893 20.2176 6.05893 20.9999V23.8235C6.05893 24.6058 5.42951 25.2352 4.64716 25.2352H1.82363C1.04422 25.2352 0.411865 24.6058 0.411865 23.8235V20.9999C0.411865 20.2176 1.04422 19.5882 1.82363 19.5882H4.64716Z"
        fill="#00644B"
        fillOpacity={!blocState ? "1" : "0.25"}
      />
    </svg>
  );
};

export default List;
