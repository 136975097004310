import { add0IfNeededBefore } from "../../../../utils";

export function computeListRemunerationParPuissance(
  selectedSociete,
  addsociete,
  data
) {
  const res = [];

  Array.from({ length: 37 }).forEach((val, i) => {
    // if modify
    const index = add0IfNeededBefore(i);

    if (
      selectedSociete &&
      (addsociete?.[`KVA_${index}`] ||
        addsociete?.modifSociete?.[`KVA_${index}`])
    ) {
      return res.push(
        addsociete?.[`KVA_${index}`]
          ? { ...addsociete?.[`KVA_${index}`], idSociete: data?.idSociete }
          : {
              ...addsociete?.modifSociete?.[`KVA_${index}`],
              idSociete: data?.idSociete,
            }
      );
    }

    // if create
    if (!selectedSociete && i < 10 && i !== 0) {
      if (addsociete?.[`KVA_0${i}`]) {
        return res.push(addsociete?.[`KVA_0${i}`]);
      } else {
        return res.push({ kva: `KVA_0${i}`, remuneration: 0 });
      }
    }
    if (!selectedSociete && i > 9) {
      if (addsociete?.[`KVA_${i}`]) {
        return res.push(addsociete?.[`KVA_${i}`]);
      } else {
        return res.push({ kva: `KVA_${i}`, remuneration: 0 });
      }
    }
  });

  return res;
}
