import React, { useState } from "react";
import { Grid } from "@mui/material";
import Lien from "../../Lien/Lien";
import Button from "../../Button/Button";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as saleStore from "../../../core/stores/saleStore";
import { useDispatch } from "react-redux";

const OfferChoice = ({ offer }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    setValue,
    formState: { errors },
    control,
  } = useFormContext();

  const idOffer = offer?.ouid;

  const isOffer = offer?.offerDetail?.find((v) => v.ouid === idOffer);

  return (
    <>
      <Grid item xs={6}>
        <div className="bloc_offer-detail">
          <h3>{isOffer?.productSpecification?.name}</h3>
          <div className={`bloc_txt-details ${open ? "open" : ""}`}>
            {isOffer?.productSpecification?.description}
          </div>

          <Lien
            icon={true}
            className={`${open && "txt_open"}`}
            onClick={() => setOpen(!open)}
          >
            Détails
          </Lien>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Controller
            control={control}
            rules={{ required: t("error.required") }}
            name="currentOffer.selectOffer"
            render={({ field: { onChange, value } }) => {
              return (
                <div className="barrebutton-offer">
                  <Button
                    name="selectOffer"
                    value={isOffer?.productSpecification?.productNumber}
                    className={
                      value === isOffer?.productSpecification?.productNumber
                        ? "green "
                        : "green outline"
                    }
                    error={errors?.currentOffer?.selectOffer}
                    onClick={(e) => {
                      setValue("currentOffer.selectTarif", null);
                      dispatch(
                        saleStore.update({
                          target: "offerStorage",
                          value: isOffer?.productSpecification,
                        })
                      );
                      dispatch(
                        saleStore.update({
                          target: "optionTarifaireStorage",
                          value: null,
                        })
                      );
                      dispatch(saleStore.emptyOption());
                      onChange(e.target.value);
                    }}
                  >
                    {value === isOffer?.productSpecification?.productNumber
                      ? t("souscription.offre.btnSelectionnee")
                      : t("souscription.offre.btnSelection")}
                  </Button>
                </div>
              );
            }}
          />
        </div>
      </Grid>
    </>
  );
};

export default OfferChoice;
