import React, { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import "./comboBox.scss";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";

const ComboBox = ({
  list,
  label,
  value,
  onChange,
  error,
  helperText,
  fullWidth = false,
  isbool = false,
  ...props
}) => {
  const [selected, setSelected] = useState("" || value);

  const handleChange = (e) => {
    setSelected(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className="container_combobox">
      <FormControl
        sx={{ m: 1, minWidth: 280 }}
        size="small"
        fullWidth={fullWidth}
      >
        <InputLabel id="select-small" error={error} required>
          {label}
        </InputLabel>
        <Select
          labelId="select-small"
          label={label}
          value={selected || value || ""}
          onChange={handleChange}
          defaultValue={selected}
          autoWidth={!fullWidth}
          placeholder={label}
          error={error}
          {...props}
        >
          {list.map((item) => {
            return (
              <MenuItem
                value={item}
                key={item}
              >
                {item}
              </MenuItem>
            );
          })}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default ComboBox;
