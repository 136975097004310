import React from "react";
import PropTypes from "prop-types";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import "./styles/customtablehead.scss";

const CustomTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    action,
  } = props;
  const createSortHandler = (property, idForTri) => (event) => {
    onRequestSort(event, property, idForTri);
  };

  // function handleAction() {
  //   if (action.fnc) {
  //     return action.fnc();
  //   }
  //   return;
  // }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all",
            }}
            size="small"
          />
        </TableCell>
        {headCells?.map((headCell, index) => (
          <TableCell
            key={headCell.id + "headCell" + index}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id, headCell.idForTri)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        {/* {!!action && (
          <>
            <TableCell></TableCell>
            <TableCell
              align="center"
              className="modal_relative"
              onClick={() => handleAction()}
            >
              {action?.icon}
            </TableCell>
          </>
        )} */}
      </TableRow>
    </TableHead>
  );
};

CustomTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default CustomTableHead;
