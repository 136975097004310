import { TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowwDownSvg from "../../../SvgComponents/ArrowDownSvg";
import PaginationTab from "../Pagination/PaginationTab";
import "./styles/pagination.scss";

const PaginationManager = ({
  numbersPage,
  currentPage,
  rows,
  pagination = true,
  onChange,
  size,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(size);
  const [page, setPage] = useState(0);

  const count = -1;

  const { t } = useTranslation();

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    onChange(0, parseInt(event.target.value, 10));
  };

  const handleChangePage = (event, newPage) => {
    if (newPage >= 0 && newPage < numbersPage) {
      onChange(newPage, rowsPerPage);
    }
  };

  return (
    pagination && (
      <div className="pagination_container">
        {numbersPage > 1 && (
          <PaginationTab
            count={numbersPage}
            onChange={handleChangePage}
            currentPage={page}
          />
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={count === -1 ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="pagination_row"
          labelRowsPerPage={t("pagination.perPage")}
          SelectProps={{
            IconComponent: ArrowwDownSvg,
          }}
        />
      </div>
    )
  );
};

export default PaginationManager;
