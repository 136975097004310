import i18n from "i18next";
import { regularMobilePhoneToInternational, trueOrFalseDo } from ".";
import _ from "lodash";
import moment from "moment";

export function preventUndefinedString(params) {
   return params !== undefined ? params : "";
}

export function createTripicaOrder(data, sale) {
   let optList = sale?.optionStorage?.map((o) => o.name);

   let CAR = trueOrFalseDo(
      sale?.PDLInfos?.estimatedCar?.carBase?.value,
      () => {
         let conso = sale?.PDLInfos?.estimatedCar?.carBase?.value;

         return conso;
      },
      () => {
         return 0;
      }
   );
   const gender =
      data.gender === i18n.t("souscription.id.MALE") ? "MALE" : "FEMALE";

   function formatDate(date) {
      return new Date(date).getTime();
   }

   const valueStreet2 =
      sale?.billingAddress?.additionnalAddress?.length !== 0
         ? sale?.billingAddress?.additionnalAddress
         : " ";

   const valueStree2Pdl = data.PDLInfos.deliveryAddress.additionnalAddress?.length === 0 ? " " : data.PDLInfos.deliveryAddress.additionnalAddress;

   return {
      state: "ACKNOWLEDGED",
      planOrderComponent: {
         planName: sale.offerStorage.productNumber,
      },
      offerOrderComponent: {
         offerName: sale.offerStorage.productNumber,
         vendorType: "PRIMEO",
         vendorName: "PRIMEO",
         turpeIncluded: true,
         formuleTarifaireAcheminementCode:
            data.PDLInfos.formuleTarifaireAcheminement.code, // ???
         calendrierFrnCode: data.PDLInfos.calendrierFrn.code,
         puissanceMaximumSouscrite: data.PDLInfos.contractPowerMax.valeur,
      },
      offerOptionOrderComponent: {
         optionName: sale.optionTarifaireStorage.productNumber,
         indexName: data.currentOffer.selectTarif,
         optList: optList, // ???
      },
      loginOrderComponent: {
         email: data?.customerEmail, // ???
      },
      individualOrderComponent: {
         familyName: data.familyName,
         givenName: data.givenName,
         fullName: data.givenName + " " + data.familyName,
         gender: gender,
         birthdate: formatDate(data?.birthdate),
         nationality: "FR",
      },
      contactNumberOrderComponent: {
         mobile: regularMobilePhoneToInternational(
            "+33",
            "0",
            data.customerMobile
         ),
      },
      contactAddressOrderComponent: {
         street1:
            data.billingAddressChoice === "Adresse du PDL"
               ? data.PDLInfos.deliveryAddress?.housenumber +
               " " +
               data.PDLInfos.deliveryAddress?.street
               : sale?.billingAddress?.housenumberAndStreet,
         hamlet:
            data.billingAddressChoice === "Adresse du PDL"
               ? ""
               : sale?.billingAddress?.hamlet,
         supplement1:
            data.billingAddressChoice === "Adresse du PDL"
               ? ""
               : sale?.billingAddress?.additionnalAddress1,
         supplement2:
            data.billingAddressChoice === "Adresse du PDL"
               ? ""
               : sale?.billingAddress?.additionnalAddress2,
         city:
            data.billingAddressChoice === "Adresse du PDL"
               ? data.PDLInfos.deliveryAddress.city
               : sale?.billingAddress?.city,
         country: "FR",
         postCode:
            data.billingAddressChoice === "Adresse du PDL"
               ? data.PDLInfos.deliveryAddress.zipCode
               : sale?.billingAddress?.zipCode,
      },
      pointDeLivraisonOrderComponent: {
         customerFullName: data.givenName + " " + data.familyName,
         deliveryAddress: {
            street:
               data.PDLInfos.deliveryAddress?.housenumber +
               " " +
               data.PDLInfos.deliveryAddress?.street,
            additionnalAddress:
               data.PDLInfos.deliveryAddress?.additionnalAddress,
            city: data.PDLInfos.deliveryAddress.city,
            inseeCode: data.PDLInfos.deliveryAddress.inseeCode,
            zipCode: data.PDLInfos.deliveryAddress.zipCode,
            additionnalAddress2:
               data.PDLInfos.deliveryAddress.additionnalAddress2 || "",
         },
         car: CAR,
         pdlId: data.PDLInfos.pdlId,
         autoReleveBase: "1000",
         autoReleveHP: "1000",
         autoReleveHC: "1000",
         activationDate: moment(data.debitingDate).valueOf(),
         pointDonneesGenerales: sale?.PDLInfos?.pointDonneesGenerales,
      },
      souscriptionOrderComponent: {
         denyRightOfWithdrawal: data.renounceRetractation,
         billingType: data.consoMode,
         priceOffer: {
            monthlyAmount:
               sale?.consoMode === "SCHEDULED"
                  ? sale?.valueMonthSelected
                  : sale?.monthlyAmount,
         },
      },
      bankAccountPaymentMeanOrderComponent: {
         bankName: data.payment?.bankName || "string",
         iban: data.iban,
         bic: data.payment?.bic || "string",
         holder: data?.givenHolderBank,
         country: data.iban.split("")[0] + data.iban.split("")[1],
         city: data.payment?.city || "string",
         postCode: data.payment?.postCode || "string",
         street: data.payment?.street || "string",
      },
      billingRows: {
         subscriptionAmount: (sale?.monthlyAmountDetailed?.aboPartFixeMensuelTTC / 100)?.toFixed(2),
         consumptionBasePrice: (sale?.monthlyAmountDetailed?.consoPartVariableBaseTTC / 100000).toFixed(4),
         consumptionHP: (sale?.monthlyAmountDetailed?.consoPartVariableHpTTC / 100000).toFixed(4),
         consumptionHC: (sale?.monthlyAmountDetailed?.consoPartVariableHcTTC / 100000).toFixed(4),
         options:  sale?.optionStorage.map(option => {
            let prices = [...option?.productsOfferingPrices["0"]?.priceAlterations];
            prices.push(option?.productsOfferingPrices["0"]?.price);
            if (sale?.optionStorage?.length < 2) {
               return {
                  name : option?.productSpecification?.name,
                  price : prices.find((p) => p?.priceCondition === undefined)?.taxIncludedAmount /100 / 12
               }
            }
         })
      }
   };
}
