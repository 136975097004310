/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormContext, Controller, useFieldArray } from "react-hook-form";
import { Grid } from "@mui/material";
import TextInput from "../TextInput/TextInput";
import PhoneInput from "../TextInput/PhoneInput";
import Bloc from "../Bloc/Bloc";
import DeleteButton from "../DeleteButton/DeleteButton";
import BarreButton from "../BarreButton/BarreButton";
import CheckboxButton from "../Checkbox/CheckboxButton";
import DatePicker from "../DatePicker/datePickerV1";
import {
   dateIsBetween,
   formatErrorNames,
   formatErrorPhoneNumber,
   setMinMaxDate,
   formatErrorEmail,
   autoCompleteAlea,
   formatErrorLastnames,
} from "../../utils";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/min/moment-with-locales";
import * as saleActions from "../../core/stores/saleStore";
import "./styles/identityBloc.scss";
moment.locale("fr");

const IdentityBloc = ({ open, setOpen }) => {
   const [checkIdentity, setCheckIdentity] = useState(false);

   const params = useSelector((state) => state.params);
   const broker = useSelector((state) => state.auth?.broker);
   const sale = useSelector((state) => state.sale);

   const { t } = useTranslation();
   const dispatch = useDispatch();

   const {
      watch,
      getValues,
      control,
      formState: { errors },
      setError,
      clearErrors,
      setValue,
   } = useFormContext();

   const { fields, append, remove } = useFieldArray({
      control: control,
      name: "relative",
   });

   function resetMainCustomerFields() {
      setValue("familyName", "");
      setValue("givenName", "");
      setValue("gender", null);
      setValue("birthdate", params?.dates.birth.defaultvalue);
      setValue("validTitulaire", null);
      clearErrors([
         "birthdate",
         "familyName",
         "givenName",
         "gender",
         "validTitulaire",
      ]);
      return dispatch(
         saleActions.update({
            target: "birthdate",
            value: JSON.stringify(new Date(params?.dates.birth.defaultvalue)),
         })
      );
   }

   const contactValid = () => {
      const testValidName = getValues("relative")?.map((v, i) => {
         return (
            formatErrorNames(getValues(`relative.${i}.givenName`)) ===
               undefined && getValues(`relative.${i}.givenName`)?.length > 0
         );
      });
      const testValidFamilyName = getValues("relative")?.map(
         (v, i) =>
         formatErrorLastnames(getValues(`relative.${i}.familyName`)) ===
               undefined && getValues(`relative.${i}.familyName`)?.length > 0
      );
      if (
         (testValidName?.every((value) => value) ||
            testValidName === undefined) &&
         (testValidFamilyName?.every((value) => value) ||
            testValidFamilyName === undefined)
      ) {
         return true;
      }
      return false;
   };

   const handleClickContact = () => {
      append({
         gender: "",
         familyName: "",
         givenName: "",
         birthdate: new Date(params?.dates.birth.defaultvalue),
      });
   };

   const isIdentityComplete = () => {
      if (
         getValues("gender") !== (null || undefined) &&
         !formatErrorNames(getValues("familyName")) &&
         !formatErrorNames(getValues("givenName")) &&
         contactValid() === true &&
         dateIsBetween(
            getValues("birthdate"),
            params?.dates.birth.min,
            params?.dates.birth.max
         ) === true &&
         !formatErrorEmail(getValues("customerEmail")) &&
         !formatErrorPhoneNumber(getValues("customerMobile"), broker?.phone) &&
         !!getValues("validTitulaire") &&
         errors.gender === undefined &&
         errors.birthdate === undefined &&
         errors.relative === undefined &&
         errors.validTitulaire === undefined
      ) {
         dispatch(saleActions.update({ target: "identityCheck", value: true }));
         return setCheckIdentity(true);
      }
      dispatch(saleActions.update({ target: "identityCheck", value: false }));
      return setCheckIdentity(false);
   };

   useEffect(() => {
      const fieldResfresh = sale?.fieldNames;
      setValue(
         "relative",
         fieldResfresh?.map((v) => {
            return {
               ...v,
               birthdate: JSON.parse(v.birthdate),
            };
         })
      );
      if (sale.birthdate) {
         setValue("birthdate", JSON.parse(sale?.birthdate));
      }
      // else {
      //   dispatch(
      //     saleActions.update({
      //       target: "birthdate",
      //       value: JSON.stringify(
      //         moment(params?.dates.birth.defaultvalue).format("DD-MM-YYYY")
      //       ),
      //     })
      //   );
      // }
      isIdentityComplete();
   }, []);

   useEffect(() => {
      isIdentityComplete();
   }, [
      checkIdentity,
      handleClickContact,
      watch("gender"),
      watch("familyName"),
      watch("givenName"),
      watch("birthdate"),
      watch("customerEmail"),
      watch("customerMobile"),
      watch("relative.0.gender"),
      watch("relative.1.gender"),
      watch("relative.2.gender"),
      watch("relative.0.givenName"),
      watch("relative.1.givenName"),
      watch("relative.2.givenName"),
      watch("relative.0.familyName"),
      watch("relative.1.familyName"),
      watch("relative.2.familyName"),
      watch("relative.0.birthdate"),
      watch("relative.1.birthdate"),
      watch("relative.2.birthdate"),
   ]);

   // Synchronisation for resfresh data
   useEffect(() => {
      dispatch(
         saleActions.update({
            target: "fieldNames",
            value: fields?.map((v, i) => {
               return {
                  ...v,
                  gender: getValues("relative")[i].gender,
                  familyName: getValues("relative")[i].familyName,
                  givenName: getValues("relative")[i].givenName,
                  birthdate: JSON.stringify(getValues("relative")[i].birthdate),
               };
            }),
         })
      );
   }, [
      watch("relative.0.gender"),
      watch("relative.1.gender"),
      watch("relative.2.gender"),
      watch("relative.0.givenName"),
      watch("relative.1.givenName"),
      watch("relative.2.givenName"),
      watch("relative.0.familyName"),
      watch("relative.1.familyName"),
      watch("relative.2.familyName"),
      watch("relative.0.birthdate"),
      watch("relative.1.birthdate"),
      watch("relative.2.birthdate"),
   ]);

   return (
      <Bloc
         icon={"id"}
         title={t("souscription.id.title")}
         checked={checkIdentity}
         info={
            checkIdentity
               ? getValues("givenName") + " " + getValues("familyName")
               : null
         }
         open={open}
         setIsOpen={setOpen}
         className="bloc_id"
         note={
            params?.noteAdmin?.filter((v) => v.word === "IDENTITY")[0]?.value
         }
      >
         <Grid container spacing={3}>
            <Grid item xs={8}>
               <Controller
                  control={control}
                  rules={{ required: t("error.required") }}
                  name="gender"
                  render={({ field: { onChange, value, onBlur } }) => (
                     <BarreButton
                        name="gender"
                        value={value}
                        labelA={"Madame"}
                        labelB={"Monsieur"}
                        onChange={(v) => {
                           onChange(v);
                           dispatch(
                              saleActions.update({
                                 target: "gender",
                                 value: v,
                              })
                           );
                        }}
                        error={errors.gender}
                        onBlur={(e) => {
                           dispatch(
                              saleActions.update({
                                 target: "gender",
                                 value: value,
                              })
                           );
                           onBlur(e);
                        }}
                     />
                  )}
               />
            </Grid>
            <DeleteButton xs={4} onClick={resetMainCustomerFields} />
            <Grid item xs={12} sm={4} xl={3}>
               <Controller
                  name="givenName"
                  control={control}
                  rules={{
                     required: t("error.required"),
                     validate: formatErrorNames,
                  }}
                  defaultValue=""
                  render={({ field: { value, onChange, onBlur } }) => (
                     <TextInput
                        value={value}
                        label="Prénom"
                        onChange={onChange}
                        required
                        error={errors.givenName !== undefined}
                        helperText={errors.givenName?.message}
                        onBlur={(e) => {
                           dispatch(
                              saleActions.update({
                                 target: "givenName",
                                 value: value,
                              })
                           );
                           onBlur(e);
                        }}
                        autoComplete={autoCompleteAlea()}
                     />
                  )}
               />
            </Grid>
            <Grid item xs={12} sm={4} xl={3}>
               <Controller
                  name="familyName"
                  control={control}
                  rules={{
                     required: t("error.required"),
                     validate: formatErrorLastnames,
                  }}
                  render={({ field: { value, onChange, onBlur } }) => (
                     <TextInput
                        value={value}
                        label="Nom"
                        onChange={onChange}
                        required
                        error={errors.familyName !== undefined}
                        helperText={errors.familyName?.message}
                        onBlur={(e) => {
                           dispatch(
                              saleActions.update({
                                 target: "familyName",
                                 value: value,
                              })
                           );
                           onBlur(e);
                        }}
                        autoComplete={autoCompleteAlea()}
                     />
                  )}
               />
            </Grid>

            <Grid item xs={12} sm={4} xl={3}>
               <Controller
                  control={control}
                  rules={{
                     required: t("error.required"),
                     // valueAsDate: true,
                     // ICI
                     validate: {
                        isBetween: (v) =>
                           dateIsBetween(
                              v,
                              params?.dates.birth.min,
                              params?.dates.birth.max
                           )
                              ? true
                              : t("error.dateNotInRange"),
                     },
                  }}
                  // defaultValue={params?.dates.birth.defaultvalue}
                  name="birthdate"
                  render={({ field: { onChange, value, onBlur } }) => (
                     <DatePicker
                        value={value}
                        name="birthdate"
                        onChange={(e) => {
                           dispatch(
                              saleActions.update({
                                 target: "birthdate",
                                 value: JSON.stringify(value),
                              })
                           );
                           onChange(e);
                        }}
                        onBlur={(e) => {
                           dispatch(
                              saleActions.update({
                                 target: "birthdate",
                                 value: JSON.stringify(value),
                              })
                           );
                           onBlur(e);
                        }}
                        label="Date de naissance"
                        limitation={{
                           min: setMinMaxDate(params?.dates.birth.min),
                           max: setMinMaxDate(params?.dates.birth.max),
                        }}
                        error={errors.birthdate}
                        required
                     />
                  )}
               />
            </Grid>
         </Grid>

         {fields?.map((field, index) => (
            <Grid container spacing={3} key={field.id}>
               <Grid item xs={8}>
                  <Controller
                     control={control}
                     rules={{ required: t("error.required") }}
                     name={`relative.${index}.gender`}
                     render={({ field: { onChange, value, onBlur } }) => (
                        <BarreButton
                           name={`relative.${index}.gender`}
                           value={value}
                           labelA={"Madame"}
                           labelB={"Monsieur"}
                           onChange={onChange}
                           error={errors?.relative?.[index]?.gender}
                           onBlur={(e) => {
                              dispatch(
                                 saleActions.update({
                                    target: `relative.${index}.gender`,
                                    value: value,
                                 })
                              );
                              onBlur(e);
                           }}
                        />
                     )}
                  />
               </Grid>
               <DeleteButton xs={4} onClick={() => remove(index)} />
               <Grid item xs={12} sm={4} xl={3}>
                  <Controller
                     control={control}
                     rules={{
                        required: t("error.required"),
                        validate: formatErrorNames,
                     }}
                     name={`relative.${index}.familyName`}
                     render={({ field: { value, onChange, onBlur } }) => (
                        <TextInput
                           value={value}
                           label="Nom"
                           onChange={onChange}
                           required
                           error={
                              errors?.relative?.[index]?.familyName !==
                              undefined
                           }
                           helperText={
                              errors?.relative?.[index]?.familyName?.message
                           }
                           onBlur={(e) => {
                              dispatch(
                                 saleActions.update({
                                    target: `relative.${index}.familyName`,
                                    value: value,
                                 })
                              );
                              onBlur(e);
                           }}
                           autoComplete={autoCompleteAlea()}
                        />
                     )}
                  />
               </Grid>
               <Grid item xs={12} sm={4} xl={3}>
                  <Controller
                     control={control}
                     rules={{
                        required: t("error.required"),
                        validate: formatErrorNames,
                     }}
                     name={`relative.${index}.givenName`}
                     render={({ field: { value, onChange, onBlur } }) => (
                        <TextInput
                           value={value}
                           label="Prénom"
                           onChange={onChange}
                           required
                           error={
                              errors?.relative?.[index]?.givenName !== undefined
                           }
                           helperText={
                              errors?.relative?.[index]?.givenName?.message
                           }
                           onBlur={(e) => {
                              dispatch(
                                 saleActions.update({
                                    target: `relative.${index}.givenName`,
                                    value: value,
                                 })
                              );
                              onBlur(e);
                           }}
                           autoComplete={autoCompleteAlea()}
                        />
                     )}
                  />
               </Grid>
               <Grid item xs={12} sm={4} xl={3}>
                  <Controller
                     control={control}
                     rules={{
                        required: t("error.required"),
                        valueAsDate: true,
                        validate: {
                           isBetween: (v) =>
                              dateIsBetween(
                                 v,
                                 params?.dates.birth.min,
                                 params?.dates.birth.max
                              ),
                        },
                     }}
                     defaultValue={moment(params?.dates.birth.defaultvalue)}
                     name={`relative.${index}.birthdate`}
                     render={({ field: { onChange, value, onBlur } }) => (
                        <DatePicker
                           value={value}
                           onChange={(e) => {
                              dispatch(
                                 saleActions.update({
                                    target: `relative.${index}.birthdate`,
                                    value: JSON.stringify(e),
                                 })
                              );
                              onChange(e);
                           }}
                           label="Date de naissance"
                           limitation={{
                              min: setMinMaxDate(params?.dates.birth.min),
                              max: setMinMaxDate(params?.dates.birth.max),
                           }}
                           error={errors?.relative?.[index]?.birthdate}
                           onBlur={(e) => {
                              dispatch(
                                 saleActions.update({
                                    target: `relative.${index}.birthdate`,
                                    value: JSON.stringify(value),
                                 })
                              );

                              onBlur(e);
                           }}
                           required
                        />
                     )}
                  />
               </Grid>
            </Grid>
         ))}

         {
            // HERE Add user on the contrat
            /* {fields.length < 3 && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
               style={{ display: "inline-flex", marginTop: "15px" }}
               onClick={() => handleClickContact()}
            >
               Ajouter un nom au contrat +
            </a>
         )} */
         }

         <Grid container spacing={3} style={{ marginTop: "0px" }}>
            <Grid item xs={16} sm={8} xl={6}>
               <Controller
                  control={control}
                  rules={{
                     required: t("error.required"),
                     validate: formatErrorEmail,
                  }}
                  name="customerEmail"
                  render={({ field: { onChange, value, onBlur } }) => (
                     <TextInput
                        value={value}
                        type="email"
                        label={"E-mail"}
                        onChange={onChange}
                        required={true}
                        error={errors.customerEmail !== undefined}
                        helperText={errors.customerEmail?.message}
                        onBlur={(e) => {
                           dispatch(
                              saleActions.update({
                                 target: "customerEmail",
                                 value: value,
                              })
                           );
                           onBlur(e);
                        }}
                        autoComplete={autoCompleteAlea()}
                     />
                  )}
               />
            </Grid>
            <Grid item xs={16} sm={4} xl={3}>
               <Controller
                  control={control}
                  rules={{
                     required: t("error.required"),
                     validate: {
                        validation: (v) =>
                           formatErrorPhoneNumber(v, broker?.phone),
                     },
                  }}
                  name="customerMobile"
                  render={({ field: { onChange, value, onBlur } }) => (
                     <PhoneInput
                        value={value}
                        label={"Mobile"}
                        // placeholder={t("souscription.id.phone")}
                        required={true}
                        onChange={onChange}
                        error={errors.customerMobile !== undefined}
                        helperText={errors.customerMobile?.message}
                        onBlur={(e) => {
                           dispatch(
                              saleActions.update({
                                 target: "customerMobile",
                                 value: value,
                              })
                           );
                           onBlur(e);
                        }}
                        autoComplete={autoCompleteAlea()}
                        maxLength={10}
                     />
                  )}
               />
               {/* Fix autocomplete form .... */}
               <input
                  value=""
                  autoComplete="on"
                  style={{
                     display: "none",
                     opacity: 0,
                     position: "absolute",
                     left: "-100000px",
                  }}
                  readOnly
               />
            </Grid>
            <Grid item xs={12}>
               <Controller
                  control={control}
                  name="validTitulaire"
                  defaultValue={false}
                  rules={{
                     required: t("error.required"),
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                     <CheckboxButton
                        className="gray"
                        text={t("souscription.id.validTitulaire")}
                        onChange={(v) => {
                           onChange(v);

                           if (v === false) {
                              setError("validTitulaire", {
                                 type: "required",
                                 message: t("error.required"),
                              });
                           } else {
                              clearErrors("validTitulaire");
                              dispatch(
                                 saleActions.update({
                                    target: "validTitulaire",
                                    value: v,
                                 })
                              );
                           }
                        }}
                        required={true}
                        value={value}
                        checked={value}
                        error={errors.validTitulaire}
                        helperText={t("error.required")}
                        onBlur={(v) => {
                           dispatch(
                              saleActions.update({
                                 target: "validTitulaire",
                                 value: value,
                              })
                           );
                           onBlur(v);
                        }}
                     />
                  )}
               />
            </Grid>
         </Grid>
      </Bloc>
   );
};

export default IdentityBloc;
