import React from "react";
import { useTranslation } from "react-i18next";
import "./errorCodeListDisplay.scss";

const PDLSGTError = ({ errorCode }) => {
   const { t } = useTranslation();

   if (errorCode) {
      return (
         <div style={{ padding: "0 24px" }}>
            <p key={errorCode} className="errorCode">
               {t("souscription.pdl.PDLSGTErrorPart1")} {errorCode}{" "}
               {t("souscription.pdl.PDLSGTErrorPart2")}
            </p>
         </div>
      );
   }
   return null;
};

export default PDLSGTError;
