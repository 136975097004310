import React from "react";

const Search = ({ fill }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3503_15625)">
        <path
          d="M11.6255 10.5H11.033L10.823 10.2975C11.723 9.24748 12.188 7.81498 11.933 6.29248C11.5805 4.20748 9.84047 2.54248 7.74047 2.28748C4.56797 1.89748 1.89797 4.56748 2.28797 7.73998C2.54297 9.83998 4.20797 11.58 6.29297 11.9325C7.81547 12.1875 9.24797 11.7225 10.298 10.8225L10.5005 11.0325V11.625L13.688 14.8125C13.9955 15.12 14.498 15.12 14.8055 14.8125C15.113 14.505 15.113 14.0025 14.8055 13.695L11.6255 10.5ZM7.12547 10.5C5.25797 10.5 3.75047 8.99248 3.75047 7.12498C3.75047 5.25748 5.25797 3.74998 7.12547 3.74998C8.99297 3.74998 10.5005 5.25748 10.5005 7.12498C10.5005 8.99248 8.99297 10.5 7.12547 10.5Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3503_15625">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Search;
