import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CheckboxButton from "../../Checkbox/CheckboxButton";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as saleActions from "../../../core/stores/saleStore";
import _ from "lodash";
import { replacePointByCommaInDecimals, safeEval } from "../../../utils";

const Option = ({ offers, selectedOffer }) => {
  const [currentOffer, setCurrentOffer] = useState([]);
  const [currentTarif, setCurrentTarif] = useState([]);
  const sale = useSelector((state) => state?.sale);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    watch,
    getValues,
    formState: { errors },
    control,
  } = useFormContext();

  function getOptionsPrix(option, nbBundleName) {
    // je dois aller chercher le bon prix dans l'option en f° de la puissanceSouscrite et du nbBundleName
    //   let nbBundleName = sale?.optionStorage?.length;

    let prices = [...option?.productsOfferingPrices["0"]?.priceAlterations];
    prices.push(option?.productsOfferingPrices["0"]?.price);
    if (nbBundleName < 2) {
      return (
        <>
          <h2>
            {replacePointByCommaInDecimals(
              (
                prices.find((p) => p?.priceCondition === undefined)
                  ?.taxIncludedAmount / 100
              ).toFixed(2) / 12
            )}
            €
          </h2>
          <p>TTC/mois</p>
        </>
      );
    } else {
      return (
        <>
          <h3
            style={{
              textDecoration: "line-through",
              margin: "0 8px 0 0",
            }}
          >
            {replacePointByCommaInDecimals(
              (
                prices.find((p) => p?.priceCondition === undefined)
                  ?.taxIncludedAmount / 100
              ).toFixed(2) / 12
            )}
            €
          </h3>
          <h2 className="green">
            {replacePointByCommaInDecimals(
              (
                prices.find(
                  (p) =>
                    p?.priceCondition !== undefined &&
                    safeEval(p.priceCondition.split(" "), nbBundleName)
                )?.price?.taxIncludedAmount / 100
              ).toFixed(2) / 12
            )}{" "}
            €
          </h2>
          <p>TTC/mois</p>
        </>
      );
    }
  }

  useEffect(() => {
    let tmpCurrentOffer = offers?.find((o) => o?.name === selectedOffer);
    setCurrentOffer(tmpCurrentOffer);
    let tmpCurrentTarif = tmpCurrentOffer?.tarifications.find(
      (t) =>
        t?.tarification?.productSpecification?.productNumber ===
        getValues("currentOffer.selectTarif")
    );
    setCurrentTarif(tmpCurrentTarif);
  }, [watch("currentOffer.selectedOffer"), watch("currentOffer.selectTarif")]);

  return (
    <Grid item xs={12}>
      {!!sale?.currentOffer?.selectTarif && (
        <div className="offer-container">
          <h3>{t("souscription.offre.optionTitle")}</h3>
          {_.uniq(currentOffer?.optionsCommercials)?.map((option) => {
            return (
              <div
                className="line-options"
                key={option?.productSpecification?.ouid}
              >
                <CheckboxButton
                  value={option}
                  checked={
                    !!sale?.optionStorage.find((o) => o.ouid === option.ouid)
                  }
                  valueIsObject
                  text={option?.productSpecification?.name}
                  priceLabel={getOptionsPrix(
                    option,
                    sale?.optionStorage?.length
                  )}
                  onChange={(v) => {
                    dispatch(
                      saleActions.updateOption({
                        value: v,
                      })
                    );
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
    </Grid>
  );
};

export default Option;
