import React from "react";
import "./loader.scss";

const Loader = ({ isAddressLoader }) => {
  const circles = [...Array(16)].map((_, index) => {
    return (
      <div key={index}>
        <div className="div-after"></div>
      </div>
    );
  });
  return (
    <div className="anchor">
      <div className={isAddressLoader ? "spinner addressLoader" : "spinner"}>
        {circles}
      </div>
    </div>
  );
};

export default Loader;
