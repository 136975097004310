import React from "react";
import "./styles/pagination.scss";
import { ReactComponent as BeforeSvg } from "../../../../core/assets/picto/paginationBefore.svg";
import { ReactComponent as NextSvg } from "../../../../core/assets/picto/paginationNext.svg";
import { useTranslation } from "react-i18next";
import usePagination from "@mui/material/usePagination";
import { List } from "@mui/material";

const PaginationTab = ({ count, onChange, currentPage }) => {
  const { t } = useTranslation();

  const { items } = usePagination({
    count: count,
    page: currentPage + 1,
  });

  return (
    <nav className="pagination_nav">
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = <div className="ellipsis">...</div>;
          } else if (type === "previous") {
            children = (
              <button type="button" className="prevBtn" {...item}>
                <BeforeSvg /> {t("pagination.precedent")}
              </button>
            );
          } else if (type === "next") {
            children = (
              <button type="button" className="nextBtn" {...item}>
                {t("pagination.suivant")}
                <NextSvg />
              </button>
            );
          } else if (type === "page") {
            children = (
              <button
                type="button"
                className={`${selected && "selected"}`}
                {...item}
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button type="button" {...item}>
                {type}
              </button>
            );
          }

          return (
            <li onClick={(e) => onChange(e, page - 1)} key={index}>
              {children}
            </li>
          );
        })}
      </List>
    </nav>
  );
};

export default PaginationTab;
