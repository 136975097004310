import React from "react";

const ArrowUpSvg = ({ fill }) => {
  return (
    <>
      <svg
        width="10"
        height="9"
        viewBox="0 0 10 9"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.15384 1.34345C4.54607 0.720702 5.45392 0.720702 5.84615 1.34345L9.23065 6.71706C9.65007 7.38298 9.17149 8.25 8.3845 8.25H1.6155C0.828507 8.25 0.349928 7.38298 0.769348 6.71706L4.15384 1.34345Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default ArrowUpSvg;
