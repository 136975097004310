/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextInput from "../../TextInput/TextInput";
import {
  checkEnterKeyDown,
  formatErrorPhoneNumber,
  trueOrFalseDo,
} from "../../../utils";
import Button from "../../Button/Button";
import CustomRadio from "../CustomRadio/CustomRadio";
import BarreButton from "../../BarreButton/BarreButton";
import {
  formatErrorNames,
  formatErrorEmail,
  autoCompleteAlea,
  check0to100,
} from "../../../utils";
import AuthorizationLayout from "../../HOC/AuthorizationLayout/AuthorizationLayout";
import { Grid } from "@mui/material";
import * as adduserStore from "../../../core/stores/adduserStore";
import ComboBoxObject from "../../ComboBox/ComboBoxObject";
import "../PopinAddUser/styles/popinadduser.scss";
import "./styles/popinmodifyuser.scss";
import CheckboxButton from "../../Checkbox/CheckboxButton";
import Loader from "../../Loader/Loader";
import GeoExclu from "../GeoExclu/GeoExclu";
import {
  getUsers,
  logout,
  putUser,
} from "../../../core/services/httpRequest/axios";
import { update } from "../../../core/stores/paramsStore";
import { sendNotif } from "../../../core/stores/utils";
import { filterRespo, handleRespoIdByType } from "./utils/utils";
import Notifaction from "../../../components/BOCO/Notification/Notifaction";
import { ReactComponent as CrossSvg } from "../../../core/assets/picto/cross.svg";
import handleRequestResponse from "../../../utils/responsesHandler";
import _ from "lodash";
import PermissionWrapper from "../../HOC/PermissionWrapper";

const PopinModifyUser = ({
  user,
  selectedUser,
  onClose,
  onSuccess,
  onTransferSuccessPopin,
  onTransfer,
  loading,
}) => {
  // const [newUserIsAdminPrimeo, setnewUserIsAdminPrimeo] = useState(true);
  // const [newUserIsAdminCourtage, setnewUserIsAdminCourtage] = useState(true);
  const [newUserIsResponsableVendeur, setnewUserIsResponsableVendeur] =
    useState(true);
  const [newUserIsVendeur, setnewUserIsVendeur] = useState(false);
  const [responsables, setResponsables] = useState([]);
  const [respoInit, setrespoInit] = useState([]);
  const [showTeam, setShowTeam] = useState(false);
  const [team, setTeam] = useState([]);
  const [alertType, setAlertType] = useState(false);
  const [isTransferDisabled, setIsTransferDisabled] = useState(false);
  const [listResponsables, setlistResponsables] = useState([]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const statuts = [
    { value: "ACTIF", name: t("adduser.active") },
    { value: "INACTIF", name: t("adduser.inactive") },
  ];

  const typeVendeur = [
    { value: "REMOTE", name: t("adduser.remote") },
    { value: "DOOR_TO_DOOR", name: t("adduser.door2door") },
    { value: "BOTH", name: t("adduser.both") },
  ];

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
    // defaultValues: useMemo(() => {
    //   return selectedUser;
    // }, [selectedUser]),
    defaultValues: selectedUser,
  });

  // Return list responsable
  useEffect(() => {
    setlistResponsables(
      filterRespo(user, selectedUser, responsables).map((r) => {
        return {
          value: r.idUtilisateur,
          name: r.prenom + " " + r.nom,
        };
      })
    );
  }, [user, selectedUser, responsables]);

  function authorizeModifGeo(selectedUserType) {
    let authCases = [
      {
        type: "ADMIN_PRIMEO",
        rights: ["ADMIN_COURTAGE", "RESPONSABLE_VENDEUR", "VENDEUR"],
      },
      { type: "ADMIN_COURTAGE", rights: ["RESPONSABLE_VENDEUR", "VENDEUR"] },
      { type: "REPSONSABLE_VENDEUR", rights: ["VENDEUR"] },
      { type: "VENDEUR", rights: [] },
    ];
    let res = authCases
      .filter((x) => _.includes(x.rights, selectedUserType))
      ?.map((r) => r.type);
    return res;
  }

  function transferVendeur() {
    let transferedVendeur = getValues("vendeurs")?.filter(
      (v) => v?.isPicked === true
    );

    let idResponsable = getValues("newIdResponsable");
    setIsTransferDisabled(true);
    setValue("newIdResponsable", undefined);

    let promise = new Promise((resolve, reject) => {
      resolve(onTransfer(transferedVendeur, idResponsable));
    });
    promise.then((res) => {
      getUsers(selectedUser.idSocieteCourtage).then((res) => {
        setTeam(
          res?.data?.filter(
            (u) => u.idResponsable === selectedUser.idUtilisateur
          )
        );
        setValue(
          "vendeurs",
          res?.data?.filter(
            (u) => u.idResponsable === selectedUser.idUtilisateur
          )
        );
        onTransferSuccessPopin();
      });
      // TODO POPUP NOTIFICATION res === message i18n
    });
  }

  function logoutOnCase(nextType, selectedUser, user) {
    trueOrFalseDo(
      nextType !== selectedUser.type &&
      selectedUser.idUtilisateur === user.idUtilisateur,
      () => {
        return logout();
      },
      () => {
        return;
      }
    );
  }

  async function submit(data) {
    var correspondingEnum = [];

    correspondingEnum[t("adduser.remote")] = ["REMOTE"];
    correspondingEnum[t("adduser.door2door")] = ["DOOR_TO_DOOR"];
    correspondingEnum[t("adduser.both")] = ["REMOTE", "DOOR_TO_DOOR"];

    let prevType = selectedUser.type;
    let nextType = data.type;



    let tv = typeVendeur.find((obj) => {
      return obj.value === data?.typesVendeur;
    });


    let query = {
      idUtilisateur: selectedUser.idUtilisateur,
      type: data.type,
      nom: data.nom,
      prenom: data.prenom,
      email: data.email,
      telephone: data.telephone,
      exclusitiveGeo: JSON.stringify(data?.exclusitiveGeo),
      idResponsable: await handleRespoIdByType(data.type, data.idResponsable),
      pourcentageVendeurCommission: data?.pourcentageVendeurCommission,
      visuCommission: data?.visuCommission,
      statut: data.statut,
      idSocieteCourtage: data?.idSocieteCourtage,
      typesVendeur: data.type === "VENDEUR" ? correspondingEnum[tv?.name] ? correspondingEnum[tv?.name] : data?.typesVendeur : [],
    };


    trueOrFalseDo(
      prevType === "RESPONSABLE_VENDEUR" && nextType === "VENDEUR",
      () => {
        return getUsers(selectedUser.idSocieteCourtage).then((res) => {
          let list = res.data?.find(
            (u) => u.idResponsable === selectedUser.idUtilisateur
          );
          return trueOrFalseDo(
            list,
            () => {
              return setError("type", {
                type: "custom",
                message: "Transfer d'équipe requis au préalable.",
              });
            },
            () => {
              dispatch(adduserStore.empty());
              putUser(query).then((res) => {
                handleRequestResponse(
                  res,
                  () => {
                    dispatch(update(sendNotif("modifyUser")));
                    onSuccess();
                    logoutOnCase(nextType, selectedUser, user);

                  },
                  () => {
                    dispatch(update(sendNotif(res.data.status)));
                  }
                );
              });
            }
          );
        });
      },
      () => {
        return putUser(query).then((res) => {
          dispatch(adduserStore.empty());
          handleRequestResponse(
            res,
            () => {
              dispatch(update(sendNotif("modifyUser")));
              onSuccess();
              logoutOnCase(nextType, selectedUser, user);
            },
            () => {
              dispatch(update(sendNotif(res.data.status)));
            }
          );
        });
      }
    );
  }

  useEffect(() => {
    setResponsables(respoInit);
    getUsers().then((res) => {
      let respos = res?.data?.filter((u) => {
        return trueOrFalseDo(
          u?.type === "RESPONSABLE_VENDEUR" &&
          u?.idSocieteCourtage === selectedUser.idSocieteCourtage &&
          u?.idUtilisateur !== selectedUser.idUtilisateur,
          () => {
            return {
              name: u.nomResponsable,
              idUtilisateur: u.idUtilisateur,
            };
          },
          () => {
            return;
          }
        );
      });

      setResponsables(respos);
      setrespoInit(respos);
    });
  }, []);

  useEffect(() => {
    reset(selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    let newIdResponsable = getValues("newIdResponsable");
    let isVendeursSelected = getValues("vendeurs")?.find(
      (v) => v?.isPicked === true
    );
    return trueOrFalseDo(
      !!newIdResponsable && !!isVendeursSelected,
      () => {
        return setIsTransferDisabled(false);
      },
      () => {
        return setIsTransferDisabled(true);
      }
    );
  }, [watch("newIdResponsable"), watch(["vendeurs"])]);

  useEffect(() => {
    let newUserType = getValues("type");

    // trueOrFalseDo(
    //   newUserType === "ADMIN_PRIMEO",
    //   () => {
    //     setnewUserIsAdminPrimeo(true);
    //   },
    //   () => {
    //     setnewUserIsAdminPrimeo(false);
    //   }
    // );

    // trueOrFalseDo(
    //   newUserType === "ADMIN_COURTAGE",
    //   () => {
    //     setnewUserIsAdminCourtage(true);
    //   },
    //   () => {
    //     setnewUserIsAdminCourtage(false);
    //   }
    // );

    trueOrFalseDo(
      newUserType === "RESPONSABLE_VENDEUR",
      () => {
        setnewUserIsResponsableVendeur(true);
        setShowTeam(true);
      },
      () => {
        setnewUserIsResponsableVendeur(false);
        setShowTeam(false);
      }
    );

    trueOrFalseDo(
      newUserType === "VENDEUR",
      () => {
        setnewUserIsVendeur(true);
      },
      () => {
        setnewUserIsVendeur(false);
      }
    );

    trueOrFalseDo(
      newUserType !== selectedUser.type &&
      selectedUser.idUtilisateur === user.idUtilisateur,
      () => {
        setAlertType(true);
      },
      () => {
        setAlertType(false);
      }
    );

    trueOrFalseDo(
      selectedUser.type === "RESPONSABLE_VENDEUR" && newUserType === "VENDEUR",
      () => {
        getUsers(selectedUser.idSocieteCourtage).then((res) => {
          let list = res.data?.find(
            (u) => u.idResponsable === selectedUser.idUtilisateur
          );
          return trueOrFalseDo(
            list,
            () => {
              return setError("type", {
                type: "custom",
                message: "Transfer d'équipe requis au préalable.",
              });
            },
            () => {
              return;
            }
          );
        });
      },
      () => {
        return;
      }
    );
  }, [watch("type")]);

  useEffect(() => {
    return trueOrFalseDo(
      showTeam,
      () => {
        getUsers(selectedUser.idSocieteCourtage).then((res) => {
          setTeam(
            res?.data?.filter(
              (u) => u.idResponsable === selectedUser.idUtilisateur
            )
          );
        });
      },
      () => {
        setTeam([]);
      }
    );
  }, [showTeam]);

  return (
    <>
      <Notifaction />

      <div className="popin popinModifyUser">
        <div className="page_content_addduser">
          <div className="container" style={{ margin: "0 auto" }}>
            <header className="popin_adduser_header">
              <h2 className="title">{t("modifyuser.title")}</h2>
              {alertType && (
                <p className="alert">
                  {t("notifications.changeCurrentAuthorization2")}
                </p>
              )}
              <CrossSvg className="cross" onClick={onClose} />
            </header>
            <div className="main_container_adduser">
              <form
                onSubmit={handleSubmit(submit)}
                onKeyDown={(e) => checkEnterKeyDown(e)}
                noValidate
                autoComplete="off"
                className="form_modifyuser"
              >
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Controller
                      name="type"
                      control={control}
                      rules={{
                        required: t("error.required"),
                      }}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <>
                          <div
                            className={trueOrFalseDo(
                              errors.type,
                              () => {
                                return "radio_controller_container error_wrapper";
                              },
                              () => {
                                return "radio_controller_container";
                              }
                            )}
                          >
                            <p id="label-radio-group-selectedUser-type">
                              {t("modifyuser.type")}
                            </p>
                            <div className="radio_group_container">
                              <AuthorizationLayout
                                authorized={["ADMIN_PRIMEO"]}
                                current={user?.type}
                              >
                                <CustomRadio
                                  value="ADMIN_PRIMEO"
                                  controllerValue={value}
                                  name={t("modifyuser.type1")}
                                  target="type"
                                  onChange={(e) => {
                                    dispatch(
                                      adduserStore.update({
                                        target: "type",
                                        value: e.target.value,
                                      })
                                    );
                                    onChange(e.target.value);
                                  }}
                                  onBlur={onBlur}
                                />
                              </AuthorizationLayout>
                              <AuthorizationLayout
                                authorized={["ADMIN_PRIMEO"]}
                                current={user?.type}
                              >
                                <CustomRadio
                                  value="ADMIN_COURTAGE"
                                  controllerValue={value}
                                  name={t("modifyuser.type2")}
                                  target="type"
                                  onChange={(e) => {
                                    dispatch(
                                      adduserStore.update({
                                        target: "type",
                                        value: e.target.value,
                                      })
                                    );
                                    onChange(e.target.value);
                                  }}
                                  onBlur={onBlur}
                                />
                              </AuthorizationLayout>
                              <AuthorizationLayout
                                authorized={["ADMIN_PRIMEO", "ADMIN_COURTAGE"]}
                                current={user?.type}
                              >
                                <CustomRadio
                                  value="RESPONSABLE_VENDEUR"
                                  controllerValue={value}
                                  name={t("modifyuser.type3")}
                                  target="type"
                                  onChange={(e) => {
                                    dispatch(
                                      adduserStore.update({
                                        target: "type",
                                        value: e.target.value,
                                      })
                                    );
                                    onChange(e.target.value);
                                  }}
                                  onBlur={onBlur}
                                />
                              </AuthorizationLayout>
                              <AuthorizationLayout
                                authorized={[
                                  "ADMIN_PRIMEO",
                                  "ADMIN_COURTAGE",
                                  "RESPONSABLE_VENDEUR",
                                ]}
                                current={user?.type}
                              >
                                <CustomRadio
                                  value="VENDEUR"
                                  controllerValue={value}
                                  name={t("modifyuser.type4")}
                                  target="type"
                                  onChange={(e) => {
                                    dispatch(
                                      adduserStore.update({
                                        target: "type",
                                        value: e.target.value,
                                      })
                                    );
                                    onChange(e.target.value);
                                  }}
                                  onBlur={onBlur}
                                />
                              </AuthorizationLayout>
                            </div>
                          </div>
                          {trueOrFalseDo(
                            errors?.type,
                            () => {
                              return (
                                <p className="type_error">
                                  {errors.type.message}
                                </p>
                              );
                            },
                            () => {
                              return;
                            }
                          )}
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="nom"
                      control={control}
                      rules={{
                        required: t("error.required"),
                        validate: formatErrorNames,
                      }}
                      defaultValue=""
                      render={({ field: { value, onChange, onBlur } }) => (
                        <TextInput
                          className="modifyuser-input-text"
                          value={value}
                          label={t("modifyuser.labelFamilyName")}
                          onChange={onChange}
                          required
                          error={errors.nom !== undefined}
                          helperText={errors.nom?.message}
                          onBlur={(e) => {
                            dispatch(
                              adduserStore.update({
                                target: "nom",
                                value: value,
                              })
                            );
                            onBlur(e);
                          }}
                          autoComplete="off"
                          disabled
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="prenom"
                      control={control}
                      rules={{
                        required: t("error.required"),
                        validate: formatErrorNames,
                      }}
                      defaultValue=""
                      render={({ field: { value, onChange, onBlur } }) => (
                        <TextInput
                          value={value}
                          label="Prénom"
                          onChange={onChange}
                          required
                          error={errors.prenom !== undefined}
                          helperText={errors.prenom?.message}
                          onBlur={(e) => {
                            dispatch(
                              adduserStore.update({
                                target: "prenom",
                                value: value,
                              })
                            );
                            onBlur(e);
                          }}
                          autoComplete={autoCompleteAlea()}
                          disabled
                        />
                      )}
                    />
                  </Grid>
                  <AuthorizationLayout
                    authorized={["ADMIN_PRIMEO"]}
                    current={user?.type}
                  >
                    <Grid item xs={12}>
                      <Controller
                        name="nomSociete"
                        control={control}
                        defaultValue=""
                        render={({ field: { value } }) => (
                          <TextInput value={value} label="Société" disabled />
                        )}
                      />
                    </Grid>
                  </AuthorizationLayout>
                  <Grid item xs={12}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: t("error.required"),
                        validate: formatErrorEmail,
                      }}
                      render={({ field: { onChange, value, onBlur } }) => (
                        <TextInput
                          value={value}
                          type="email"
                          label={"E-mail"}
                          onChange={onChange}
                          required={true}
                          error={errors.email !== undefined}
                          helperText={errors.email?.message}
                          onBlur={(e) => {
                            dispatch(
                              adduserStore.update({
                                target: "email",
                                value: value,
                              })
                            );
                            onBlur(e);
                          }}
                          autoComplete={autoCompleteAlea()}
                          disabled
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="telephone"
                      control={control}
                      rules={{
                        required: t("error.required"),
                        validate: formatErrorPhoneNumber,
                      }}
                      render={({ field: { onChange, value, onBlur } }) => (
                        <TextInput
                          value={value}
                          type="tel"
                          label={"Mobile"}
                          required={true}
                          onChange={onChange}
                          error={errors.telephone !== undefined}
                          helperText={errors.telephone?.message}
                          onBlur={(e) => {
                            onBlur(e);
                            dispatch(
                              adduserStore.update({
                                target: "telephone",
                                value: value,
                              })
                            );
                          }}
                          autoComplete={autoCompleteAlea()}
                          maxLength={10}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {/* {!newUserIsAdminPrimeo === true && ( */}
                    <AuthorizationLayout
                      authorized={authorizeModifGeo(selectedUser.type)}
                      current={user?.type}
                    >
                      <Controller
                        name="exclusitiveGeo"
                        control={control}
                        rules={{
                          required: t("error.required"),
                        }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <GeoExclu
                              required={
                                getValues("type") === "VENDEUR" ? true : false
                              }
                              label={t("geoComp.label")}
                              value={value}
                              error={errors.exclusitiveGeo}
                              helperText={!!errors?.exclusitiveGeo}
                              synch={selectedUser?.exclusitiveGeo}
                              idSocieteCourtage={selectedUser.idSocieteCourtage}
                              onChange={(value) => {
                                onChange(value);
                              }}
                            />
                          );
                        }}
                      />
                    </AuthorizationLayout>
                    {/* )} */}
                  </Grid>

                  <Grid item xs={12}>
                    {newUserIsVendeur && (
                      <Controller
                        name="idResponsable"
                        control={control}
                        rules={{
                          required: t("error.required"),
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <ComboBoxObject
                            label={t("adduser.labelResp")}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            value={
                              listResponsables.length > 0 ? value : undefined
                            }
                            canBeCanceled={false}
                            list={listResponsables}
                            required
                            helperText={errors?.idResponsable?.message}
                            error={errors?.idResponsable !== undefined}
                            onBlur={(e) => {
                              dispatch(
                                adduserStore.update({
                                  target: "idResponsable",
                                  value: value,
                                })
                              );
                              onBlur(e);
                            }}
                            autoComplete={autoCompleteAlea()}
                            fullWidth={true}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {newUserIsResponsableVendeur &&
                      showTeam &&
                      team.length > 0 && (
                        <>
                          <p className="simulate_input_label">
                            {t("modifyuser.team")}
                          </p>
                          <div className="team_container">
                            {loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Loader />
                              </div>
                            ) : (
                              <>
                                {team?.map((vendeur, index) => {
                                  return (
                                    <Controller
                                      key={index}
                                      control={control}
                                      name={`vendeurs.${index}`}
                                      defaultValue={vendeur}
                                      render={({
                                        field: { onChange, value },
                                      }) => {
                                        return (
                                          <CheckboxButton
                                            text={`${vendeur.prenom} ${vendeur.nom}`}
                                            className="popin_checkbox"
                                            valueIsObject
                                            value={vendeur}
                                            onChange={(e) => {
                                              onChange(e);
                                            }}
                                          />
                                        );
                                      }}
                                    />
                                  );
                                })}
                                <Grid
                                  container
                                  spacing={2}
                                  style={{
                                    alignItems: "center",
                                    marginBottom: "-5px",
                                  }}
                                >
                                  <Grid item xs={12} sm={9}>
                                    <Controller
                                      name="newIdResponsable"
                                      control={control}
                                      // rules={{
                                      //   required: t("error.required"),
                                      // }}
                                      render={({
                                        field: { onChange, onBlur, value },
                                      }) => (
                                        <ComboBoxObject
                                          label={t("modifyuser.labelNewResp")}
                                          list={filterRespo(
                                            user,
                                            selectedUser,
                                            responsables
                                          ).map((r) => {
                                            return {
                                              value: r.idUtilisateur,
                                              name: r.prenom + " " + r.nom,
                                            };
                                          })}
                                          onChange={(e) => {
                                            onChange(e);
                                          }}
                                          value={value}
                                          canBeCanceled={false}
                                          // required
                                          helperText={t("error.required")}
                                          error={!!errors?.newIdResponsable}
                                          onBlur={(e) => {
                                            onBlur(e);
                                            dispatch(
                                              adduserStore.update({
                                                target: "newIdResponsable",
                                                value: value,
                                              })
                                            );
                                          }}
                                          autoComplete={autoCompleteAlea()}
                                          fullWidth={true}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                    <Button
                                      className={trueOrFalseDo(
                                        isTransferDisabled,
                                        () => {
                                          return "green-v2 reverse";
                                        },
                                        () => {
                                          return "green-v2 ";
                                        }
                                      )}
                                      disabled={isTransferDisabled}
                                      onClick={() => transferVendeur()}
                                    >
                                      Transférer
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </div>
                        </>
                      )}
                  </Grid>

                  <Grid item xs={12}>
                    {(newUserIsResponsableVendeur || newUserIsVendeur) && (
                      <Controller
                        name="pourcentageVendeurCommission"
                        control={control}
                        rules={{
                          required: t("error.required"),
                          validate: check0to100,
                        }}
                        render={({ field: { onChange, value, onBlur } }) => (
                          <TextInput
                            value={value}
                            type="tel"
                            label={t("modifyuser.labelComm")}
                            required={true}
                            onChange={(v) => {
                              onChange(v);
                            }}
                            error={
                              errors.pourcentageVendeurCommission !== undefined
                            }
                            helperText={
                              errors.pourcentageVendeurCommission?.message
                            }
                            onBlur={(e) => {
                              dispatch(
                                adduserStore.update({
                                  target: "commission",
                                  value: value,
                                })
                              );
                              onBlur(e);
                            }}
                            autoComplete={autoCompleteAlea()}
                            maxLength={3}
                            percentage
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {(newUserIsResponsableVendeur || newUserIsVendeur) && (
                      <Controller
                        name="visuCommission"
                        control={control}
                        rules={{
                          required: t("error.required"),
                        }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <div className="visuCommission_container">
                            <p
                              style={{
                                marginRight: "20px",
                              }}
                            >
                              {" "}
                              {t("modifyuser.visuCommission")}{" "}
                            </p>
                            <BarreButton
                              name="visuCommission"
                              value={value}
                              labelA={t("modifyuser.yes")}
                              labelB={t("modifyuser.no")}
                              error={errors.visuCommission}
                              onChange={onChange}
                              onBlur={(v) => {
                                dispatch(
                                  adduserStore.update({
                                    target: "visuCommission",
                                    value: value,
                                  })
                                );
                                onBlur(v);
                              }}
                            />
                          </div>
                        )}
                      />
                    )}
                  </Grid>
                  <PermissionWrapper requiredPermission={["UPDATE_SELLER"]}>
                    <Grid item xs={12}>
                      <Controller
                        name="statut"
                        control={control}
                        rules={{
                          required: t("error.required"),
                        }}
                        render={({ field: { onChange, onBlur, value } }) => {
                          return (
                            <ComboBoxObject
                              canBeCanceled={false}
                              label={t("modifyuser.labelStatut")}
                              isbool={true}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              value={value}
                              list={statuts}
                              required
                              // helperText={t("error.required")}
                              error={errors?.statut !== undefined}
                              onBlur={(e) => {
                                dispatch(
                                  adduserStore.update({
                                    target: "statut",
                                    value: value,
                                  })
                                );
                                onBlur(e);
                              }}
                              autoComplete={autoCompleteAlea()}
                              fullWidth={true}
                            />
                          );
                        }}
                      />
                    </Grid>
                  </PermissionWrapper>

                  <Grid item xs={12}>
                    {newUserIsVendeur && (
                      <Controller
                        name="typesVendeur"
                        control={control}
                        rules={{
                          required: t("error.required"),
                        }}
                        render={({ field: { onChange, onBlur, value } }) => {
                          return (
                            <ComboBoxObject
                              canBeCanceled={true}
                              label={t("adduser.labelTypeVendeur")}
                              isbool={false}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              value={
                                value.length > 1
                                  ? "BOTH"
                                  : value.length === 0
                                    ? undefined
                                    : value
                              }
                              list={typeVendeur}
                              required
                              // helperText={t("error.required")}
                              error={errors?.statut !== undefined}
                              onBlur={(e) => {
                                onBlur(e);
                              }}
                              autoComplete={autoCompleteAlea()}
                              fullWidth={true}
                            />
                          );
                        }}
                      />
                    )}
                  </Grid>
                </Grid>

                <Button type="submit" className="green save">
                  {t("modifyuser.save")}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopinModifyUser;
