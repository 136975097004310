import React from "react";
import { ReactComponent as ArrowSvg } from "../../core/assets/picto/arrow.svg";
import { ReactComponent as CheckSvg } from "../../core/assets/picto/check.svg";
import { ReactComponent as VenteSvg } from "../../core/assets/picto/vente.svg";

import "./button.scss";

const Button = ({ children, icon, className, type, disabled, ...props }) => {
  let typeIcon;
  switch (icon) {
    case "arrow":
      typeIcon = <ArrowSvg className="icon" />;
      break;
    case "check":
      typeIcon = <CheckSvg className="icon" />;
      break;
    case "sale":
        typeIcon = <VenteSvg className="icon" />;
        break;
    default:
      break;
  }
  return (
    <button
      className={className}
      type={type || `button`}
      disabled={disabled}
      {...props}
    >
      {children}
      {icon && typeIcon}
    </button>
  );
};

export default Button;
