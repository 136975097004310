import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PasswordInput from "../../../../components/PasswordInput/PasswordInput";
import Button from "../../../../components/Button/Button";
import { updatePassword } from "../../../../core/services/httpRequest/axios";
import { useForm, Controller } from "react-hook-form";
import { ReactComponent as Polygon } from "../../../../core/assets/picto/Polygon.svg";
import Cross from "../../../../components/SvgComponents/Cross";
import _ from "lodash";
import handleRequestResponse from "../../../../utils/responsesHandler";
import ActiveSvg from "../../../../components/SvgComponents/ActiveSvg";

const Step3 = () => {
   const [checkers, setCheckers] = useState({
      min: false,
      maj: false,
      number: false,
      spec: false,
      minlength: false,
   });
   const [token, setToken] = useState(null);
   const [showChecker, setShowChecker] = useState(false);
   const [submitDisabled, setSubmitDisabled] = useState(true);
   const { t } = useTranslation();
   const history = useHistory();
   const [errorToken, setErrorToken] = useState(0);

   const rules = {
      min: {
         rule: (x) =>
            x === x?.toLowerCase() && isNaN(x * 1) && /[a-zA-ZÀ-ÿ]/?.test(x),
      },
      maj: {
         rule: (x) =>
            x === x?.toUpperCase() && isNaN(x * 1) && /[a-zA-ZÀ-ÿ]/?.test(x),
      },
      number: { rule: (x) => !isNaN(x * 1) },
      spec: {
         rule: (x) =>
            /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/?.test(x) &&
            !/[a-zA-ZÀ-ÿ]/?.test(x),
      },
      minlength: { rule: (x) => (x?.length >= 8 ? true : false) },
      same: { rule: (x, y) => x === y },
   };

   const {
      handleSubmit,
      getValues,
      watch,
      control,
      setError,
      clearErrors,
      formState: { errors },
   } = useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: { password1: "", password2: "" },
   });

   function submit(data) {
      if (_.every(checkers, (x) => x === true)) {
         updatePassword(
            token,
            data.password1
         ).then((res) => {
            handleRequestResponse(
               res,
               () => {
                  history.push("/password/new-password-accepted");
               },
               () => {
                  if(res.data === "Token expiré"){
                     setErrorToken(1);
                  }
                  else{
                     setErrorToken(2);
                  }
               }
            );
         });
      } else {
         setError("password1", {
            type: "format",
            message: t("password.formaterror"),
         });
      }
   }

   useEffect(() => {
      setToken(history.location.search.split("").slice(7).join(""));
   }, []);

   useEffect(() => {
      const psw = getValues("password1")?.split("");
      const nextState = {};
      if (psw?.some(rules.min.rule)) {
         nextState.min = true;
      }
      if (psw?.some(rules.min.rule) === false) {
         nextState.min = false;
      }

      if (psw?.some(rules.maj.rule)) {
         nextState.maj = true;
      }
      if (psw?.some(rules.maj.rule) === false) {
         nextState.maj = false;
      }

      if (psw?.some(rules.number.rule)) {
         nextState.number = true;
      }
      if (psw?.some(rules.number.rule) === false) {
         nextState.number = false;
      }

      if (psw?.some(rules.spec.rule)) {
         nextState.spec = true;
      }
      if (psw?.some(rules.spec.rule) === false) {
         nextState.spec = false;
      }

      if (rules.minlength.rule(psw)) {
         nextState.minlength = true;
      }
      if (rules.minlength.rule(psw) === false) {
         nextState.minlength = false;
      }

      setCheckers(nextState);
   }, [watch("password1")]);

   useEffect(() => {
      if (getValues("password1") === getValues("password2")) {
         setSubmitDisabled(false);
      } else {
         setSubmitDisabled(true);
      }
   }, [watch("password1"), watch("password2")]);

   useEffect(() => {
      if (errorToken === true) {
         setTimeout(() => {
            setErrorToken(1);
         }, 5000);
      }
   }, [errorToken]);

   return (
      <div className="container_step">
         <h1>{t("password.step3")}</h1>
         <p className="blue">{t("password.step3sub")}</p>
         <div className="container_form">
            <form
               name="resetForm"
               onSubmit={handleSubmit(submit)}
               noValidate
               autoComplete="off"
            >
               <Controller
                  control={control}
                  name="password1"
                  rules={{
                     required: t("error.required"),
                  }}
                  render={({ field: { value, onChange, onBlur } }) => (
                     <PasswordInput
                        value={value}
                        onChange={onChange}
                        onBlur={(e) => {
                           onBlur(e);
                           setShowChecker(false);
                        }}
                        onFocus={() => setShowChecker(true)}
                        label={t("auth.password")}
                        placeholder={t("password.new")}
                        variant="outlined"
                        error={errors.password1 !== undefined}
                        helperText={errors.password1?.message}
                        required
                     />
                  )}
               />
               {/*  */}
               <Controller
                  control={control}
                  name="password2"
                  rules={{
                     required: t("error.required"),
                     validate: (x) =>
                        rules.same.rule(x, getValues("password1"))
                           ? true
                           : t("password.nomatch"),
                  }}
                  render={({ field: { value, onChange, onBlur } }) => (
                     <PasswordInput
                        value={value}
                        onChange={(e) => {
                           onChange(e.target.value);
                           if (
                              rules.same.rule(
                                 e.target.value,
                                 getValues("password1")
                              )
                           ) {
                              clearErrors("password2");
                           }
                        }}
                        onBlur={onBlur}
                        label={t("auth.password")}
                        placeholder={t("password.repete")}
                        variant="outlined"
                        error={errors.password2 !== undefined}
                        helperText={errors.password2?.message}
                        required
                     />
                  )}
               />
               {showChecker && (
                  <div className="password_checker">
                     <div className="custom_arrow">
                        <Polygon />
                     </div>
                     <div className="checker">
                        {checkers.maj ? (
                           <>
                              <div style={{ width: "22px" }}>
                                 <ActiveSvg fill="#00644B" />{" "}
                              </div>
                              <p className="isOk">{t("password.maj")}</p>
                           </>
                        ) : (
                           <>
                              <div style={{ width: "22px", marginLeft: "5px" }}>
                                 <Cross fill="red" />
                              </div>
                              <p>{t("password.maj")}</p>
                           </>
                        )}
                     </div>
                     <div className="checker">
                        {checkers.min ? (
                           <>
                              <div style={{ width: "22px" }}>
                                 <ActiveSvg fill="#00644B" />{" "}
                              </div>
                              <p className="isOk">{t("password.min")}</p>
                           </>
                        ) : (
                           <>
                              <div style={{ width: "22px", marginLeft: "5px" }}>
                                 <Cross fill="red" />
                              </div>
                              <p>{t("password.min")}</p>
                           </>
                        )}
                     </div>
                     <div className="checker">
                        {checkers.number ? (
                           <>
                              <div style={{ width: "22px" }}>
                                 <ActiveSvg fill="#00644B" />{" "}
                              </div>
                              <p className="isOk">{t("password.number")}</p>
                           </>
                        ) : (
                           <>
                              <div style={{ width: "22px", marginLeft: "5px" }}>
                                 <Cross fill="red" />
                              </div>
                              <p>{t("password.number")}</p>
                           </>
                        )}
                     </div>
                     <div className="checker">
                        {checkers.spec ? (
                           <>
                              <div style={{ width: "22px" }}>
                                 <ActiveSvg fill="#00644B" />{" "}
                              </div>
                              <p className="isOk">{t("password.spec")}</p>
                           </>
                        ) : (
                           <>
                              <div style={{ width: "22px", marginLeft: "5px" }}>
                                 <Cross fill="red" />
                              </div>
                              <p>{t("password.spec")}</p>
                           </>
                        )}
                     </div>
                     <div className="checker">
                        {checkers.minlength ? (
                           <>
                              <div style={{ width: "22px" }}>
                                 <ActiveSvg fill="#00644B" />{" "}
                              </div>
                              <p className="isOk">{t("password.minlength")}</p>
                           </>
                        ) : (
                           <>
                              <div style={{ width: "22px", marginLeft: "5px" }}>
                                 <Cross fill="red" />
                              </div>
                              <p>{t("password.minlength")}</p>
                           </>
                        )}
                     </div>
                  </div>
               )}
               {errorToken === 1 && (
                  <p style={{ color: "red" }}>
                     Le lien de réinitialisation du mot de passe a expiré.
                     Veuillez en regénérer en cliquant{" "}
                     <span
                        onClick={() => {
                           history.push("/password/forgotten");
                        }}
                        style={{ color: "green", textDecoration: "underline", cursor: "pointer" }}
                     >
                        ici
                     </span>
                     .
                  </p>
               )}
               {errorToken === 2 && (
                  <p style={{ color: "red" }}>
                     Le mot de passe est invalide{" "}
                     <span
                        onClick={() => {
                           history.push("/password/forgotten");
                        }}
                        style={{ color: "green", textDecoration: "underline", cursor: "pointer" }}
                     >
                        ici
                     </span>
                     .
                  </p>
               )}
               <div className="container_btn">
                  <Button
                     type="submit"
                     className="green "
                     style={{ margin: "0 auto" }}
                     disabled={submitDisabled}
                  >
                     {t("password.change")}
                  </Button>
               </div>
            </form>
         </div>
      </div>
   );
};

export default Step3;
