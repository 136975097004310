import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BarreButtonList from "../../BarreButton/BarreButtonList";
import { useDispatch, useSelector } from "react-redux";
import * as saleStore from "../../../core/stores/saleStore";
import { replacePointByCommaInDecimals, safeEval } from "../../../utils";
import _ from "lodash";

const Tarification = ({
   offers,
   selectedOffer,
   monthlyAmountDetailed,
   loading = false,
}) => {
   // const [currentTarif, setCurrentTarif] = useState([]);
   // const sale = useSelector((state) => state?.sale);
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const {
      watch,
      getValues,
      formState: { errors },
      control,
   } = useFormContext();

   // const offerSelected1 = offers?.filter((v) => v.name === selectedOffer)[0]
   //    ?.offerDetail;

   // const offerSelected2 = offers?.filter((v) => v.name === selectedOffer);

   // const allDiscount = offerSelected1?.filter((v) =>
   //    _.includes(v.name, "DISCOUNT")
   // );

   // const discountVariable = allDiscount?.filter((v) =>
   //    _.includes(v.name, "VARIABLE")
   // );
   // const discountFixe = allDiscount?.filter((v) => _.includes(v.name, "FIXE"));

   function getPrixKwh(values) {

      // booléen pour savoir quel tarif display si valeur TTC > 0
      let base = values?.consoPartVariableBaseTTC > 0;
      let hphc =
         values?.consoPartVariableHcTTC > 0 &&
         values?.consoPartVariableHpTTC > 0;
      let hpehphhcehch =
         values?.consoPartVariableHceTTC > 0 &&
         values?.consoPartVariableHchTTC > 0 &&
         values?.consoPartVariableHpeTTC > 0 &&
         values?.consoPartVariableHphTTC > 0;

      if (base && !hphc && !hpehphhcehch)
         return (
            <div
               key={"consoPartVariableBaseTTC"}
               style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
               }}
            >
               <p style={{ marginBottom: 0 }}>
                  {t("souscription.offre.consoPartVariableBaseTTC")}
               </p>

               <p style={{ marginBottom: 0 }}>
                  {replacePointByCommaInDecimals(
                     (values?.consoPartVariableBaseTTC / 100000).toFixed(4)
                  )}{" "}
                  {t("souscription.offre.unitTarif")}
               </p>
            </div>
         );
      if (hphc && !base && !hpehphhcehch)
         return (
            <>
               <div
                  key={"consoPartVariableHcTTC"}
                  style={{
                     width: "100%",
                     display: "flex",
                     justifyContent: "space-between",
                  }}
               >
                  <p style={{ marginBottom: 0 }}>
                     {t("souscription.offre.consoPartVariableHcTTC")}
                  </p>

                  <p style={{ marginBottom: 0 }}>
                     {replacePointByCommaInDecimals(
                        (values?.consoPartVariableHcTTC / 100000).toFixed(4)
                     )}{" "}
                     {t("souscription.offre.unitTarif")}
                  </p>
               </div>
               <div
                  key={"consoPartVariableHpTTC"}
                  style={{
                     width: "100%",
                     display: "flex",
                     justifyContent: "space-between",
                  }}
               >
                  <p style={{ marginBottom: 0 }}>
                     {t("souscription.offre.consoPartVariableHpTTC")}
                  </p>

                  <p style={{ marginBottom: 0 }}>
                     {replacePointByCommaInDecimals(
                        (values?.consoPartVariableHpTTC / 100000).toFixed(4)
                     )}{" "}
                     {t("souscription.offre.unitTarif")}
                  </p>
               </div>
            </>
         );
      if (hpehphhcehch && !base && !hphc)
         return (
            <>
               <div
                  key={"consoPartVariableHceTTC"}
                  style={{
                     width: "100%",
                     display: "flex",
                     justifyContent: "space-between",
                  }}
               >
                  <p style={{ marginBottom: 0 }}>
                     {" "}
                     {t("souscription.offre.consoPartVariableHceTTC")}
                  </p>

                  <p style={{ marginBottom: 0 }}>
                     {replacePointByCommaInDecimals(
                        (values?.consoPartVariableHceTTC / 100000).toFixed(4)
                     )}{" "}
                     {t("souscription.offre.unitTarif")}
                  </p>
               </div>
               <div
                  key={"consoPartVariableHchTTC"}
                  style={{
                     width: "100%",
                     display: "flex",
                     justifyContent: "space-between",
                  }}
               >
                  <p style={{ marginBottom: 0 }}>
                     {t("souscription.offre.consoPartVariableHchTTC")}
                  </p>

                  <p style={{ marginBottom: 0 }}>
                     {replacePointByCommaInDecimals(
                        (values?.consoPartVariableHchTTC / 100000).toFixed(4)
                     )}{" "}
                     {t("souscription.offre.unitTarif")}
                  </p>
               </div>
               <div
                  key={"consoPartVariableHpeTTC"}
                  style={{
                     width: "100%",
                     display: "flex",
                     justifyContent: "space-between",
                  }}
               >
                  <p style={{ marginBottom: 0 }}>
                     {t("souscription.offre.consoPartVariableHpeTTC")}
                  </p>

                  <p style={{ marginBottom: 0 }}>
                     {replacePointByCommaInDecimals(
                        (values?.consoPartVariableHpeTTC / 100000).toFixed(4)
                     )}{" "}
                     {t("souscription.offre.unitTarif")}
                  </p>
               </div>
               <div
                  key={"consoPartVariableHphTTC"}
                  style={{
                     width: "100%",
                     display: "flex",
                     justifyContent: "space-between",
                  }}
               >
                  <p style={{ marginBottom: 0 }}>
                     {t("souscription.offre.consoPartVariableHphTTC")}
                  </p>

                  <p style={{ marginBottom: 0 }}>
                     {replacePointByCommaInDecimals(
                        (values?.consoPartVariableHphTTC / 100000).toFixed(4)
                     )}{" "}
                     {t("souscription.offre.unitTarif")}
                  </p>
               </div>
            </>
         );
      else {
         if (values)
            for (const [key, value] of Object?.entries(values)) {
               let current = key?.split("");
               let currentEnd = current?.slice(-3, current?.length)?.join("");
               let currentStart = current?.slice(0, 17)?.join("");
               let clefStart = "consoPartVariable";
               let clefEnd = "TTC";
               if (
                  currentStart === clefStart &&
                  currentEnd === clefEnd &&
                  value > 0
               ) {
                  let libelle =
                     _?.replace(
                        _?.replace(current?.join(""), clefStart, ""),
                        clefEnd,
                        ""
                     ) || "";
                  return (
                     <div
                        key={current?.join("")}
                        style={{
                           width: "100%",
                           display: "flex",
                           justifyContent: "space-between",
                        }}
                     >
                        <p style={{ marginBottom: 0 }}>
                           {t("souscription.offre.consoPartVariableUnknownTTC")}{" "}
                           {libelle}
                        </p>
                        <p style={{ marginBottom: 0 }}>
                           {replacePointByCommaInDecimals(
                              (values?.[current?.join("")] / 100000).toFixed(4)
                           )}{" "}
                           {t("souscription.offre.unitTarif")}
                        </p>
                     </div>
                  );
               }
            }
      }
   }

   function getPrixAbonnement(values) {
      if (values?.aboPartFixeMensuelTTC > 0)
         return (
            <>
               <p style={{ marginBottom: 0 }}>
                  {t("souscription.offre.titleAbonnement")}
               </p>
               <p style={{ marginBottom: 0 }}>
                  {replacePointByCommaInDecimals(
                     (values?.aboPartFixeMensuelTTC / 100)?.toFixed(2)
                  )}{" "}
                  € TTC/mois
               </p>
            </>
         );
      else return null;
   }

   // useEffect(() => {
   //    let tmpCurrentOffer = offers?.find((o) => o?.name === selectedOffer);
   //    let tmpCurrentTarif = tmpCurrentOffer?.tarifications.find(
   //       (t) =>
   //          t?.tarification?.productSpecification?.productNumber ===
   //          getValues("currentOffer.selectTarif")
   //    );
   //    setCurrentTarif(tmpCurrentTarif);
   // }, [watch("currentOffer.selectTarif")]);

   return (
      <Grid item xs={12}>
         {!!getValues("currentOffer.selectOffer") && (
            <>
               <span>
                  <h3>{t("souscription.offre.optionPriceTitle")}</h3>
               </span>

               <Controller
                  control={control}
                  rules={{ required: t("error.required") }}
                  name="currentOffer.selectTarif"
                  render={({ field: { onChange, value, onBlur } }) => (
                     <div className="barrebutton-offer">
                        <BarreButtonList
                           name="selectTarif"
                           value={value}
                           current={(() => {
                              return offers.find(
                              (o) => o?.name === selectedOffer)
                           })()}
                           onChange={(e) => {
                              onChange(e);
                              let tmpCurrentOffer = offers?.find(
                                 (o) => o?.name === selectedOffer
                              );
                              let tmpCurrentTarif =
                                 tmpCurrentOffer?.tarifications.find(
                                    (t) =>
                                       t?.tarification?.productSpecification
                                          ?.productNumber ===
                                       getValues("currentOffer.selectTarif")
                                 );
                              dispatch(
                                 saleStore.update({
                                    target: "optionTarifaireStorage",
                                    value: tmpCurrentTarif?.tarification
                                       ?.productSpecification,
                                 })
                              );
                           }}
                           error={errors?.currentOffer?.selectTarif}
                           onBlur={(v) => {
                              onBlur(v);
                           }}
                        />
                     </div>
                  )}
               />
            </>
         )}

         {!!getValues("currentOffer.selectTarif") && (
            <>
               <div
                  style={{
                     width: "100%",
                     display: "flex",
                     justifyContent: "space-between",
                  }}
               >
                  {!loading && getPrixAbonnement(monthlyAmountDetailed)}
               </div>

               {!loading && getPrixKwh(monthlyAmountDetailed)}
            </>
         )}
      </Grid>
   );
};

export default Tarification;
