import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import ParametrageWordingAdmin from "../../../components/BOCO/Parametrage/ParametrageWordingAdminPrimeo";
import ParametrageWordingAdminCourtage from "../../../components/BOCO/Parametrage/ParametrageWordingAdminCourtage";
import ParametrageDroits from "../../../components/BOCO/Parametrage/ParametrageDroits";
import PermissionWrapper from "../../../components/HOC/PermissionWrapper";
import AuthorizationLayout from "../../../components/HOC/AuthorizationLayout/AuthorizationLayout";
import ComboBoxObject from "../../../components/ComboBox/ComboBoxObject";
import Notifaction from "../../../components/BOCO/Notification/Notifaction";
import handleRequestResponse from "../../../utils/responsesHandler";
import {
  getListSociete,
  getSocieteDetails,
} from "../../../core/services/httpRequest/axios";
import "./styles/parametrage.scss";
import ParametrageSociete from "../../../components/BOCO/Parametrage/ParametrageSociete";
import ParametrageGlobal from "../../../components/BOCO/Parametrage/ParametrageGlobal";

const Parametrage = ({ userType }) => {
  const { t } = useTranslation();

  const [idSocieteCourtage, setidSocieteCourtage] = useState(undefined);
  const [societes, setSocietes] = useState([]);
  const [selectedSociete, setSelectedSociete] = useState([]);
  const broker = useSelector((state) => state.auth.broker);
  const [sellersPermissions, setSellersPermissions] = useState({});

  function transformPermissions(sellersPermissions) {
    let transformedPermissions = {};
    sellersPermissions?.forEach((seller) => {
      let typeUser = seller?.typeUser;
      let prefix =
        typeUser === "RESPONSABLE_VENDEUR"
          ? "RV_"
          : typeUser === "VENDEUR"
            ? "V_"
            : "";
      seller?.permissions?.forEach((permission) => {
        transformedPermissions[prefix + permission] = permission;
      });
    });
    return transformedPermissions;
  }

  useEffect(() => {
    if (userType === "ADMIN_PRIMEO") {
      getListSociete().then((res) => {
        let businesses = [];
        res.data.forEach((s) => {
          businesses.push({
            ...s,
            idSocieteCourtage: s.idSociete,
            name: s.nomSociete,
          });
        });

        setSocietes(businesses);
        setSellersPermissions(
          transformPermissions(res?.data?.societe?.sellersPermissions)
        );
      });
    } else {
      setidSocieteCourtage(broker?.idSocieteCourtage);
      getSocieteDetails(broker?.idSocieteCourtage).then((res) => {
        //   console.log("res", res);
        //   let businesses = [];
        //   res.data.forEach((s) => {
        //     businesses.push({
        //       ...s,
        //       idSocieteCourtage: s.idSociete,
        //       name: s.nomSociete,
        //     });
        //   });

        //   setSocietes(businesses);
        setSellersPermissions(
          transformPermissions(res?.data?.societe?.sellersPermissions)
        );
      });
    }
  }, [broker]);

  useEffect(() => {
    // Si je susi admin_primeo alors je veux les détails de la société que je vise
    if (userType === "ADMIN_PRIMEO" && !!idSocieteCourtage) {
      getSocieteDetails(idSocieteCourtage).then((res) => {
        setSelectedSociete(res.data);
        handleRequestResponse(
          res,
          () => {
            setSelectedSociete(res.data);
            setSellersPermissions(
              transformPermissions(res?.data?.societe?.sellersPermissions)
            );
          },
          () => {
            console.error(res);
          }
        );
      });
    }
  }, [idSocieteCourtage]);

  return (
    <>
      <Notifaction />
      <div className="parametrage_container">
        <h2 style={{ margin: "0 0 24px" }}>{t("parametrage.title")}</h2>

        {userType === "ADMIN_PRIMEO" && (
          <>
            <Grid item xs={12}>
            <ParametrageGlobal>
            </ParametrageGlobal>
            </Grid>
            <Grid item xs={12}>
              <ComboBoxObject
                label={t("adduser.labelBusiness")}
                value={idSocieteCourtage}
                onChange={(e) => {
                  setidSocieteCourtage(e);
                }}
                list={societes.map((b) => {
                  return {
                    value: b.idSocieteCourtage,
                    name: b.name,
                  };
                })}
                required
                canBeCanceled
                helperText={t("error.required")}
              />
            </Grid>
            <ParametrageSociete
              idSocieteCourtage={idSocieteCourtage}
              selectedSociete={selectedSociete}
            />
            <ParametrageWordingAdmin
              idSocieteCourtage={idSocieteCourtage}
              selectedSociete={selectedSociete}
            />
            <ParametrageDroits
              idSocieteCourtage={idSocieteCourtage}
              sellersPermissions={sellersPermissions}
            />
          </>
        )}
        {userType !== "ADMIN_PRIMEO" && (
          <>
            <ParametrageSociete
              idSocieteCourtage={idSocieteCourtage}
              selectedSociete={selectedSociete}
            />
            <PermissionWrapper requiredPermission={["UPDATE_WORDING"]}>
              <ParametrageWordingAdminCourtage
                idSocieteCourtage={idSocieteCourtage}
                selectedSociete={selectedSociete}
              />
            </PermissionWrapper>
            <AuthorizationLayout
              authorized={["ADMIN_PRIMEO", "ADMIN_COURTAGE"]}
              current={broker?.type}
            >
              <ParametrageDroits
                idSocieteCourtage={idSocieteCourtage}
                sellersPermissions={sellersPermissions}
              />
            </AuthorizationLayout>
          </>
        )}
      </div>
    </>
  );
};

export default Parametrage;
