/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ComboBoxObject from "../../ComboBox/ComboBoxObject";
import TextInput from "../../TextInput/TextInput";
import Button from "../../Button/Button";
import { Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  getListSociete,
  getParametrage,
  getSocieteDetails,
  postParametrage,
  postParamsOfSociete,
} from "../../../core/services/httpRequest/axios";
import { autoCompleteAlea, checkEnterKeyDown } from "../../../utils";
import handleRequestResponse from "../../../utils/responsesHandler";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../../core/stores/paramsStore";
import { sendNotif } from "../../../core/stores/utils";
import Notifaction from "../Notification/Notifaction";
import CheckboxButton from "../../Checkbox/CheckboxButton";

const ParametrageSociete = ({
  idSocieteCourtage,
  selectedSociete,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    setValue,
    resetField,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
    // defaultValues: { ...selectedSociete },
  });

  async function submit(data) {
    const params = [
      { param: "EXECUTE_EXPORT", value: data?.EXECUTE_EXPORT },
      { param: "BILLING_PDL_ADDRESS_BY_DEFAULT", value: data?.BILLING_PDL_ADDRESS_BY_DEFAULT },
      { param: "FORCE_RIGHT_OF_WITHDRAWAL_BY_DEFAULT", value: data?.FORCE_RIGHT_OF_WITHDRAWAL_BY_DEFAULT},
      { param: "FORCE_PRICING_OPTION_OF_PDL", value: data?.FORCE_PRICING_OPTION_OF_PDL}
    ];

    for (const param of params) {
      console.log(param)
      postParamsOfSociete(idSocieteCourtage, param).then((res) => {
        handleRequestResponse(
          res,
          () => {
            /* empty success callback */
          },
          () => {
            dispatch(update(sendNotif(res.data.status)));
          }
        );
      });
      dispatch(update(sendNotif("createAdminParam")));
    }
  }

  function reset() {
    resetField("EXECUTE_EXPORT");
    resetField("BILLING_PDL_ADDRESS_BY_DEFAULT");
    resetField("FORCE_RIGHT_OF_WITHDRAWAL_BY_DEFAULT");
    resetField("FORCE_PRICING_OPTION_OF_PDL");
  }

  useEffect(() => {
    const fieldsToSet = [
      "EXECUTE_EXPORT",
      "BILLING_PDL_ADDRESS_BY_DEFAULT",
      "FORCE_RIGHT_OF_WITHDRAWAL_BY_DEFAULT",
      "FORCE_PRICING_OPTION_OF_PDL"
    ];

    if (idSocieteCourtage) {
      getSocieteDetails(idSocieteCourtage).then((res) => {
        fieldsToSet.forEach((field) => {
          setValue(field, res?.data?.societe?.parameters?.[field] === "true");
        });
      });
    } else {
      reset();
    }
  }, [idSocieteCourtage]);

  return (
    <div>
      <h3 style={{ margin: "0 0 12px" }}>Parametrage</h3>
      {!!idSocieteCourtage ? (
      <form
        onSubmit={handleSubmit(submit)}
        onKeyDown={(e) => checkEnterKeyDown(e)}
        noValidate
        autoComplete="off"
        className="form_parametrage"
      >
        <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            <Controller
              control={control}
              name="EXECUTE_EXPORT"
              defaultValue={false}
              render={({ field: { onChange, value } })  => (
                <CheckboxButton
                text="Activer l'export automatique des ventes"
                //    className="gray"
                onChange={(e) => {
                  onChange(e);
                }}
               value={value}
                checked={value}
              />
              )}
            />
            </Grid>
            <Grid item xs={6} md={6}>
            <Controller
              control={control}
              name="BILLING_PDL_ADDRESS_BY_DEFAULT"
              defaultValue={false}
              render={({ field: { onChange, value } })  => (
                <CheckboxButton
                text="L'adresse du PDL est l'adresse de facturation par défaut."
                //    className="gray"
                onChange={(e) => {
                  onChange(e);
                }}
               value={value}
                checked={value}
              />
              )}
            />
            </Grid>
            <Grid item xs={6} md={6}>
            <Controller
              control={control}
              name="FORCE_RIGHT_OF_WITHDRAWAL_BY_DEFAULT"
              defaultValue={false}
              render={({ field: { onChange, value } })  => (
                <CheckboxButton
                text="Le client ne peut pas renoncer à son droit de rétractation."
                //    className="gray"
                onChange={(e) => {
                  onChange(e);
                }}
               value={value}
                checked={value}
              />
              )}
            />
            </Grid>
            <Grid item xs={6} md={6}>
            <Controller
              control={control}
              name="FORCE_PRICING_OPTION_OF_PDL"
              defaultValue={false}
              render={({ field: { onChange, value } })  => (
                <CheckboxButton
                text="Le client garde la même option tarifaire pour son nouveau contrat. "
                //    className="gray"
                onChange={(e) => {
                  onChange(e);
                }}
               value={value}
                checked={value}
              />
              )}
            />
            </Grid>
          <div
            style={{
              width: "100%",
              margin: "20px auto 0",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              className={"green"}
              type="submit"
              style={{ marginRight: "6px" }}
            >
              {t("parametrage.submit")}
            </Button>
            <Button
              className={"red"}
              style={{ marginLeft: "6px" }}
              onClick={reset}
            >
              {t("parametrage.cancel")}
            </Button>
          </div>
        </Grid>
      </form>
      )  : (
        <p>Sélectionnez une société</p>
      )}
    </div>
  );
};

export default ParametrageSociete;
