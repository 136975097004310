import React, { useState } from "react";
import Button from "../../../components/Button/Button";
import Header from "../../../components/Header/Header";
import { Modal } from "@mui/material";

import { ReactComponent as LogoBigBgSvg } from "../../../core/assets/picto/logoBig.svg";
import { ReactComponent as LogoSmallSvg } from "../../../core/assets/picto/logo.svg";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as auth from "../../../core/stores/authStore";
import * as params from "../../../core/stores/paramsStore";
import * as sale from "../../../core/stores/saleStore";
import * as adduser from "../../../core/stores/adduserStore";
import * as addsociete from "../../../core/stores/addSocieteStore";

// style
import "./styles/accueil.scss";

const Home = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const history = useHistory();
  const { t } = useTranslation();
  const params = useSelector((state) => state.params);
  const currentUser = useSelector((state) => state.auth.broker);

  function disconnect() {
    setOpen(false);
    localStorage.removeItem("accessToken");
    // Delete persist root
    localStorage.removeItem("persist:root");
    history.push("/auth");
    dispatch(auth.empty());
    dispatch(params.empty());
    dispatch(sale.empty());
    dispatch(adduser.empty());
    dispatch(addsociete.empty());
  }

  const user = useSelector((state) => state.auth);

  return (
    <>
      <div className="page_content_accueil">
        <Header isHome>
          <Button
            className={"blue outline"}
            style={{ width: "100%" }}
            onClick={() => {
              setOpen(true);
            }}
          >
            {t("actions.disconnect")}
          </Button>
          <Modal open={open} onClose={() => setOpen(false)}>
            <div className="modal">
              <h2>{t("actions.disconnect2")}</h2>
              <div className="buttons">
                <Button
                  className={"blue"}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  NON
                </Button>
                <Button className={"blue outline"} onClick={disconnect}>
                  OUI
                </Button>
              </div>
            </div>
          </Modal>
        </Header>
        <div className="container_white">
          <h1>
            {user?.broker?.firstName},<br />
            {t("accueil.titre")}
          </h1>
          <p>{t("accueil.text")}</p>
          <div className="container_btn">
            {params.maintenanceStatus !== 'MAINTENANCE' && currentUser?.type !== "CHARGE_CLIENT" && (
              <Button
                icon="arrow"
                className="green"
                onClick={() => history.push("/subscription")}
              >
                {t("accueil.bouton")}
              </Button>
            )}
            <Button
              icon="arrow"
              className="green"
              onClick={() => history.push("/accueil")}
            >
              {t("accueil.labelBtnToEC")}
            </Button>
          </div>
          <LogoSmallSvg className="logoSmall-bg" />
        </div>
        <LogoBigBgSvg className="logoBig-bg" />
      </div>
    </>
  );
};

export default Home;
