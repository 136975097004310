import React from "react";

const FilterSvg = ({ fill }) => {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.249934 1.61C2.26993 4.2 5.99993 9 5.99993 9V15C5.99993 15.55 6.44993 16 6.99993 16H8.99993C9.54993 16 9.99993 15.55 9.99993 15V9C9.99993 9 13.7199 4.2 15.7399 1.61C16.2499 0.95 15.7799 0 14.9499 0H1.03993C0.209934 0 -0.260066 0.95 0.249934 1.61Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default FilterSvg;
