import React, { useEffect, useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "../styles/adressInput.scss";
import Loader from "../../Loader/Loader";

const AddressInput = ({
  onChange,
  onBlur,
  error,
  required,
  val,
  label,
  helperText,
}) => {
  const [value, setValue] = useState(null || val);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  function handleOnChange(v) {
    return onChange(v);
  }

  const fetch = useMemo(() => {
    if (!!inputValue && inputValue.length > 2) {
      return axios.get(
        `https://api-adresse.data.gouv.fr/search/?q=${inputValue}&limit=8`
      );
    }
  }, [inputValue]);

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    setLoading(true);
    if (!!inputValue && inputValue.length > 2) {

      fetch
        .then((res) => {
          if (active) {
            let newOptions = [];

            if (res?.data?.features) {
              let responses = res?.data?.features?.map((address) => {
                let v = address.properties;
                let formated = {};
                if (v.type === "street") {
                  formated = {
                    ...v,
                    city: v?.city,
                    zipCode: v?.postcode,
                    additionnalAddress: "",
                    inseeCode: v?.cityCode,
                    street: v?.name,
                    housenumber: v?.housenumber,
                  };
                } else {
                  formated = {
                    ...v,
                    city: v?.city,
                    zipCode: v?.postcode,
                    additionnalAddress: "",
                    inseeCode: v?.cityCode,
                    street: v?.street,
                    housenumber: v?.housenumber,
                  };
                }

                return formated;
              });
              newOptions = [...responses];
            }

            setOptions(newOptions);
          }
        })
        .finally(() => setLoading(false));
      return () => {
        active = false;
      };
    }

  }, [value, inputValue, fetch]);

  return (
    <div
      style={{ marginTop: "16px", marginBottom: "32px" }}
      className="container_adresse"
    >
      <Autocomplete
        style={{ height: "48px" }}
        fullWidth
        disablePortal
        id="combo-box-demo"
        autoComplete
        autoSelect
        includeInputInList
        filterSelectedOptions
        noOptionsText={t("common.addressPlaceholder")}
        value={value}
        options={options}
        loading={loading}
        loadingText={t("common.loading")}
        filterOptions={(x) => {
          return x.filter((o) => !!o.street || o.type === "street");
        }}
        getOptionLabel={(option) => option?.label || ""}
        isOptionEqualToValue={(option, val) => {
          return option?.label === val?.label;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            fullWidth
            required={required}
            error={!!error}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {loading ? <Loader isAddressLoader={true} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              // inputProps: {
              //   required: value === null,
              // },
            }}
            onBlur={onBlur}
          />
        )}
        onChange={(e, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          handleOnChange(newValue);
        }}
        onInputChange={(e, newInputValue) => {
          setInputValue(newInputValue);
        }}
      />
    </div>
  );
};

AddressInput.defaultProps = {
  onChange: (e) => e,
  onBlur: (e) => e,
};

export default AddressInput;
