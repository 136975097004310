import React, { useEffect, useState, PureComponent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
   ComposedChart,
   Line,
   Bar,
   XAxis,
   YAxis,
   CartesianGrid,
   Tooltip,
   Legend,
   ResponsiveContainer,
} from "recharts";
import { TIME_RANGES } from "../../../pages/BOCO/home/utils/utils";
import _ from "lodash";

import "./styles/chart.scss";

const Chart = ({ data, view, loading }) => {
   const [interval, setInterval] = useState(0);
   const menuDesktopIsOpen = useSelector(
      (state) => state.params.menuDesktopIsOpen
   );
   const { t } = useTranslation();

   const detectResize = () => {
      setTimeout(() => {
         choixAffichageXAxis(
            view,
            document.getElementById("dash_container").clientWidth
         );
      }, 500);
   };

   useEffect(() => {
      window.addEventListener("resize", detectResize);
   }, []);

   useEffect(() => {
      detectResize();
   }, [view, menuDesktopIsOpen]);

   function choixAffichageXAxis(view, currentWidth) {
      let currentView = TIME_RANGES.find((r) => r.value === view);
      if (currentWidth > currentView.breakPoint) return setInterval(0);
      else return setInterval(1);
   }

   class CustomizedAxisTick extends PureComponent {
      render() {
         const { x, y, stroke, payload, view } = this.props;
         return (
            <g transform={`translate(${x},${y})`}>
               <text
                  x={0}
                  y={0}
                  dy={16}
                  textAnchor={view === "ANNUALLY" ? "end" : "middle"}
                  fill="#666"
                  transform={view === "ANNUALLY" ? "rotate(-35)" : ""}
               >
                  {payload.value}
               </text>
            </g>
         );
      }
   }

   return (
      <>
         <ResponsiveContainer height={view === "ANNUALLY" ? 575 : 550}>
            {data?.length > 0 ? (
               <ComposedChart
                  data={data}
                  margin={{
                     top: 20,
                     right: 20,
                     left: 20,
                     bottom: 10,
                  }}
               >
                  <CartesianGrid vertical={false} stroke="#f0f0f0" />
                  <XAxis
                     dataKey="name"
                     scale="point"
                     interval={interval}
                     height={view === "ANNUALLY" ? 90 : 35}
                     tick={<CustomizedAxisTick view={view} />}
                     padding={{ left: 10, right: 10 }}
                  />
                  <YAxis orientation="left" dataKey="commissionAmount" />
                  <YAxis yAxisId="right" orientation="right" />
                  <Tooltip />
                  <Legend
                     verticalAlign={view === "ANNUALLY" ? "top" : "bottom"}
                  />
                  <Bar
                     dataKey="commissionAmount"
                     barSize={20}
                     fill="#57B9CF"
                     name={t("dash.totalPrice")}
                     legendType="circle"
                  />
                  <Line
                     yAxisId="right"
                     type="monotone"
                     dataKey="totalSales"
                     stroke="#272727"
                     strokeWidth={3}
                     activeDot={{ stroke: "black", strokeWidth: 4 }}
                     name={t("dash.totalSales")}
                  />
               </ComposedChart>
            ) : (
               <div
                  className={loading ? "nodata" : "nodata active"}
                  style={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     height: "100%",
                  }}
               >
                  <h3 className="blue">No data</h3>
               </div>
            )}
         </ResponsiveContainer>
      </>
   );
};

export default Chart;
