import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { customRequest } from "../services/httpRequest/axios";

const retreiveCompany = createAsyncThunk(
   'auth/retreiveCompany',
   async (idSocieteCourtage, thunkAPI) => {
      const res = await customRequest.get(`/societe/${idSocieteCourtage}`);
      return {
         idSociete: res.data?.societe?.idSociete,
         nomSociete: res.data?.societe?.nomSociete,
         type: res.data?.societe?.type,
         siret: res.data?.societe?.siret,
         tva: res.data?.societe?.tva,
         rcs: res.data?.societe?.rcs,
         capitalSocial: res.data?.societe?.capitalSocial,
         codeNAF: res.data?.societe?.codeNAF,
         adresse: res.data?.societe?.adresse,
         typeConvention: res.data?.societe?.typeConvention,
         dateSignature: res.data?.societe?.dateSignature,
         dateFinConvention: res.data?.societe?.dateFinConvention,
         exclusiviteGeo: res.data?.societe?.exclusitiveGeo,
         bonusMalus: res.data?.societe?.bonusMalus,
         adminSociete: res.data?.societe?.adminSociete,
         statut: res.data?.societe?.statut,
         wordings: res.data?.societe?.wordings,
         sellersPermissions:
            res.data?.societe?.sellersPermissions,
         parameters: res.data?.societe?.parameters
      }
   }
)

const authSlice = createSlice({
   name: "auth",
   initialState: {
      broker: {},
      society: {},
   },
   reducers: {
      update: (state, action) => {
         state[action.payload.target] = action.payload.value;
      },
      empty: () => {
         return {
            broker: {},
            society: {},
         };
      },
   },
   extraReducers: (builder) => {
      builder.addCase(retreiveCompany.fulfilled, (state, action) => {
         if(action.payload === undefined){return}
         //console.log(action.payload)
         let parameters = {...state.society.parameters}
         parameters = Object.keys(parameters).sort().reduce(
            (obj, key) => { 
              obj[key] = parameters[key]; 
              return obj;
            },{});
            action.payload.parameters = Object.keys(action.payload.parameters).sort().reduce(
               (obj, key) => { 
                 obj[key] = action.payload.parameters[key]; 
                 return obj;
               },{});
         let hasParametersChanged = JSON.stringify(parameters) !== JSON.stringify(action.payload.parameters)
         let sellersPermissions = [...action.payload.sellersPermissions]
         sellersPermissions =  sellersPermissions.sort((perm1,perm2) => perm1.id.localeCompare(perm2))
         action.payload.sellersPermissions = action.payload.sellersPermissions.sort((perm1,perm2) => perm1.id.localeCompare(perm2))
         let hasSellerPermissionChanged = JSON.stringify(sellersPermissions) !== JSON.stringify(action.payload.sellersPermissions)
         
         if(hasParametersChanged || hasSellerPermissionChanged){
            console.log("Updated permissions")
            state.society = action.payload
         }

         // if(JSON.stringify(state.society) !== JSON.stringify(action.payload)){
         // state.society = action.payload
         // }
      })
   }
});

export const { update, empty } = authSlice.actions;

export {retreiveCompany}

export default authSlice.reducer;
