import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Button from "../../../Button/Button";

// Liste svg
import { ReactComponent as BurgerSvg } from "../../../../core/assets/picto/lineBurger.svg";
import { ReactComponent as HomeSvg } from "../../../../core/assets/picto/home.svg";
import { ReactComponent as VenteSvg } from "../../../../core/assets/picto/vente.svg";
import { ReactComponent as UserSvg } from "../../../../core/assets/picto/user.svg";
import { ReactComponent as SocieteSvg } from "../../../../core/assets/picto/societe.svg";
import { ReactComponent as SettingsSvg } from "../../../../core/assets/picto/settings.svg";

import { useHistory } from "react-router-dom";
import { switchMenuUser } from "../../../../utils";

const MenuBocoResp = ({ user }) => {
   const history = useHistory();
   const [selectedIndex, setSelectedIndex] = useState(0);
   const [open, setOpen] = useState(null);
   const [listMenuTypeUser, setListMenuTypeUser] = useState([]);
   const isOpen = Boolean(open);

   const userBroker = user?.broker;

   useEffect(() => {
      setListMenuTypeUser(switchMenuUser(userBroker?.type));
   }, [userBroker]);

   const handleClick = (event) => {
      setOpen(event.currentTarget);
   };

   const handleMenuItemClick = (event, index, link) => {
      setSelectedIndex(index);
      history.push(link);
      setOpen(null);
   };
   const handleClose = () => {
      setOpen(null);
   };

   const handleClickTCO = () => {
      history.push("/home");
   };

   useEffect(() => {
      listMenuTypeUser?.find((l, index) => {
         if (l.link === history?.location?.pathname) {
            setSelectedIndex(index);
            return { ...l, index: index };
         }
      });
   }, [listMenuTypeUser]);

   return (
      <div className="menu_Resp_Burger">
         <Button
            className={`green burger ${isOpen ? "open" : "closed"}`}
            onClick={handleClick}
         >
            <BurgerSvg />
         </Button>
         <Menu
            id="menuBurger"
            // listMenuTypeUserProps={{
            //   "aria-labelledby": "long-button",
            // }}
            anchorEl={open}
            open={isOpen}
            onClose={handleClose}
         >
            {listMenuTypeUser?.map((option, index) => (
               <MenuItem
                  key={option.label}
                  selected={index === selectedIndex}
                  onClick={(event) =>
                     handleMenuItemClick(event, index, option.link)
                  }
               >
                  <span className="icon">
                     {(() => {
                        switch (option.icon) {
                           case "Home":
                              return <HomeSvg />;
                           case "Vente":
                              return <VenteSvg />;
                           case "User":
                              return <UserSvg />;
                           case "Societe":
                              return <SocieteSvg />;
                           case "Settings":
                              return <SettingsSvg />;
                           default:
                              return null;
                        }
                     })()}
                  </span>
                  <p>{option.label}</p>
               </MenuItem>
            ))}
            {(user?.society?.statut === "ACTIF" || userBroker?.type === 'ADMIN_PRIMEO') && (
               <div className="TCO">
                  <h4>Tunnel de souscription</h4>
                  <p>Vous êtes en rendez-vous ?</p>
                  <Button className="blue" onClick={handleClickTCO}>
                     <VenteSvg />
                     Commencer une vente
                  </Button>
               </div>
            )}
         </Menu>
      </div>
   );
};

export default MenuBocoResp;
