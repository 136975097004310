import React, { useEffect, useState } from "react";

import "./textInput.scss";
import { ReactComponent as ErrorSvg } from "../../core/assets/picto/error.svg";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

const TextInput = ({
  value,
  label,
  placeholder,
  error,
  required = false,
  disabled = false,
  margin = "normal",
  variant = "outlined",
  fullwidth = true,
  style,
  type,
  area,
  InputLabelProps,
  id,
  name,
  onChange,
  autoComplete,
  maxLength,
  percentage,
  tel = false,
  className,
  currency,
  euroHT,
  reset = false,
  resetFunction = () => null,
  ...props
}) => {
  // Juste letters
  const isLetters = (str) => /^[a-zA-ZÀ-ÿ]*$/.test(str);
  const isNumber = (str) => /^[0-9]+$/.test(str);

  const [val, setVal] = useState(value || "");

  const onInputChange = (e) => {
    const text = e.target.value;
    if (
      (type === "tel" || type === "number") &&
      isNumber(text) &&
      !isLetters(text)
    ) {
      setVal(text);
      onChange(text);
    }
    if (type === "tel" && text.length <= 0) {
      setVal("");
      onChange("");
    }
  };

  const invalidChars = [".", "e", "-", "+"];

  useEffect(() => {
    if (type === "number") {
      let target = document.getElementsByClassName("textinput");

      target[0].addEventListener("keydown", function (e) {
        if (invalidChars.includes(e.key)) {
          e.preventDefault();
        }
      });
    }
  }, [type]);

  const addSymbol = () => {
    if (percentage) {
      return <InputAdornment position="start">%</InputAdornment>;
    }
  };

  const getPattern = (type) => {
    if (type === "tel") return "([/^+(33)(6|7)d{8}$/])";
    if (type === "number") return "([^0-9]*)";
    else return "";
  };

  const displayEndAdornment = () => {
    if (error === true && reset === false) {
      return <ErrorSvg />;
    }
    if (error === true && reset === true) {
      return <ErrorSvg style={{ cursor: "pointer" }} onClick={resetFunction} />;
    }
    if (euroHT === true) {
      return <p className="endAdornmentHT">€ HT</p>;
    }
  };

  return (
    <div className={area ? "input-text area" : "input-text"}>
      <TextField
        className={type === "number" || type === "tel" ? "textinput" : null}
        id={id}
        InputLabelProps={{ ...InputLabelProps }}
        onChange={type === "tel" ? onInputChange : onChange}
        label={label}
        placeholder={placeholder ?? label}
        margin={margin}
        required={required}
        variant={variant}
        error={!!error}
        helperText={error?.message ?? ""}
        disabled={disabled}
        fullWidth={fullwidth}
        style={{ ...style }}
        type={type}
        name={"noAutoFill"}
        autoComplete={autoComplete}
        value={type === "tel" ? val : value || ""}
        InputProps={{
          startAdornment: addSymbol(),
          endAdornment: displayEndAdornment(),
          inputProps: {
            maxLength: maxLength,
            pattern: getPattern(type),
          },
        }}
        {...props}
      />
    </div>
  );
};

export default TextInput;
