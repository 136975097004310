import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CheckboxButton from "../../Checkbox/CheckboxButton";
import Button from "../../Button/Button";
import handleRequestResponse from "../../../utils/responsesHandler";
import { checkEnterKeyDown } from "../../../utils";
import { useTranslation } from "react-i18next";
import { putPermissions } from "../../../core/services/httpRequest/axios";
import { sendNotif } from "../../../core/stores/utils";
import { update } from "../../../core/stores/paramsStore";
import { useDispatch } from "react-redux";
import _ from "lodash";

const ParametrageDroits = ({ idSocieteCourtage, sellersPermissions }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit, control, setValue } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  async function submit(data) {
    let RESPONSABLE_VENDEUR = {
      typeUser: "RESPONSABLE_VENDEUR",
      permissions: _.compact([
        data.RV_CREATE_SELLER ? "CREATE_SELLER" : "",
        data.RV_UPDATE_SELLER ? "UPDATE_SELLER" : "",
        data.RV_TOGGLE_SELLER ? "TOGGLE_SELLER" : "",
        data.RV_RETREIVE_DETAIL_SALE ? "RETREIVE_DETAIL_SALE" : "",
        data.RV_VALIDATE_SALE ? "VALIDATE_SALE" : "",
        data.RV_CANCEL_SALE ? "CANCEL_SALE" : "",
        data.RV_EXPORT_SALE ? "EXPORT_SALE" : "",
        data.RV_UPDATE_WORDING ? "UPDATE_WORDING" : "",
      ]),
    };

    let VENDEUR = {
      typeUser: "VENDEUR",
      permissions: _.compact([
        data.V_CREATE_SELLER ? "CREATE_SELLER" : "",
        data.V_UPDATE_SELLER ? "UPDATE_SELLER" : "",
        data.V_TOGGLE_SELLER ? "TOGGLE_SELLER" : "",
        data.V_RETREIVE_DETAIL_SALE ? "RETREIVE_DETAIL_SALE" : "",
        data.V_VALIDATE_SALE ? "VALIDATE_SALE" : "",
        data.V_CANCEL_SALE ? "CANCEL_SALE" : "",
        data.V_EXPORT_SALE ? "EXPORT_SALE" : "",
        data.V_UPDATE_WORDING ? "UPDATE_WORDING" : "",
      ]),
    };

    putPermissions(idSocieteCourtage, RESPONSABLE_VENDEUR).then((res) => {
      handleRequestResponse(
        res,
        () => dispatch(update(sendNotif("UPDATE_PERMISSIONS", ""))),
        () => dispatch(update(sendNotif(res.status)))
      );
    });
    putPermissions(idSocieteCourtage, VENDEUR).then((res) => {
      handleRequestResponse(
        res,
        () => dispatch(update(sendNotif("UPDATE_PERMISSIONS", ""))),
        () => dispatch(update(sendNotif(res.status)))
      );
    });
  }

  function reset() {
    for (const [key, value] of Object.entries(sellersPermissions)) {
      setValue(key, value);
      eraseBeforeInit();
    }
    eraseBeforeInit();
  }

  function eraseBeforeInit() {
    [
      "RV_CREATE_SELLER",
      "RV_UPDATE_SELLER",
      "RV_TOGGLE_SELLER",
      "RV_RETREIVE_DETAIL_SALE",
      "RV_VALIDATE_SALE",
      "RV_CANCEL_SALE",
      "RV_EXPORT_SALE",
      "RV_UPDATE_WORDING",
      "V_CREATE_SELLER",
      "V_UPDATE_SELLER",
      "V_TOGGLE_SELLER",
      "V_RETREIVE_DETAIL_SALE",
      "V_VALIDATE_SALE",
      "V_CANCEL_SALE",
      "V_EXPORT_SALE",
      "V_UPDATE_WORDING",
    ].forEach((el) => {
      setValue(el, false);
    });
  }

  useEffect(() => {
    eraseBeforeInit();
  }, [idSocieteCourtage]);

  useEffect(() => {
    for (const [key, value] of Object.entries(sellersPermissions)) {
      setValue(key, value);
    }
  }, [sellersPermissions]);

  return (
    <div className="parametrage_droits">
      <h3 style={{ margin: "0 0 24px" }}>{t("parametrage.title_droits")}</h3>

      {!!idSocieteCourtage ? (
        <form
          onSubmit={handleSubmit(submit)}
          onKeyDown={(e) => checkEnterKeyDown(e)}
          noValidate
          autoComplete="off"
          className="form_droits"
          name="form_droits"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={6} md={3}>
              Responsable Vendeur
            </Grid>
            <Grid item xs={6} md={3}>
              Vendeur
            </Grid>

            <Grid item xs={12} md={6}>
              <p>Création d'un vendeur</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                control={control}
                name="RV_CREATE_SELLER"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <CheckboxButton
                    onChange={onChange}
                    value={value === "CREATE_SELLER" ? true : value}
                    checked={value === "CREATE_SELLER" ? true : value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="V_CREATE_SELLER"
                control={control}
                defaultValue={false}
                render={() => (
                  <CheckboxButton className="gray" checked={false} disabled />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <p>Modification d'un vendeur</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="RV_UPDATE_SELLER"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <CheckboxButton
                    onChange={onChange}
                    value={value === "UPDATE_SELLER" ? true : value}
                    checked={value === "UPDATE_SELLER" ? true : value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="V_UPDATE_SELLER"
                control={control}
                defaultValue={false}
                render={() => (
                  <CheckboxButton className="gray" checked={false} disabled />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <p>Activation/désactivation d'un vendeur</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="RV_TOGGLE_SELLER"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <CheckboxButton
                    onChange={onChange}
                    value={value === "TOGGLE_SELLER" ? true : value}
                    checked={value === "TOGGLE_SELLER" ? true : value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="V_TOGGLE_SELLER"
                control={control}
                defaultValue={false}
                render={() => (
                  <CheckboxButton className="gray" checked={false} disabled />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <p>Accès aux informations détaillées de la vente</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="RV_RETREIVE_DETAIL_SALE"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <CheckboxButton
                    onChange={onChange}
                    value={value === "RETREIVE_DETAIL_SALE" ? true : value}
                    checked={value === "RETREIVE_DETAIL_SALE" ? true : value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="V_RETREIVE_DETAIL_SALE"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <CheckboxButton
                    onChange={onChange}
                    value={value === "RETREIVE_DETAIL_SALE" ? true : value}
                    checked={value === "RETREIVE_DETAIL_SALE" ? true : value}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <p>Validation d'une vente</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="RV_VALIDATE_SALE"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <CheckboxButton
                    onChange={onChange}
                    value={value === "VALIDATE_SALE" ? true : value}
                    checked={value === "VALIDATE_SALE" ? true : value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="V_VALIDATE_SALE"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <CheckboxButton
                    onChange={onChange}
                    value={value === "VALIDATE_SALE" ? true : value}
                    checked={value === "VALIDATE_SALE" ? true : value}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <p>Annulation d'une vente</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="RV_CANCEL_SALE"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <CheckboxButton
                    onChange={onChange}
                    value={value === "CANCEL_SALE" ? true : value}
                    checked={value === "CANCEL_SALE" ? true : value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="V_CANCEL_SALE"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <CheckboxButton
                    onChange={onChange}
                    value={value === "CANCEL_SALE" ? true : value}
                    checked={value === "CANCEL_SALE" ? true : value}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <p>Exporter les ventes</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="RV_EXPORT_SALE"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <CheckboxButton
                    onChange={onChange}
                    value={value === "EXPORT_SALE" ? true : value}
                    checked={value === "EXPORT_SALE" ? true : value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="V_EXPORT_SALE"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <CheckboxButton
                    onChange={onChange}
                    value={value === "EXPORT_SALE" ? true : value}
                    checked={value === "EXPORT_SALE" ? true : value}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <p>Modification des textes de script</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="RV_UPDATE_WORDING"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <CheckboxButton
                    onChange={onChange}
                    value={value === "UPDATE_WORDING" ? true : value}
                    checked={value === "UPDATE_WORDING" ? true : value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="V_UPDATE_WORDING"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <CheckboxButton
                    onChange={onChange}
                    value={value === "UPDATE_WORDING" ? true : value}
                    checked={value === "UPDATE_WORDING" ? true : value}
                  />
                )}
              />
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              margin: "20px auto 0",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              className={"green"}
              type="submit"
              style={{ marginRight: "6px" }}
            >
              {t("parametrage.submit")}
            </Button>
            <Button
              className={"red"}
              style={{ marginLeft: "6px" }}
              onClick={reset}
            >
              {t("parametrage.cancel")}
            </Button>
          </div>
        </form>
      ) : (
        <p>Sélectionnez une société</p>
      )}
    </div>
  );
};

export default ParametrageDroits;
