export class ChartDataItem {
   constructor(
      view,
      name,
      dayNumber,
      month,
      year,
      commissionAmount,
      totalSales
   ) {
      this.view = view;
      this.day = name;
      this.dayNumber = dayNumber;
      this.month = month;
      this.year = year;
      this.commissionAmount = commissionAmount || 0;
      this.totalSales = totalSales || 0;
      this.name = name;
   }

   changerLibelle() {
      switch (this.view) {
         case "WEEKLY":
            return this.day;
         case "MONTHLY":
            return this.dayNumber;
         case "ANNUALLY":
            return this.day + " " + this.year;
         default:
            break;
      }
   }

   format() {
      return {
         name: this.changerLibelle(),
         commissionAmount: this.commissionAmount,
         totalSales: this.totalSales,
      };
   }
}

export const TIME_RANGES = [
   {
      value: "WEEKLY",
      breakPoint: 700,
   },
   {
      value: "MONTHLY",
      breakPoint: 950,
   },
   {
      value: "ANNUALLY",
      breakPoint: 950,
   },
];

