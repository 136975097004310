/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Bloc from "../Bloc/Bloc";
import { useTranslation } from "react-i18next";
import BarreButton from "../BarreButton/BarreButton";
import DatePicker from "../DatePicker/datePickerV1";
import "./styles/billingBloc.scss";
import {
  setMinMaxDate,
  dateIsBetween,
  addDays,
  dateIsLower,
} from "../../utils";
import CheckboxButton from "../Checkbox/CheckboxButton";
import AddressInput from "../Address/AddressInput/AddressInput";
import AddressDisplay from "../Address/AddressDisplay/AddressDisplay";
import moment from "moment";
import * as saleStore from "../../core/stores/saleStore";
import TextInput from "../TextInput/TextInput";
import "moment/min/moment-with-locales";
import Button from "../Button/Button";
moment.locale("fr");

const BillingBloc = ({ open, setOpen }) => {
  const [update, setUpdate] = useState(false);
  const [checkBilling, setCheckBilling] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [info, setInfo] = useState("");

  const params = useSelector((state) => state.params);
  const sale = useSelector((state) => state.sale);
  const auth = useSelector((state) => state.auth);

  const forceRightOfWithdrawal = useSelector((state) =>  (state.auth.society?.parameters?.FORCE_RIGHT_OF_WITHDRAWAL_BY_DEFAULT === "true"))
  const pdlAddressByDefault = useSelector((state) =>  (state.auth.society?.parameters?.BILLING_PDL_ADDRESS_BY_DEFAULT === "true"))
  // const [displayedAddress, setdisplayedAddress] = useState({
  //    ...sale?.billingAddress,
  // });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    watch,
    getValues,
    setValue,
    control,

    formState: { errors },
    resetField,
  } = useFormContext();

  function isBillingCheck() {
    if (
      !!getValues("consoMode") &&
      !!getValues("debitingDateChoice") &&
      !!getValues("billingAddressChoice") &&
      dateIsBetween(
        getValues("debitingDate"),
        4,
        31, "days"
      ) === true &&
      checkRetract() &&
      !!sale?.billingAddress
    ) {
      return setCheckBilling(true);
    }
    return setCheckBilling(false);
  }

  function checkRetract() {
    if (
      dateIsBetween(getValues("debitingDate"), 0, 31, "days") &&
      dateIsLower(getValues("debitingDate"), params.dates.start.standard)
    ) {
      return getValues("renounceRetractation");
    }
    if (
      dateIsBetween(getValues("debitingDate"), 0, 31, "days") &&
      !dateIsLower(getValues("debitingDate"), params.dates.start.standard)
    ) {
      return true;
    }

    return true;
  }

  useEffect(() => {
    if (!!sale?.billingAddress) {
      setValue("billingAddress", sale?.billingAddress);
      setShowAddress(true);
    }

    if (sale?.validMandat) {
      setValue("renounceRetractation", sale?.renounceRetractation);
    }

  }, []);

  useEffect(() => {
    if (getValues("consoMode") === "REAL") {
      setInfo(t("souscription.facturation.factuR"));
      dispatch(
        saleStore.update({
          target: "consoMode",
          value: getValues("consoMode"),
        })
      );
    }
    if (getValues("consoMode") === "SCHEDULED") {
      setInfo(t("souscription.facturation.factuE"));
      dispatch(
        saleStore.update({
          target: "consoMode",
          value: getValues("consoMode"),
        })
      );
    }
  }, [watch("consoMode")]);

  useEffect(() => {
    if (
      getValues("billingAddressChoice") ===
      t("souscription.facturation.pdlAddress")
    ) {
      dispatch(
        saleStore.update({
          target: "billingAddress",
          value: sale?.PDLInfos?.deliveryAddress,
        })
      );
      setShowAddress(true);
    }
    // ------------------
    if (
      getValues("billingAddressChoice") ===
      t("souscription.facturation.otherAddress")
    ) {

      setValue(
        "housenumberAndStreet",
        sale?.billingAddress?.housenumberAndStreet
      );
      setValue("additionnalAddress", sale?.billingAddress?.additionnalAddress);
      setValue("city", sale?.billingAddress?.city);
      setValue("zipCode", sale?.billingAddress?.zipCode);
      setShowAddress(true);
    }
  }, [watch("billingAddressChoice")]);


  useEffect(() => {
    if (
      getValues("debitingDateChoice") === t("souscription.facturation.earlier")
    ) {
      resetField("debitingDate");
      setValue("debitingDate", forceRightOfWithdrawal ? addDays(params.dates.start.standard) : addDays(params.dates.start.earlier));
    } else {

      resetField("debitingDate");
      sale?.debitingDate ? setValue("debitingDate", JSON.parse(sale?.debitingDate)) : setValue("debitingDate", addDays(params.dates.start.standard));
    }
  }, [watch("debitingDateChoice")]);

  useEffect(() => {

    isBillingCheck();

  }, [
    checkBilling,
    watch("consoMode"),
    watch("billingAddress"),
    watch("billingAddressChoice"),
    watch("debitingDate"),
    watch("renounceRetractation"),
    sale,
  ]);

  useEffect(() => {
    dispatch(saleStore.update({ target: "billingCheck", value: checkBilling }));
  }, [checkBilling]);

  useEffect(() => {
    if (sale?.forceFacturationReele === true) {
      setValue("consoMode", "REAL");
    }
  }, [sale.forceFacturationReele]);

  return (
    <Bloc
      icon="facturation"
      title={t("souscription.facturation.title")}
      setIsOpen={setOpen}
      open={open}
      checked={checkBilling}
      info={info}
      className="bloc_billing"
      note={params?.noteAdmin?.filter((v) => v.word === "BILLING")[0]?.value}
    >
      {/* Choix du mode de facturation */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h3>{t("souscription.facturation.mode")}</h3>
          {sale.forceFacturationReele === true ? (
            <Controller
              control={control}
              rules={{ required: t("error.required") }}
              name="consoMode"
              render={({ field: { onChange, value } }) => {
                return (
                  <div className="barrebutton-offer">
                    <Button
                      name="consoMode"
                      value={value === "REAL"}
                      className={value === "REAL" ? "green " : "green outline"}
                      error={errors?.consoMode}
                      onClick={(e) => {
                        dispatch(
                          saleStore.update({
                            target: "consoMode",
                            value: value,
                          })
                        );
                        onChange("REAL");
                      }}
                    >
                      {t("souscription.facturation.consoR")}
                    </Button>
                  </div>
                );
              }}
            />
          ) : (
            <Controller
              name="consoMode"
              control={control}
              rules={{ required: t("error.required") }}
              render={({ field: { value, onChange, onBlur } }) => (
                <BarreButton
                  name="consoMode"
                  value={
                    value !== undefined
                      ? value === "REAL"
                        ? t("souscription.facturation.consoR")
                        : t("souscription.facturation.consoE")
                      : value
                  }
                  labelA={t("souscription.facturation.consoR")}
                  labelB={t("souscription.facturation.consoE")}
                  error={errors.consoMode}
                  onChange={(value) => {
                    value === t("souscription.facturation.consoR")
                      ? onChange("REAL")
                      : onChange("SCHEDULED");
                  }}
                  onBlur={(v) => {
                    dispatch(
                      saleStore.update({
                        target: "consoMode",
                        value: value,
                      })
                    );
                    onBlur(v);
                  }}
                />
              )}
            />
          )}
        </Grid>

        {/* Choix de l'addresse de facturation */}
        <Grid item xs={12}>
          <h3>{t("souscription.facturation.address")}</h3>
          <Controller
            name="billingAddressChoice"
            defaultValue={(pdlAddressByDefault) ? (() => {
              dispatch(
                saleStore.update({
                  target: "billingAddressChoice",
                  value: t("souscription.facturation.pdlAddress"),
                })
              );
              return t("souscription.facturation.pdlAddress")})() 
              : undefined}
            control={control}
            rules={{ required: t("error.required") }}
            render={({ field: { value, onChange, onBlur } }) => (
              <BarreButton
                name="billingAddressChoice"
                value={value}
                labelA={t("souscription.facturation.pdlAddress")}
                labelB={t("souscription.facturation.otherAddress")}
                error={errors.billingAddressChoice}
                onChange={(v) => {
                  onChange(v);
                  dispatch(
                    saleStore.update({
                      target: "billingAddressChoice",
                      value: v,
                    })
                  );
                }}
                onBlur={(v) => {
                  dispatch(
                    saleStore.update({
                      target: "billingAddressChoice",
                      value: value,
                    })
                  );
                  onBlur(v);
                }}
              />
            )}
          />

          {getValues("billingAddressChoice") ===
            t("souscription.facturation.otherAddress") && (
              <Grid item xs={10} style={{ marginTop: "16px", marginBottom: "0" }}>
                <Controller
                  control={control}
                  
                  name="billingAddress"
                  // rules={{
                  // required: t("error.required"),
                  // }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <AddressInput
                      value={value}
                      label={t("souscription.facturation.address")}
                      onChange={(v) => {
                        setUpdate(!update);
                        onChange(v);
                        dispatch(
                          saleStore.update({
                            target: "billingAddress",
                            value: {
                              housenumber: v?.housenumber,
                              street: v?.street,
                              housenumberAndStreet: v?.name,
                              additionnalAddress: "",
                              city: v?.city,
                              zipCode: v?.zipCode,
                            },
                          })
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            )}

          {showAddress &&
            getValues("billingAddressChoice") ===
            t("souscription.facturation.pdlAddress") && (
              <>
                <AddressDisplay address={sale?.PDLInfos} isPDL isBillingBloc />
              </>
            )}

          {showAddress &&
            sale?.billingAddress &&
            getValues("billingAddressChoice") ===
            t("souscription.facturation.otherAddress") && (
              <>
                <AddressDisplay address={sale?.billingAddress} isBillingBloc>
                  {/*  */}
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={10}>
                      <Controller
                        name="housenumberAndStreet"
                        control={control}
                        defaultValue={sale?.billingAddress.housenumberAndStreet}
                        render={({ field: { onBlur, onChange } }) => (
                          <TextInput
                            style={{ margin: "3px 0" }}
                            name="housenumberAndStreet"
                            value={sale?.billingAddress.housenumberAndStreet}
                            onChange={(e) => {
                              onChange(e);
                              dispatch(
                                saleStore.update({
                                  target: "billingAddress",
                                  value: {
                                    ...sale?.billingAddress,
                                    housenumberAndStreet: e.target.value,
                                  },
                                })
                              );
                            }}
                            onBlur={onBlur}
                            error={errors?.housenumberAndStreet}
                            placeholder={t(
                              "souscription.facturation.placeholderHousenumberAndStreet"
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Controller
                        name="hamlet"
                        control={control}
                        defaultValue={sale?.billingAddress?.hamlet}
                        render={({ field: { onBlur, onChange } }) => (
                          <TextInput
                            name="hamlet"
                            style={{ margin: "3px 0" }}
                            value={sale?.billingAddress?.hamlet}
                            onChange={(e) => {
                              onChange(e);
                              dispatch(
                                saleStore.update({
                                  target: "billingAddress",
                                  value: {
                                    ...sale?.billingAddress,
                                    hamlet: e.target.value,
                                  },
                                })
                              );
                            }}
                            onBlur={onBlur}
                            error={errors?.additionnalAddress}
                            placeholder={t(
                              "souscription.facturation.placeholderHamlet"
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Controller
                        name="additionnalAddress1"
                        control={control}
                        defaultValue={sale?.billingAddress?.additionnalAddress1}
                        render={({ field: { onBlur, onChange } }) => (
                          <TextInput
                            name="additionnalAddress1"
                            style={{ margin: "3px 0" }}
                            value={sale?.billingAddress?.additionnalAddress1}
                            onChange={(e) => {
                              onChange(e);
                              dispatch(
                                saleStore.update({
                                  target: "billingAddress",
                                  value: {
                                    ...sale?.billingAddress,
                                    additionnalAddress1: e.target.value,
                                  },
                                })
                              );
                            }}
                            onBlur={onBlur}
                            error={errors?.additionnalAddress2}
                            placeholder={t(
                              "souscription.facturation.placeholderAdditionnalAddress1"
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Controller
                        name="additionnalAddress2"
                        control={control}
                        defaultValue={sale?.billingAddress?.additionnalAddress2}
                        render={({ field: { onBlur, onChange } }) => (
                          <TextInput
                            name="additionnalAddress2"
                            style={{ margin: "3px 0" }}
                            value={sale?.billingAddress?.additionnalAddress2}
                            onChange={(e) => {
                              onChange(e);
                              dispatch(
                                saleStore.update({
                                  target: "billingAddress",
                                  value: {
                                    ...sale?.billingAddress,
                                    additionnalAddress2: e.target.value,
                                  },
                                })
                              );
                            }}
                            onBlur={onBlur}
                            error={errors?.additionnalAddress2}
                            placeholder={t(
                              "souscription.facturation.placeholderAdditionnalAddress2"
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Controller
                        name="zipCode"
                        rules={{ required: t("error.required") }}
                        control={control}
                        defaultValue={sale?.billingAddress?.zipCode}
                        render={({ field: { onBlur, onChange } }) => (
                          <TextInput
                            maxLength={5}
                            name="zipCode"
                            style={{ margin: "3px 0" }}
                            value={sale?.billingAddress?.zipCode}
                            onChange={(e) => {
                              onChange(e);
                              dispatch(
                                saleStore.update({
                                  target: "billingAddress",
                                  value: {
                                    ...sale?.billingAddress,
                                    zipCode: e.target.value,
                                  },
                                })
                              );
                            }}
                            onBlur={onBlur}
                            error={errors?.zipCode}
                            placeholder={t(
                              "souscription.facturation.placeholderZip"
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Controller
                        name="city"
                        rules={{ required: t("error.required") }}
                        defaultValue={sale?.billingAddress?.city}
                        control={control}
                        render={({ field: { onBlur, onChange } }) => (
                          <TextInput
                            name="city"
                            style={{ margin: "3px 0 16px" }}
                            value={sale?.billingAddress?.city}
                            onChange={(e) => {
                              onChange(e);
                              dispatch(
                                saleStore.update({
                                  target: "billingAddress",
                                  value: {
                                    ...sale?.billingAddress,
                                    city: e.target.value,
                                  },
                                })
                              );
                            }}
                            onBlur={onBlur}
                            error={errors?.city}
                            placeholder={t(
                              "souscription.facturation.placeholderCity"
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  {/*  */}
                </AddressDisplay>
              </>
            )}
        </Grid>

        {/* Choix de la date de prélèvement */}
        <Grid item xs={12}>
          <h3>{t("souscription.facturation.date")}</h3>
          <Controller
            control={control}
            rules={{ required: t("error.required") }}
            name="debitingDateChoice"
            render={({ field: { value, onChange, onBlur } }) => (
              <BarreButton
                name="debitingDateChoice"
                value={value}
                labelA={t("souscription.facturation.earlier")}
                labelB={t("souscription.facturation.choose")}
                error={errors.debitingDateChoice}
                onChange={(v) => {
                  onChange(v);
                  dispatch(
                    saleStore.update({
                      target: "debitingDateChoice",
                      value: v,
                    })
                  );
                  const newD = v === t("souscription.facturation.earlier") ? (forceRightOfWithdrawal ? JSON.stringify(addDays(params.dates.start.standard)) : JSON.stringify(addDays(6)) ): JSON.stringify(getValues("debitingDate"));
                  console.log(newD)
                  dispatch(
                    saleStore.update({
                      target: "debitingDate",
                      value: newD,
                    })
                  );

                }}
                // onBlur={(v) => {
                //   dispatch(
                //     saleStore.update({
                //       target: "debitingDateChoice",
                //       value: v,
                //     })
                //   );
                //   const newD = v === t("souscription.facturation.earlier") ? (forceRightOfWithdrawal ? JSON.stringify(addDays(params.dates.start.standard)) : JSON.stringify(addDays(6)) ): JSON.stringify(getValues("debitingDate"));
                //   dispatch(
                //     saleStore.update({
                //       target: "debitingDate",
                //       value: newD,
                //     })
                //   );
                //   onBlur(v);
                // }}
              />
            )}
          />
          {/* choisir une date spécifique (choose) donne le datepicker */}
          {getValues("debitingDateChoice") ===
            t("souscription.facturation.choose") && (
              <Grid item xs={10} style={{ marginTop: "16px", marginBottom: "0" }}>
                <Controller
                  control={control}
                  rules={{
                    required: t("error.required"),
                    validate: {
                      isBetween: (v) => dateIsBetween(v, 6, 31, "days") ? true : t("error.required"),
                    },
                  }}
                  name="debitingDate"
                  defaultValue={
                    setMinMaxDate(
                    params.dates.start.standard,
                    "days"
                  )}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <DatePicker
                      value={value}
                      name="debitingDate"
                      label={t("souscription.facturation.date")}
                      limitation={{
                        min: forceRightOfWithdrawal ? addDays(params.dates.start.standard) : addDays(params.dates.start.earlier),
                        max: addDays(31),
                      }}
                      error={errors?.debitingDate}
                      required
                      onBlur={(e) => {
                        dispatch(
                          saleStore.update({
                            target: "debitingDate",
                            value: JSON.stringify(value),
                          })
                        );
                        onBlur(e);
                      }}
                      onChange={(e) => {

                        dispatch(
                          saleStore.update({
                            target: "debitingDate",
                            value: JSON.stringify(e),
                          })
                        );
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Grid>
            )}
          {/* choisir sur la barrebutton de le texte */}
          {getValues("debitingDateChoice") !== undefined &&
            getValues("debitingDate") !== undefined &&
            moment(getValues("debitingDate")).format("L").toString() !==
            "Invalid date" &&
            errors.debitingDate === undefined && (
              <>
                <h3 style={{ marginBottom: "4px" }}>
                  {t("souscription.facturation.plannedDate")}
                </h3>
                <h2 className="green" style={{ marginTop: "0px" }}>
                  {moment(getValues("debitingDate")).format("L")}
                </h2>
              </>
            )}
          {/* renonciation si date earlier */}
          {getValues("debitingDateChoice") !== undefined &&
            dateIsLower(
              getValues("debitingDate"),
              params.dates.start.standard
            ) && !forceRightOfWithdrawal
             &&
            errors.debitingDate === undefined && (
              <Controller
                rules={{
                  required: t("error.required"),
                }}
                control={control}
                name="renounceRetractation"
                defaultValue={false}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CheckboxButton
                    className="gray"
                    text={t("souscription.facturation.renounce")}
                    onChange={(v) => {
                      onChange(v);
                      dispatch(
                        saleStore.update({
                          target: "renounceRetractation",
                          value: v,
                        })
                      );
                    }}
                    onBlur={(v) => {
                      dispatch(
                        saleStore.update({
                          target: "renounceRetractation",
                          value: value,
                        })
                      );
                      onBlur(v);
                    }}
                    value={value}
                    checked={value}
                  />
                )}
              />
            )}
        </Grid>
      </Grid>
    </Bloc>
  );
};

export default BillingBloc;
