import i18n from "i18next";
import IBAN from "iban";
import _ from "lodash";
import moment from "moment";
import transform from "moment-transform";
import "moment/locale/fr";
import {
   listProfilWhenAdminCourtage,
   listProfilWhenAdminPrimeo,
   listProfilWhenResponsable,
   listProfilWhenVendeur,
   menuListAdmin,
   menuListAdminCourtage,
   menuListChargeClient,
   menuListRespo,
   menuListVendeur,
} from "./BOCO/enums";
moment.locale("fr");

export function formatErrorEmail(email) {
   if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
         email
      ) === false
   )
      return i18n.t("error.mail");
}

/**
 * @name addDays
 * @description Returns a date depending on date (date) and days (days) you give as args
 * @param {number} days
 *
 * @returns {date} date with days added
 */
export function addDays(days) {
   return moment().transform("00:00:00.000").add(days, "days");
}

export function addDaysBis(days) {
   return moment().transform("23:59:59.000").add(days, "days");
}

/**
 * @name setMinMaxDate
 * @description Returns a date depending on value you give as arg (value) and the mesure of ths value (days or years)
 * @param {number} value
 * @param {string} mesure
 * @returns {date} birthdate
 */
export function setMinMaxDate(value, mesure) {
   switch (mesure) {
      case "year":
         return moment().transform("00:00:00.000").subtract(value, "year");
      case "days":
         return addDaysBis(value);
      case "now":
         return moment().transform("00:00:00.000");

      default:
         return moment().transform("00:00:00.000").subtract(value, "year");
   }
}

/**
 * @name dateIsBetween
 * @description Returns true or error message if age (date) is out min & max range, depending on mesure (year ou days)
 * @param {date} date
 * @param {date} min
 * @param {date} max
 * @param {string} mesure
 * @returns {*} answer
 */
export function dateIsBetween(date, min, max, mesure) {
   return moment(new Date(Date.parse(date))) >= setMinMaxDate(min, mesure) &&
      moment(new Date(Date.parse(date))) <= setMinMaxDate(max, mesure)
      ? true
      : false;
}

export function dateIsBetweenNowAnd(date, value, mesure) {
   return moment(date) >= setMinMaxDate("now") &&
      moment(date) <= setMinMaxDate(value, mesure)
      ? true
      : false;
}

export function dateFormatL(date) {
   return moment(date).format("L");
}
export function dateIsLower(date, standard) {
   return moment(date).transform("00:00:00.000") < addDays(standard)
      ? true
      : false;
}
export function dateVentesRetractation(date, standard) {
   return moment().transform("00:00:00.000") <
      moment(date).transform("00:00:00.000").add(standard, "days")
      ? true
      : false;
}

export function checkEnterKeyDown(e) {
   if (e.code === "Enter") e.preventDefault();
}

export function formatErrorPhoneNumber(value, forbidden) {
   if (value === forbidden) {
      return i18n.t("error.invalidPhone");
   }
   if (value?.length < 10) {
      return i18n.t("error.phoneLength");
   }
   if (/^(0)(6|7)\d{8}$/?.test(value) === false) {
      return i18n.t("error.mustBeMobilePhone");
   }
}

export function formatErrorInternationalPhoneNumber(value, forbidden) {
   const fbd = `+33${forbidden * 1}`;
   if (value === fbd) {
      return i18n.t("error.invalidPhone");
   }
   if (value?.length < 12) {
      return i18n.t("error.internationalPhoneLength");
   }
   if (/^\+(33)(6|7)\d{8}$/?.test(value) === false) {
      return i18n.t("error.mustBeInternationalMobilePhone");
   }
}

export function checkPDLValue(value) {
   if (value) {
      return value.length === 14 ? true : false;
   } else {
      return false;
   }
}

export const autoCompleteAlea = () => {
   const crypto = window.crypto || window.msCrypto;
   var array = new Uint32Array(1);
   const valueAlea = crypto.getRandomValues(array);
   return valueAlea[0].toString();
};

export const ibanIsValide = (iban) => {
   return IBAN.isValid(iban);
};

export const ibanIsValideError = (iban) => {
   if (iban) {
      return IBAN.isValid(iban) &&
         onlyNumbersAndLetters(iban) &&
         getIbanCountryCode(iban) === "FR"
         ? true
         : false;
   } else {
      return null;
   }
};

export const getIbanCountryCode = (iban) => {
   return iban.slice(0, 2);
};

export const extractFirstNumbers = (value) => {
   if (!!value) {
      return value.replace(/(^\d+)(.+$)/i, "$1");
   }
   return "";
};

export const replaceFirstNumbers = (value) => {
   if (!!value) {
      return value?.split(" ")?.slice(1, value?.split(" ").length)?.join(" ");
   }
};

export const check0to100 = (value) => {
   if (value > 100 || value < 0) {
      return i18n.t("error.mustbe0to100");
   }
};

export function capitalizeFirstLetter(string) {
   return string.charAt(0).toUpperCase() + string.slice(1);
}

export function checkSiretValue(value) {
   if (value.toString().length === 14) {
      return true;
   } else {
      return false;
   }
}

export function add0IfNeededBefore(numb) {
   return ("0" + numb).slice(-2);
}

export function labelMotifAnnulation(motifAnnulation) {
   switch (motifAnnulation) {
      case "VENTE_ANNULE_PRIMEO":
         return "Vente annulée par Primeo Energie";
      case "SOLVENCY_KO":
         return "Echec du prélèvement";
      case "VENTE_ANNULE":
         return "Vente annulée";
      case "RETRACTATION_CLIENT":
         return "Rétractation client";
      case "KO_ENEDIS":
         return "KO Enedis";
      case "DECOMMISSIONNEMENT":
         return "Décommissionnement";
      default:
         return;
   }
}

export function sortDates(current) {
   let dates = [
      {
         statut: "A_CONFIRMER_COURTIER",
         date: current.dateAconfirmer,
         prio: 3,
         elt: (
            <p>
               A confirmer par courtier
               <br />
               {dateFormatL(current?.dateAconfirmer)}
            </p>
         ),
      },
      {
         statut: "A_VALIDER_PRIMEO",
         date: current.dateAvalider,
         prio: 2,
         elt: (
            <p>
               Confirmée par courtier,
               <br />
               à valider par Primeo
               <br />
               {dateFormatL(current?.dateAvalider)}
            </p>
         ),
      },
      {
         statut: "VALIDE",
         date: current.dateValide,
         prio: 1,
         elt: (
            <p>
               Validée Primeo
               <br />
               {dateFormatL(current?.dateValide)}
            </p>
         ),
      },
      {
         statut: "ANNULE",
         prio: 0,
         date: current.dateAnnule,
         elt: (
            <p>
               Annulée
               <br />
               {dateFormatL(current?.dateAnnule)}
               <br />
               {labelMotifAnnulation(current?.motifAnnulation)}
            </p>
         ),
      },
   ];

   return dates.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      if(a.date === undefined){
         return 1
      }
      else if(b.date === undefined){
         return -1
      }
      let valueA = new Date(a.date).getTime();
      let valueB = new Date(b.date).getTime();

      if(valueA === valueB){
         return a.prio > b.prio ? 1 : -1
      }
      return valueB - valueA;
   });
}

/**
 * @name trueOrFalseDo transforme value en booléen en renvoie une action dépendant de true ou false
 * @description Same as if/else
 */
export function trueOrFalseDo(value, actionTrue, actionFalse) {
   if (!!value === true) {
      return actionTrue();
   } else {
      return actionFalse();
   }
}

/**
 * @name checkIfDisplayAnnuleBtn
 * @description Return true if the conditions for showing the btn annule is good
 * @param {Array} user
 * @param {Array} current
 */
export const checkIfDisplayAnnuleBtn = (user, current) => {
   let statut = sortDates(current)[0]?.statut;

   const checkIfVenteValid = dateVentesRetractation(
      current.dateCreationVente,
      14
   );

   const condition1 = statut === "VALIDE" && user?.type === "ADMIN_PRIMEO";
   const condition2 = statut === "A_CONFIRMER_COURTIER";
   const condition3 =
      statut === "A_VALIDER_PRIMEO"

   return (condition1 || condition2 || condition3);
};

/**
 * @name isAnnule
 * @description Return true if statut  vente is ANNULE
 * @param {Array} vente
 */
export const isAnnule = (vente) => {
   let checkIsAnnule = vente.map((m) => (m.motifAnnulation ? true : false));

   return checkIsAnnule.every((element) => element === true);
};

/**
 * @name switchMenuUser
 * @description Change menu user right
 * @param {string} user type
 */
export const switchMenuUser = (user) => {
   switch (user) {
      case "ADMIN_PRIMEO":
         return menuListAdmin;
      case "ADMIN_COURTAGE":
         return menuListAdminCourtage;
      case "RESPONSABLE_VENDEUR":
         return menuListRespo;
      case "VENDEUR":
         return menuListVendeur;
      case "CHARGE_CLIENT":
         return menuListChargeClient;
      default:
         return;
   }
};

/**
 * Compare la valeur à la condition suivant les cas donnés dans la fonction (prix KWH par puissance, bonus sur les services) et retourne un booléen.
 *
 * @param condition priceCondition donnée par Tripica.
 * @param value la valeur à comparer, puissanceSouscrite pour retrouver le prix par puissance ou nbBundleName pour les bonus sur les services
 * @returns bool.
 */
export function safeEval(condition, value) {
   let stringParams = condition.join(" ");

   // always true conditions
   if (stringParams === "1 == 1") {
      return true;
   }

   // puissanceSouscrite conditions list for kwh prices
   else if (condition[0] === "puissanceSouscrite") {
      switch (stringParams) {
         case "puissanceSouscrite == 3":
            return value === 3;
         case "puissanceSouscrite == 6":
            return value === 6;
         case "puissanceSouscrite == 9":
            return value === 9;
         case "puissanceSouscrite ==  12":
            return value === 12;
         case "puissanceSouscrite == 15":
            return value === 15;
         case "puissanceSouscrite == 18":
            return value === 18;
         case "puissanceSouscrite == 24":
            return value === 24;
         case "puissanceSouscrite == 30":
            return value === 30;
         case "puissanceSouscrite == 36":
            return value === 36;

         case "puissanceSouscrite > 3 && puissanceSouscrite <= 6":
            return value > 3 && value <= 6;
         case "puissanceSouscrite > 6 && puissanceSouscrite <= 9":
            return value > 6 && value <= 9;
         case "puissanceSouscrite > 9 && puissanceSouscrite <= 12":
            return value > 9 && value <= 12;
         case "puissanceSouscrite > 12 && puissanceSouscrite <= 15":
            return value > 12 && value <= 15;
         case "puissanceSouscrite > 15 && puissanceSouscrite <= 18":
            return value > 15 && value <= 18;
         case "puissanceSouscrite > 18 && puissanceSouscrite <= 24":
            return value > 18 && value <= 24;
         case "puissanceSouscrite > 24 && puissanceSouscrite <= 30":
            return value > 24 && value <= 30;
         case "puissanceSouscrite > 30 && puissanceSouscrite <= 36":
            return value > 30 && value <= 36;

         case "puissanceSouscrite < 3":
            return value < 3;
         case "puissanceSouscrite < 6":
            return value < 6;
         case "puissanceSouscrite < 9":
            return value < 9;
         case "puissanceSouscrite < 12":
            return value < 12;
         case "puissanceSouscrite < 15":
            return value < 15;
         case "puissanceSouscrite < 18":
            return value < 18;
         case "puissanceSouscrite < 24":
            return value < 24;
         case "puissanceSouscrite < 30":
            return value < 30;
         case "puissanceSouscrite < 36":
            return value < 36;

         case "puissanceSouscrite > 3":
            return value > 3;
         case "puissanceSouscrite > 6":
            return value > 6;
         case "puissanceSouscrite > 9":
            return value > 9;
         case "puissanceSouscrite > 12":
            return value > 12;
         case "puissanceSouscrite > 15":
            return value > 15;
         case "puissanceSouscrite > 18":
            return value > 18;
         case "puissanceSouscrite > 24":
            return value > 24;
         case "puissanceSouscrite > 30":
            return value > 30;
         case "puissanceSouscrite > 36":
            return value > 36;

         case "puissanceSouscrite <= 3":
            return value <= 3;
         case "puissanceSouscrite <= 6":
            return value <= 6;
         case "puissanceSouscrite <= 9":
            return value <= 9;
         case "puissanceSouscrite <= 12":
            return value <= 12;
         case "puissanceSouscrite <= 15":
            return value <= 15;
         case "puissanceSouscrite <= 18":
            return value <= 18;
         case "puissanceSouscrite <= 24":
            return value <= 24;
         case "puissanceSouscrite <= 30":
            return value <= 30;
         case "puissanceSouscrite <= 36":
            return value <= 36;

         case "puissanceSouscrite >= 3":
            return value >= 3;
         case "puissanceSouscrite >= 6":
            return value >= 6;
         case "puissanceSouscrite >= 9":
            return value >= 9;
         case "puissanceSouscrite >= 12":
            return value >= 12;
         case "puissanceSouscrite >= 15":
            return value >= 15;
         case "puissanceSouscrite >= 18":
            return value >= 18;
         case "puissanceSouscrite >= 24":
            return value >= 24;
         case "puissanceSouscrite >= 30":
            return value >= 30;
         case "puissanceSouscrite >= 36":
            return value >= 36;

         default:
            return false;
      }
   }

   // bundleName condition list for services
   else if (condition[0] === "nbBundleName") {
      switch (stringParams) {
         case "nbBundleName == 2":
            return value === 2;
         case "nbBundleName >= 3":
            return value >= 3;
         default:
            return false;
      }
   } else {
      console.log("safeEval: Une condition de prix n'est pas prise en compte");
      return false;
   }
}

export function getWeekNumber(date) {
   var oneJan = new Date(date.getFullYear(), 0, 1);
   var numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000));
   var result = Math.ceil((date.getDay() + 1 + numberOfDays) / 7);
   return result;
}
export function replacePointByCommaInDecimals(str) {
   return str?.toString()?.replace(".", ",");
}

export function onlyNumbersAndLetters(str) {
   return /^[a-z A-Z0-9]+$/?.test(str);
}

export function internationalMobilePhoneToRegular(
   internationalCode,
   regularNumber,
   phoneNumber
) {
   return phoneNumber?.replace(internationalCode, regularNumber);
}

export function regularMobilePhoneToInternational(
   internationalCode,
   regularNumber,
   phoneNumber
) {
   return phoneNumber?.replace(regularNumber, internationalCode);
}

export function daysToMonths(days) {
   const monthInDays = 30;
   return _.round(days / monthInDays);
}

export function getProduitRemunerations(societe) {
   let i = 0;
   let result = [];
   while (societe.hasOwnProperty(`produitRemuneration${i}`)) {
      result.push(societe[`produitRemuneration${i}`]);
      i++;
   }
   return result;
}

export const switchUserProfilUpdateUserTable = (user) => {
   switch (user) {
      case "ADMIN_PRIMEO":
         return listProfilWhenAdminPrimeo;
      case "ADMIN_COURTAGE":
         return listProfilWhenAdminCourtage;
      case "RESPONSABLE_VENDEUR":
         return listProfilWhenResponsable;
      case "VENDEUR":
         return listProfilWhenVendeur;
      default:
         return;
   }
};

export function formatErrorNames(value = "") {
   if (/^[A-Za-zÀ-ÿ' -]*$/?.test(value) === false || value?.length === 0 || value.length > 32) {
     return i18n.t("error.name");
   }
 }
 
 export function formatErrorLastnames(value = "") {
   if (/^[A-Za-zÀ-ÿ' çÇ\-]*$/?.test(value) === false || value?.length === 0 || value.length > 32) {
     return i18n.t("error.name");
   }
 }
