import React from "react";
import { Redirect } from "react-router-dom";
import _ from "lodash";

const Authorization = ({
    current,
    authorized,
    children,
    redirect = false,
    destination,
    user,
    TCOStep,
    currentTCOStep,
}) => {

    const societyEmpty = !!!user.society.statut;
    const checkStatut = societyEmpty ? user.broker.statut === "INACTIF" : (user.broker.statut === "INACTIF" || user.society.statut === "INACTIF");

    if (redirect && !_.includes(authorized, current.type) && checkStatut
    ) {
        return <Redirect to={destination} />
    }

    if (!_.isEqual(TCOStep, currentTCOStep)) {
        return <Redirect to={destination} />
    }

    return <>{children}</>
};

export default Authorization;
