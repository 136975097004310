import React, { useState, useEffect } from "react";
import HeaderBOCO from "../../BOCO/Header/HeaderBOCO";

import "./styles/BOCOContainerLayout.scss";

const BOCOContainerLayout = ({ children, is40X = false }) => {
   const [screenSize, setScreenSize] = useState(window.innerWidth);
   const [menuIsOpen, setmenuIsOpen] = useState(true);
   const [style, setStyle] = useState({});

   const detectSize = () => {
      setScreenSize(window.innerWidth);
   };

   function collectOpen(params) {
      setmenuIsOpen(params);
      return params;
   }

   useEffect(() => {
      window.addEventListener("resize", detectSize);
      if (screenSize <= 1024) {
         setStyle({ width: "calc(100% - 56px )" });
      }
      if (screenSize > 1024) {
         if (menuIsOpen) {
            setStyle({ width: "calc(100% - 328px )" });
         } else {
            setStyle({ width: "calc(100% - 153px )" });
         }
      }

      return () => {
         window.removeEventListener("resize", detectSize);
      };
   }, [screenSize, menuIsOpen]);

   return (
      <div className="BOCOContainerLayout">
         <div className="header_BOCO_container">
            <HeaderBOCO collectOpen={collectOpen} />
         </div>
         <div className="main_BOCO_container">
            <div className="main_BOCO_content" style={style}>
               {children}
            </div>
         </div>
      </div>
   );
};

export default BOCOContainerLayout;
