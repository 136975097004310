/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import TextInput from "../../TextInput/TextInput";
import { Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  getParametrage,
  postParametrage,
} from "../../../core/services/httpRequest/axios";
import { checkEnterKeyDown } from "../../../utils";
import handleRequestResponse from "../../../utils/responsesHandler";
import Button from "../../Button/Button";
import { useDispatch } from "react-redux";
import { update } from "../../../core/stores/paramsStore";
import { sendNotif } from "../../../core/stores/utils";

const ParametrageWordingAdmin = ({ idSocieteCourtage, selectedSociete }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    setValue,
    resetField,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
    defaultValues: { ...selectedSociete },
  });

  async function submit(data) {
    const idSociete = idSocieteCourtage;
    const words = [
      { word: "PDL", value: data?.PDL },
      { word: "OFFER_AND_OPTIONS", value: data?.OFFER_AND_OPTIONS },
      { word: "BILLING", value: data?.BILLING },
      { word: "IDENTITY", value: data?.IDENTITY },
      { word: "PAYMENT", value: data?.PAYMENT },
      {
        word: "SUBSCRIPTION_VALIDATION",
        value: data?.SUBSCRIPTION_VALIDATION,
      },
    ];

    for (const word of words) {
      postParametrage(idSociete, word).then((res) => {
        handleRequestResponse(
          res,
          () => {
            /* empty success callback */
          },
          () => {
            dispatch(update(sendNotif(res.data.status)));
          }
        );
      });
    }
    dispatch(update(sendNotif("createAdminNote")));
  }

  function reset() {
    resetField("PDL");
    resetField("IDENTITY");
    resetField("OFFER_AND_OPTIONS");
    resetField("BILLING");
    resetField("PAYMENT");
    resetField("SUBSCRIPTION_VALIDATION");
  }

  useEffect(() => {
    const fieldsToSet = [
      "PDL",
      "IDENTITY",
      "OFFER_AND_OPTIONS",
      "BILLING",
      "PAYMENT",
      "SUBSCRIPTION_VALIDATION",
    ];

    if (idSocieteCourtage) {
      getParametrage(idSocieteCourtage).then((res) => {
        fieldsToSet.forEach((field) => {
          setValue(field, res?.data?.[field]);
        });
      });
    } else {
      reset();
    }
  }, [idSocieteCourtage, selectedSociete]);

  return (
    <div class="parametrage_notes">
      <h3 style={{ margin: "0 0 12px" }}>{t("parametrage.title_wording")}</h3>

      {!!idSocieteCourtage ? (
        <form
          onSubmit={handleSubmit(submit)}
          onKeyDown={(e) => checkEnterKeyDown(e)}
          noValidate
          autoComplete="off"
          className="form_parametrage"
        >
          <Grid container spacing={1}>
            {!!idSocieteCourtage && (
              <>
                <Grid item xs={12} lg={6}>
                  <Controller
                    name="PDL"
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <TextInput
                          label={"Note interne PDL"}
                          placeholder={"Note"}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          area
                          multiline
                          minRows="2"
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Controller
                    name="OFFER_AND_OPTIONS"
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <TextInput
                          label={"Note interne Offres et Options"}
                          placeholder={"Note"}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          area
                          multiline
                          minRows="2"
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Controller
                    name="IDENTITY"
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <TextInput
                          label={"Note interne Identité"}
                          placeholder={"Note"}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          area
                          multiline
                          minRows="2"
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Controller
                    name="BILLING"
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <TextInput
                          label={"Note interne Mode de facturation"}
                          placeholder={"Note"}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          area
                          multiline
                          minRows="2"
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Controller
                    name="PAYMENT"
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <TextInput
                          label={"Note interne Paiement"}
                          placeholder={"Note"}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          area
                          multiline
                          minRows="2"
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Controller
                    name="SUBSCRIPTION_VALIDATION"
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <TextInput
                          label={"Note interne Récapitulatif"}
                          placeholder={"Note"}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          area
                          multiline
                          minRows="2"
                        />
                      );
                    }}
                  />
                </Grid>
                <div
                  style={{
                    width: "100%",
                    margin: "20px auto 0",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    className={"green"}
                    type="submit"
                    style={{ marginRight: "6px" }}
                  >
                    {t("parametrage.submit")}
                  </Button>
                  <Button
                    className={"red"}
                    style={{ marginLeft: "6px" }}
                    onClick={reset}
                  >
                    {t("parametrage.cancel")}
                  </Button>
                </div>
              </>
            )}
          </Grid>
        </form>
      ) : (
        <p>Sélectionnez une société</p>
      )}
    </div>
  );
};

export default ParametrageWordingAdmin;
