/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import CustomTableHead from "../../CustomTableHead/CustomTableHead";
import ActiveSvg from "../../../SvgComponents/ActiveSvg";
import InactiveSvg from "../../../SvgComponents/InactiveSvg";
import TextInput from "../../../TextInput/TextInput";
import ComboBoxObject from "../../../ComboBox/ComboBoxObject";
// import { ReactComponent as Dot } from "../../../../core/assets/picto/dot.svg";
import {
  getUserDetails,
  getUsers,
  logout,
  putUser,
} from "../../../../core/services/httpRequest/axios";
import { useTranslation } from "react-i18next";
import "./styles/customtable.scss";
import { useSelector } from "react-redux";
import handleRequestResponse from "../../../../utils/responsesHandler";
import PermissionWrapper from "../../../HOC/PermissionWrapper";
import { switchUserProfilUpdateUserTable } from "../../../../utils";

const UsersTable = ({
  headCells,
  rows,
  actions,
  pagination = false,
  editInRow = false,
  collect,
  onSuccess,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [openOrganize, setOpenOrganize] = useState(false);
  const [edit, setEdit] = useState(null);
  const [editedUser, setEditedUser] = useState({});
  const [responsables, setResponsables] = useState([]);
  const [initRespo, setInitRespo] = useState([]);
  const [errors, setErrors] = useState({
    responsable: false,
    profil: false,
    statut: false,
  });
  const [listType, setlistType] = useState();

  const currentUser = useSelector((state) => state.auth.broker);

  const { t } = useTranslation();

  // console.log("userTable selected :", selected)

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getRespoFullName(id) {
    let nom;
    let prenom;

    nom = initRespo.find((r) => r.idUtilisateur === id)?.nom;
    prenom = initRespo.find((r) => r.idUtilisateur === id)?.prenom;

    if (!!nom && !!prenom) {
      return prenom + " " + nom;
    }

    return t("users.noRespo");
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // function toggleOrganize() {
  //   setOpenOrganize(!openOrganize);
  // }

  function handleSuccess() {
    setSelected([]);
    onSuccess();
  }

  async function submitRowEditing() {
    let prevType = "";
    let nextType = editedUser.type;
    let findVendeur = rows?.find(
      (r) => r.idResponsable === editedUser.idUtilisateur
    );
    let tmpEditedUser = editedUser;
    await getUserDetails(edit).then((res) => {
      prevType = res?.data?.type;
      if (
        prevType === "RESPONSABLE_VENDEUR" &&
        nextType === "VENDEUR" &&
        !!findVendeur
      ) {
        return setErrors({
          ...errors,
          type: "Transfer d'équipe requis au préalable.",
        });
      } else if (editedUser.idResponsable === null) {
        return setErrors({ ...errors, responsable: t("error.required") });
      }
      if (tmpEditedUser.idResponsable === null) {
        tmpEditedUser = {
          ...editedUser,
          idResponsable: null,
        };
      }
      if (
        prevType !== nextType &&
        editedUser.idUtilisateur === currentUser.idUtilisateur
      ) {
        return putUser(tmpEditedUser).then((res) => {
          handleRequestResponse(
            res,
            () => {
              setEdit(null);
              setEditedUser({});
              handleSuccess();
              logout();
            },
            () => {
              return;
            }
          );
        });
      }
      return putUser(tmpEditedUser).then((res) => {
        handleRequestResponse(
          res,
          () => {
            setEdit(null);
            setEditedUser({});
            handleSuccess();
          },
          () => {
            return;
          }
        );
      });
    });
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows?.map((n) => n.idUtilisateur);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, idUtilisateur) => {
    const selectedIndex = selected.indexOf(idUtilisateur);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, idUtilisateur);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (idUtilisateur) => {
    return selected.indexOf(idUtilisateur) !== -1;
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  useEffect(() => {
    if (pagination) {
      setRowsPerPage(6);
    } else {
      setRowsPerPage(rows?.length);
    }
    getUsers().then((res) => {
      setInitRespo(res.data.filter((r) => r?.type === "RESPONSABLE_VENDEUR"));
    });
  }, [rows]);

  useEffect(() => {
    let prevType = currentUser.type;
    let nextType = editedUser.type;
    if (
      prevType !== nextType &&
      editedUser.idUtilisateur === currentUser.idUtilisateur
    ) {
      setErrors({
        ...errors,
        type: t("notifications.changeCurrentAuthorization"),
      });
    }
  }, [editedUser]);

  useEffect(() => {
    collect(selected);
  }, [selected]);

  useEffect(() => {
    getUsers().then((res) => {
      let respos = res?.data?.filter((u) => {
        if (
          u?.type === "RESPONSABLE_VENDEUR" &&
          u.statut === "ACTIF" &&
          u.idSocieteCourtage === editedUser?.idSocieteCourtage &&
          u?.idUtilisateur !== editedUser.idUtilisateur
        ) {
          return {
            name: u.nomResponsable,
            idUtilisateur: u.idUtilisateur,
          };
        }
      });
      setResponsables(respos);
    });
    const typeList = switchUserProfilUpdateUserTable(currentUser?.type)
    setlistType(typeList)
  }, [edit]);

  useEffect(() => {
    setSelected([]);
  }, [rows]);


  return (
    <Box className="table_container">
      <TableContainer>
        <Table
          // sx={{ minWidth: 750 }}
          padding={"none"}
          aria-labelledby="tableTitle"
          size="medium"
          stickyHeader={true}
        >
          <CustomTableHead
            headCells={headCells}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
          // action={{ icon: <Dot /> }}
          />
          {openOrganize && (
            <Modal
              className="organize_modal"
              open={openOrganize}
              onClose={() => setOpenOrganize(false)}
            >
              <p>MODAL</p>
            </Modal>
          )}
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, i) => {
                const isItemSelected = isSelected(row.idUtilisateur);
                const labelId = `enhanced-table-checkbox-${row.idUtilisateur}`;

                return (
                  <TableRow
                    key={row + i}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={1}
                    selected={isItemSelected}
                    className={`custom-table-row ${edit === row.idUtilisateur && "edit"
                      }`}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) =>
                          handleClick(event, row.idUtilisateur)
                        }
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        size="small"
                      />
                    </TableCell>

                    {edit !== row.idUtilisateur && (
                      <>
                        <TableCell
                          align="left"
                          onClick={() => actions.modify.fnc(row.idUtilisateur)}
                        >
                          <p>{row.nom}</p>
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => actions.modify.fnc(row.idUtilisateur)}
                        >
                          <p>{row.prenom}</p>
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => actions.modify.fnc(row.idUtilisateur)}
                        >
                          <p>{row.nomSociete}</p>
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => actions.modify.fnc(row.idUtilisateur)}
                        >
                          <p>{row.email}</p>
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => actions.modify.fnc(row.idUtilisateur)}
                        >
                          <p>{getRespoFullName(row.idResponsable)}</p>
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => actions.modify.fnc(row.idUtilisateur)}
                        >
                          <p>{t("common." + row.type)}</p>
                        </TableCell>
                        <TableCell
                          align="left"
                          className="custom-cell"
                          onClick={() => actions.modify.fnc(row.idUtilisateur)}
                        >
                          <div>
                            {row.statut === "ACTIF" ? (
                              <ActiveSvg fill="#109F7B" />
                            ) : (
                              <InactiveSvg fill="#9D9D9D" />
                            )}
                            <p>{t("common." + row.statut)}</p>
                          </div>
                        </TableCell>
                      </>
                    )}

                    {edit === row.idUtilisateur && (
                      <>
                        <TableCell align="left">
                          <TextInput disabled value={editedUser.nom} />
                        </TableCell>
                        <TableCell align="left">
                          <TextInput disabled value={editedUser.prenom} />
                        </TableCell>
                        <TableCell align="left">
                          <TextInput disabled value={editedUser.nomSociete} />
                        </TableCell>
                        <TableCell align="left">
                          <TextInput disabled value={editedUser.email} />
                        </TableCell>
                        <TableCell align="left">
                          <ComboBoxObject
                            required={false}
                            minWidth={240}
                            canBeCanceled={true}
                            list={responsables?.map((r) => {
                              return {
                                value: r.idUtilisateur,
                                name: r.prenom + " " + r.nom,
                              };
                            })}
                            disabled={
                              row.type === "ADMIN_PRIMEO" ||
                              row.type === "ADMIN_COURTAGE" ||
                              row.type === "RESPONSABLE_VENDEUR"
                            }
                            value={editedUser.idResponsable}
                            error={errors.responsable}
                            helperText={
                              errors.responsable ? errors.responsable : ""
                            }
                            onChange={(e) => {
                              setErrors({
                                ...errors,
                                responsable: false,
                              });
                              return setEditedUser({
                                ...editedUser,
                                idResponsable: e,
                              });
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <ComboBoxObject
                            required={false}
                            minWidth={180}
                            canBeCanceled={false}
                            list={listType}
                            value={editedUser.type}
                            error={errors.type}
                            helperText={errors.type ? errors.type : ""}
                            onChange={(e) => {
                              setErrors({
                                ...errors,
                                type: false,
                              });
                              return setEditedUser({
                                ...editedUser,
                                type: e,
                              });
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <ComboBoxObject
                            required={false}
                            minWidth={180}
                            canBeCanceled={false}
                            list={[
                              {
                                value: "ACTIF",
                                name: t("common.ACTIF"),
                              },
                              {
                                value: "INACTIF",
                                name: t("common.INACTIF"),
                              },
                            ]}
                            value={editedUser.statut}
                            onChange={(e) => {
                              return setEditedUser({
                                ...editedUser,
                                statut: e,
                              });
                            }}
                          />
                        </TableCell>
                      </>
                    )}

                    {editInRow && edit !== row.idUtilisateur && (
                      <>
                        <TableCell align="left"></TableCell>

                        <PermissionWrapper
                          requiredPermission={["UPDATE_SELLER"]}
                          frag={<TableCell align="left"></TableCell>}
                        >
                          <TableCell align="left">
                            <div
                              className="modif_icon"
                              style={{
                                height: "48px",
                                width: "50px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setEditedUser(row);
                                setEdit(row.idUtilisateur);
                              }}
                            >
                              {actions.modify.component}
                            </div>
                          </TableCell>
                        </PermissionWrapper>
                      </>
                    )}

                    {editInRow && edit === row.idUtilisateur && (
                      <>
                        <TableCell align="left">
                          <div
                            style={{
                              height: "50px",
                              width: "50px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelected([]);
                              setErrors({
                                responsable: false,
                                profil: false,
                                statut: false,
                              });
                              setEditedUser({});
                              setEdit(null);
                            }}
                          >
                            {actions.close.component}
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div
                            style={{
                              height: "50px",
                              width: "50px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelected([]);
                              submitRowEditing();
                            }}
                          >
                            {actions.save.component}
                          </div>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 48 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

export default UsersTable;
