import React, { useEffect, useState } from "react";

// icons
import { ReactComponent as PdlSvg } from "../../core/assets/picto/pdl.svg";
import { ReactComponent as FactureSvg } from "../../core/assets/picto/facturation.svg";
import { ReactComponent as IdSvg } from "../../core/assets/picto/id.svg";
import { ReactComponent as OffreSvg } from "../../core/assets/picto/offre.svg";
import { ReactComponent as PaieSvg } from "../../core/assets/picto/paiement.svg";
import { ReactComponent as CheckSvg } from "../../core/assets/picto/check.svg";
import { ReactComponent as ArrowDownSvg } from "../../core/assets/picto/arrow-down-bloc.svg";
import { ReactComponent as ArrowUpSvg } from "../../core/assets/picto/arrow-up-bloc.svg";
import { ReactComponent as RecapSvg } from "../../core/assets/picto/recap.svg";

// style
import "./bloc.scss";

const Bloc = ({
   title,
   icon,
   children,
   note = false,
   open = false,
   setIsOpen,
   checked = false,
   info,
   className,
   isFix,
   ...props
}) => {
   const [isCheck, setIsCheck] = useState(checked);

   useEffect(() => {
      setIsCheck(checked);
   }, [checked]);

   let iconType;
   switch (icon) {
      case "pdl":
         iconType = <PdlSvg />;
         break;
      case "offre":
         iconType = <OffreSvg />;
         break;
      case "id":
         iconType = <IdSvg />;
         break;
      case "facturation":
         iconType = <FactureSvg />;
         break;
      case "paiement":
         iconType = <PaieSvg />;
         break;
      case "recap":
         iconType = <RecapSvg />;
         break;
      default:
         break;
   }
   const handleChange = () => {
      if (isFix) {
         return;
      }
      return setIsOpen(!open);
   };

   return (
      <div className={`container_bloc ${className}`} {...props}>
         <header
            onClick={handleChange}
            className={`${isCheck ? "" : "notCheck"}${open ? " isOpen" : ""}`}
         >
            <div className="header_start">
               {icon && iconType}
               <h2>{title}</h2>
               {isCheck && <CheckSvg />}
            </div>
            <div
               className="header_end"
               style={isFix ? { display: "none" } : undefined}
            >
               {info}
               {open ? <ArrowUpSvg /> : <ArrowDownSvg />}
            </div>
         </header>
         {open && (
            <main>
               {!!note && (
                  <div className="bloc_note">
                     <p className="note_title">Note interne : </p> {note}
                  </div>
               )}
               {children}
            </main>
         )}
      </div>
   );
};

export default Bloc;
