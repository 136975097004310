/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Bloc from "../Bloc/Bloc";

// Store
import { useDispatch, useSelector } from "react-redux";
import * as saleActions from "../../core/stores/saleStore";
import TextInput from "../TextInput/TextInput";
import {
   getIbanCountryCode,
   ibanIsValide,
   ibanIsValideError,
   trueOrFalseDo,
   formatErrorNames,
   autoCompleteAlea,
} from "../../utils";
import CheckboxButton from "../Checkbox/CheckboxButton";

import "./styles/payment.scss";

const PaymentBloc = ({ open, setOpen }) => {
   const { t } = useTranslation();
   const dispatch = useDispatch();

   const allParams = useSelector((state) => state.params);
   const sale = useSelector((state) => state.sale);

   const [checkPayment, setCheckPayment] = useState(false);

   const [ibanValid, setIbanValid] = useState(false);
   const [ibanCountry, setIbanCountry] = useState();

   const [holder, setHolder] = useState(false);

   //   const [debit, setDebit] = useState([]);

   //   useEffect(() => {
   //     if (allParam.dates) {
   //       setDebit(allParam.dates.debit);
   //     }
   //   }, [allParam]);

   // init du formulaire
   const {
      getValues,
      setValue,
      watch,
      formState: { errors },
      control,
      setError,
      resetField,
      clearErrors,
   } = useFormContext();

   // Add function qui check l'iban valid

   const isPaymentComplet = () => {
      if (
         ibanValid === true &&
         // getValues("desirDate") !== undefined &&
         getValues("validMandat") === true
         //  &&
         // errors.desirDate === undefined
      ) {
         dispatch(saleActions.update({ target: "paymentCheck", value: true }));
         return setCheckPayment(true);
      }
      dispatch(saleActions.update({ target: "paymentCheck", value: false }));
      return setCheckPayment(false);
   };

   useEffect(() => {
      if (sale?.validMandat) {
         setValue("validMandat", sale?.validMandat);
      }
   }, []);

   // check if iban is valid
   useEffect(() => {
      if (getValues("iban")) {
         setIbanCountry(getIbanCountryCode(getValues("iban")));
         setIbanValid(ibanIsValide(getValues("iban")));
      }
   }, [watch("iban")]);

   //  Update Store
   useEffect(() => {
      isPaymentComplet();
      const payment = {
         iban: getValues("iban"),
         ibanValid: ibanValid,
         ibanCountry: ibanCountry,
         // desirDate: getValues("desirDate"),
         mandat: getValues("validMandat"),
      };
      dispatch(saleActions.update({ target: "payment", value: payment }));
   }, [
      checkPayment,
      ibanValid,
      watch("iban"),
      //  watch("desirDate"),
      watch("validMandat"),
   ]);

   // update holder bank
   // useEffect(() => {
   //   if (sale?.familyName && sale?.givenName && !holder) {
   //     setHolder(true);
   //     setValue("givenHolderBank", `${sale?.givenName} ${sale?.familyName}`);
   //     console.log("change")
   //     dispatch(
   //       saleActions.update({
   //         target: "givenHolderBank",
   //         value: `${sale?.givenName} ${sale?.familyName}`,
   //       })
   //     );
   //   }
   // }, [sale?.givenName, sale?.familyName]);
   useEffect(() => {
      setValue("givenHolderBank", `${sale?.givenName} ${sale?.familyName}`);
      dispatch(
        saleActions.update({
          target: "givenHolderBank",
          value: `${sale?.givenName} ${sale?.familyName}`,
        })
      );
      setHolder(true);
      if (sale?.familyName && sale?.givenName && !holder) {
         setValue("givenHolderBank", sale?.givenHolderBank)
      }
   }, [sale?.givenName, sale?.familyName]);

   return (
      <Bloc
         icon="paiement"
         title={t("souscription.paiement.title")}
         setIsOpen={setOpen}
         open={open}
         checked={checkPayment}
         className="bloc_payment"
         note={
            allParams?.noteAdmin?.filter((v) => v.word === "PAYMENT")[0]?.value
         }
         // info={getValues("desirDate")}
      >
         <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
               <h3>{t("souscription.paiement.SEPAtitle")}</h3>
               <Controller
                  control={control}
                  rules={{
                     required: t("error.required"),
                     validate: ibanIsValideError,
                  }}
                  name="iban"
                  render={({ field: { value, onChange, onBlur } }) => (
                     <TextInput
                        value={value}
                        label={t("souscription.paiement.inputIbanLabel")}
                        onChange={onChange}
                        required
                        helperText={trueOrFalseDo(
                           !!errors.iban && errors.iban.type === "required",
                           () => {
                              return t("error.required");
                           },
                           () => {
                              if (errors?.iban?.type === "validate") {
                                 return t("error.payment.ibanError");
                              }
                           }
                        )}
                        error={!!errors.iban}
                        onBlur={(v) => {
                           dispatch(
                              saleActions.update({
                                 target: "iban",
                                 value: value,
                              })
                           );
                           onBlur(v);
                        }}
                        autoComplete="off"
                        reset
                        resetFunction={() => {
                           resetField("iban");
                           setValue("iban", "");
                        }}
                     />
                  )}
               />
            </Grid>

            {/* Name user bank */}
            <Grid container item spacing={3}>
               <Grid item xs={12} sm={4}>
                  <h3>{t("souscription.paiement.titleUserBank")}</h3>
                  <Controller
                     control={control}
                     rules={{
                        required: t("error.required"),
                        validate: formatErrorNames,
                     }}
                     name="givenHolderBank"
                     defaultValue=""
                     render={({ field: { value, onChange, onBlur } }) => (
                        <TextInput
                           value={value}
                           label="Prénom Nom"
                           onChange={onChange}
                           required
                           error={errors.givenHolderBank !== undefined}
                           helperText={errors.givenHolderBank?.message}
                           onBlur={(e) => {
                              dispatch(
                                 saleActions.update({
                                    target: "givenHolderBank",
                                    value: value,
                                 })
                              );
                              onBlur(e);
                           }}
                           autoComplete={autoCompleteAlea()}
                        />
                     )}
                  />
               </Grid>
            </Grid>
            <Grid item xs={12}>
               {/* <Controller
                  control={control}
                  rules={{
                     required: t("error.required"),
                  }}
                  name="desirDate"
                  render={({ field: { onChange, onBlur, value } }) => (
                     <ComboBox
                        label={t("souscription.paiement.inputDesirDate")}
                        onChange={onChange}
                        value={value}
                        list={debit.map((v, i) =>
                           i === 0
                              ? v?.text + "er du mois"
                              : v?.text + " du mois"
                        )}
                        required
                        helperText={t("error.required")}
                        error={errors.desirDate !== undefined}
                        onBlur={(v) => {
                           dispatch(
                              saleActions.update({
                                 target: "desirDate",
                                 value: value,
                              })
                           );
                           onBlur(v);
                        }}
                        autoComplete={autoCompleteAlea()}
                     />
                  )}
               /> */}
               <div className="legalText">
                  {t("souscription.paiement.legalText")}
               </div>

               <Controller
                  control={control}
                  name="validMandat"
                  defaultValue={false}
                  rules={{
                     required: t("error.required"),
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                     <CheckboxButton
                        className="gray"
                        text={t("souscription.paiement.checkboxLabelValid")}
                        onChange={(v) => {
                           onChange(v);

                           if (v === false) {
                              setError("validMandat", {
                                 type: "required",
                                 message: t("error.required"),
                              });
                           } else {
                              clearErrors("validMandat");
                              dispatch(
                                 saleActions.update({
                                    target: "validMandat",
                                    value: v,
                                 })
                              );
                           }
                        }}
                        required={true}
                        value={value}
                        checked={value}
                        error={errors.validMandat}
                        helperText={t("error.required")}
                        onBlur={(v) => {
                           dispatch(
                              saleActions.update({
                                 target: "validMandat",
                                 value: value,
                              })
                           );
                           onBlur(v);
                        }}
                     />
                  )}
               />
            </Grid>
         </Grid>
      </Bloc>
   );
};

export default PaymentBloc;
