/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import "./resumeBloc.scss";

// Store
import { useDispatch, useSelector } from "react-redux";
import * as saleStore from "../../core/stores/saleStore";
import Loader from "../Loader/Loader";

// SVG
import { ReactComponent as ArrowDownSvg } from "../../core/assets/picto/arrow-down-bloc.svg";
import { ReactComponent as ArrowUpSvg } from "../../core/assets/picto/arrow-up-bloc.svg";
import RadioButton from "../RadioButton/RadioButton";
import { Controller, useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";
import { replacePointByCommaInDecimals, trueOrFalseDo } from "../../utils";
import Button from "../Button/Button";
import { sendNotif } from "../../core/stores/utils";
import { update } from "../../core/stores/paramsStore";

const ResumeBloc = ({ title, open, children, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { watch, getValues, control } = useFormContext();

  // open resume bloc
  const [isOpen, setIsOpen] = useState(open);
  // show Resume bloc
  const [show, setShow] = useState(false);
  // show validation button
  const [checkResum, setCheckResume] = useState();

  // Store sale
  const sale = useSelector((state) => state.sale);

  // checking const
  const PDLCheck = sale?.PDLCheck;
  const offreCheck = sale?.currentOffer?.offreCheck;

  //CONSO - POWER
  const [power, setPower] = useState(0);
  const [conso, setConso] = useState(0);
  const [optionConso, setOptionConso] = useState();

  // PRIX
  const [prix, setPrix] = useState(0);
  const [targetRadio, settargetRadio] = useState(
    sale?.targetRadioMonthSelected || 2
  );

  const [isSendingQuote, setIsSendingQuote] = useState(false);

  // const [annuelPrix, setannuelPrix] = useState(0);

  const standardPrice = (sale?.monthlyAmount / 100)?.toFixed(2);
  const standarPriceHigh = (sale?.monthlyAmountHigh / 100)?.toFixed(2);
  const standarPriceLow = (sale?.monthlyAmountLow / 100)?.toFixed(2);

  const getValueMonthAfterAddOptions = (targetRadio) => {
    switch (targetRadio) {
      case 1:
        return standarPriceLow;

      case 2:
        return standardPrice;
      case 3:
        return standarPriceHigh;

      default:
        break;
    }
  };

  const isResumeComplete = () => {
    if (
      sale?.PDLCheck &&
      sale?.billingCheck &&
      sale?.identityCheck &&
      sale?.currentOffer?.offreCheck &&
      sale?.paymentCheck
    ) {
      return setCheckResume(true);
    }
    return setCheckResume(false);
  };

  const canSendQuoteToClient = () => {
    return sale?.PDLCheck &&
      sale?.billingCheck &&
      sale?.identityCheck &&
      sale?.currentOffer?.offreCheck
  };

  const sendQuoteToClient = () => {
    if (canSendQuoteToClient()) {
      setIsSendingQuote(true);
      dispatch(saleStore.sendQuoteToClient())
      .then((res) => {
        setIsSendingQuote(false);
        if(res.meta.requestStatus === 'fulfilled'){
          dispatch(update(sendNotif("sendQuote")));
          //alert("Not implemented yet")
        }
      })
    }
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const detectSize = () => {
    setScreenSize(window.innerWidth);
  };

  // useEffect(() => {
  //   setPrix((sale?.monthlyAmount / 100)?.toFixed(2));
  //   setannuelPrix((sale?.monthlyAmount * 12)?.toFixed(2)?.toString());
  // }, []);

  useEffect(() => {
    const valueUpdate = getValueMonthAfterAddOptions(targetRadio);
    setPrix(valueUpdate);

    // setannuelPrix((standardPrice * 12).toFixed(2)?.toString());
  }, [sale.consoMode, standardPrice]);

  useEffect(() => {
    const valueUpdate = getValueMonthAfterAddOptions(targetRadio);
    const valueSelected = !isNaN(valueUpdate)
      ? _.toNumber((valueUpdate / 0.01).toFixed(2))
      : sale?.monthlyAmount;

    dispatch(saleStore.update({ target: "recap", value: valueSelected }));
    dispatch(
      saleStore.update({
        target: "valueMonthSelected",
        value: valueSelected,
      })
    );
    dispatch(
      saleStore.update({
        target: "targetRadioMonthSelected",
        value: targetRadio,
      })
    );

    isResumeComplete();
  }, [watch("mensualite"), standardPrice, watch("consoMode")]);

  useEffect(() => {
    setPower(sale?.PDLInfos?.contractPowerMax?.valeur);

    trueOrFalseDo(
      sale?.PDLInfos?.estimatedCar?.carBase?.value,
      () => {
        setConso(sale?.PDLInfos?.estimatedCar?.carBase?.value);
      },
      () => {
        setConso("--");
      }
    );

    isResumeComplete();
  }, [sale]);

  // Show recap
  useEffect(() => {
    if (PDLCheck && offreCheck) {
      setShow(true);
    }
  }, [PDLCheck, offreCheck]);

  useEffect(() => {
    setOptionConso(getValues("consoMode"));
  }, [watch("consoMode"), sale]);

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [screenSize]);

  return (
    <div className={`container_resume ${show ? "show" : ""}`} {...props}>
      {screenSize < 1460 && (
        <div className="arrow-corner">
          {isOpen ? <ArrowUpSvg /> : <ArrowDownSvg />}
        </div>
      )}
      <header onClick={handleClick} className={`${open ? "isOpen" : ""}`}>
        <h3>{title}</h3>
      </header>
      <main>
        <div className="line-values">
          <Grid container direction="row" alignItems="baseline" rowSpacing={1}>
            <Grid item xs={6}>
              <h2>{conso} </h2>
              {t("recap.unitConso")}
            </Grid>
            <Grid item xs={6}>
              <h2>{power}</h2> {t("recap.unitPower")}
            </Grid>

            <Grid item xs={6}>
              {conso > 0 ? (
                <h2>
                  {!isNaN(prix) ? replacePointByCommaInDecimals(prix) : "--"}
                </h2>
              ) : (
                <h2>--</h2>
              )}

              {t("recap.unitSub")}
            </Grid>

            <Grid item xs={6}>
              {conso > 0 ? (
                <h2>
                  {!isNaN(prix)
                    ? replacePointByCommaInDecimals(
                      (_.toNumber(prix) * 12).toFixed(2)
                    )
                    : "--"}
                </h2>
              ) : (
                <h2>--</h2>
              )}

              {t("recap.unitPriceAnnuel")}
            </Grid>
          </Grid>
        </div>
        {(isOpen || screenSize > 1460) && (
          <>
            {optionConso !== "REAL" && (
              <>
                <h3>{t("recap.titleMensualites")}</h3>
                <div className="line-mensualites">
                  <Controller
                    control={control}
                    rules={{
                      required: t("error.required"),
                    }}
                    name="mensualite"
                    defaultValue={targetRadio}
                    render={({ field: { onChange } }) => (
                      <RadioButton
                        name="mensualite"
                        item1={standarPriceLow}
                        item2={standardPrice}
                        item3={standarPriceHigh}
                        unit="€ TTC"
                        onChange={(e, target) => {
                          setPrix(e);
                          settargetRadio(target);
                          return onChange(e);
                        }}
                        check={targetRadio}
                        pointByCommaInDecimals={true}
                      />
                    )}
                  />
                </div>
              </>
            )}
            {checkResum && (
              <div className="line-valide">{checkResum && children}</div>
            )}
            {canSendQuoteToClient() && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                { !isSendingQuote &&
                (<Button
                  className="green"
                  icon="sale"
                  onClick={sendQuoteToClient}
                >
                  {t("recap.sendQuote")}
                </Button>)
                }
                { isSendingQuote &&
                (<Loader></Loader>)
                }
              </div>
            )}
          </>
        )}
      </main>
    </div>
  );
};
export default ResumeBloc;
