import React from "react";

const Cross = ({ fill }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66885 11.1188L0.881348 10.3313L5.2126 6.0001L0.881348 1.66885L1.66885 0.881348L6.0001 5.2126L10.3313 0.881348L11.1188 1.66885L6.7876 6.0001L11.1188 10.3313L10.3313 11.1188L6.0001 6.7876L1.66885 11.1188Z"
        fill={fill}
      />
    </svg>
  );
};

export default Cross;
