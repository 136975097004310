/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import Button from "../../../Button/Button";

import "./menu.scss";

// Liste svg
import { ReactComponent as ArrowMenuSvg } from "../../../../core/assets/picto/arrowMenu.svg";
import { ReactComponent as HomeSvg } from "../../../../core/assets/picto/home.svg";
import { ReactComponent as VenteSvg } from "../../../../core/assets/picto/vente.svg";
import { ReactComponent as ProspectSvg } from "../../../../core/assets/picto/address-book.svg";
import { ReactComponent as UserSvg } from "../../../../core/assets/picto/user.svg";
import { ReactComponent as SocieteSvg } from "../../../../core/assets/picto/societe.svg";
import { ReactComponent as SettingsSvg } from "../../../../core/assets/picto/settings.svg";

import { useHistory } from "react-router-dom";
import { switchMenuUser } from "../../../../utils";
import PermissionWrapper from "../../../HOC/PermissionWrapper";
import { useSelector } from "react-redux";

const MenuBoco = ({ collectOpen, user }) => {
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = useState();
  const [open, setOpen] = useState(true);
  const [showCTA, setShowCTA] = useState(true);
  const [opacityTransition, setOpacityTransition] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const params = useSelector((state) => state.params);
  const [listMenuTypeUser, setListMenuTypeUser] = useState([]);

  const userBroker = user?.broker;

  useEffect(() => {
    setListMenuTypeUser(switchMenuUser(userBroker?.type));
  }, [userBroker]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleMenuItemClick = (index, link) => {
    setSelectedIndex(index);
    history.push(link);
  };

  const handleClickTCO = () => {
    history.push("/subscription");
  };

  function choseIcon(option) {
    switch (option.icon) {
      case "Home":
        return <HomeSvg />;
      case "Vente":
        return <VenteSvg />;
      case "Prospect":
        return <ProspectSvg />;
      case "User":
        return <UserSvg />;
      case "Societe":
        return <SocieteSvg />;
      case "Settings":
        return <SettingsSvg />;
      default:
        return null;
    }
  }

  const detectSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    const currentIndex = listMenuTypeUser?.findIndex(
      (l) => l.link === history?.location?.pathname
    );
    setSelectedIndex(currentIndex);
  }, [listMenuTypeUser]);

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [screenSize]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setShowCTA(true);
      }, 500);
      setOpacityTransition(false);
    } else {
      setTimeout(() => {
        setShowCTA(false);
      }, 750);
      setTimeout(() => {
        setOpacityTransition(true);
      }, 800);
    }
    collectOpen(open);
  }, [open]);

  return (
    screenSize > 1024 && (
      <div className={`menu_desk ${open ? "open" : "closed"}`}>
        <div className="wrapper_btn">
          <Button className="arrowMenu" onClick={handleClick}>
            <ArrowMenuSvg />
          </Button>
        </div>
        <ul className="nav_desk">
          {listMenuTypeUser?.map((option, index) => {
            const classSelected = index === selectedIndex && "selected ";
            if (!!option.isPermissionRequired) {
              return (
                <PermissionWrapper
                  requiredPermission={option.requiredPermission}
                  key={option.label}
                >
                  <li
                    key={option.label}
                    className={`${classSelected}`}
                    onClick={() => handleMenuItemClick(index, option.link)}
                    title={option.label}
                  >
                    <span className="icon">{choseIcon(option)}</span>
                    <p className="custom_nav_label">{option.label}</p>
                  </li>
                </PermissionWrapper>
              );
            } else {
              return (
                <li
                  key={option.label}
                  className={`${classSelected}`}
                  onClick={() => handleMenuItemClick(index, option.link)}
                  title={option.label}
                >
                  <span className="icon">{choseIcon(option)}</span>
                  <p className="custom_nav_label">{option.label}</p>
                </li>
              );
            }
          })}
        </ul>
        {(user?.society?.statut === "ACTIF" || userBroker?.type === 'ADMIN_PRIMEO') && params.maintenanceStatus != 'MAINTENANCE' && (
          <div className="TCO">
            <h4>Tunnel de souscription</h4>
            <p>Vous êtes en rendez-vous ?</p>

            {open
              ? showCTA && (
                <Button className="blue" onClick={handleClickTCO}>
                  <VenteSvg />
                  <p>Commencer une vente</p>
                </Button>
              )
              : !showCTA && (
                <Button
                  className={
                    opacityTransition ? "blue closed opacity" : "blue closed"
                  }
                  onClick={handleClickTCO}
                  title="Commencer une vente"
                >
                  <VenteSvg />
                </Button>
              )}
          </div>
        )}
      </div>
    )
  );
};

export default MenuBoco;
