import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../core/assets/picto/logoPrimeo.svg";
import MenuBocoResp from "./Menu/MenuBocoResp";
import MenuBoco from "./Menu/MenuBoco";
import MenuRight from "./Menu/MenuRight";
import "./headerBoco.scss";
import { useSelector } from "react-redux";

const HeaderBOCO = ({ collectOpen }) => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const currentUser = useSelector((state) => state.auth);

  const detectSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [screenSize]);

  return (
    <header className="header-boco">
      <div className="wrapper">
        <div className="container">
          <div className="left">
            {screenSize <= 1024 ? (
              <MenuBocoResp user={currentUser} />
            ) : (
              <MenuBoco collectOpen={collectOpen} user={currentUser} />
            )}

            <img src={Logo} alt="Logo Primeo" className="logoPrimeo" />

            <Link to="/Auth">Espace courtier</Link>
          </div>
          <MenuRight />
        </div>
      </div>
      <div className="container_bg" />
    </header>
  );
};

export default HeaderBOCO;
