/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import CheckboxButton from "../../Checkbox/CheckboxButton";

import { ReactComponent as ArrowDownSvg } from "../../../core/assets/picto/arrow-down-bloc.svg";
import { ReactComponent as ArrowUpSvg } from "../../../core/assets/picto/arrow-up-bloc.svg";

import { useTranslation } from "react-i18next";
import {
  regionsData,
  departementsData,
} from "../../../utils/departements-region";
import _ from "lodash";

import "./geoExclu.scss";
import { getSocieteDetails } from "../../../core/services/httpRequest/axios";

const GeoExclu = ({
  label,
  value = [],
  onChange,
  error,
  helperText,
  required,
  synch = [],
  idSocieteCourtage,
  ...props
}) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState([]);
  const [countDepartements, setCountDepartements] = useState(0);
  const [countRegions, setCountRegions] = useState(0);

  const [dataGeo, setdataGeo] = useState([]);

  const [choseData, setChoseData] = useState([]);
  const [choseDataDepartements, setChoseDataDepartements] = useState();
  const [allDep, setAllDep] = useState([]);

  useEffect(() => {
    let data = regionsData.map((r) => {
      let tmplist = departementsData.filter(
        (d) => d.codeRegion === r.codeRegion
      );
      return {
        ...r,
        departements: tmplist,
        count: tmplist.length,
      };
    });
    setdataGeo(data);
    setChoseData(data);
    if (!!value) {
      let res = value.map((v) => {
        return departementsData.find((d) => d.codeDpt === v);
      });

      setSelected(res);
    }

    setChoseDataDepartements(departementsData.length);
    setAllDep([...departementsData]);
  }, []);

  // Change value with societe exclu geo list
  useEffect(() => {
    // si sync est sup 0 alors on vient chercher les dpts, selected c'est les data du user courant
    //  mais il faut juste charger une liste des dpts/regions dispo

    if (synch.length > 0) {
      let currentUserDpts = synch.map((v) => {
        return departementsData.find((d) => d.codeDpt === v);
      });

      setSelected(currentUserDpts); // ce qui est selectionné dans la liste OK

      getSocieteDetails(idSocieteCourtage).then((res) => {
        let data = JSON.parse(res?.data?.societe?.exclusiviteGeo);

        let currentSocieteDpts = data.map((v) => {
          return departementsData.find((d) => d.codeDpt === v);
        });

        let currentSocieteExclusivite = dataGeo?.filter((v) => {
          return currentSocieteDpts.find((d) => d.codeRegion === v.codeRegion);
        });

        setChoseData(currentSocieteExclusivite);
        setChoseDataDepartements(currentSocieteExclusivite.length);
        setAllDep(currentSocieteExclusivite.flatMap((d) => d.departements));
      });

      // là c'est pas bon il me faut pas les data filtrées par user mais par societe
      // let currentSocieteExclusivite = dataGeo?.filter((v) => {
      //    return currentUserDpts.find((d) => d.codeRegion === v.codeRegion);
      // });

      // ici en dessous c'est là que je viens set la liste de ce qui est dispo donc dataSociete doit etre les data de la societe

      // setChoseData(currentSocieteExclusivite);
      // setChoseDataDepartements(currentSocieteExclusivite.length);
      // setAllDep(currentSocieteExclusivite.flatMap((d) => d.departements));
    }
  }, [synch, dataGeo]);

  // useEffect(() => {
  //   return () => {
  //     if (value.length === 0 && value !== selected) setSelected([]);
  //   };
  // }, [onChange]);

  const MenuProps = {
    PaperProps: {
      style: {
        width: 320,
      },
    },

    variant: "menu",
  };

  // Handle All
  function handlePickAll(checkboxIsCheck) {
    const count = allDep.length;

    if (checkboxIsCheck) {
      setCountRegions(count);
      return setSelected(allDep);
    }

    setCountRegions(0);
    return setSelected([]);
  }

  function isAllSelected() {
    return selected.length === choseDataDepartements ? true : false;
  }

  //   handle Region
  function handlePickRegion(checkboxIsCheck, r) {
    // eslint-disable-next-line array-callback-return
    let res = [...selected];
    if (checkboxIsCheck) {
      return setSelected([
        ...res.filter((d) => d.codeRegion !== r.codeRegion),
        ...r.departements,
      ]);
    } else {
      return setSelected(res.filter((d) => d.codeRegion !== r.codeRegion));
    }
  }

  function isRegionChecked(selected, r) {
    let actualLength = selected.filter(
      (d) => d.codeRegion === r.codeRegion
    ).length;
    return actualLength < r.count * 1 ? false : true;
  }

  // handle departement
  function handlePickDepartement(checkboxIsCheck, d, r) {
    // eslint-disable-next-line array-callback-return
    let res = [...selected];
    if (checkboxIsCheck) {
      return setSelected([...selected, d]);
    } else {
      return setSelected(res.filter((sd) => sd.codeDpt !== d.codeDpt));
    }
  }

  function isDepartementChecked(selected, d) {
    return _.includes(selected, d);
  }

  //   update onChange
  useEffect(() => {
    var resetCountDpt = 0;
    var resetCountReg = 0;

    onChange(selected.map((d) => d.codeDpt));

    const catchList = (r) =>
      r.departements.every((d) => _.includes(selected, d));
    const filterList = (r) =>
      selected.filter((sd) => sd.codeRegion === r.codeRegion);

    dataGeo.forEach((r) => {
      if (catchList(r)) {
        resetCountReg += 1;
      }
      if (catchList(r) === false && filterList(r).length > 0) {
        resetCountDpt += filterList(r).length;
      }
    });
    setCountDepartements(resetCountDpt);
    setCountRegions(resetCountReg);
    // }
  }, [selected]);

  //   Open sub Menu
  const [open, setOpen] = useState([]);

  const handleClickOpen = (openRegion) => {
    if (open.indexOf(openRegion) === -1) {
      return setOpen([...open, openRegion]);
    } else {
      return setOpen(open.filter((item) => item !== openRegion));
    }
  };

  useEffect(() => {
    if (!!value === false) {
      setSelected([]);
    }
  }, [value]);

  // return select values
  const renderSelected = () => {
    return (
      <div className="wrapper_tag">
        {countRegions > 0 && (
          <div className="tagSelectRegion">
            <p>
              {countRegions}
              {countRegions > 0
                ? " régions sélectionnées"
                : " région sélectionnée"}
            </p>
          </div>
        )}
        {countDepartements > 0 && (
          <div className="tagSelectDepartement">
            <p>
              {countDepartements}
              {countDepartements > 0
                ? " départements sélectionnés"
                : " département sélectionné"}
            </p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container_combobox_multi">
      <FormControl sx={{ m: 1, minWidth: 280 }} size="small">
        <InputLabel id="select-small" error={error} required={required}>
          {label}
        </InputLabel>
        <Select
          required={required}
          multiple
          labelId="select-small"
          label={label}
          value={selected || value || []}
          defaultValue={selected}
          autoWidth
          placeholder={label}
          error={error}
          renderValue={renderSelected}
          MenuProps={MenuProps}
          {...props}
        >
          <MenuItem
            value="all"
            key="all"
            classes={{
              root: isAllSelected ? "all menuAll" : "menuAll",
            }}
          >
            <CheckboxButton
              defaultValue={false}
              className="gray"
              onChange={(checked) => {
                handlePickAll(checked);
              }}
              required={false}
              value={selected.length === allDep.length ? true : false}
              checked={selected.length === allDep.length ? true : false}
            />
            <span>
              {selected.length === allDep.length
                ? t("geoComp.notAll")
                : t("geoComp.all")}
            </span>
          </MenuItem>
          {choseData?.flatMap((r) => (
            <div key={r.codeRegion}>
              <MenuItem
                key={r.codeRegion}
                value={r.codeRegion}
                className={`region`}
              >
                <CheckboxButton
                  className="gray"
                  text={r.nomRegion}
                  onChange={(checked) => {
                    handlePickRegion(checked, r);
                  }}
                  required={false}
                  value={isRegionChecked(selected, r)}
                  checked={isRegionChecked(selected, r)}
                  indeterminate={
                    selected.filter((d) => d.codeRegion === r.codeRegion)
                      .length > 0 && isRegionChecked(selected, r) === false
                  }
                />
                <span
                  className="arrowSvg"
                  onClick={() => {
                    handleClickOpen(r.codeRegion);
                  }}
                >
                  {open.indexOf(r.codeRegion) > -1 ? (
                    <ArrowUpSvg />
                  ) : (
                    <ArrowDownSvg />
                  )}
                </span>
              </MenuItem>
              <div
                className={`wrapper_departement ${
                  open.indexOf(r.codeRegion) > -1 ? "open" : "hide"
                }`}
              >
                {r.departements.map((d) => (
                  <MenuItem
                    key={d.codeDpt}
                    value={d.codeDpt}
                    className="departement"
                  >
                    <CheckboxButton
                      className="gray"
                      text={d.nomDpt}
                      onChange={(checked) => {
                        handlePickDepartement(checked, d, r);
                      }}
                      value={isDepartementChecked(selected, d)}
                      checked={isDepartementChecked(selected, d)}
                    />
                  </MenuItem>
                ))}
              </div>
            </div>
          ))}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default GeoExclu;
