import axios from "axios";
import moment from "moment";

export const customRequest = axios.create({
  baseURL: document.ENVIRONNEMENTS.API_BASE_PATH,
});

customRequest.interceptors.request.use((config) => {
  const accessToken = AuthVerify();

  //checking if accessToken exists
  if (accessToken) {
    config.headers["Authorization"] = "Bearer " + accessToken;
  }
  return config;
});

customRequest.interceptors.response.use(
  (response) => response,
  (error) => {
    //extracting response and config objects
    const response = error;

    //checking if error is Aunothorized error
    if (response.status === 401) {
      //clear local storage and log user out
      logout();
      return error;
    }

    let SGT;

    error?.response?.data?.message?.split('"').map((text) => {
      if (text.split("", 3).join("") === "SGT") {
        return (SGT = text);
      }
    });

    if (SGT !== undefined && error.response.status === 500) {
      return {
        ...error.response,
        data: { ...error.response.data, errorCodeList: SGT },
      };
    }

    // return status code as object (status code schema same as resolved for responsesHandler)
    //  return { status: error.response.status };
    return error.response;
  }
);

export const logout = () => {
  //handle logout
  localStorage.removeItem("accessToken");
  // Delete persist root
  localStorage.removeItem("persist:root");

  // window.location.reload(true);
};

export const logoutRedirec = () => {
  window.location.reload(true);
};

const parseJwt = (token) => {
  return JSON.parse(atob(token.split(".")[1]));
};

export const AuthVerify = () => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    if (token === "undefined") {
      logout();
    }
    const decodedJwt = parseJwt(token);

    if (decodedJwt?.exp * 1000 < Date.now()) {
      logout();
    } else {
      return token;
    }
  }
};

function queryBuilder(params) {
  let res = ["?"];
  let and = "&";
  let index = 0;

  function addAnd(index) {
    if (index > 0) {
      return res.push(and);
    }
  }

  for (const [key, value] of Object.entries(params)) {
    let param;
    if (key === "month") {
      param = `${key}=${value + 1}`;
      addAnd(index);
      res.push(param);
    } else if (
      key === "departements" &&
      value !== undefined &&
      value.length > 0 &&
      !!value
    ) {
      param = `${key}=${value}`;
      addAnd(index);

      res.push(param);
    } else if (key !== "departements" && key !== "month" && !!value) {
      param = `${key}=${value}`;
      addAnd(index);

      res.push(param);
    }

    index++;
  }
  return res.join("");
}

export const handleLogin = (email, password) => {
  return customRequest
    .post("/login", { email: email, password: password })
    .then((response) => {
      // Delete persist root
      localStorage.removeItem("persist:root");
      const token = response.data.token;
      localStorage.setItem("accessToken", token);
      return response;
    })
    .catch((e) => {
      return e;
    });
};

export function postDeliveryPoint(input) {
  return customRequest.post("/deliveryPoint", {
    pdlId: input.pdlId,
    authorized: true,
    newCustomerContractConcluedOnSite: true,
    offer: "ELECTRICITE_RES_PART_FIXE_TRV_BASE",
    origine : input.origine,
    nom : input.nom,
    prenom : input.prenom,
    dateNaissance : input.dateNaissance,
    email : input.email,
    numeroTel : input.numeroTel,
    serviceDemande : "consulterPoint consulterMesure",
    authCollectDonnes : input.authCollectDonnes,
    dateDemande : moment().format("YYYY-MM-DDTHH:mm:ssZ"),
  });
}

export function getCatalog(consumption) {
  return customRequest.get(`/catalog/${consumption}`);
}

export function createUser(newUser) {
  return customRequest.post(`/user`, newUser);
}

export function putUser(user) {
  return customRequest.put(`/user`, user);
}

export function getUsers(idSocieteCourtage, statut, typeVendeur) {
  let req = `/users`;
  return customRequest.get(req, {
    params: {
      idSocieteCourtage: idSocieteCourtage,
      statut: statut,
      typeVendeur: typeVendeur,
    },
  });
}

export function getUserDetails(idUtilisateur) {
  return customRequest.get(`/user/${idUtilisateur}`);
}

export function putStatuts(args) {
  return customRequest.put(`/users/active`, args);
}

// Add societe
export function createSociete(newSociete) {
  return customRequest.post(`/societe`, newSociete);
}

// Get one societe details
export function getSocieteDetails(idSociete) {
  return customRequest.get(`/societe/${idSociete}`);
}

// Get list societe
export function getListSociete() {
  return customRequest.get(`/societes`);
  // let response = await ;
  // return {
  //    ...response,
  //    data: response.data?.filter((d) => !!d.nomSociete && !!d.statut),
  // };
}

// Modify societe
export function putSociete(societe) {
  return customRequest.put(`/societe`, societe);
}

export function sendMailPassWord(email) {
  email = encodeURIComponent(email)
  return customRequest.get(`/user/mailPassword?email=${email}`);
}

export function updatePassword(token, newPassword) {
  return customRequest.post(`/user/password/${token}`, {
    newPassword: newPassword,
  });
}

// Liste vente

function queryBuilderExportVentes(params) {
  let res = ["?"];
  let and = "&";
  let index = 0;

  function addAnd(index) {
    if (index > 0) {
      return res.push(and);
    }
  }

  for (const [key, value] of Object.entries(params)) {
    var param;
    if (moment(value, "DD-MM-YYYY") !== "Invalid date") {
      param = `${key}=${value}`;
      addAnd(index);
      res.push(param);
    }
    index++;
  }
  return res.join("");
}

function queryBuilderVentes(params) {
  let res = ["?"];
  let and = "&";
  let index = 0;

  function addAnd(index) {
    if (index > 0) {
      return res.push(and);
    }
  }

  for (const [key, value] of Object.entries(params)) {
    var param;
    if (!!value || key === "page") {
      if (key === "departements" && value !== undefined && value.length > 0) {
        param = `${key}=${value}`;
        addAnd(index);
        res.push(param);
      } else if (key === "startDate" || key === "endDate") {
        param = `${key}=${moment(value).format('YYYY-MM-DD')}`;
        addAnd(index);
        res.push(param);
      } else if (key === "page") {
        param = value === undefined ? `${key}=0` : `${key}=${value}`;
        addAnd(index);
        res.push(param);
      } else if (
        key !== "departements" &&
        key !== "page" &&
        key !== "startDate" &&
        key !== "endDate"
      ) {
        param = `${key}=${value}`;
        addAnd(index);
        res.push(param);
      }
    }
    index++;
  }
  return encodeURI(res.join(""));
}

export function getListVente(page, pageSize, filters) {
  return customRequest.get(
    `/ventes${queryBuilderVentes({ ...filters, page: page, size: pageSize })}`
    // , {
    //    params: { page: page, size: pageSize, ...filters },
    // }
  );
}

export async function putVente(data) {
  return await customRequest.put("/vente", data);
}

export function getVente(id) {
  return customRequest.get(`/vente/${id}`);
}

export function updateIdMetierVente(id, idMetier){
  return customRequest.put(`/vente/${id}/updateIdMetier`, idMetier);
}
// Annulation vente
export function putAnnulationVente(
  idVente,
  motifAnnulation,
  MontantDecommissionement
) {
  return customRequest.put(
    `/vente/${idVente}/annuler?motifAnnulation=${motifAnnulation}&MontantDecommissionnement=${MontantDecommissionement}`
  );
}

export function putConfirmerVente(idVente) {
  return customRequest.put(`/vente/${idVente}/confirmer`);
}

/**
 * @name createVente Créé une vente coté backend primeo
 * @param {Object} data objet vente
 */
export function createVente(data) {
  return customRequest.post("/vente", data);
}

/**
 * @name createOrder Créé une vente dans la sandbox tripica
 * @param {Object} data objet order
 */
export function createOrder(data) {
  return customRequest.post("/order", data);
}

export async function getVentesDashboardAnnually(filters) {
  return await customRequest.get(
    `/ventes/dashboard/annually${queryBuilder(filters)}`
  );
}

export async function getVentesDashboardMonthly(filters) {
  return await customRequest.get(
    `/ventes/dashboard/monthly${queryBuilder(filters)}`
  );
}

export async function getVentesDashboardWeekly(filters) {
  return await customRequest.get(
    `/ventes/dashboard/weekly${queryBuilder(filters)}`
  );
}

export function getOffres() {
  return customRequest.get("/ventes/offres");
}

export function getOptions() {
  return customRequest.get("/catalog/options");
}

export function getExportWeekly(filters) {
  return customRequest.get(
    `/ventes/dashboard/weekly/export${queryBuilder(filters)}`
  );
}
export function getExportMonthly(filters) {
  return customRequest.get(
    `/ventes/dashboard/monthly/export${queryBuilder(filters)}`
  );
}
export function getExportAnnually(filters) {
  return customRequest.get(
    `/ventes/dashboard/annually/export${queryBuilder(filters)}`
  );
}

export function getExportVentes(start, end) {
  if (
    moment(start, "DD-MM-YYYY") !== "Invalid date" &&
    moment(end, "DD-MM-YYYY") !== "Invalid date"
  ) {
    return customRequest.get(
      `/ventes/export${queryBuilderExportVentes({
        dateDebut: start,
        dateFin: end,
      })}`
    );
  } else {
    return () => Promise.resolve([]);
  }
}

export function getParametrage(idSociete) {
  return customRequest.get(`/societe/${idSociete}/wording`);
}

export function postParametrage(idSociete, data) {
  return customRequest.put(`/societe/${idSociete}/wording`, { ...data });
}

export function postParamsOfSociete(idSociete, data) {
  return customRequest.put(`/societe/${idSociete}/param`, { ...data });
}

export function getWordingSociete(idSociete) {
  return customRequest.get(`/societe/${idSociete}/parametrage`);
}

export function putWordingSociete(idSociete, data) {
  return customRequest.put(`/societe/${idSociete}/parametrage`, { ...data });
}

export function putPermissions(idSociete, data) {
  return customRequest.put(`societe/${idSociete}/permissions`, { ...data });
}

export function postMonthlyPayment(data) {
  return customRequest.post(`/monthly-payment`, {
    subscriptionPlanName: data.subscriptionPlanName,
    pricingOptionName: data.pricingOptionName,
    servicesNames: data.servicesNames,
    deliveryPoint: data.deliveryPoint,
  });
}
