import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";

const PermissionWrapper = ({ requiredPermission, children, frag }) => {
  const userType = useSelector((state) => state.auth.broker?.type);
  const [permissions, setPermissions] = useState([]);

  const includesPermissions = (currentValue) =>
    !permissions?.includes(currentValue);

  function parseJwt() {
    const token = localStorage.getItem("accessToken");
    var base64Url = token?.split(".")[1];
    var base64 = base64Url?.replace(/-/g, "+")?.replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        ?.atob(base64)
        ?.split("")
        ?.map(function (c) {
          return "%" + ("00" + c?.charCodeAt(0)?.toString(16))?.slice(-2);
        })
        ?.join("")
    );

    return JSON?.parse(jsonPayload)?.primeo_permissions;
  }

  useEffect(() => {
    setPermissions(parseJwt());
  }, [children]);

  if (
    requiredPermission?.every(includesPermissions) &&
    !["ADMIN_PRIMEO", "ADMIN_COURTAGE", "CHARGE_CLIENT"]?.includes(userType)
  ) {
    return !frag ? <Fragment /> : frag;
  } else {
    return <>{children}</>;
  }
};

export default PermissionWrapper;
