import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import DatePicker from "../../../components/DatePicker/datePickerV1";
import Loader from "../../../components/Loader/Loader";
import ComboBox from "../../../components/ComboBox/ComboBox";
import { ReactComponent as Confirmation } from "../../../core/assets/picto/confirmation.svg";
import { ReactComponent as Check } from "../../../core/assets/picto/check.svg";
import { ReactComponent as Arrow } from "../../../core/assets/picto/arrow.svg";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  setMinMaxDate,
  autoCompleteAlea,
  checkEnterKeyDown,
  dateIsBetweenNowAnd,
  regularMobilePhoneToInternational,
} from "../../../utils";
import * as saleActions from "../../../core/stores/saleStore";
import moment from "moment";
import "moment/min/moment-with-locales";
import "./styles/validation.scss";
import { putVente } from "../../../core/services/httpRequest/axios";
import handleRequestResponse from "../../../utils/responsesHandler";
moment.locale("fr");

const Validation = () => {
  const [status, setStatus] = useState(false);
  const [slots, setSlots] = useState([]);
  const [loading, setLoading] = useState(false);

  const sale = useSelector((state) => state.sale);
  const params = useSelector((state) => state.params);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    getValues,
    setValue,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      rdvDate: sale?.rdvDate ? JSON.parse(sale?.rdvDate) : new Date(),
      timeSlot: sale?.timeSlot || undefined,
    },
  });

  const DEFAULTSLOTS = [
    {
      key: 1,
      value: t("validation.slot1"),
      disabledAt: moment().transform("12:00:00.000"),
    },
    {
      key: 2,
      value: t("validation.slot2"),
      disabledAt: moment().transform("14:00:00.000"),
    },
    {
      key: 3,
      value: t("validation.slot3"),
      disabledAt: moment().transform("18:00:00.000"),
    },
    {
      key: 4,
      value: t("validation.slot4"),
      disabledAt: moment().add(1, "day"),
    },
  ];

  function submit(data) {
    let query = {
      idVente: sale?.acceptedSale.idVente,
      prenomClient: sale?.acceptedSale.prenomClient,
      nomClient: sale?.acceptedSale.nomClient,
      periodeRendezVous: data.timeSlot,
      emailClient: sale.customerEmail,
      jourRendezVous: data.rdvDate,
      telephone: regularMobilePhoneToInternational(
        "+33",
        "0",
        sale.customerMobile
      ),
      adresseFacturation: {

        street1: sale?.billingAddress.housenumberAndStreet,
        hamlet: sale?.billingAddress.hamlet,
        supplement1: sale?.billingAddress.additionnalAddress1,
        supplement2: sale?.billingAddress.additionnalAddress2,
        city: sale.billingAddress.city,
        country: "FR",
        postCode: sale.billingAddress.zipCode,
      },
    };

    setLoading(true);
    putVente(query).then((res) => {
      handleRequestResponse(
        res,
        () => {
          dispatch(
            saleActions.update({ target: "validationRDV", value: status })
          );
          setTimeout(() => {
            setLoading(false);
            setStatus(true);
          }, 1000);
        },
        () => {
          setTimeout(() => {
            setLoading(false);
            setStatus(false);
          }, 1000);
        }
      );
    });
  }

  function handleClick() {
    dispatch(saleActions.empty());
    history.push("/home");
  }

  useEffect(() => {
    setSlots(DEFAULTSLOTS);
    setStatus(!!sale?.validationRDV);
  }, []);

  useEffect(() => {
    let date = getValues("rdvDate");
    if (
      moment(date).transform("00:00:00.000").format("llll") ===
      moment().transform("00:00:00.000").format("llll")
    ) {
      let tmp = DEFAULTSLOTS;
      setSlots(tmp.filter((slot) => slot.disabledAt > moment()));
    } else {
      setSlots(DEFAULTSLOTS);
    }
  }, [watch("rdvDate")]);

  useEffect(() => {
    let slot = getValues("timeSlot");
    if (!slots?.map((s) => s.value).includes(slot) && !!slot) {
      setValue("timeSlot", undefined);
    }

    dispatch(
      saleActions.update({
        target: "timeSlot",
        value: slots[0]?.value,
      })
    );
    setValue("timeSlot", slots[0]?.value);
  }, [slots]);

  useEffect(() => {
    if (sale?.timeSlot) {
      setValue("timeSlot", sale?.timeSlot);
    }
  }, []);

  return (
    <div className="page_content_validation">
      <div className="container">
        <Header />
        <div className="main_container">
          <div className="validation_bloc">
            <div className="text_container">
              <h1 className="sm_margin_top">{t("validation.title")}</h1>
              <p>{t("validation.text1")}</p>
              <div className="margin_container">
                <h3 className="sm_margin_bot">{t("validation.subtitle")}</h3>
                <p className="sm_margin_top">{t("validation.text2")}</p>
              </div>
            </div>
            <form
              onSubmit={handleSubmit(submit)}
              onKeyDown={(e) => checkEnterKeyDown(e)}
              noValidate
              autoComplete="off"
              name="validation_form"
            >
              {!loading && !status && (
                <div className="dynamic_container">
                  <Controller
                    control={control}
                    rules={{
                      required: t("error.required"),
                    }}
                    name="rdvDate"
                    render={({ field: { value, onChange, onBlur } }) => (
                      <DatePicker
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          if (dateIsBetweenNowAnd(e, 31, "days")) {
                            clearErrors("rdvDate");
                            dispatch(
                              saleActions.update({
                                target: "rdvDate",
                                value: JSON.stringify(e),
                              })
                            );
                          }
                        }}
                        onBlur={(e) => {
                          onBlur(e);
                          if (dateIsBetweenNowAnd(value, 31, "days")) {
                            clearErrors("rdvDate");
                            dispatch(
                              saleActions.update({
                                target: "rdvDate",
                                value: JSON.stringify(e),
                              })
                            );
                          }
                          if (!dateIsBetweenNowAnd(value, 31, "days")) {
                            setError("rdvDate", {
                              type: "custom",
                              message: t("error.invalidDate"),
                            });
                          }
                        }}
                        label={t("validation.selectDate")}
                        limitation={{
                          min: setMinMaxDate("now"),
                          max: setMinMaxDate(31, "days"),
                        }}
                        error={errors?.rdvDate}
                        required
                        fullWidth
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    rules={{
                      required: t("error.required"),
                    }}
                    name="timeSlot"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <ComboBox
                        label={t("validation.selectTimeSlot")}
                        onChange={onChange}
                        value={value ? value : slots[0]?.value}
                        list={slots?.map((slot) => slot.value)}
                        required
                        helperText={t("error.required")}
                        error={errors?.timeSlot !== undefined}
                        onBlur={(e) => {
                          dispatch(
                            saleActions.update({
                              target: "timeSlot",
                              value: value,
                            })
                          );
                          onBlur(e);
                        }}
                        autoComplete={autoCompleteAlea()}
                      />
                    )}
                  />
                </div>
              )}

              {!loading && status && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Confirmation />
                  <h3 className="blue">{t("validation.confirmed")}</h3>
                </div>
              )}

              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader />
                </div>
              )}

              {!loading && !status && (
                <button className="green btn_margin" type="submit">
                  {t("validation.confirm")}
                  <Check className="margin_left" />
                </button>
              )}

              {!loading && status && (
                <button
                  className="green btn_margin"
                  type="button"
                  onClick={() => handleClick()}
                >
                  {t("validation.back")}
                  <Arrow className="margin_left" />
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Validation;
