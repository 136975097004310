import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { daysToMonths } from "../../../utils";

const Remise = ({ offers, selectedOffer }) => {
  const { t } = useTranslation();

  const offerSelected = offers?.filter((v) => v.name === selectedOffer)[0]
    ?.offerDetail;

  const allDiscount = offerSelected?.filter((v) =>
    _.includes(v.name, "DISCOUNT")
  );

  const discountVariable = allDiscount?.filter((v) =>
    _.includes(v.name, "VARIABLE")
  );
  const discountFixe = allDiscount?.filter((v) => _.includes(v.name, "FIXE"));

  function formatDiscountMessage(value, type) {
    let message = "";
    const isFixed = type === "fixe";
    const productType = isFixed ? "l'abonnement" : "la consommation";
    // const prefix = isFixed ? "" : "";
    value = value.sort((v1,v2) => {
      const characteristics1 = v1.productSpecification.characteristics;
      const [start1] = getDuration(characteristics1);
      const characteristics2 = v2.productSpecification.characteristics;
      const [start2] = getDuration(characteristics2);
      return start1-start2
    })


    for (let i = 0; i < value.length; i++) {
      let discount = value[i];

      const characteristics = discount.productSpecification.characteristics;

      const productsOfferingPrices = discount.productsOfferingPrices[0];

      const [start, end] = getDuration(characteristics);

      const startM1 = value.length === i + 1 ? start + 1 : start;

      const startText = start === 1 ? startM1 + "er" : startM1 + "e";

      if (productsOfferingPrices?.price.percentage < 0 ) {
        if (start && end) {
          message += `${productsOfferingPrices?.price.percentage}% de remise sur ${productType} du ${startText} mois au ${end}e mois. \n`;
        }

        if (start && !!end === false) {
          message += `${productsOfferingPrices?.price.percentage}% de remise sur ${productType} à partir du ${startText} mois. `;
        }
        if (characteristics.length <= 2) {
          message += `${productsOfferingPrices?.price.percentage}% de remise sur ${productType}.`;
        }
      }
    }

    return message;
  }

  function getDuration(characteristics) {
    if (characteristics[3] && characteristics[4]) {
      let start =
        daysToMonths(characteristics[3].characteristicValues[0].value) + 1;
      let end = daysToMonths(characteristics[4].characteristicValues[0].value);

      return [start, end];
    }

    if (characteristics[3] && !!characteristics[4] === false) {
      let start = daysToMonths(
        characteristics[3].characteristicValues[0].value
      );

      return [start, null];
    }
    return [null, null];
  }

  return (
    <>
      {!!selectedOffer &&
        (!!discountVariable?.length || !!discountFixe?.length) && (
          <Grid item xs={12}>
            <span>
              <h3>{t("souscription.offre.titleRemise")}</h3>
            </span>

            <div
              className="container_information"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {formatDiscountMessage(discountVariable, "variable")}
              <br />
              <br />
              {formatDiscountMessage(discountFixe, "fixe")}
              {/* -5% de remise sur la consommation du 1er mois au 12e mois.<br/>
              -10% de remise sur la consommation du 13e mois au 24e mois.<br/>
              -15% de remise sur la consommation du 25e mois au 36e mois.<br/> */}
            </div>
          </Grid>
        )}
    </>
  );
};

export default Remise;
