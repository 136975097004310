import React from "react";

const ArrowDownSvg = ({ fill }) => {
  return (
    <>
      <svg
        width="10"
        height="9"
        viewBox="0 0 10 9"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.84616 7.65655C5.45393 8.2793 4.54608 8.2793 4.15385 7.65655L0.769351 2.28294C0.34993 1.61702 0.82851 0.749997 1.6155 0.749997L8.3845 0.749998C9.17149 0.749998 9.65007 1.61702 9.23065 2.28294L5.84616 7.65655Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default ArrowDownSvg;
