import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CrossSvg } from "../../../core/assets/picto/cross.svg";
import Button from "../../Button/Button";
import "./styles/popinValidVente.scss";

const PopinValidVente = ({ onClose, list, onClick }) => {
  const { t } = useTranslation();
  const [validList, setvalidList] = useState();

  useEffect(() => {
    setvalidList(
      list?.filter((item) => item.statut === "A_CONFIRMER_COURTIER")
    );
  }, [list]);

  return (
    <>
      <div className="popin">
        <div className="page_content_valid">
          <div className="container" style={{ margin: "0 auto" }}>
            <header className="header_popin_valid">
              <h2 className="title">{t("popinValidation.title")}</h2>
              <p className="subtitle bold">{validList?.length} </p>
              <CrossSvg className="cross" onClick={onClose} />
              <div className="idLabel">
                <span>{t("popinValidation.idTitle")} : </span>
                <span>{validList?.map((l) => l.idMetier)?.join(", ")}</span>
              </div>
            </header>

            <div className="main_container_valid">
              <div className="btn_container">
                <Button
                  type="submit"
                  className="green outline"
                  onClick={onClose}
                >
                  {t("popinValidation.retour")}
                </Button>
                <Button
                  type="submit"
                  className="green"
                  onClick={() => onClick(validList)}
                  disabled={validList?.length === 0}
                >
                  {t("popinValidation.confirm")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopinValidVente;
