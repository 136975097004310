import { createSlice } from "@reduxjs/toolkit";

const adduserSlice = createSlice({
  name: "adduser",
  initialState: {},
  reducers: {
    update: (state, action) => {
      state[action.payload.target] = action.payload.value;
    },
    empty: () => {
      return {};
    },
  },
});

export const { update, empty } = adduserSlice.actions;

export default adduserSlice.reducer;
