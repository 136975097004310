import React, { useEffect, useState } from "react";
import "./inputSMS.scss";

const InputSMS = ({ onChange, error }) => {
  const [code, setCode] = useState("");
  const [focused, setFocused] = useState(false);
  const [clickSelect, setClickSelect] = useState(0);

  const code_length = new Array(4).fill(0);

  const values = code.toString().split("");

  const input = React.createRef();

  const hideInput = values.length >= code_length.length;

  const isNumber = (str) => /^[0-9]+$/.test(str);

  const handleClick = () => {
    input.current.focus();
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const selectedIndex =
    values.length < code_length.length ? values.length : code_length.length - 1;

  const handleChange = (e) => {
    const val = e.target.value;

    setCode((value) => {
      if (value.length >= code_length || !isNumber(val)) {
        return "";
      } else {
        return (value + val).toString().slice(0, code_length.length);
      }
    });
  };

  const handleKeyUp = (e) => {
    if (e.key === "Backspace") {
      setCode((value) => value.slice(0, value.length - 1));
    }
  };

  const handleClickInput = (e) => {
    setClickSelect(e.target.id);
    input.current.focus();
  };

  useEffect(() => {
    setClickSelect(selectedIndex);
  }, [selectedIndex]);

  // Return input value
  useEffect(() => {
    onChange(code);
  }, [code]);

  return (
    <div className="container_inputSms">
      <div
        className={`input_wrapper ${
          values.length > 0 && values.length !== 4 && "notCompleted"
        }`}
      >
        {code_length.map((v, i) => {
          const selected = values.length === i;
          const filled =
            values.length === code_length.length &&
            i === code_length.length - 1;

          return (
            <div
              className={`display ${error && " error"}`}
              key={i}
              id={i}
              onClick={(e) => handleClickInput(e)}
            >
              {values[i]}
              {(selected || filled) && focused && <div className="shadows" />}
            </div>
          );
        })}
        <div className="wrap" onClick={handleClick}>
          <input
            value=""
            ref={input}
            className="input"
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyUp={handleKeyUp}
            style={{
              left: `${clickSelect * 44}px`,
              opacity: hideInput ? 0 : 1,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default InputSMS;
