import React, { useState, useEffect } from "react";

// style
import "./barreButtonList.scss";
import { useSelector } from "react-redux";

const BarreButton = ({
   name,
   value,
   current,
   onChange,
   error,
   className,
   ...props
}) => {
   function handleOnChange(params) {
      return onChange(params);
   }

   const parseNamePricingOption = (name)=>{
      return name.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'');
   }

   const forcePricingOptionOfPdl = useSelector((state) =>  (state.auth.society?.parameters?.FORCE_PRICING_OPTION_OF_PDL === "true"))
   const currentPricingOptionOfPdl = useSelector((state) =>  (state.sale?.PDLInfos?.optionTarifaireOffre))
   const validPricingOption = ["BASE","HPHC"]

   return (
      <>
         <div className={`barreButtonList ${className || ""}`}>
            {current?.tarifications?.filter(i => (forcePricingOptionOfPdl && parseNamePricingOption(i?.tarification?.productSpecification?.name) === currentPricingOptionOfPdl) || !forcePricingOptionOfPdl || !validPricingOption.includes(currentPricingOptionOfPdl)).map((i, index) => {
               return (
                  <label
                     //  style={{ marginLeft: -1 }}
                     key={i?.tarification?.productSpecification?.productNumber}
                  >
                     <input
                        type="radio"
                        name={name}
                        onBlur={props?.onBlur}
                        value={
                           i?.tarification?.productSpecification?.productNumber
                        }
                        checked={
                           value ===
                           i?.tarification?.productSpecification?.productNumber
                        }
                        onChange={() =>
                           handleOnChange(
                              i?.tarification?.productSpecification
                                 ?.productNumber
                           )
                        }
                     />
                     <button
                        type="button"
                        onBlur={props?.onBlur}
                        className={`green outline ${
                           value ===
                           i?.tarification?.productSpecification?.productNumber
                              ? "checked"
                              : ""
                        }`}
                        onClick={() => {
                           handleOnChange(
                              i?.tarification?.productSpecification
                                 ?.productNumber
                           );
                        }}
                     >
                        {i?.tarification?.productSpecification?.name}
                     </button>
                  </label>
               );
            })}
         </div>
         {error ? (
            <p className="barreButtonList_error">{error.message}</p>
         ) : undefined}
      </>
   );
};

export default BarreButton;
