import React from "react";
import Header from "../../../components/Header/Header";
import { ReactComponent as LogoBigBgSvg } from "../../../core/assets/picto/logoBig.svg";
import { ReactComponent as LogoSmallSvg } from "../../../core/assets/picto/logo.svg";
import "./styles/passwordreset.scss";

const PasswordReset = ({ children }) => {

  return (
    <div className="page_content_mdp">
      <Header isHome />
      <div className="container_white">
        {React.cloneElement(children)}
        <LogoSmallSvg className="logoSmall-bg" />
      </div>
      <LogoBigBgSvg className="logoBig-bg" />
    </div>
  );
};

export default PasswordReset;
