import React, { useEffect, useMemo } from "react";
import {
   Route,
   Switch,
   Redirect,
   useHistory,
   Router as BrowserRouter,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthVerify } from "./core/services/httpRequest/axios";
import Auth from "./pages/COMMON/auth/Auth";
import Home from "./pages/TCO/home";
import Subscription from "./pages/TCO/subscription/Subscription";
import Validation from "./pages/TCO/validation/Validation";
import Recap from "./pages/TCO/recap/Recap";
import Utilisateurs from "./pages/BOCO/utilisateurs/Utilisateurs";
import BOCOContainerLayout from "./components/HOC/BOCOContainerLayout/BOCOContainerLayout";
import AuthorizationLayout from "./components/HOC/AuthorizationLayout/AuthorizationLayout";
import Societes from "./pages/BOCO/societes/Societes";
import PasswordReset from "./pages/COMMON/passwordReset/PasswordReset";
import Step1 from "./pages/COMMON/passwordReset/Steps/Step1";
import Step2 from "./pages/COMMON/passwordReset/Steps/Step2";
import Step3 from "./pages/COMMON/passwordReset/Steps/Step3";
import Step4 from "./pages/COMMON/passwordReset/Steps/Step4";
import Ventes from "./pages/BOCO/ventes/ventes";
import Dash from "./pages/BOCO/home/Dash";
import "./core/styles/main.scss";
import Block from "./components/COMMON/Errors/Block/Block";
import Block404 from "./components/COMMON/Errors/404/Block404";
import Block403 from "./components/COMMON/Errors/403/Block403";
import Parametrage from "./pages/BOCO/parametrage/Parametrage";
import PermissionWrapper from "./components/HOC/PermissionWrapper";
import Authorization from "./components/HOC/AuthorizationLayout/Authorization";
import { retreiveCompany } from "./core/stores/authStore";
import { checkMaintenance } from "./core/stores/paramsStore";
import Prospect from "./pages/BOCO/prospect/prospect";

function App() {
   const history = useHistory();
   const currentUser = useSelector((state) => state.auth);
   const saleStep = useSelector((state) => state.sale.saleTCOStep);
   const maintenanceTco = useSelector((state) => state.params.maintenanceStatus === 'MAINTENANCE');
   const dispatch = useDispatch();


   // check token valid if refresh
   AuthVerify();

   useEffect(() => {
      dispatch(retreiveCompany(currentUser.broker.idSocieteCourtage))
      setInterval(() => {
         if (currentUser.broker.idSocieteCourtage !== undefined) {
            dispatch(retreiveCompany(currentUser.broker.idSocieteCourtage))
         }
         dispatch(checkMaintenance())
      }, 5000);
   }, [])


   const isAuthenticated = () => localStorage.getItem("accessToken");

   const PublicRoute = (props) =>
      !isAuthenticated() ? <Route {...props} /> : <Redirect to={"/home"} />;
   const PrivateRoute = (props) =>
      isAuthenticated() ? <Route {...props} /> : <Redirect to={"/auth"} />;

   return (
      <div className="App">
            <BrowserRouter history={history} basename="/">
               <Switch>
                  <PublicRoute path="/auth" exact>
                     <Auth />
                  </PublicRoute>

                  <PublicRoute path="/" exact>
                     <Auth />
                  </PublicRoute>
                  
                     <Route path="/password/forgotten" exact>
                     
                        <PasswordReset>
                           <Step1 />
                        </PasswordReset>
                        
                     </Route>
                  
                     <Route path="/password/mail-sent" exact>
                     
                        <PasswordReset>
                           <Step2 />
                        </PasswordReset>
                        
                     </Route>
                     <Route path="/password/new-password" exact>
                     
                        <PasswordReset>
                           <Step3 />
                        </PasswordReset>
                        
                     </Route>
                     <Route path="/password/new-password-accepted" exact>
                     
                        <PasswordReset>
                           <Step4 />
                        </PasswordReset>
                        
                     </Route>
                     <PrivateRoute path="/home" exact>
                     
                        <Home />
                        
                     </PrivateRoute>
                     {!maintenanceTco && (<PrivateRoute path="/subscription" exact>
                     
                        <Authorization
                           current={currentUser.broker}
                           authorized={[
                              "ADMIN_PRIMEO",
                              "ADMIN_COURTAGE",
                              "RESPONSABLE_VENDEUR",
                              "VENDEUR",
                           ]}
                           redirect={true}
                           destination="/403"
                           user={currentUser}

                        >
                           <Subscription />
                        </Authorization>
                        
                     </PrivateRoute>
                     )}
                     <PrivateRoute path="/recap" exact>
                     
                        <Authorization
                           current={currentUser.broker}
                           authorized={[
                              "ADMIN_PRIMEO",
                              "ADMIN_COURTAGE",
                              "RESPONSABLE_VENDEUR",
                              "VENDEUR",
                           ]}
                           redirect={true}
                           destination="/403"
                           user={currentUser}
                           TCOStep={["SUBSCRIPTION", "RECAP"]}
                           currentTCOStep={saleStep}
                        >

                           <Recap />
                        </Authorization>
                        
                     </PrivateRoute>
                     <PrivateRoute path="/validation" exact>
                        
                           <Authorization
                           current={currentUser.broker}
                           authorized={[
                              "ADMIN_PRIMEO",
                              "ADMIN_COURTAGE",
                              "RESPONSABLE_VENDEUR",
                              "VENDEUR",
                           ]}
                           redirect={true}
                           destination="/403"
                           user={currentUser}
                           TCOStep={["SUBSCRIPTION", "RECAP", "VALIDATION"]}
                           currentTCOStep={saleStep}
                        >

                           <Validation />
                        </Authorization>
                        
                     </PrivateRoute>
                     <PrivateRoute path="/accueil" exact>
                     
                        <BOCOContainerLayout>
                           <Dash />
                        </BOCOContainerLayout>
                       
                     </PrivateRoute>
                     <PrivateRoute path="/utilisateurs" exact>
                     
                        <BOCOContainerLayout>
                           <AuthorizationLayout
                              current={useMemo(() => currentUser.broker.type, [currentUser.broker])}
                              authorized={[
                                 "ADMIN_PRIMEO",
                                 "ADMIN_COURTAGE",
                                 "RESPONSABLE_VENDEUR",
                                 "CHARGE_CLIENT"
                              ]}
                              // authorized={useMemo(() => {
                              //    return [
                              //       authorizations[0],
                              //       authorizations[1],
                              //       authorizations[2],
                              //    ];
                              // }, [authorizations])}
                              redirect={true}
                              destination="/403"
                           >
                              <Utilisateurs />
                           </AuthorizationLayout>
                        </BOCOContainerLayout>
                        
                     </PrivateRoute>
                     <PrivateRoute path="/ventes" exact>
                     
                        <BOCOContainerLayout>
                           <Ventes />
                        </BOCOContainerLayout>
                        
                     </PrivateRoute>
                     <PrivateRoute path="/prospect" exact>
                     
                     <BOCOContainerLayout>
                           <AuthorizationLayout
                              current={useMemo(() => currentUser.broker.type, [currentUser.broker])}
                              authorized={[
                                 "ADMIN_PRIMEO",
                                 "ADMIN_COURTAGE",
                              ]}
                              // authorized={useMemo(() => {
                              //    return [
                              //       authorizations[0],
                              //       authorizations[1],
                              //       authorizations[2],
                              //    ];
                              // }, [authorizations])}
                              redirect={true}
                              destination="/403"
                           >
                        <Prospect />
                        </AuthorizationLayout>
                     </BOCOContainerLayout>
                     
                     </PrivateRoute>
                     <PrivateRoute path="/societes" exact>
                     
                        <BOCOContainerLayout>
                           <AuthorizationLayout
                              current={useMemo(() => currentUser.broker.type, [currentUser.broker])}
                              authorized={["ADMIN_PRIMEO"]}
                              // authorized={useMemo(() => {
                              //    return [authorizations[0]];
                              // }, [authorizations])}
                              redirect={true}
                              destination="/403"
                           >
                              <Societes />
                           </AuthorizationLayout>
                        </BOCOContainerLayout>
                        
                     </PrivateRoute>
                     <PrivateRoute path="/parametrage" exact>
                     
                        <BOCOContainerLayout>
                           <PermissionWrapper
                              requiredPermission={["UPDATE_WORDING"]}
                              frag={
                                 <Block>
                                    <Block403 />
                                 </Block>
                              }
                           >
                              <Parametrage
                                 userType={useMemo(
                                    () => currentUser.broker.type,
                                    [currentUser.broker]
                                 )}
                              />
                           </PermissionWrapper>
                        </BOCOContainerLayout>
                        
                     </PrivateRoute>
                     <PrivateRoute path="/403" exact>
                     
                        <BOCOContainerLayout>
                           <Block>
                              <Block403 />
                           </Block>
                        </BOCOContainerLayout>
                        
                     </PrivateRoute>
                     <Route path="*">
                     
                        <BOCOContainerLayout>
                           <Block>
                              <Block404 />
                           </Block>
                        </BOCOContainerLayout>
                        
                     </Route>
               </Switch>
            </BrowserRouter>
      </div>
   );
}

export default App;
