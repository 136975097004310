import React, { useState, useEffect } from "react";
import { Checkbox, Grid } from "@mui/material";

import _, { set } from "lodash";
import CheckboxButton from "../../Checkbox/CheckboxButton";

const Voltalis = ({
  onChange
}) => {

  const [checked, setChecked] = useState(false);
  const [isElectric, setIsElectric] = useState(false);
  const [isMainResidence, setIsMainResidence] = useState(false);
  const [hasMoreThanTwoRadiators, setHasMoreThanTwoRadiators] = useState(false);

  const [isEligible, setIsEligible] = useState(false);

  const [isTouched, setIsTouched] = useState(false);

  const [isElectricTouched, setIsElectricTouched] = useState(false);
  const [isMainResidenceTouched, setIsMainResidenceTouched] = useState(false);
  const [hasMoreThanTwoRadiatorsTouched, setHasMoreThanTwoRadiatorsTouched] = useState(false);

  useEffect(() => {
    let isEligible = false;
    if (checked && isElectric && isMainResidence && !hasMoreThanTwoRadiators) {
      isEligible = true;
      setIsEligible(isEligible);
    }
    else{
      setIsEligible(false);
    }
    if(isElectricTouched && isMainResidenceTouched && hasMoreThanTwoRadiatorsTouched){
      setIsTouched(true);
    }
    onChange(isEligible);
  }, [checked, isElectric, isMainResidence, hasMoreThanTwoRadiators, isElectricTouched, isMainResidenceTouched, hasMoreThanTwoRadiatorsTouched]);


  return (
    <Grid item xs={12}>
      <div className="offer-container">
        <div className="line-options">
          <CheckboxButton
            value={undefined}
            checked={checked
            }
            valueIsObject
            priceLabel={<span style={{display : "flex", alignItems: "center"}}><h2 style={{fontWeight: "600"}} >0€</h2> TTC/mois</span>}
            text={"Thermostat connecté Voltalis"}
            onChange={(v) => {
              setChecked(v.isPicked);
            }}
          />
          {checked && <div style={{ marginLeft: "30px", marginTop: "15px", display: "flex", flexDirection: "column", gap: "12px" }}>
            <span>1 - Votre chauffage est-il éléctrique</span>
            <div style={{ display: "flex", gap: "12px", marginLeft: "16px" }}>
              <CheckboxButton
                value={undefined}
                checked={isElectric && isElectricTouched}
                valueIsObject
                text={"Oui"}
                onChange={(v) => {
                  setIsElectricTouched(true);
                  setIsElectric(true);
                }}
              />
              <CheckboxButton
                value={undefined}
                checked={!isElectric && isElectricTouched}
                valueIsObject
                text={"Non"}
                onChange={(v) => {
                  setIsElectricTouched(true);
                  setIsElectric(false);
                }}
              />
            </div>

            <span>2 - Est-ce votre résidence principale ?</span>
            <div style={{ display: "flex", gap: "12px", marginLeft: "16px" }}>
              <CheckboxButton
                value={undefined}
                checked={
                  isMainResidence && isMainResidenceTouched
                }
                valueIsObject
                text={"Oui"}
                onChange={(v) => {
                  setIsMainResidenceTouched(true);
                  setIsMainResidence(v.isPicked);
                }}
              />
              <CheckboxButton
                value={undefined}
                checked={
                  !isMainResidence && isMainResidenceTouched
                }
                valueIsObject
                text={"Non"}
                onChange={(v) => {
                  setIsMainResidenceTouched(true);
                  setIsMainResidence(!v.isPicked);
                }}
              />
            </div>
            <span>3 - Combien avez-vous de radiateurs (hors sèche-serviette) ?</span>
            <div style={{ display: "flex", gap: "12px", marginLeft: "16px" }}>
              <CheckboxButton
                value={undefined}
                checked={
                  hasMoreThanTwoRadiators && hasMoreThanTwoRadiatorsTouched
                }
                valueIsObject
                text={"Moins de 2"}
                onChange={(v) => {
                  setHasMoreThanTwoRadiatorsTouched(true);
                  setHasMoreThanTwoRadiators(v.isPicked);
                }}
              />
              <CheckboxButton
                value={undefined}
                checked={
                  !hasMoreThanTwoRadiators && hasMoreThanTwoRadiatorsTouched
                }
                valueIsObject
                text={"Plus de 2"}
                onChange={(v) => {
                  setHasMoreThanTwoRadiatorsTouched(true);
                  setHasMoreThanTwoRadiators(!v.isPicked);
                }}
              />
            </div>

            {isEligible && isTouched && <div style={{ marginTop: "15px" }}>
              <span style={{color : "green"}}>Vous êtes éligible à l'offre Voltalis</span>
            </div>}

            {!isEligible && isTouched && <div style={{ marginTop: "15px" }}>
              <span style={{color : "red"}}>Malheureusement votre client n'est pas éligible ! La solution Voltalis est disponible pour les résidences principales chauffées avec plus de 2 radiateurs éléctriques</span>
            </div>}
          </div>
          }
        </div>
      </div>
    </Grid>
  );
};

export default Voltalis;
