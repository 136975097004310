import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import Button from "../../Button/Button";
import {
  autoCompleteAlea,
  checkEnterKeyDown,
  checkSiretValue,
  getProduitRemunerations,
  onlyNumbersAndLetters,
  trueOrFalseDo,
} from "../../../utils";
import { Grid } from "@mui/material";
import ComboBoxObject from "../../ComboBox/ComboBoxObject";
import DatePicker from "../../DatePicker/datePickerV1";
import TextInput from "../../TextInput/TextInput";
import AddressInput from "../../Address/AddressInput/AddressInput";

// Styles
import "./styles/popinAddSocietes.scss";
import { ReactComponent as CrossSvg } from "../../../core/assets/picto/cross.svg";

// store
import * as addsocieteStore from "../../../core/stores/addSocieteStore";
import SmallTextInput from "../SmallTextInput/SmallTextInput";
import {
  createSociete,
  getOptions,
  putSociete,
} from "../../../core/services/httpRequest/axios";
import GeoExclu from "../GeoExclu/GeoExclu";
import FormattedInputs from "../../TextInput/TextInputSuffix";
import { computeListRemunerationParPuissance } from "./utils/computePerPower";
import { computeRemunerationProduit } from "./utils/computePerProduct";
import { computeListRemunerationParProfil } from "./utils/computePerProfil";
import handleRequestResponse from "../../../utils/responsesHandler";
import Notifaction from "../../../components/BOCO/Notification/Notifaction";
import { update } from "../../../core/stores/paramsStore";
import { sendNotif } from "../../../core/stores/utils";

const PopinAddSocietes = ({
  onClose,
  onSuccess,
  listAdmin,
  selectedSociete = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const addsociete = useSelector((state) => state.addsociete);

  const [tab, setTab] = useState(false);

  const [choosedAddress, setChoosedAddress] = useState(undefined);

  const authorizations = useSelector((state) => state?.params?.authorizations);

  const [products, setProducts] = useState([]);

  const initDefaultValuePopin = selectedSociete
    ? selectedSociete
    : addsociete?.modifSociete;

  const {
    getValues,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
    defaultValues: {
      dateFinConvention: "",
      dateSignature: "",
      ...initDefaultValuePopin,
    },
  });

  function submit(data) {
    const roles = computeListRemunerationParProfil(
      selectedSociete,
      addsociete,
      authorizations,
      data
    );

    const remunerationProduit = computeRemunerationProduit(
      selectedSociete,
      addsociete,
      products,
      data
    );

    const kva_puissance = computeListRemunerationParPuissance(
      selectedSociete,
      addsociete,
      data
    );

    let query = {
      societe: {
        idSociete: data?.idSociete || "",
        nomSociete: data.nomSociete,
        type: data.type,
        siret: Number(data.siret),
        tva: data.tva,
        rcs: data.rcs,
        capitalSocial: Number(data.capitalSocial),
        codeNAF: data.codeNAF,
        adresse: !selectedSociete ? `${choosedAddress.housenumberAndStreet} ${choosedAddress.additionnalAddress}, ${choosedAddress.zipCode} ${choosedAddress.city} `: data?.adresse,
        typeConvention: data.typeConvention,
        dateSignature: data.dateSignature,
        dateFinConvention: data.dateFinConvention,
        exclusiviteGeo: JSON.stringify(data?.exclusiviteGeo),
        bonusMalus: data.bonusMalus,
        adminSociete: addsociete?.adminSociete || data?.adminSociete || null,
        statut: data?.statut || "ACTIF",
      },
      listRemunerationParPuissance: kva_puissance,
      listRemunerationParProduit: remunerationProduit,
      listRemunerationParProfil: roles,
    };

    trueOrFalseDo(
      selectedSociete,
      () => {
        // update
        return putSociete(query).then((res) => {
          handleRequestResponse(
            res,
            () => {
              onSuccess();
              dispatch(update(sendNotif("modifySociete", "")));
              dispatch(addsocieteStore.empty());
            },
            () => {
              dispatch(update(sendNotif(res.status)));
            }
          );
        });
      },
      () => {
        //add
        return createSociete(query).then((res) => {
          handleRequestResponse(
            res,
            () => {
              onSuccess();
              dispatch(update(sendNotif("createSociete", "")));
              dispatch(addsocieteStore.empty());
            },
            () => {
              dispatch(update(sendNotif(res.status)));
            }
          );
        });
      }
    );
  }

  //   init date value if there's the value in the store
  useEffect(() => {
    // getOffres().then(res => {
    //   setProducts(res.data)
    // })
    getOptions().then((res) => {
      setProducts(res.data);
    });
    // getListSociete().then((res) => {
    //    let tmp = res.data?.map((s) => {
    //       return { ...s.admin, idSociete: s.idSociete };
    //    });
    //    setListAdminSociete(_.uniqBy(tmp, "id"));
    // });

    trueOrFalseDo(
      selectedSociete,
      () => {
        dispatch(
          addsocieteStore.update({
            target: "modifSociete",
            value: initDefaultValuePopin,
          })
        );
      },
      () => {
        return;
      }
    );

    trueOrFalseDo(
      addsociete?.dateSignature,
      () => {
        setValue("dateSignature", JSON.parse(addsociete.dateSignature));
      },
      () => {
        return;
      }
    );

    trueOrFalseDo(
      addsociete?.dateFinConvention,
      () => {
        setValue("dateFinConvention", JSON.parse(addsociete.dateFinConvention));
      },
      () => {
        return;
      }
    );

    trueOrFalseDo(
      addsociete?.modifSociete?.adresse,
      () => {
        setValue("adresse", addsociete?.modifSociete?.adresse);
      },
      () => {
        return;
      }
    );
  }, []);

  return (
    <>
      <Notifaction />

      <div className="popin popinAddSocietes">
        <div className="page_content_addsociete">
          <div className="container" style={{ margin: "0 auto" }}>
            <header className="popin_adduser_header">
              <h2 className="title">
                {trueOrFalseDo(
                  selectedSociete,
                  () => {
                    return t("addsociete.titleModif");
                  },
                  () => {
                    return t("addsociete.title");
                  }
                )}
              </h2>
              <CrossSvg className="cross" onClick={onClose} />
            </header>
            <div className="main_container_adduser">
              <form
                onSubmit={handleSubmit(submit)}
                onKeyDown={(e) => checkEnterKeyDown(e)}
                noValidate
                autoComplete="off"
                className="form_addsociete"
              >
                <Grid container spacing={0}>
                  <Grid container spacing={0} className="container_header">
                    <Grid
                      item
                      xs={5.5}
                      className={`headerTab bold ${!tab}`}
                      onClick={() => setTab(!tab)}
                    >
                      {t("addsociete.titleTab1")}
                    </Grid>
                    <Grid
                      item
                      xs={5.5}
                      className={`headerTab bold ${tab}`}
                      onClick={() => setTab(!tab)}
                    >
                      {t("addsociete.titleTab2")}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={0}
                    className={`container_data ${!tab}`}
                  >
                    {selectedSociete && (
                      <Grid item xs={12}>
                        {/* Admin */}
                        <Controller
                          name="adminSociete"
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => {
                            // const ifselect = listAdmin?.map(
                            //    (s) =>
                            //       value === s.idUtilisateur &&
                            //       s.prenom
                            // );
                            return (
                              <ComboBoxObject
                                label={t("addsociete.labelAdministrateur")}
                                onChange={(e) => {
                                  onChange(e);
                                  // const idAdmin =
                                  //    listAdmin?.map(
                                  //       (s) =>
                                  //          s.prenom === e &&
                                  //          s.idUtilisateur
                                  //    );
                                  dispatch(
                                    addsocieteStore.update({
                                      target: "adminSociete",
                                      value: e,
                                    })
                                  );
                                }}
                                value={value}
                                list={listAdmin.map((ac) => {
                                  if (
                                    ac.idSocieteCourtage ===
                                    selectedSociete.idSociete &&
                                    ac.statut === "ACTIF"
                                  ) {
                                    return {
                                      name: ac.prenom + " " + ac.nom,
                                      value: ac.idUtilisateur,
                                    };
                                  }
                                })}
                                error={!!errors?.adminSociete}
                                helperText={t("error.required")}
                                onBlur={(e) => {
                                  // const idAdmin =
                                  //    listAdmin.map(
                                  //       (s) =>
                                  //          s.prenom ===
                                  //             value &&
                                  //          s.idUtilisateur
                                  //    );
                                  // dispatch(
                                  //    addsocieteStore.update({
                                  //       target: "adminSociete",
                                  //       value: e,
                                  //    })
                                  // );
                                  onBlur(e);
                                }}
                                autoComplete={autoCompleteAlea()}
                                fullWidth={true}
                              />
                            );
                          }}
                        />
                      </Grid>
                    )}
                    {/* Name */}
                    <Grid item xs={12}>
                      <Controller
                        name="nomSociete"
                        control={control}
                        rules={{
                          required: t("error.required"),
                        }}
                        defaultValue=""
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextInput
                            className="addsociete-input-text"
                            value={value}
                            label={t("addsociete.labelName")}
                            onChange={onChange}
                            required
                            error={errors.nomSociete !== undefined}
                            helperText={errors.nomSociete?.message}
                            onBlur={(e) => {
                              dispatch(
                                addsocieteStore.update({
                                  target: "nomSociete",
                                  value: value,
                                })
                              );
                              onBlur(e);
                            }}
                            autoComplete="off"
                          />
                        )}
                      />
                    </Grid>
                    {/* Type */}
                    <Grid item xs={12}>
                      <Controller
                        name="type"
                        control={control}
                        rules={{
                          required: t("error.required"),
                        }}
                        defaultValue=""
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextInput
                            className="addsociete-input-text"
                            value={value}
                            label={t("addsociete.labelType")}
                            onChange={onChange}
                            required
                            error={errors.type !== undefined}
                            helperText={errors.type?.message}
                            onBlur={(e) => {
                              dispatch(
                                addsocieteStore.update({
                                  target: "type",
                                  value: value,
                                })
                              );
                              onBlur(e);
                            }}
                            autoComplete="off"
                          />
                        )}
                      />
                    </Grid>
                    {/* Siret */}
                    <Grid item xs={12}>
                      <Controller
                        name="siret"
                        control={control}
                        rules={{
                          required: t("error.required"),
                          validate: checkSiretValue,
                        }}
                        defaultValue=""
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextInput
                            className="addsociete-input-text"
                            value={value}
                            type="tel"
                            label={t("addsociete.labelSiret")}
                            onChange={onChange}
                            required
                            error={errors.siret !== undefined}
                            helperText={
                              errors.siret !== undefined &&
                              t("addsociete.errorSiret")
                            }
                            onBlur={(e) => {
                              dispatch(
                                addsocieteStore.update({
                                  target: "siret",
                                  value: Number(value),
                                })
                              );
                              onBlur(e);
                            }}
                            autoComplete="off"
                            maxLength={14}
                          />
                        )}
                      />
                    </Grid>
                    {/* TVA */}
                    <Grid item xs={12}>
                      <Controller
                        name="tva"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: t("error.required"),
                          validate: onlyNumbersAndLetters,
                        }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextInput
                            className="addsociete-input-text"
                            value={value}
                            label={t("addsociete.labelTva")}
                            onChange={onChange}
                            error={errors.tva !== undefined}
                            helperText={trueOrFalseDo(
                              !!errors.tva && errors.tva.type === "required",
                              () => {
                                return t("error.required");
                              },
                              () => {
                                if (errors?.tva?.type === "validate") {
                                  return t("error.tva");
                                }
                              }
                            )}
                            required
                            maxLength={13}
                            onBlur={(e) => {
                              dispatch(
                                addsocieteStore.update({
                                  target: "tva",
                                  value: value,
                                })
                              );
                              onBlur(e);
                            }}
                            autoComplete="off"
                          />
                        )}
                      />
                    </Grid>
                    {/* RCS */}
                    <Grid item xs={12}>
                      <Controller
                        name="rcs"
                        control={control}
                        rules={{
                          required: t("error.required"),
                          maxLength: 50,
                        }}
                        defaultValue=""
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextInput
                            className="addsociete-input-text"
                            value={value}
                            label={t("addsociete.labelRcs")}
                            onChange={onChange}
                            required
                            error={errors.rcs !== undefined}
                            helperText={errors.rcs?.message}
                            onBlur={(e) => {
                              dispatch(
                                addsocieteStore.update({
                                  target: "rcs",
                                  value: value,
                                })
                              );
                              onBlur(e);
                            }}
                            autoComplete="off"
                          />
                        )}
                      />
                    </Grid>
                    {/* capitalSocial */}
                    <Grid item xs={12}>
                      <Controller
                        name="capitalSocial"
                        control={control}
                        rules={{
                          required: t("error.required"),
                        }}
                        defaultValue=""
                        render={({ field: { value, onChange, onBlur } }) => (
                          <FormattedInputs
                            className="addsociete-input-text"
                            label={t("addsociete.labelCapital")}
                            onChange={onChange}
                            value={value}
                            required
                            error={errors.capitalSocial !== undefined}
                            helperText={errors.capitalSocial?.message}
                            currency="€"
                            onBlur={(e) => {
                              dispatch(
                                addsocieteStore.update({
                                  target: "capitalSocial",
                                  value: Number(value),
                                })
                              );
                              onBlur(e);
                            }}
                            autoComplete="off"
                          />
                        )}
                      />
                    </Grid>
                    {/* Adresse */}
                    <Grid item xs={12}>
                      {!selectedSociete && <AddressInput
                        required={true}
                        label={"Adresse"}
                        onChange={(v) => {
                          setChoosedAddress({
                            housenumber: v?.housenumber,
                            street: v?.street,
                            housenumberAndStreet: v?.name,
                            additionnalAddress: "",
                            city: v?.city,
                            zipCode: v?.zipCode,
                          })
                        }}
                      />}
                      {selectedSociete && (
                        <Controller
                          name="adresse"
                          control={control}
                          rules={{
                            required: t("error.required"),
                          }}
                          defaultValue={addsociete?.modifSociete?.adresse}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextInput
                              style={{ margin: "3px 0" }}
                              name="adresse"
                              value={value}
                              onChange={(e) => {
                                onChange(e)
                              }}
                              placeholder={"Adresse"}
                            />
                          )} />)
                          }
  
                    </Grid>
                    {choosedAddress != undefined && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={10}>
                          <TextInput
                            style={{ margin: "3px 0" }}
                            value={choosedAddress?.housenumberAndStreet}
                            name="housenumberAndStreet"
                            onChange={(e) => {
                              setChoosedAddress({
                                ...choosedAddress,
                                housenumberAndStreet: e.target.value
                              })
                            }}
                            placeholder={t(
                              "souscription.facturation.placeholderHousenumberAndStreet"
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={10}>
                          <TextInput
                            name="additionnalAddress"
                            style={{ margin: "3px 0" }}
                            onChange={(e) => {
                              setChoosedAddress({
                                ...choosedAddress,
                                additionnalAddress: e.target.value
                              })
                            }}
                            placeholder={t(
                              "souscription.facturation.placeholderStreet2"
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextInput
                            maxLength={5}
                            name="zipCode"
                            style={{ margin: "3px 0" }}
                            value={choosedAddress.zipCode}
                            onChange={(e) => {
                              setChoosedAddress({
                                ...choosedAddress,
                                zipCode: e.target.value
                              })
                            }}
                            error={errors?.zipCode}
                            placeholder={t(
                              "souscription.facturation.placeholderZip"
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={10}>
                          <TextInput
                            name="city"
                            style={{ margin: "3px 0 16px" }}
                            value={choosedAddress.city}
                            onChange={(e) => {
                              setChoosedAddress({
                                ...choosedAddress,
                                city: e.target.value
                              })
                            }}
                            placeholder={t(
                              "souscription.facturation.placeholderCity"
                            )}
                          />
                        </Grid>
                      </Grid>)
                    }
                    {/* typeConvention */}
                    <Grid item xs={12}>
                      <Controller
                        name="typeConvention"
                        control={control}
                        defaultValue=""
                        render={({ field: { value, onChange, onBlur } }) => (
                          <TextInput
                            className="addsociete-input-text"
                            value={value}
                            label={t("addsociete.labelConvention")}
                            onChange={onChange}
                            onBlur={(e) => {
                              dispatch(
                                addsocieteStore.update({
                                  target: "typeConvention",
                                  value: value,
                                })
                              );
                              onBlur(e);
                            }}
                            autoComplete="off"
                          />
                        )}
                      />
                    </Grid>
                    {/* Dates */}
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name="dateSignature"
                          render={({ field: { value, onChange, onBlur } }) => (
                            <DatePicker
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                dispatch(
                                  addsocieteStore.update({
                                    target: "dateSignature",
                                    value: JSON.stringify(e),
                                  })
                                );
                              }}
                              onBlur={(e) => {
                                dispatch(
                                  addsocieteStore.update({
                                    target: "dateSignature",
                                    value: JSON.stringify(e.target.value),
                                  })
                                );
                                onBlur(e);
                              }}
                              label={t("addsociete.labelDateSignature")}
                              limitation={{
                                max: trueOrFalseDo(
                                  getValues("dateFinConvention"),
                                  () => {
                                    return getValues("dateFinConvention");
                                  },
                                  () => {
                                    return undefined;
                                  }
                                ),
                              }}
                              error={errors?.dateSignature}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name="dateFinConvention"
                          render={({ field: { value, onChange, onBlur } }) => (
                            <DatePicker
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                dispatch(
                                  addsocieteStore.update({
                                    target: "dateFinConvention",
                                    value: JSON.stringify(e),
                                  })
                                );
                              }}
                              onBlur={(e) => {
                                dispatch(
                                  addsocieteStore.update({
                                    target: "dateFinConvention",
                                    value: JSON.stringify(e.target.value),
                                  })
                                );
                                onBlur(e);
                              }}
                              label={t("addsociete.labelDateFinConvention")}
                              limitation={{
                                min: trueOrFalseDo(
                                  getValues("dateSignature"),
                                  () => {
                                    return getValues("dateSignature");
                                  },
                                  () => {
                                    return undefined;
                                  }
                                ),
                              }}
                              error={errors?.dateFinConvention}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    {/* Exclusivite Geo*/}
                    <Grid item xs={12}>
                      <Controller
                        name="exclusiviteGeo"
                        control={control}
                        defaultValue=""
                        render={({ field: { value, onChange } }) => (
                          <GeoExclu
                            required={false}
                            label={t("geoComp.label")}
                            value={value}
                            error={errors.exclusitiveGeo}
                            helperText={!!errors?.exclusitiveGeo}
                            onChange={(value) => {
                              onChange(value);
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* Rémunération block */}
                <Grid
                  container
                  spacing={0}
                  className={`container_remuneration ${tab}`}
                >
                  <Grid item xs={12} className="title_line">
                    <h4>{t("addsociete.titleBonus")}</h4>
                  </Grid>
                  <Grid container spacing={0} className="line_gray">
                    <Grid item xs={6}>
                      {t("addsociete.titleBonus")}
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="bonusMalus"
                        control={control}
                        rules={{
                          required: t("error.required"),
                        }}
                        defaultValue={0}
                        render={({ field: { value, onChange } }) => (
                          <SmallTextInput
                            percentage={true}
                            required
                            error={errors.bonusMalus !== undefined}
                            helperText={errors.bonusMalus?.message}
                            onChange={(v) => {
                              dispatch(
                                addsocieteStore.update({
                                  target: "bonusMalus",
                                  value: v,
                                })
                              );
                              onChange(v);
                            }}
                            autoComplete="off"
                            value={value}
                            max={100}
                            min={-100}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  {/* Ratio par profil */}
                  <Grid item xs={12} className="title_line">
                    <h4>{t("addsociete.titleRatios")}</h4>
                  </Grid>
                  <Grid container spacing={0} className="title_col_line">
                    <Grid item xs={6}>
                      {t("addsociete.labelProfil")}
                    </Grid>
                    <Grid item xs={6}>
                      {t("addsociete.labelRatio")}
                    </Grid>
                  </Grid>
                  {authorizations.filter((a) => a !== "ADMIN_PRIMEO").map(
                    (a, index) =>
                    (
                      <Grid
                        container
                        spacing={0}
                        className="line_gray"
                        key={a}
                      >
                        <Grid item xs={6}>
                          {t(`addsociete.titleRole_${a}`)}
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name={`ratio-${a}`}
                            control={control}
                            rules={{
                              required: t("error.required"),
                            }}
                            defaultValue={100}
                            render={({ field: { value, onChange } }) => (
                              <SmallTextInput
                                percentage={true}
                                required
                                error={errors[`ratio-${a}`] !== undefined}
                                helperText={errors[`ratio-${a}`]?.message}
                                onChange={(v) => {
                                  onChange(v);
                                  const remunProfil = {
                                    profil: a,
                                    ratio: v,
                                  };

                                  dispatch(
                                    addsocieteStore.update({
                                      target: `ratio-${a}`,
                                      value: remunProfil,
                                    })
                                  );
                                }}
                                autoComplete="off"
                                value={value.ratio || value}
                                max={100}
                                min={0}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    )
                  )}
                  <Grid item xs={12} className="title_line">
                    <h4>{t("addsociete.titleCrossSelling")}</h4>
                  </Grid>
                  <Grid container spacing={0} className="title_col_line">
                    <Grid item xs={6}>
                      {t("addsociete.labelProduit")}
                    </Grid>
                    <Grid item xs={6}>
                      {t("addsociete.labelRemunerationProduit")}
                    </Grid>
                  </Grid>

                  {!!!products === false && products?.map((a, index) => {
                    const productRemFind = getProduitRemunerations(selectedSociete);

                    return (
                      <Grid
                        container
                        spacing={0}
                        className="line_gray"
                        key={a.name}
                      >
                        <Grid item xs={6}>
                          {trueOrFalseDo(
                            selectedSociete &&
                            addsociete?.modifSociete?.[
                            `produitRemuneration${index}`
                            ]?.["produit"],
                            () => {
                              return (
                                productRemFind.find(
                                  (p) => p.produit === a.productNumber
                                ) && a.name
                              );
                            },
                            () => {
                              return a.name;
                            }
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name={`produitRemuneration${index}`}
                            control={control}
                            defaultValue=""
                            render={({ field: { value, onChange } }) => (
                              <SmallTextInput
                                percentage={false}
                                required
                                onChange={(v) => {
                                  onChange(v);
                                  const produitRemu = {
                                    produit: trueOrFalseDo(
                                      selectedSociete &&
                                      addsociete?.modifSociete?.[
                                      `produitRemuneration${index}`
                                      ]["produit"],
                                      () => {
                                        return addsociete?.modifSociete?.[
                                          `produitRemuneration${index}`
                                        ]["produit"];
                                      },
                                      () => {
                                        return a.productNumber;
                                      }
                                    ),
                                    remuneration: v,
                                  };
                                  dispatch(
                                    addsocieteStore.update({
                                      target: `produitRemuneration${index}`,
                                      value: produitRemu,
                                    })
                                  );
                                }}
                                className="euro"
                                autoComplete="off"
                                value={trueOrFalseDo(
                                  value.remuneration,
                                  () => {
                                    return value.remuneration;
                                  },
                                  () => {
                                    return value;
                                  }
                                )}
                                min={0}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12} className="title_line">
                    <h4>{t("addsociete.titleCrossSelling")}</h4>
                  </Grid>
                  <Grid
                    container
                    spacing={0}
                    className="title_col_line title_3c"
                  >
                    <Grid item xs={4}>
                      {t("addsociete.labelPuissanceRemuneration")}
                    </Grid>
                    <Grid item xs={4}>
                      {t("addsociete.labelPuissanceRemuneration")}
                    </Grid>
                    <Grid item xs={4}>
                      {t("addsociete.labelPuissanceRemuneration")}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    className="containe_bloc_puissance"
                  >
                    {Array.from({ length: 36 }).map((val, i) => {
                      return (
                        <Grid
                          item
                          xs={3.9}
                          className="line_gray"
                          key={`${i + 1}_line`}
                        >
                          <Controller
                            name={trueOrFalseDo(
                              i + 1 < 10,
                              () => {
                                return `KVA_0${i + 1}`;
                              },
                              () => {
                                return `KVA_${i + 1}`;
                              }
                            )}
                            control={control}
                            defaultValue=""
                            render={({ field: { value, onChange } }) => (
                              <div className="bloc_puissance">
                                {trueOrFalseDo(
                                  i + 1 < 10,
                                  () => {
                                    return <p key={i + 1}>0{i + 1}</p>;
                                  },
                                  () => {
                                    return <p key={i + 1}>{i + 1}</p>;
                                  }
                                )}

                                <SmallTextInput
                                  percentage={false}
                                  onChange={(v) => {
                                    onChange(v);
                                    const kvaNb = {
                                      kva: trueOrFalseDo(
                                        i + 1 < 10,
                                        () => {
                                          return `KVA_0${i + 1}`;
                                        },
                                        () => {
                                          return `KVA_${i + 1}`;
                                        }
                                      ),
                                      remuneration: v,
                                    };
                                    dispatch(
                                      addsocieteStore.update({
                                        target: trueOrFalseDo(
                                          i + 1 < 10,
                                          () => {
                                            return `KVA_0${i + 1}`;
                                          },
                                          () => {
                                            return `KVA_${i + 1}`;
                                          }
                                        ),
                                        value: kvaNb,
                                      })
                                    );
                                  }}
                                  className="euro"
                                  autoComplete="off"
                                  value={trueOrFalseDo(
                                    value.remuneration,
                                    () => {
                                      return Number(value.remuneration);
                                    },
                                    () => {
                                      return Number(value);
                                    }
                                  )}
                                  min={0}
                                />
                              </div>
                            )}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>

                <Button type="submit" className={`green save`}>
                  {t("addsociete.save")}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopinAddSocietes;
