import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as paramsStore from "../../../core/stores/paramsStore";
import Cross from "../../SvgComponents/Cross";
import "./styles/notification.scss";

const Notifaction = () => {
  const notif = useSelector((state) => state.params.notif);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function close() {
    dispatch(
      paramsStore.update({
        target: "notif",
        value: { action: "", res: "", show: false },
      })
    );
  }

  function printMessage(action) {
    switch (action) {
      case "createUser":
        return (
          <>
            <h2 className="titre">{t("notifications.createUserSuccess")}</h2>
          </>
        );

      case "modifyUser":
        return (
          <>
            <h2 className="titre">{t("notifications.modifyUserSuccess")}</h2>
          </>
        );
      case "createSociete":
        return (
          <>
            <h2 className="titre">{t("notifications.createSocieteSuccess")}</h2>
          </>
        );
      case "modifySociete":
        return (
          <>
            <h2 className="titre">{t("notifications.modifySocieteSuccess")}</h2>
          </>
        );
      case "transferMass":
        return (
          <>
            <h2 className="titre">{t("notifications.transferMassSuccess")}</h2>
            <p className="description">{t("notifications.forUsers")}</p>
          </>
        );
      case "modifyMassSocietes":
        return (
          <>
            <h2 className="titre">{t("notifications.modifyMassSuccess")}</h2>
            <p className="description">{t("notifications.forSocietes")}</p>
          </>
        );
      case "modifyMassUsers":
        return (
          <>
            <h2 className="titre">{t("notifications.modifyMassSuccess")}</h2>
            <p className="description">{t("notifications.forUsers")}</p>
          </>
        );
      case "modifyMassUserStatusACTIF":
        return (
          <>
            <h2 className="titre">
              {t("notifications.modifyMassStatusACTIF")}
            </h2>
            <p className="description">{t("notifications.forUsers")}</p>
          </>
        );
      case "modifyMassUserStatusINACTIF":
        return (
          <>
            <h2 className="titre">
              {t("notifications.modifyMassStatusINACTIF")}
            </h2>
            <p className="description">{t("notifications.forUsers")}</p>
          </>
        );
      case "modifyMassSocieteStatusACTIF":
        return (
          <>
            <h2 className="titre">
              {t("notifications.modifyMassStatusACTIF")}
            </h2>
            <p className="description">{t("notifications.forSocietes")}</p>
          </>
        );
      case "modifyMassSocieteStatusINACTIF":
        return (
          <>
            <h2 className="titre">
              {t("notifications.modifyMassStatusINACTIF")}
            </h2>
            <p className="description">{t("notifications.forSocietes")}</p>
          </>
        );
      case "modifyMassContrats":
        return (
          <>
            <h2 className="titre">{t("notifications.modifyMassSuccess")}</h2>
            <p className="description">{t("notifications.forContrats")}</p>
          </>
        );
      case "modifyContrat":
        return (
          <>
            <h2 className="titre">{t("notifications.modifyContratSuccess")}</h2>
          </>
        );
      case "modifySale":
          return (
            <>
              <h2 className="titre">{t("notifications.modifySaleSuccess")}</h2>
            </>
        );
      case "validContrat":
        return (
          <>
            <h2 className="titre">{t("notifications.validContrat")}</h2>
          </>
        );
      case "validContratMass":
        return (
          <>
            <h2 className="titre">{t("notifications.validContratMass")}</h2>
          </>
        );
      case "annulationVente":
        return (
          <>
            <h2 className="titre">{t("notifications.annulationVente")}</h2>
            <p className="description">{t("notifications.forVentes")}</p>
          </>
        );
      case "createAdminNote":
        return (
          <>
            <h2 className="titre">{t("notifications.createAdminNote")}</h2>
          </>
        );
      case "createAdminParam":
          return (
            <>
              <h2 className="titre">{"Les paramètres ont bien été mis à jour."}</h2>
            </>
          );
      case "modifyAdminNote":
        return (
          <>
            <h2 className="titre">{t("notifications.modifyAdminNote")}</h2>
          </>
        );
      case "UPDATE_PERMISSIONS":
        return (
          <>
            <h2 className="titre">{t("notifications.UPDATE_PERMISSIONS")}</h2>
          </>
        )
      case 500:
        return <h2 className="titre">{t("notifications.500")}</h2>;
      case 403:
        return <h2 className="titre">{t("notifications.403")}</h2>;
      case 404:
        return <h2 className="titre">{t("notifications.500")}</h2>;
      case 406:
        return <h2 className="titre">{t("notifications.406")}</h2>;
      case 400:
        return <h2 className="titre">{t("notifications.500")}</h2>;
      case "sendQuote":
          return (
              <h2 className="titre">Le devis a bien été envoyé.</h2>
          );
      default:
        break;
    }
  }

  useEffect(() => {
    const time = setTimeout(() => close(), 6000);

    return () => {
      clearTimeout(time);
    };
  }, [notif?.show]);

  return (
    <div className={notif?.show ? "toast show" : "toast"}>
      <div className="crossNotif" onClick={() => close()}>
        <Cross fill={"#FFFFFF"} />
      </div>
      <div className="content">
        {printMessage(notif?.action)}
        <p className="cible">{notif?.res}</p>
      </div>
      {notif?.show && (
        <div
          className="round-time-bar"
          style={{ "--duration": "6" }}
          data-style="smooth"
        >
          <div></div>
        </div>
      )}
    </div>
  );
};

export default Notifaction;
