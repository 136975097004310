import React from "react";
import "./customRadio.scss";

const CustomRadio = ({
   value,
   controllerValue,
   name,
   target,
   onChange,
   onBlur,
}) => {

   return (
      <div className="customRadio_wrapper">
         <input
            type="radio"
            id={name}
            name={target}
            value={value}
            checked={value === controllerValue ? true : false
            }
            onChange={onChange}
            onBlur={onBlur}
            className="customRadio"
         />
         <span className="customRadio_cache"></span>
         <label htmlFor={name}>{name}</label>
      </div>
   );
};

export default CustomRadio;
