import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/Header/Header";
import Button from "../../../components/Button/Button";
import { Modal } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowSvg } from "../../../core/assets/picto/arrow.svg";
import RecapBloc from "../../../components/BlocsComponents/RecapBloc";
import * as saleStore from "../../../core/stores/saleStore";
// scss
import "./styles/recap.scss";

const Recap = ({ props }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const sale = useSelector((state) => state.sale);

  function annul() {
    dispatch(saleStore.empty());
    history.push("/home");
  }

  // Return home if store is empty
  useEffect(() => {
    if (sale?.saleValid === true) {
      history.push("/home");
      dispatch(saleStore.empty());
    }
  }, []);

  return (
    <div className="page_content_recap">
      <div className="container">
        <Header>
          <Button
            className={"blue outline"}
            style={{ width: "100%" }}
            onClick={() => {
              setOpen(true);
            }}
          >
            {t("actions.annul")}
          </Button>
          <Modal open={open} onClose={() => setOpen(false)}>
            <div className="modal">
              <h2>{t("actions.annul2")}</h2>
              <div className="buttons">
                <Button
                  className={"blue"}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  NON
                </Button>
                <Button className={"blue outline"} onClick={() => annul()}>
                  OUI
                </Button>
              </div>
            </div>
          </Modal>
        </Header>
        <div className="main_container">
          <Button
            className="blue outline"
            style={{ margin: "30px 0 20px", padding: "16px" }}
            onClick={() => {
              history.push("/subscription");
            }}
          >
            <ArrowSvg className="arrow" />
          </Button>
          <RecapBloc />
        </div>
      </div>
    </div>
  );
};

export default Recap;
