import React, { useState, useEffect } from "react";
import CheckboxButton from "../../Checkbox/CheckboxButton";
import Button from "../../Button/Button";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getUsers } from "../../../core/services/httpRequest/axios";
import { ReactComponent as CrossSvg } from "../../../core/assets/picto/cross.svg";
import ComboBoxObject from "../../ComboBox/ComboBoxObject";
import { autoCompleteAlea } from "../../../utils";
import _ from "lodash";
import "./styles/popintransferrespo.scss";

const PopinTransferRespo = ({ list, onClose, onSuccess, onTransfer }) => {
  const [respo, setRespo] = useState([]);
  const [previousRespo, setPreviousRespo] = useState([]);
  const [isTransferDisabled, setIsTransferDisabled] = useState(false);
  const [listRespWithoutOldResp, setlistRespWithoutOldResp] = useState([]);

  const { t } = useTranslation();

  const {
    getValues,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  function transferVendeur() {
    let transferedVendeur = getValues("vendeurs")?.filter(
      (v) => v?.isPicked === true
    );
    let idResponsable = getValues("newIdResponsable");
    setIsTransferDisabled(true);
    setValue("newIdResponsable", undefined);
    let promise = new Promise((resolve, reject) => {
      resolve(onTransfer(transferedVendeur, idResponsable));
    });
    promise.then((res) => {
      // TODO POPUP NOTIFICATION res === message i18n
      onSuccess();
      onClose();
    });
  }

  function updateInfos() {
    getUsers(list[0]?.idSocieteCourtage).then((res) =>
      setRespo(res.data?.filter((u) => u.type === "RESPONSABLE_VENDEUR"))
    );

    let uRes = _.uniqBy(list, "idResponsable")?.map((r) => r);

    console.log("🚀 ------------------------------🚀");
    console.log("🚀 ~ updateInfos ~ uRes:", uRes);
    console.log("🚀 ------------------------------🚀");

    setPreviousRespo(uRes);

    setValue("vendeurs", list);
  }

  useEffect(() => {
    updateInfos();
  }, []);

  useEffect(() => {
    if (previousRespo.length === 1) {
      setlistRespWithoutOldResp(
        respo.filter((r) => r.idUtilisateur !== previousRespo[0].idResponsable)
      );
    }
  }, [respo, previousRespo]);

  useEffect(() => {
    let newIdResponsable = getValues("newIdResponsable");
    let isVendeursSelected = getValues("vendeurs")?.find(
      (v) => v?.isPicked === true
    );
    if (!!newIdResponsable && !!isVendeursSelected) {
      setIsTransferDisabled(false);
    } else {
      setIsTransferDisabled(true);
    }
  }, [watch("newIdResponsable"), watch(["vendeurs"])]);

  return (
    <>
      <div className="popin">
        <div className="page_content_transfermass">
          <div className="container">
            <header className="popin_transfermass_header">
              <h2 className="title">{t("transfermass.title")}</h2>
              <p className="subtitle">{t("transfermass.subtitle")}</p>
              <CrossSvg className="cross" onClick={onClose} />
            </header>
            <div className="main_container_transfermass">
              <form>
                <div className="team_container">
                  {list?.map((vendeur, index) => {
                    return (
                      <Controller
                        key={index}
                        control={control}
                        name={`vendeurs.${index}`}
                        defaultValue={vendeur}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <CheckboxButton
                              text={`${vendeur.prenom} ${vendeur.nom}`}
                              className="popin_checkbox"
                              valueIsObject
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              defaultChecked={true}
                            />
                          );
                        }}
                      />
                    );
                  })}
                </div>
                {/*  */}
                <p>{t("transfermass.previousRespo")}</p>
                {previousRespo?.map((respo, index) => {
                  return (
                    <div key={index} className="tagSelectRespo">
                      {respo.nomResponsable}
                    </div>
                  );
                })}
                {/*  */}
                <Controller
                  name="newIdResponsable"
                  control={control}
                  rules={{
                    required: t("error.required"),
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <ComboBoxObject
                      label={t("modifyuser.labelNewResp")}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      value={value}
                      canBeCanceled={false}
                      list={listRespWithoutOldResp?.map((r) => {
                        return {
                          value: r.idUtilisateur,
                          name: r.prenom + " " + r.nom,
                        };
                      })}
                      required
                      helperText={errors?.newIdResponsable?.message}
                      error={errors?.newIdResponsable !== undefined}
                      onBlur={(e) => {
                        onBlur(e);
                      }}
                      autoComplete={autoCompleteAlea()}
                      fullWidth={true}
                    />
                  )}
                />
                <Button
                  className={
                    isTransferDisabled ? "green-v2 reverse" : "green-v2 "
                  }
                  style={{ margin: "10px auto 0" }}
                  disabled={isTransferDisabled}
                  onClick={() => transferVendeur()}
                >
                  Transférer
                </Button>
              </form>
            </div>
          </div>
        </div>

        {/* {fields} */}
      </div>
    </>
  );
};

export default PopinTransferRespo;
