export function computeRemunerationProduit(
   selectedSociete,
   addsociete,
   produits,
   data
) {
   // if modify
   const res = [];
   if (selectedSociete) {
      produits.forEach((v, i) => {

         return res.push({
            ...addsociete?.modifSociete?.[`produitRemuneration${i}`],
            idSociete: data?.idSociete,
         });
      });

      res.forEach((v, i) => {
         if (v?.produit === addsociete?.[`produitRemuneration${i}`]?.produit) {
            return (res[i] = {
               produit: v.produit,
               remuneration:
                  addsociete?.[`produitRemuneration${i}`]?.remuneration || 0,
               idSociete: data?.idSociete,
            });
         }
      });
   }
   if (!selectedSociete) {
      produits.forEach((v, i) => {
         // Creation

         if (!!addsociete?.[`produitRemuneration${i}`]) {

          return res.push(addsociete?.[`produitRemuneration${i}`]);
        } else {

            return res.push({
               produit: v.productNumber,
               remuneration: 0,
            });
         }
      });
   }
   return res;
}
