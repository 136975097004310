import React from "react";
import { useTranslation } from "react-i18next";

import "./styles/404.scss";

const Block404 = () => {
   const { t } = useTranslation();
   return (
      <div>
         <h3>{t("error.title404")}</h3>
         <p>{t("error.sub404")}</p>
      </div>
   );
};

export default Block404;
