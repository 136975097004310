import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { formatErrorEmail } from "../../../utils";

import { ReactComponent as LogoBigBgSvg } from "../../../core/assets/picto/logoBig.svg";
import { ReactComponent as LogoSmallSvg } from "../../../core/assets/picto/logo.svg";
import CheckboxButton from "../../../components/Checkbox/CheckboxButton";
import Button from "../../../components/Button/Button";
import TextInput from "../../../components/TextInput/TextInput";
import { useDispatch, useSelector } from "react-redux";
import * as auth from "../../../core/stores/authStore";
import { useTranslation } from "react-i18next";
import {
   getSocieteDetails,
   handleLogin,
} from "../../../core/services/httpRequest/axios";
import "./styles/auth.scss";
import PasswordInput from "../../../components/PasswordInput/PasswordInput";
import * as paramsStore from "../../../core/stores/paramsStore";

const Auth = () => {
   const [logsError, setLogsError] = useState(false);
   const dispatch = useDispatch();
   const { t } = useTranslation();

   const history = useHistory();
   const {
      setValue,
      handleSubmit,
      getValues,
      watch,
      control,
      formState: { errors },
   } = useForm({ mode: "onBlur", reValidateMode: "onChange" });

   const [disableValid, setDisableValid] = useState(false);
   const [rememberMe, setRememberMe] = useState(false);
   const [inactive, setInactive] = useState(false);

   function connexion(data) {
      // Reinit paramsStore
      dispatch(paramsStore.empty())
      handleLogin(data.user, data.password).then((response) => {
         if (response.status === 200) {
            // si OK alors je récupère et stoke les infos de la société
            dispatch(auth.retreiveCompany(response.data.idSocieteCourtage))

            history.push("/home");
            if (rememberMe) {
               localStorage.setItem(
                  "rememberMe:primeo",
                  JSON.stringify({ status: true, user: data.user })
               );
            } else {
               localStorage.removeItem("rememberMe:primeo");
            }
            dispatch(
               auth.update({
                  target: "broker",
                  value: {
                     email: response.data.email,
                     exclusitiveGeo: response.data.exclusitiveGeo,
                     idUtilisateur: response.data.idUtilisateur,
                     pourcentageVendeurCommission:
                        response.data.pourcentageVendeurCommission,
                     lastName: response.data.nom,
                     firstName: response.data.prenom,
                     statut: response.data.statut,
                     phone: response.data.telephone,
                     type: response.data.type,
                     visuCommission: response.data.visuCommission,
                     fullName: response.data.prenom + " " + response.data.nom,
                     idSocieteCourtage: response.data.idSocieteCourtage,
                  },
               })
            );
         } else {
            if (
               response.status === 401 &&
               response?.data?.details[0] === "L'utilisateur est inactif"
            ) {
               setInactive(true);
            } else {
               setInactive(false);
            }

            const time = setTimeout(() => setLogsError(false), 6000);
            setLogsError(true);
            return () => {
               clearTimeout(time);
            };
         }
      });
   }

   useEffect(() => {
      localStorage.getItem("rememberMe:primeo") !== null
         ? setRememberMe(true)
         : setRememberMe(false);

      if (localStorage.getItem("rememberMe:primeo") !== null) {
         let user = JSON.parse(localStorage.getItem("rememberMe:primeo"))?.user;
         setValue("user", user);
      }
   }, []);

   useEffect(() => {
      if (!(!!getValues("password") && !!getValues("user"))) {
         setDisableValid(true);
      } else {
         setDisableValid(false);
      }
   }, [watch("user"), watch("password")]);

   return (
      <>
         <main className="page_content_auth">
            <div className="container_white">
               <h1 className="green">{t("auth.title")}</h1>
               <p className="blue">{t("auth.subtitle")}</p>
               <form
                  name="connexionForm"
                  onSubmit={handleSubmit(connexion)}
                  noValidate
                  autoComplete="off"
               >
                  <fieldset className="form">
                     <div style={{ margin: "0 0 10px" }}>
                        <Controller
                           control={control}
                           name="user"
                           rules={{
                              required: t("error.required"),
                              validate: formatErrorEmail,
                           }}
                           render={({ field: { value, onChange, onBlur } }) => (
                              <TextInput
                                 value={value}
                                 onChange={onChange}
                                 onBlur={onBlur}
                                 label={t("auth.email")}
                                 placeholder={t("auth.email")}
                                 variant="outlined"
                                 type="email"
                                 error={errors.user !== undefined}
                                 helperText={errors.user?.message}
                                 required
                              />
                           )}
                        />
                     </div>
                     <div style={{ margin: "0 0 10px" }}>
                        <Controller
                           control={control}
                           name="password"
                           defaultValue=""
                           rules={{
                              required: t("error.required"),
                           }}
                           render={({ field: { value, onChange, onBlur } }) => (
                              <PasswordInput
                                 value={value}
                                 onChange={onChange}
                                 onBlur={onBlur}
                                 label={t("auth.password")}
                                 placeholder={t("auth.password")}
                                 variant="outlined"
                                 error={errors.password !== undefined}
                                 helperText={errors.password?.message}
                                 required
                              />
                           )}
                        />
                     </div>
                     <div className="rememberMe">
                        <CheckboxButton
                           text={t("auth.rememberMe")}
                           checked={rememberMe}
                           onChange={() => setRememberMe(!rememberMe)}
                        />
                     </div>

                     <Button
                        className="green"
                        type="submit"
                        disabled={disableValid}
                        style={{ marginTop: "12px" }}
                     >
                        {t("auth.connect")}
                     </Button>

                     <span
                        className={`error-message ${logsError && "isError"}`}
                     >
                        {inactive ? t("auth.inactive") : t("auth.incorrect")}
                     </span>

                     <div>
                        <p style={{ textAlign: "center" }}>
                           <span
                              className="forgotPassword"
                              onClick={() =>
                                 history.push("/password/forgotten")
                              }
                           >
                              {t("auth.forgotPassword")}
                           </span>
                        </p>
                     </div>
                  </fieldset>
               </form>
               <LogoSmallSvg className="logoSmall-bg" />
            </div>
            <LogoBigBgSvg className="logoBig-bg" />
         </main>
      </>
   );
};

export default Auth;
