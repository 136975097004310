import React from "react";
import {
   getExportAnnually,
   getExportMonthly,
   getExportWeekly,
} from "../../../core/services/httpRequest/axios";
import { ReactComponent as Dwnld } from "../../../core/assets/picto/dwnld.svg";
import { download_csv } from "./utils.js"
const CSVDash = ({ view, filters }) => {

   function callExportCSVAPI() {
      if (view === "WEEKLY") {
         return getExportWeekly({
            week: filters.week,
            year: filters.year,
         });
      }
      if (view === "MONTHLY") {
         return getExportMonthly({
            month: filters.month,
            year: filters.year,
         });
      }
      if (view === "ANNUALLY") {
         return getExportAnnually({
            year: filters.year,
         });
      }
   }


   return (
      <Dwnld
         onClick={() =>
            callExportCSVAPI().then((res) =>
               download_csv("ventes_dashboard_primeo.csv", res.data)
            )
         }
      />
   );
};

export default CSVDash;
