import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ReactComponent as CrossSvg } from "../../../core/assets/picto/cross.svg";
import { putAnnulationVente } from "../../../core/services/httpRequest/axios";
import { update } from "../../../core/stores/paramsStore";
import { sendNotif } from "../../../core/stores/utils";
import { checkIfDisplayAnnuleBtn, trueOrFalseDo } from "../../../utils";
import handleRequestResponse from "../../../utils/responsesHandler";
import Button from "../../Button/Button";
import ComboBoxObject from "../../ComboBox/ComboBoxObject";
import TextInput from "../../TextInput/TextInput";
import "./styles/popinAnnuleVente.scss";

const PopinAnnuleVente = ({
  onClose,
  selected,
  user,
  authorization,
  success,
  list,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedMotif, setSelectedMotif] = useState();
  const [valueDecom, setValueDecom] = useState(0);
  const [confirmationDisabled, setConfirmationDisabled] = useState(true);
  const [valueCommission, setValueCommission] = useState();

  const [listMotif, setListMotif] = useState([]);

  const [current, setCurrent] = useState({});
  const [listValid, setListValid] = useState([]);

  useState(() => {
    let target = list?.filter((v) =>
      selected.find((a) => a.idVente === v.idVente)
    );
    setCurrent(target);

    const resultCom = selected.reduce((a, v) => (a = a + v.commissionVente), 0);
    setValueCommission(resultCom);

    trueOrFalseDo(
      authorization === "ADMIN_PRIMEO",
      () => {
        setListMotif([
          {
            name: `Vente annulée par Primeo Energie`,
            value: `VENTE_ANNULE_PRIMEO`,
          },
          {
            name: `Echec du prélèvement`,
            value: `SOLVENCY_KO`,
          },
          {
            name: `Vente annulée par ${user?.fullName}`,
            value: `VENTE_ANNULE`,
          },
          { name: "Rétractation client", value: "RETRACTATION_CLIENT" },
          {
            name: "KO Enedis",
            value: "KO_ENEDIS",
          },
          {
            name: "Décommissionnement",
            value: "DECOMMISSIONNEMENT",
          },
        ]);
      },
      () => {
        setListMotif([
          {
            name: `Vente annulée par ${user?.fullName}`,
            value: `VENTE_ANNULE`,
          },
          { name: "Rétractation client", value: "RETRACTATION_CLIENT" },
        ]);
      }
    );
  }, []);

  useEffect(() => {
    setListValid(current.filter((v) => checkIfDisplayAnnuleBtn(user, v) && v));
  }, [current]);

  useEffect(() => {
    let cond1 = selectedMotif && selectedMotif !== "DECOMMISSIONNEMENT";

    if (cond1 || (selectedMotif === "DECOMMISSIONNEMENT" && valueDecom)) {
      setConfirmationDisabled(false);
    } else {
      setConfirmationDisabled(true);
    }
  }, [selectedMotif, valueDecom]);

  const handleConfirmation = () => {
    const listIdVente = listValid?.flatMap((v) => v.idVente);
    const listIdMetier = listValid?.flatMap((v) => v.idMetier);

    const ordre = "annulationVente";

    listIdVente.forEach((v) =>
      putAnnulationVente(v, selectedMotif, valueDecom).then((res) => {
        handleRequestResponse(
          res,
          () => {
            success();
            dispatch(update(sendNotif(ordre, listIdMetier.join(", "))));
          },
          () => {
            dispatch(update(sendNotif(res.status)));
          }
        );
      })
    );
  };

  return (
    <>
      <div className="popin">
        <div className="page_content_annule">
          <div className="container" style={{ margin: "0 auto" }}>
            <header className="header_popin_annule">
              <h2 className="title">{t("popinAnnulation.title")}</h2>
              <p className="subtitle bold">
                {listValid?.length}{" "}
                {trueOrFalseDo(
                  listValid?.length > 1,
                  () => {
                    return t("popinAnnulation.subtitleVentes");
                  },
                  () => {
                    return t("popinAnnulation.subtitleVenteOne");
                  }
                )}
              </p>
              <CrossSvg className="cross" onClick={onClose} />
              <div className="idLabel">
                <span>{t("popinAnnulation.idTitle")} : </span>
                <span>{listValid?.map((v) => v.idMetier + ", ")}</span>
              </div>
            </header>
            <div className="main_container_annule">
              <div>
                <ComboBoxObject
                  label={t("popinAnnulation.motifLabel")}
                  canBeCanceled={true}
                  list={listMotif.map((b) => {
                    return {
                      value: b.value,
                      name: b.name,
                    };
                  })}
                  value={selectedMotif}
                  onChange={(e) => {
                    setSelectedMotif(e);
                  }}
                />
              </div>

              {selectedMotif === "DECOMMISSIONNEMENT" && (
                <div>
                  <h4 className="title_line">
                    {t("popinAnnulation.titleValCom")} : {valueCommission}€
                  </h4>
                  <TextInput
                    label={t("popinAnnulation.montantDecom")}
                    type="number"
                    value={valueDecom}
                    autoComplete="off"
                    onChange={(e) => {
                      setValueDecom(e.target.value);
                    }}
                    // ipad
                    inputPutProps={{ pattern: "([^0-9]*)" }}
                  />
                </div>
              )}
              <div className="btn_container">
                <Button
                  type="submit"
                  className="green outline"
                  onClick={onClose}
                >
                  {t("popinAnnulation.retour")}
                </Button>
                <Button
                  type="submit"
                  className="green"
                  disabled={
                    listValid.length === 0
                      ? true
                      : confirmationDisabled
                      ? true
                      : false
                  }
                  onClick={() => {
                    trueOrFalseDo(
                      selectedMotif,
                      () => {
                        handleConfirmation();
                      },
                      () => {
                        return;
                      }
                    );
                  }}
                >
                  {t("popinAnnulation.confirm")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopinAnnuleVente;
