import { InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import "./styles/smallTextInput.scss";

const SmallTextInput = ({
  value,
  placeholder,
  error,
  name,
  onChange,
  autoComplete,
  className,
  percentage,
  margin = "normal",
  variant = "outlined",
  type = "number",
  max,
  min,
  required = false,
  ...props
}) => {
  const [val, setVal] = useState(0);

  const endAdornmentChose = (percentage) => {
    if (percentage) {
      return (
        <InputAdornment position="start">
          <span className="bold pourcentage">{"%"}</span>
        </InputAdornment>
      );
    }
    if (!percentage && !!value && value?.remuneration !== 0) {
      return (
        <InputAdornment position="start" className="endEuro">
          <span className="bold pourcentage">{"€"}</span>
        </InputAdornment>
      );
    }
  };

  return (
    <div className="input-small-number">
      <TextField
        className={`number textinput ${className}`}
        required={required}
        onChange={(e) => {
          if (e.target.value === "") {
            setVal(min < 0 ? "" : 0);
            onChange(min < 0 ? "" : 0);
            return;
          }

          const value = +e.target.value;
          if (value > max) {
            setVal(max);
            onChange(max);
          } else if (value < min) {
            setVal(min);
            onChange(min);
          } else {
            setVal(value);
            onChange(value);
          }
        }}
        // onBlur={(e) => {
        //   if (e.target.value === "") {
        //     setVal(min < 0 ? "" : 0);
        //     onChange(min < 0 ? "" : 0);
        //     return;
        //   }

        //   const value = +e.target.value;
        //   if (value > max) {
        //     setVal(Math.round(max));
        //     onChange(Math.round(max));
        //   } else if (value < min) {
        //     setVal(Math.round(min));
        //     onChange(Math.round(min));
        //   } else {
        //     setVal(value);
        //     onChange(value);
        //   }
        // }}
        placeholder={percentage === true ? "100" : "-€"}
        error={!!error}
        helperText={error?.message ?? ""}
        type={type}
        onKeyDown={(evt) =>
          ["e", "E", "+", ","].includes(evt.key) && evt.preventDefault()
        }
        name={"noAutoFill"}
        autoComplete={autoComplete}
        value={val || value || value === 0 ? value : 0 || ""}
        InputProps={{
          endAdornment: endAdornmentChose(percentage),
          inputProps: {
            max: max,
            min: min,
            pattern: "([^0-9]*)",
            onWheel: (e) => e.target.blur(),
          },
        }}
        {...props}
      />
    </div>
  );
};

export default SmallTextInput;
