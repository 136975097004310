export const dataGeo = [
  {
    nomDpt: "Ain",
    codeDpt: "01",
    nomRegion: "Auvergne-Rhône-Alpes",
    codeRegion: "84",
  },
  {
    nomDpt: "Aisne",
    codeDpt: "02",
    nomRegion: "Hauts-de-France",
    codeRegion: "32",
  },
  {
    nomDpt: "Allier",
    codeDpt: "03",
    nomRegion: "Auvergne-Rhône-Alpes",
    codeRegion: "84",
  },
  {
    nomDpt: "Alpes-de-Haute-Provence",
    codeDpt: "04",
    nomRegion: "Provence-Alpes-Côte d'Azur",
    codeRegion: "93",
  },
  {
    nomDpt: "Hautes-Alpes",
    codeDpt: "05",
    nomRegion: "Provence-Alpes-Côte d'Azur",
    codeRegion: "93",
  },
  {
    nomDpt: "Alpes-Maritimes",
    codeDpt: "06",
    nomRegion: "Provence-Alpes-Côte d'Azur",
    codeRegion: "93",
  },
  {
    nomDpt: "Ardèche",
    codeDpt: "07",
    nomRegion: "Auvergne-Rhône-Alpes",
    codeRegion: "84",
  },
  {
    nomDpt: "Ardennes",
    codeDpt: "08",
    nomRegion: "Grand Est",
    codeRegion: "44",
  },
  { nomDpt: "Ariège", codeDpt: "09", nomRegion: "Occitanie", codeRegion: "76" },
  { nomDpt: "Aube", codeDpt: "10", nomRegion: "Grand Est", codeRegion: "44" },
  { nomDpt: "Aude", codeDpt: "11", nomRegion: "Occitanie", codeRegion: "76" },
  {
    nomDpt: "Aveyron",
    codeDpt: "12",
    nomRegion: "Occitanie",
    codeRegion: "76",
  },
  {
    nomDpt: "Bouches-du-Rhône",
    codeDpt: "13",
    nomRegion: "Provence-Alpes-Côte d'Azur",
    codeRegion: "93",
  },
  {
    nomDpt: "Calvados",
    codeDpt: "14",
    nomRegion: "Normandie",
    codeRegion: "28",
  },
  {
    nomDpt: "Cantal",
    codeDpt: "15",
    nomRegion: "Auvergne-Rhône-Alpes",
    codeRegion: "84",
  },
  {
    nomDpt: "Charente",
    codeDpt: "16",
    nomRegion: "Nouvelle-Aquitaine",
    codeRegion: "75",
  },
  {
    nomDpt: "Charente-Maritime",
    codeDpt: "17",
    nomRegion: "Nouvelle-Aquitaine",
    codeRegion: "75",
  },
  {
    nomDpt: "Cher",
    codeDpt: "18",
    nomRegion: "Centre-Val de Loire",
    codeRegion: "24",
  },
  {
    nomDpt: "Corrèze",
    codeDpt: "19",
    nomRegion: "Nouvelle-Aquitaine",
    codeRegion: "75",
  },
  {
    nomDpt: "Côte-d'Or",
    codeDpt: "21",
    nomRegion: "Bourgogne-Franche-Comté",
    codeRegion: "27",
  },
  {
    nomDpt: "Côtes-d'Armor",
    codeDpt: "22",
    nomRegion: "Bretagne",
    codeRegion: "53",
  },
  {
    nomDpt: "Creuse",
    codeDpt: "23",
    nomRegion: "Nouvelle-Aquitaine",
    codeRegion: "75",
  },
  {
    nomDpt: "Dordogne",
    codeDpt: "24",
    nomRegion: "Nouvelle-Aquitaine",
    codeRegion: "75",
  },
  {
    nomDpt: "Doubs",
    codeDpt: "25",
    nomRegion: "Bourgogne-Franche-Comté",
    codeRegion: "27",
  },
  {
    nomDpt: "Drôme",
    codeDpt: "26",
    nomRegion: "Auvergne-Rhône-Alpes",
    codeRegion: "84",
  },
  { nomDpt: "Eure", codeDpt: "27", nomRegion: "Normandie", codeRegion: "28" },
  {
    nomDpt: "Eure-et-Loir",
    codeDpt: "28",
    nomRegion: "Centre-Val de Loire",
    codeRegion: "24",
  },
  {
    nomDpt: "Finistère",
    codeDpt: "29",
    nomRegion: "Bretagne",
    codeRegion: "53",
  },
  {
    nomDpt: "Corse-du-Sud",
    codeDpt: "2A",
    nomRegion: "Corse",
    codeRegion: "94",
  },
  {
    nomDpt: "Haute-Corse",
    codeDpt: "2B",
    nomRegion: "Corse",
    codeRegion: "94",
  },
  { nomDpt: "Gard", codeDpt: "30", nomRegion: "Occitanie", codeRegion: "76" },
  {
    nomDpt: "Haute-Garonne",
    codeDpt: "31",
    nomRegion: "Occitanie",
    codeRegion: "76",
  },
  { nomDpt: "Gers", codeDpt: "32", nomRegion: "Occitanie", codeRegion: "76" },
  {
    nomDpt: "Gironde",
    codeDpt: "33",
    nomRegion: "Nouvelle-Aquitaine",
    codeRegion: "75",
  },
  {
    nomDpt: "Hérault",
    codeDpt: "34",
    nomRegion: "Occitanie",
    codeRegion: "76",
  },
  {
    nomDpt: "Ille-et-Vilaine",
    codeDpt: "35",
    nomRegion: "Bretagne",
    codeRegion: "53",
  },
  {
    nomDpt: "Indre",
    codeDpt: "36",
    nomRegion: "Centre-Val de Loire",
    codeRegion: "24",
  },
  {
    nomDpt: "Indre-et-Loire",
    codeDpt: "37",
    nomRegion: "Centre-Val de Loire",
    codeRegion: "24",
  },
  {
    nomDpt: "Isère",
    codeDpt: "38",
    nomRegion: "Auvergne-Rhône-Alpes",
    codeRegion: "84",
  },
  {
    nomDpt: "Jura",
    codeDpt: "39",
    nomRegion: "Bourgogne-Franche-Comté",
    codeRegion: "27",
  },
  {
    nomDpt: "Landes",
    codeDpt: "40",
    nomRegion: "Nouvelle-Aquitaine",
    codeRegion: "75",
  },
  {
    nomDpt: "Loir-et-Cher",
    codeDpt: "41",
    nomRegion: "Centre-Val de Loire",
    codeRegion: "24",
  },
  {
    nomDpt: "Loire",
    codeDpt: "42",
    nomRegion: "Auvergne-Rhône-Alpes",
    codeRegion: "84",
  },
  {
    nomDpt: "Haute-Loire",
    codeDpt: "43",
    nomRegion: "Auvergne-Rhône-Alpes",
    codeRegion: "84",
  },
  {
    nomDpt: "Loire-Atlantique",
    codeDpt: "44",
    nomRegion: "Pays de la Loire",
    codeRegion: "52",
  },
  {
    nomDpt: "Loiret",
    codeDpt: "45",
    nomRegion: "Centre-Val de Loire",
    codeRegion: "24",
  },
  { nomDpt: "Lot", codeDpt: "46", nomRegion: "Occitanie", codeRegion: "76" },
  {
    nomDpt: "Lot-et-Garonne",
    codeDpt: "47",
    nomRegion: "Nouvelle-Aquitaine",
    codeRegion: "75",
  },
  { nomDpt: "Lozère", codeDpt: "48", nomRegion: "Occitanie", codeRegion: "76" },
  {
    nomDpt: "Maine-et-Loire",
    codeDpt: "49",
    nomRegion: "Pays de la Loire",
    codeRegion: "52",
  },
  { nomDpt: "Manche", codeDpt: "50", nomRegion: "Normandie", codeRegion: "28" },
  { nomDpt: "Marne", codeDpt: "51", nomRegion: "Grand Est", codeRegion: "44" },
  {
    nomDpt: "Haute-Marne",
    codeDpt: "52",
    nomRegion: "Grand Est",
    codeRegion: "44",
  },
  {
    nomDpt: "Mayenne",
    codeDpt: "53",
    nomRegion: "Pays de la Loire",
    codeRegion: "52",
  },
  {
    nomDpt: "Meurthe-et-Moselle",
    codeDpt: "54",
    nomRegion: "Grand Est",
    codeRegion: "44",
  },
  { nomDpt: "Meuse", codeDpt: "55", nomRegion: "Grand Est", codeRegion: "44" },
  {
    nomDpt: "Morbihan",
    codeDpt: "56",
    nomRegion: "Bretagne",
    codeRegion: "53",
  },
  {
    nomDpt: "Moselle",
    codeDpt: "57",
    nomRegion: "Grand Est",
    codeRegion: "44",
  },
  {
    nomDpt: "Nièvre",
    codeDpt: "58",
    nomRegion: "Bourgogne-Franche-Comté",
    codeRegion: "27",
  },
  {
    nomDpt: "Nord",
    codeDpt: "59",
    nomRegion: "Hauts-de-France",
    codeRegion: "32",
  },
  {
    nomDpt: "Oise",
    codeDpt: "60",
    nomRegion: "Hauts-de-France",
    codeRegion: "32",
  },
  { nomDpt: "Orne", codeDpt: "61", nomRegion: "Normandie", codeRegion: "28" },
  {
    nomDpt: "Pas-de-Calais",
    codeDpt: "62",
    nomRegion: "Hauts-de-France",
    codeRegion: "32",
  },
  {
    nomDpt: "Puy-de-Dôme",
    codeDpt: "63",
    nomRegion: "Auvergne-Rhône-Alpes",
    codeRegion: "84",
  },
  {
    nomDpt: "Pyrénées-Atlantiques",
    codeDpt: "64",
    nomRegion: "Nouvelle-Aquitaine",
    codeRegion: "75",
  },
  {
    nomDpt: "Hautes-Pyrénées",
    codeDpt: "65",
    nomRegion: "Occitanie",
    codeRegion: "76",
  },
  {
    nomDpt: "Pyrénées-Orientales",
    codeDpt: "66",
    nomRegion: "Occitanie",
    codeRegion: "76",
  },
  {
    nomDpt: "Bas-Rhin",
    codeDpt: "67",
    nomRegion: "Grand Est",
    codeRegion: "44",
  },
  {
    nomDpt: "Haut-Rhin",
    codeDpt: "68",
    nomRegion: "Grand Est",
    codeRegion: "44",
  },
  {
    nomDpt: "Rhône",
    codeDpt: "69",
    nomRegion: "Auvergne-Rhône-Alpes",
    codeRegion: "84",
  },
  {
    nomDpt: "Haute-Saône",
    codeDpt: "70",
    nomRegion: "Bourgogne-Franche-Comté",
    codeRegion: "27",
  },
  {
    nomDpt: "Saône-et-Loire",
    codeDpt: "71",
    nomRegion: "Bourgogne-Franche-Comté",
    codeRegion: "27",
  },
  {
    nomDpt: "Sarthe",
    codeDpt: "72",
    nomRegion: "Pays de la Loire",
    codeRegion: "52",
  },
  {
    nomDpt: "Savoie",
    codeDpt: "73",
    nomRegion: "Auvergne-Rhône-Alpes",
    codeRegion: "84",
  },
  {
    nomDpt: "Haute-Savoie",
    codeDpt: "74",
    nomRegion: "Auvergne-Rhône-Alpes",
    codeRegion: "84",
  },
  {
    nomDpt: "Paris",
    codeDpt: "75",
    nomRegion: "Île-de-France",
    codeRegion: "11",
  },
  {
    nomDpt: "Seine-Maritime",
    codeDpt: "76",
    nomRegion: "Normandie",
    codeRegion: "28",
  },
  {
    nomDpt: "Seine-et-Marne",
    codeDpt: "77",
    nomRegion: "Île-de-France",
    codeRegion: "11",
  },
  {
    nomDpt: "Yvelines",
    codeDpt: "78",
    nomRegion: "Île-de-France",
    codeRegion: "11",
  },
  {
    nomDpt: "Deux-Sèvres",
    codeDpt: "79",
    nomRegion: "Nouvelle-Aquitaine",
    codeRegion: "75",
  },
  {
    nomDpt: "Somme",
    codeDpt: "80",
    nomRegion: "Hauts-de-France",
    codeRegion: "32",
  },
  { nomDpt: "Tarn", codeDpt: "81", nomRegion: "Occitanie", codeRegion: "76" },
  {
    nomDpt: "Tarn-et-Garonne",
    codeDpt: "82",
    nomRegion: "Occitanie",
    codeRegion: "76",
  },
  {
    nomDpt: "Var",
    codeDpt: "83",
    nomRegion: "Provence-Alpes-Côte d'Azur",
    codeRegion: "93",
  },
  {
    nomDpt: "Vaucluse",
    codeDpt: "84",
    nomRegion: "Provence-Alpes-Côte d'Azur",
    codeRegion: "93",
  },
  {
    nomDpt: "Vendée",
    codeDpt: "85",
    nomRegion: "Pays de la Loire",
    codeRegion: "52",
  },
  {
    nomDpt: "Vienne",
    codeDpt: "86",
    nomRegion: "Nouvelle-Aquitaine",
    codeRegion: "75",
  },
  {
    nomDpt: "Haute-Vienne",
    codeDpt: "87",
    nomRegion: "Nouvelle-Aquitaine",
    codeRegion: "75",
  },
  { nomDpt: "Vosges", codeDpt: "88", nomRegion: "Grand Est", codeRegion: "44" },
  {
    nomDpt: "Yonne",
    codeDpt: "89",
    nomRegion: "Bourgogne-Franche-Comté",
    codeRegion: "27",
  },
  {
    nomDpt: "Territoire de Belfort",
    codeDpt: "90",
    nomRegion: "Bourgogne-Franche-Comté",
    codeRegion: "27",
  },
  {
    nomDpt: "Essonne",
    codeDpt: "91",
    nomRegion: "Île-de-France",
    codeRegion: "11",
  },
  {
    nomDpt: "Hauts-de-Seine",
    codeDpt: "92",
    nomRegion: "Île-de-France",
    codeRegion: "11",
  },
  {
    nomDpt: "Seine-Saint-Denis",
    codeDpt: "93",
    nomRegion: "Île-de-France",
    codeRegion: "11",
  },
  {
    nomDpt: "Val-de-Marne",
    codeDpt: "94",
    nomRegion: "Île-de-France",
    codeRegion: "11",
  },
  {
    nomDpt: "Val-d'Oise",
    codeDpt: "95",
    nomRegion: "Île-de-France",
    codeRegion: "11",
  },
  {
    nomDpt: "Guadeloupe",
    codeDpt: "971",
    nomRegion: "Guadeloupe",
    codeRegion: "01",
  },
  {
    nomDpt: "Martinique",
    codeDpt: "972",
    nomRegion: "Martinique",
    codeRegion: "02",
  },
  { nomDpt: "Guyane", codeDpt: "973", nomRegion: "Guyane", codeRegion: "03" },
  {
    nomDpt: "La Réunion",
    codeDpt: "974",
    nomRegion: "La Réunion",
    codeRegion: "04",
  },
  { nomDpt: "Mayotte", codeDpt: "976", nomRegion: "Mayotte", codeRegion: "06" },
];

export const departementsData = [
  { nomDpt: "Ain", codeDpt: "01", codeRegion: "84" },
  { nomDpt: "Aisne", codeDpt: "02", codeRegion: "32" },
  { nomDpt: "Allier", codeDpt: "03", codeRegion: "84" },
  { nomDpt: "Alpes-de-Haute-Provence", codeDpt: "04", codeRegion: "93" },
  { nomDpt: "Hautes-Alpes", codeDpt: "05", codeRegion: "93" },
  { nomDpt: "Alpes-Maritimes", codeDpt: "06", codeRegion: "93" },
  { nomDpt: "Ardèche", codeDpt: "07", codeRegion: "84" },
  { nomDpt: "Ardennes", codeDpt: "08", codeRegion: "44" },
  { nomDpt: "Ariège", codeDpt: "09", codeRegion: "76" },
  { nomDpt: "Aube", codeDpt: "10", codeRegion: "44" },
  { nomDpt: "Aude", codeDpt: "11", codeRegion: "76" },
  { nomDpt: "Aveyron", codeDpt: "12", codeRegion: "76" },
  { nomDpt: "Bouches-du-Rhône", codeDpt: "13", codeRegion: "93" },
  { nomDpt: "Calvados", codeDpt: "14", codeRegion: "28" },
  { nomDpt: "Cantal", codeDpt: "15", codeRegion: "84" },
  { nomDpt: "Charente", codeDpt: "16", codeRegion: "75" },
  { nomDpt: "Charente-Maritime", codeDpt: "17", codeRegion: "75" },
  { nomDpt: "Cher", codeDpt: "18", codeRegion: "24" },
  { nomDpt: "Corrèze", codeDpt: "19", codeRegion: "75" },
  { nomDpt: "Côte-d'Or", codeDpt: "21", codeRegion: "27" },
  { nomDpt: "Côtes-d'Armor", codeDpt: "22", codeRegion: "53" },
  { nomDpt: "Creuse", codeDpt: "23", codeRegion: "75" },
  { nomDpt: "Dordogne", codeDpt: "24", codeRegion: "75" },
  { nomDpt: "Doubs", codeDpt: "25", codeRegion: "27" },
  { nomDpt: "Drôme", codeDpt: "26", codeRegion: "84" },
  { nomDpt: "Eure", codeDpt: "27", codeRegion: "28" },
  { nomDpt: "Eure-et-Loir", codeDpt: "28", codeRegion: "24" },
  { nomDpt: "Finistère", codeDpt: "29", codeRegion: "53" },
  { nomDpt: "Corse-du-Sud", codeDpt: "2A", codeRegion: "94" },
  { nomDpt: "Haute-Corse", codeDpt: "2B", codeRegion: "94" },
  { nomDpt: "Gard", codeDpt: "30", codeRegion: "76" },
  { nomDpt: "Haute-Garonne", codeDpt: "31", codeRegion: "76" },
  { nomDpt: "Gers", codeDpt: "32", codeRegion: "76" },
  { nomDpt: "Gironde", codeDpt: "33", codeRegion: "75" },
  { nomDpt: "Hérault", codeDpt: "34", codeRegion: "76" },
  { nomDpt: "Ille-et-Vilaine", codeDpt: "35", codeRegion: "53" },
  { nomDpt: "Indre", codeDpt: "36", codeRegion: "24" },
  { nomDpt: "Indre-et-Loire", codeDpt: "37", codeRegion: "24" },
  { nomDpt: "Isère", codeDpt: "38", codeRegion: "84" },
  { nomDpt: "Jura", codeDpt: "39", codeRegion: "27" },
  { nomDpt: "Landes", codeDpt: "40", codeRegion: "75" },
  { nomDpt: "Loir-et-Cher", codeDpt: "41", codeRegion: "24" },
  { nomDpt: "Loire", codeDpt: "42", codeRegion: "84" },
  { nomDpt: "Haute-Loire", codeDpt: "43", codeRegion: "84" },
  { nomDpt: "Loire-Atlantique", codeDpt: "44", codeRegion: "52" },
  { nomDpt: "Loiret", codeDpt: "45", codeRegion: "24" },
  { nomDpt: "Lot", codeDpt: "46", codeRegion: "76" },
  { nomDpt: "Lot-et-Garonne", codeDpt: "47", codeRegion: "75" },
  { nomDpt: "Lozère", codeDpt: "48", codeRegion: "76" },
  { nomDpt: "Maine-et-Loire", codeDpt: "49", codeRegion: "52" },
  { nomDpt: "Manche", codeDpt: "50", codeRegion: "28" },
  { nomDpt: "Marne", codeDpt: "51", codeRegion: "44" },
  { nomDpt: "Haute-Marne", codeDpt: "52", codeRegion: "44" },
  { nomDpt: "Mayenne", codeDpt: "53", codeRegion: "52" },
  { nomDpt: "Meurthe-et-Moselle", codeDpt: "54", codeRegion: "44" },
  { nomDpt: "Meuse", codeDpt: "55", codeRegion: "44" },
  { nomDpt: "Morbihan", codeDpt: "56", codeRegion: "53" },
  { nomDpt: "Moselle", codeDpt: "57", codeRegion: "44" },
  { nomDpt: "Nièvre", codeDpt: "58", codeRegion: "27" },
  { nomDpt: "Nord", codeDpt: "59", codeRegion: "32" },
  { nomDpt: "Oise", codeDpt: "60", codeRegion: "32" },
  { nomDpt: "Orne", codeDpt: "61", codeRegion: "28" },
  { nomDpt: "Pas-de-Calais", codeDpt: "62", codeRegion: "32" },
  { nomDpt: "Puy-de-Dôme", codeDpt: "63", codeRegion: "84" },
  { nomDpt: "Pyrénées-Atlantiques", codeDpt: "64", codeRegion: "75" },
  { nomDpt: "Hautes-Pyrénées", codeDpt: "65", codeRegion: "76" },
  { nomDpt: "Pyrénées-Orientales", codeDpt: "66", codeRegion: "76" },
  { nomDpt: "Bas-Rhin", codeDpt: "67", codeRegion: "44" },
  { nomDpt: "Haut-Rhin", codeDpt: "68", codeRegion: "44" },
  { nomDpt: "Rhône", codeDpt: "69", codeRegion: "84" },
  { nomDpt: "Haute-Saône", codeDpt: "70", codeRegion: "27" },
  { nomDpt: "Saône-et-Loire", codeDpt: "71", codeRegion: "27" },
  { nomDpt: "Sarthe", codeDpt: "72", codeRegion: "52" },
  { nomDpt: "Savoie", codeDpt: "73", codeRegion: "84" },
  { nomDpt: "Haute-Savoie", codeDpt: "74", codeRegion: "84" },
  { nomDpt: "Paris", codeDpt: "75", codeRegion: "11" },
  { nomDpt: "Seine-Maritime", codeDpt: "76", codeRegion: "28" },
  { nomDpt: "Seine-et-Marne", codeDpt: "77", codeRegion: "11" },
  { nomDpt: "Yvelines", codeDpt: "78", codeRegion: "11" },
  { nomDpt: "Deux-Sèvres", codeDpt: "79", codeRegion: "75" },
  { nomDpt: "Somme", codeDpt: "80", codeRegion: "32" },
  { nomDpt: "Tarn", codeDpt: "81", codeRegion: "76" },
  { nomDpt: "Tarn-et-Garonne", codeDpt: "82", codeRegion: "76" },
  { nomDpt: "Var", codeDpt: "83", codeRegion: "93" },
  { nomDpt: "Vaucluse", codeDpt: "84", codeRegion: "93" },
  { nomDpt: "Vendée", codeDpt: "85", codeRegion: "52" },
  { nomDpt: "Vienne", codeDpt: "86", codeRegion: "75" },
  { nomDpt: "Haute-Vienne", codeDpt: "87", codeRegion: "75" },
  { nomDpt: "Vosges", codeDpt: "88", codeRegion: "44" },
  { nomDpt: "Yonne", codeDpt: "89", codeRegion: "27" },
  { nomDpt: "Territoire de Belfort", codeDpt: "90", codeRegion: "27" },
  { nomDpt: "Essonne", codeDpt: "91", codeRegion: "11" },
  { nomDpt: "Hauts-de-Seine", codeDpt: "92", codeRegion: "11" },
  { nomDpt: "Seine-Saint-Denis", codeDpt: "93", codeRegion: "11" },
  { nomDpt: "Val-de-Marne", codeDpt: "94", codeRegion: "11" },
  { nomDpt: "Val-d'Oise", codeDpt: "95", codeRegion: "11" },
  { nomDpt: "Guadeloupe", codeDpt: "971", codeRegion: "01" },
  { nomDpt: "Martinique", codeDpt: "972", codeRegion: "02" },
  { nomDpt: "Guyane", codeDpt: "973", codeRegion: "03" },
  { nomDpt: "La Réunion", codeDpt: "974", codeRegion: "04" },
  { nomDpt: "Mayotte", codeDpt: "976", codeRegion: "06" },
];

export const regionsData = [
  { nomRegion: "Île-de-France", codeRegion: "11" },
  { nomRegion: "Centre-Val de Loire", codeRegion: "24" },
  { nomRegion: "Bourgogne-Franche-Comté", codeRegion: "27" },
  { nomRegion: "Normandie", codeRegion: "28" },
  { nomRegion: "Hauts-de-France", codeRegion: "32" },
  { nomRegion: "Grand Est", codeRegion: "44" },
  { nomRegion: "Pays de la Loire", codeRegion: "52" },
  { nomRegion: "Bretagne", codeRegion: "53" },
  { nomRegion: "Nouvelle-Aquitaine", codeRegion: "75" },
  { nomRegion: "Occitanie", codeRegion: "76" },
  { nomRegion: "Auvergne-Rhône-Alpes", codeRegion: "84" },
  { nomRegion: "Provence-Alpes-Côte d'Azur", codeRegion: "93" },
  { nomRegion: "Corse", codeRegion: "94" },
  { nomRegion: "Guadeloupe", codeRegion: "01" },
  { nomRegion: "Martinique", codeRegion: "02" },
  { nomRegion: "Guyane", codeRegion: "03" },
  { nomRegion: "La Réunion", codeRegion: "04" },
  { nomRegion: "Mayotte", codeRegion: "06" },
];
