/**
 * @name sendNotif
 * @description Créer un object notif avec titre, description de l'action et les cibles pour la notif, show = true
 *
 * @returns {Object} date with days added
 */
export function sendNotif(action, res) {
  return {
    target: "notif",
    value: { action: action, res: res, show: true },
  };
}
