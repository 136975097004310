import _ from "lodash";
import { logoutRedirec } from "../../core/services/httpRequest/axios";
import {
  logout,
  sendMailPassWord,
} from "../../core/services/httpRequest/axios";

export const menuList = [
  {
    label: "Accueil",
    icon: "Home",
    link: "/accueil",
    authorizations: [
      "ADMIN_PRIMEO",
      "ADMIN_COURTAGE",
      "RESPONSABLE_VENDEUR",
      "VENDEUR",
    ],
  },
  {
    label: "Ventes",
    icon: "Vente",
    link: "/ventes",
    authorizations: [
      "ADMIN_PRIMEO",
      "ADMIN_COURTAGE",
      "RESPONSABLE_VENDEUR",
      "VENDEUR",
    ],
  },
  {
    label: "Utilisateurs",
    icon: "User",
    link: "/utilisateurs",
    authorizations: ["ADMIN_PRIMEO", "ADMIN_COURTAGE", "RESPONSABLE_VENDEUR"],
  },
  {
    label: "Sociétés",
    icon: "Societe",
    link: "/societes",
    authorizations: ["ADMIN_PRIMEO"],
  },
];

export const menuListAdmin = [
  { label: "Accueil", icon: "Home", link: "/accueil" },
  { label: "Ventes", icon: "Vente", link: "/ventes" },
  { label: "Prospect", icon: "Prospect", link: "/prospect" },
  { label: "Utilisateurs", icon: "User", link: "/utilisateurs" },
  { label: "Sociétés", icon: "Societe", link: "/societes" },
  { label: "Paramétrage", icon: "Settings", link: "/parametrage" },
];

export const menuListAdminCourtage = [
  { label: "Accueil", icon: "Home", link: "/accueil" },
  { label: "Ventes", icon: "Vente", link: "/ventes" },
  { label: "Prospect", icon: "Prospect", link: "/prospect" },
  { label: "Utilisateurs", icon: "User", link: "/utilisateurs" },
  { label: "Paramétrage", icon: "Settings", link: "/parametrage" },
];

export const menuListRespo = [
  { label: "Accueil", icon: "Home", link: "/accueil" },
  { label: "Ventes", icon: "Vente", link: "/ventes" },
  { label: "Utilisateurs", icon: "User", link: "/utilisateurs" },
  {
    label: "Paramétrage",
    icon: "Settings",
    link: "/parametrage",
    isPermissionRequired: true,
    requiredPermission: ["UPDATE_WORDING"],
  },
];

export const menuListVendeur = [
  { label: "Accueil", icon: "Home", link: "/accueil" },
  { label: "Ventes", icon: "Vente", link: "/ventes" },
  {
    label: "Paramétrage",
    icon: "Settings",
    link: "/parametrage",
    isPermissionRequired: true,
    requiredPermission: ["UPDATE_WORDING"],
  },
];

export const menuListChargeClient = [
  { label: "Accueil", icon: "Home", link: "/accueil" },
  { label: "Ventes", icon: "Vente", link: "/ventes" },
  { label: "Utilisateurs", icon: "User", link: "/utilisateurs" },
];

export const menuUser = [
  {
    label: "Changer de mot de passe",
    icon: "mdp",
    link: "/password/mail-sent",
    action: (pass) => sendMailPassWord(pass),
  },
  {
    label: "Déconnexion",
    icon: "deco",
    action: () => {
      logout();
      logoutRedirec();
    },
  },
];

export const puissanceFilter = () => {
  const number = 36;
  let puissanceList = [];

  _.range(number).map((_n, i) =>
    puissanceList.push({ name: `${i + 1}kVA`, value: i + 1 })
  );

  return puissanceList;
};

export const ENUM_PUISSANCES = [
  { number: 1, enum: "KVA_01" },
  { number: 2, enum: "KVA_02" },
  { number: 3, enum: "KVA_03" },
  { number: 4, enum: "KVA_04" },
  { number: 5, enum: "KVA_05" },
  { number: 6, enum: "KVA_06" },
  { number: 7, enum: "KVA_07" },
  { number: 8, enum: "KVA_08" },
  { number: 9, enum: "KVA_09" },
  { number: 10, enum: "KVA_10" },
  { number: 11, enum: "KVA_11" },
  { number: 12, enum: "KVA_12" },
  { number: 13, enum: "KVA_13" },
  { number: 14, enum: "KVA_14" },
  { number: 15, enum: "KVA_15" },
  { number: 16, enum: "KVA_16" },
  { number: 17, enum: "KVA_17" },
  { number: 18, enum: "KVA_18" },
  { number: 19, enum: "KVA_19" },
  { number: 20, enum: "KVA_20" },
  { number: 21, enum: "KVA_21" },
  { number: 22, enum: "KVA_22" },
  { number: 23, enum: "KVA_23" },
  { number: 24, enum: "KVA_24" },
  { number: 25, enum: "KVA_25" },
  { number: 26, enum: "KVA_26" },
  { number: 27, enum: "KVA_27" },
  { number: 28, enum: "KVA_28" },
  { number: 29, enum: "KVA_29" },
  { number: 30, enum: "KVA_30" },
  { number: 31, enum: "KVA_31" },
  { number: 32, enum: "KVA_32" },
  { number: 33, enum: "KVA_33" },
  { number: 34, enum: "KVA_34" },
  { number: 35, enum: "KVA_35" },
  { number: 36, enum: "KVA_36" },
];

export const puissanceObject = () => {
  return ENUM_PUISSANCES?.map((e) => {
    return { name: `${e.number} kVA`, value: e.enum };
  });
};


export const listProfilWhenAdminPrimeo = [
  {
    value: "ADMIN_PRIMEO",
    name: "Admin Primeo",
  },
  {
    value: "ADMIN_COURTAGE",
    name: "Admin courtage",
  },
  {
    value: "RESPONSABLE_VENDEUR",
    name: "Responsable vendeur",
  },
  {
    value: "VENDEUR",
    name: "Vendeur",
  },
];

export const listProfilWhenAdminCourtage = [
  {
    value: "ADMIN_COURTAGE",
    name: "Admin courtage",
  },
  {
    value: "RESPONSABLE_VENDEUR",
    name: "Responsable vendeur",
  },
  {
    value: "VENDEUR",
    name: "Vendeur",
  },
];

export const listProfilWhenResponsable = [
  {
    value: "RESPONSABLE_VENDEUR",
    name: "Responsable vendeur",
  },
  {
    value: "VENDEUR",
    name: "Vendeur",
  },
];

export const listProfilWhenVendeur = [
  {
    value: "VENDEUR",
    name: "Vendeur",
  },
];
