/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import Bloc from "../Bloc/Bloc";
import { useTranslation } from "react-i18next";
import Lien from "../Lien/Lien";
import _ from "lodash";

import "./styles/offres.scss";

// Store
import { useDispatch, useSelector } from "react-redux";
import * as saleActions from "../../core/stores/saleStore";
import OfferChoice from "../TCO/Offer/Offer";
import Tarification from "../TCO/Offer/Tarification";
import Option from "../TCO/Offer/Option";
import Remise from "../TCO/Offer/Remise";
import { postMonthlyPayment } from "../../core/services/httpRequest/axios";
import { replacePointByCommaInDecimals } from "../../utils";
import Voltalis from "../TCO/Offer/Voltalis";

const OfferBloc = ({ open, setOpen }) => {
   const { t } = useTranslation();
   const dispatch = useDispatch();

   const allParams = useSelector((state) => state.params);
   const sale = useSelector((state) => state.sale);
   const [selectedOptions, setSelectedOptions] = useState([]);
   const [checkOffres, setCheckOffres] = useState(false);
   const [conso, setConso] = useState(0);
   const [loading, setLoading] = useState(false);

   // init du formulaire
   const {
      getValues,
      setValue,
      watch,
      formState: { errors },
      control,
   } = useFormContext();

   function isOfferComplete() {
      if (
         !!getValues("currentOffer.selectOffer") &&
         !!getValues("currentOffer.selectTarif") &&
         errors?.currentOffer?.selectOffer === undefined &&
         errors?.currentOffer?.selectTarif === undefined
      ) {
         return setCheckOffres(true);
      } else {
         return setCheckOffres(false);
      }
   }

   // function info from STORE
   function setOptionsStore() {
      sale.optionStorage.map((option) => {
         return setValue(
            `currentOffer.selectOptions.${option?.productSpecification?.name}`,
            option
         );
      });
   }

   useEffect(() => {
      //  HERE pour les option hecked au resrash
      if (sale?.currentOffer?.offreCheck) {
         setOptionsStore();
      }
      setConso(sale?.PDLInfos?.estimatedCar?.carBase?.value);
   }, [sale]);

   // Update mensualite if change with recap
   // useEffect(() => {
   //    if (sale?.monthlyAmountDetailed) {
   //       setOffreInfo(replacePointByCommaInDecimals((sale?.monthlyAmountDetailed?.monthlyAmount / 100)));
   //    }
   // }, [sale.monthlyAmountDetailed]);

   // Update store
   useEffect(() => {
      let tmp = _.compact(_.uniq(selectedOptions));
      let res = [...tmp, getValues("currentOffer.selectOptions")];
      setSelectedOptions(res);

      const offre = {
         selectOffer: getValues("currentOffer.selectOffer"),
         selectTarif: getValues("currentOffer.selectTarif"),
         options: sale.optionStorage,
         offreCheck: checkOffres,
      };

      dispatch(saleActions.update({ target: "currentOffer", value: offre }));
      isOfferComplete();

      // =>

      if (
         !!getValues("currentOffer.selectTarif") &&
         !!getValues("currentOffer.selectOffer")
      ) {
         let servicesNames = sale.optionStorage?.map((s) => s.name);
         let data = {
            subscriptionPlanName: getValues("currentOffer.selectOffer"),
            pricingOptionName: getValues("currentOffer.selectTarif"),
            servicesNames: servicesNames,
            deliveryPoint: sale?.PDLInfos,
         };

         if (conso > 0) {
            setLoading(true);
            postMonthlyPayment(data)
               ?.then((res) => {
                  dispatch(
                     saleActions.update({
                        target: "monthlyAmountDetailed",
                        value: res?.data,
                     })
                  );
                  dispatch(
                     saleActions.update({
                        target: "monthlyAmount",
                        value: res?.data?.monthlyAmount,
                     })
                  );
                  dispatch(
                     saleActions.update({
                        target: "monthlyAmountLow",
                        value: res?.data?.monthlyAmountLow,
                     })
                  );
                  dispatch(
                     saleActions.update({
                        target: "monthlyAmountHigh",
                        value: res?.data?.monthlyAmountHigh,
                     })
                  );
               })
               ?.finally(() => setLoading(false));
         }
      }
   }, [
      watch("currentOffer.selectOffer"),
      watch("currentOffer.selectTarif"),
      watch("consoMode"),
      sale.optionStorage,
   ]);

   // value: (res?.data?.monthlyAmount / 100).toFixed(2),
   useEffect(() => {
      // console.log("checkOffre", checkOffres);
      const offre = {
         selectOffer: getValues("currentOffer.selectOffer"),
         selectTarif: getValues("currentOffer.selectTarif"),
         options: sale.optionStorage,
         offreCheck: checkOffres,
      };

      dispatch(saleActions.update({ target: "currentOffer", value: offre }));
   }, [checkOffres]);

   return (
      <Bloc
         icon="offre"
         title={t("souscription.offre.title")}
         setIsOpen={setOpen}
         open={open}
         checked={checkOffres}
         className="bloc_offer"
         info={
            !loading
               ? conso > 0 && sale?.monthlyAmountDetailed
                  ? checkOffres &&
                    replacePointByCommaInDecimals(
                       (
                          sale?.monthlyAmountDetailed?.monthlyAmount / 100
                       )?.toFixed(2)
                    ) + "€ TTC/mois"
                  : checkOffres && "--€ TTC/mois"
               : ""
         }
         note={
            allParams?.noteAdmin?.filter(
               (v) => v.word === "OFFER_AND_OPTIONS"
            )[0]?.value
         }
      >
         <Lien
            href={allParams?.links?.cgv}
            target="_blank"
            style={{ float: "right", marginTop: "10px" }}
         >
            {t("souscription.offre.cgvtitle")}
         </Lien>
         <Grid container spacing={3}>
            {allParams?.offers?.map((o) => (
               <OfferChoice offer={o} key={o?.name} />
            ))}

            <Remise
               offers={allParams?.offers}
               selectedOffer={getValues("currentOffer.selectOffer")}
            />

            <Tarification
               offers={allParams?.offers}
               selectedOffer={getValues("currentOffer.selectOffer")}
               monthlyAmountDetailed={sale?.monthlyAmountDetailed}
               loading={loading}
            />

            <Option
               offers={allParams?.offers}
               selectedOffer={getValues("currentOffer.selectOffer")}
            />

            <Voltalis
               onChange={(v) => {
                  dispatch(
                  saleActions.update({
                  target: "voltalis",
                  value: v,
               }))
            }}
            />


         </Grid>
      </Bloc>
   );
};

export default OfferBloc;
