import React from "react";

const Previous = ({ fill }) => {
   return (
      <svg
         width="8"
         height="9"
         viewBox="0 0 8 9"
         fill={fill}
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M1.34143 3.87282C0.697102 4.26173 0.697103 5.19618 1.34143 5.58509L6.02166 8.41005C6.68814 8.81233 7.53842 8.33239 7.53842 7.55392L7.53842 1.90399C7.53842 1.12552 6.68814 0.645583 6.02166 1.04786L1.34143 3.87282Z"
            fill={fill}
         />
      </svg>
   );
};

export default Previous;
