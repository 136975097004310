export function computeListRemunerationParProfil(
  selectedSociete,
  addsociete,
  authorizations,
  data
) {
  const res = [];
  authorizations.forEach((v, i) => {
    // if modify

    if (
      selectedSociete &&
      ((!!addsociete?.[`ratio-${v}`] && i > 0) ||
        !!addsociete?.modifSociete?.[`ratio-${v}`])
    ) {
      res.push(
        addsociete?.[`ratio-${v}`]
          ? { ...addsociete?.[`ratio-${v}`], idSociete: data?.idSociete }
          : {
              ...addsociete?.modifSociete?.[`ratio-${v}`],
              idSociete: data?.idSociete,
            }
      );
    }

    // if create
    if (!selectedSociete) {
      if (!!addsociete?.[`ratio-${v}`] && i > 0) {
        res.push(addsociete?.[`ratio-${v}`]);
      } else if (i > 0) {
        res.push({
          profil: v,
          ratio: 100,
        });
      }
    }
  });
  return res;
}
