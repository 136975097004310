import React from "react";
import { useTranslation } from "react-i18next";
import { replacePointByCommaInDecimals } from "../../utils";

// style
import "./radioButton.scss";

const RadioButton = ({
  item1,
  item2,
  item3,
  onChange,
  check = undefined,
  unit,
  name,
  pointByCommaInDecimals = false,
}) => {
  const { t } = useTranslation();

  const handleChange = (e, target) => {
    e.preventDefault();
    onChange(e.target.value, target);
  };

  let messageInfo;
  switch (check) {
    case 1:
      messageInfo = t("recap.messageItem1");
      break;
    case 2:
      messageInfo = t("recap.messageItem2");
      break;
    case 3:
      messageInfo = t("recap.messageItem3");
      break;
    default:
      break;
  }

  return (
    <div className="radioButton_container">
      <div className="radioButton_container_inputs">
        {/* item1 */}
        <label key={"item1"} className={`${check === 1 ? "checked" : ""}`}>
          <input
            id="item1"
            type="radio"
            value={item1}
            name={name}
            onClick={(e) => {
              handleChange(e, 1);
            }}
            // onChange={(e) => handleChange(e)}
          />
          <span className="radio-point" />
          <span>
            {pointByCommaInDecimals
              ? replacePointByCommaInDecimals(item1)
              : item1}

            {unit}
          </span>
        </label>
        {/* item2 */}
        <label key={"item2"} className={`${check === 2 ? "checked" : ""}`}>
          <input
            id="item2"
            type="radio"
            value={item2}
            name={name}
            onClick={(e) => {
              handleChange(e, 2);
            }}
            // onChange={(e) => handleChange(e)}
          />
          <span className="radio-point" />
          <span>
            {pointByCommaInDecimals
              ? replacePointByCommaInDecimals(item2)
              : item2}
            {unit}
          </span>
        </label>
        {/* item3 */}
        <label key={"item3"} className={`${check === 3 ? "checked" : ""}`}>
          <input
            id="item3"
            type="radio"
            value={item3}
            name={name}
            onClick={(e) => {
              handleChange(e, 3);
            }}
            // onChange={(e) => handleChange(e)}
          />
          <span className="radio-point" />
          <span>
            {pointByCommaInDecimals
              ? replacePointByCommaInDecimals(item3)
              : item3}
            {unit}
          </span>
        </label>
      </div>

      <span className="message-item">{messageInfo}</span>
    </div>
  );
};

export default RadioButton;
