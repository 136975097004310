import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import "./errorCodeListDisplay.scss";
import { analyseErrorCodes } from "../../utils/errorCodeListHandler";

const ErrorCodeListDisplay = ({ errors = [] }) => {
   const { t } = useTranslation();

   function translateError(err) {
      return t(`errorCodeList.${err}`);
   }

   function showErrors(errors) {
      return _.compact(errors)?.map((err) => {
         return (
            <p key={err} className="errorCode">
               {translateError(analyseErrorCodes(err))}
            </p>
         );
      });
   }

   return <div>{showErrors(errors)}</div>;
};

export default ErrorCodeListDisplay;
