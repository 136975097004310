import { Grid } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Previous } from "../../../core/assets/picto/arrow-left.svg";
import { ReactComponent as Next } from "../../../core/assets/picto/arrow-right.svg";
import {
   getUserDetails,
   getVente,
   putConfirmerVente,
   putVente,
   updateIdMetierVente,
} from "../../../core/services/httpRequest/axios";
import { update } from "../../../core/stores/paramsStore";
import { sendNotif } from "../../../core/stores/utils";
import {
   autoCompleteAlea,
   checkEnterKeyDown,
   checkIfDisplayAnnuleBtn,
   formatErrorEmail,
   formatErrorNames,
   formatErrorPhoneNumber,
   internationalMobilePhoneToRegular,
   regularMobilePhoneToInternational,
   replacePointByCommaInDecimals,
   sortDates,
} from "../../../utils";
import { ENUM_PUISSANCES } from "../../../utils/BOCO/enums";
import handleRequestResponse from "../../../utils/responsesHandler";
import AddressInput from "../../Address/AddressInput/AddressInput";
import Button from "../../Button/Button";
import AuthorizationLayout from "../../HOC/AuthorizationLayout/AuthorizationLayout";
import PermissionWrapper from "../../HOC/PermissionWrapper";
import Loader from "../../Loader/Loader";
import ActiveSvg from "../../SvgComponents/ActiveSvg";
import Cross from "../../SvgComponents/Cross";
import InactiveSvg from "../../SvgComponents/InactiveSvg";
import ModifySvg from "../../SvgComponents/ModifySvg";
import PhoneInput from "../../TextInput/PhoneInput";
import TextInput from "../../TextInput/TextInput";
import { cancelSale, confirmSale, uncancelSale, unconfirmSale, validateSale } from "./SaleStatusService";
import "./styles/popindetailvente.scss";
moment.locale("fr");

const PopindDetailVente = ({
   onClose,
   onSuccess,
   selectedVente,
   user,
   list,
   currentPage,
   onChange,
   numbersPage,
   size,
   annule,
}) => {
   const [selected, setselected] = useState(selectedVente);

   const [current, setcurrent] = useState({});
   const [way, setWay] = useState("NEXT");
   const prevSelected = usePrevious(selected);
   const [modify, setModify] = useState(false);
   const [open, setOpen] = useState(false);

   const [formatedAddress, setFormatedAddress] = useState({});
   const [loading, setLoading] = useState(false);
   const [vendeur, setVendeur] = useState();
   const [responsableVendeur, setResponsableVendeur] = useState();

   
   const [loadingVente, setLoadingVente] = useState(false);

   const broker = useSelector((state) => state.auth?.broker);

   const dispatch = useDispatch();
   const { t } = useTranslation();

   const handleClickOpen = () => {
      setOpen(true);
   };

   const handleClose = () => {
      setOpen(false);
   };
   function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
         ref.current = value;
      }, [value]);
      return ref.current;
   }

   const {
      setValue,
      handleSubmit,
      control,
      formState: { errors },
   } = useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      shouldFocusError: true,
      shouldUseNativeValidation: false,
   });

   // function formatFromGouvApiAddress(address) {
   //    return {
   //       street1: address?.housenumber,
   //       street2: address?.street,
   //       city: address?.city,
   //       country: "FRA",
   //       postCode: address?.zipCode,
   //    };
   // }

   // function formatFromBDDAdress(adress) {
   //    console.log("là", adress);
   //    return {
   //       label:
   //          adress?.street +
   //          " " +
   //          adress?.additionnalAddress +
   //          " " +
   //          adress?.city +
   //          " " +
   //          adress?.zipCode,
   //       housenumber: adress.street,
   //       street: adress.additionnalAddress,
   //       city: adress.city,
   //       postCode: adress.zipCode,
   //       type: !!adress.street ? "housenumber" : "street",
   //    };
   // }
   const updateIdMetier = async () => {
      try {
         updateIdMetierVente(current?.idVente, { idMetier: current?.idMetier });
         setOpen(false);
      } catch (error) {
         console.error('Error updating idMetier:', error);
      }
   };
   function getNext() {
      setModify(false);
      let index = list?.findIndex((v, i) => v.idVente === selected);
      let protect =
         currentPage + 1 < numbersPage ? currentPage + 1 : numbersPage;

      if (index + 1 > list?.length - 1) {
         if (currentPage + 1 === numbersPage) {
            return;
         }
         setWay("NEXT");
         return onChange(protect, size);
      } else {
         return setselected(list[index + 1].idVente);
      }
   }

   function getPrevious() {
      setModify(false);
      let index = list?.findIndex((v, i) => v.idVente === selected);
      let protect = currentPage - 1 === -1 ? 0 : currentPage - 1;
      if (index - 1 < 0) {
         setWay("PREVIOUS");
         return onChange(protect, size);
      } else {
         return setselected(list[index - 1].idVente);
      }
   }

   //   action with valide btn
   async function confirmerVente(params) {
      setLoadingVente(true);
      let query = {
         idVente: current.idVente,
         prenomClient: current.prenomClient,
         nomClient: current.nomClient,
         periodeRendezVous: current.periodeRendezVous,
         emailClient: current.email,
         jourRendezVous: current.jourRendezVous,
         telephone: regularMobilePhoneToInternational(
            "+33",
            "0",
            current.telephone
         ),
         adresseFacturation: {
            street1: current.adresseFacturation?.street1,
            street2: current.adresseFacturation?.street2,
            city: current.adresseFacturation?.city,
            country: "FR",
            postCode: current.adresseFacturation?.postCode,
         },
         modifie: true,
      };

      putVente(query).then((res) =>
         handleRequestResponse(
            res,
            () => {
               // confirmer la vente

               putConfirmerVente(params.idVente).then((response) =>
                  handleRequestResponse(
                     response,
                     () => {
                        setLoadingVente(false);
                        dispatch(
                           update(sendNotif("validContrat", params.idMetier))
                        );
                        onClose();
                        onSuccess();
                     },
                     () => {
                        dispatch(update(sendNotif(response.status)));
                        setLoadingVente(false);
                     }
                  )
               );
               onSuccess();
               onClose();
            },
            () => {
               setLoadingVente(false);
               dispatch(update(sendNotif(res.status)));
            }
         )
      );
   }

   // action with valid btn after modif
   async function submit(data) {
      setLoadingVente(true);
      let query = {
         idVente: current.idVente,
         prenomClient: data.prenomClient || current.prenomClient,
         nomClient: data.nomClient || current.prenomClient,
         periodeRendezVous: data.periodeRendezVous || current.periodeRendezVous,
         emailClient: data.email || current.email,
         jourRendezVous: current.jourRendezVous,
         telephone:
            regularMobilePhoneToInternational("+33", "0", data.telephone) ||
            regularMobilePhoneToInternational("+33", "0", current.telephone),
         adresseFacturation: {
            street1: data?.street1,
            hamlet: data.hamlet || current.adresseFacturation?.hamlet,
            additionnalAddress1: data.additionnalAddress1 || current.adresseFacturation?.additionnalAddress1,
            additionnalAddress2: data.additionnalAddress2 || current.adresseFacturation?.additionnalAddress2,
            city: data.city || current.adresseFacturation?.city,
            country: "FR",
            postCode: data.postCode || current.adresseFacturation?.postCode,
         },
         modifie: true,
      };

      let res = putVente(query).then((res) => {
         handleRequestResponse(
            res,
            () => {
               onSuccess().then(() => {
                  setModify(false)
                  setWay("NONE")
                  // let vente = list?.find((v, i) => v.idVente === selected);
                  // console.log(vente)
                  // setselected(vente.idVente)
                  setLoadingVente(false);
               })
            },
            () => {
               dispatch(update(sendNotif(res.status)));
               setLoadingVente(false);
            })
         return res
      }
      )

      if (!modify) {

         putConfirmerVente(res.data.idVente).then((response) =>
            handleRequestResponse(
               response,
               () => {
                  dispatch(
                     update(
                        sendNotif("validContrat", response.data.idMetier)
                     )
                  );
                  setLoadingVente(false);
                  onClose();
                  onSuccess();
               },
               () => {
                  setLoadingVente(false);
                  dispatch(update(sendNotif(response.status)));
               }
            )
         );

      }

   }

   useEffect(() => {
      setLoading(true);
      console.log(list)
      console.log("prevSelected",prevSelected)
      console.log("selected",selected)
      let target = list?.find((v) => v.idVente === selected);
      console.log(target)
      getVente(target.idVente).then((res) => {
         setLoading(false);
         setcurrent({
            ...target,
            ...res.data,
            gender:
               res.data.gender === "MALE"
                  ? t("detailvente.MALE")
                  : t("detailvente.FEMALE"),
            telephone: internationalMobilePhoneToRegular(
               "+33",
               "0",
               res.data.telephone
            ),
         });
         console.log(current)
         getUserDetails(target?.idVendeur).then((res) => {
            setVendeur(res?.data?.prenom + " " + res?.data?.nom);
            getUserDetails(res?.data?.idResponsable).then((res) => {
               setResponsableVendeur(res?.data?.prenom + " " + res?.data?.nom);
            })
         });
        
      });
   }, [selected, list]);

   useEffect(() => {
      if (!!prevSelected && way === "NEXT") {
         setselected(list[0]?.idVente);
      }
      if (!!prevSelected && way === "PREVIOUS") {
         setselected(list[list.length - 1]?.idVente);
      }
   }, [list]);

   useEffect(() => {
      console.log(current)
   }, [current])

   return (
      <div className="popin">
         <>
            <div className="page_content_detailvente">
               <form
                  onSubmit={handleSubmit(submit)}
                  onKeyDown={(e) => checkEnterKeyDown(e)}
                  noValidate
                  autoComplete="off"
               >
                  <input type="hidden" />
                  <div className="container" style={{ margin: "0 auto" }}>
                     <div className="cross" onClick={() => onClose()}>
                        <Cross fill="black" className="cross" />
                     </div>
                     <header className="popin_detailvente_header">
                        <p>{t("detailvente.title")}</p>
                        <div className="choice-management">
                           <div
                              className="round-blue"
                              onClick={() => getPrevious()}
                           >
                              <Previous className="left" />
                           </div>
                           <h2 className="title">{current?.idMetier}</h2>
                           <div
                              className="round-blue"
                              onClick={() => getNext()}
                           >
                              <Next className="right" />
                           </div>
                        </div>
                     </header>
                     {!loading && (
                        <div className="main_container_detailvente">
                           <Grid container spacing={1}>
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.motif")}</p>
                              </Grid>
                              <Grid item xs={7}>
                                 <div className="statut-date">
                                    {sortDates(current)[0]?.elt}
                                 </div>
                              </Grid>
                              <AuthorizationLayout
                                 current={user?.type}
                                 authorized={["ADMIN_PRIMEO"]}
                              >
                                 <Grid item xs={5} className="align">
                                    <p>{t("detailvente.idTripica")}</p>
                                 </Grid>
                                 <Grid item xs={7}>
                                    {current?.idVenteTripica}
                                 </Grid>
                              </AuthorizationLayout>
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.dateVente")}</p>
                              </Grid>
                              <Grid item xs={7}>
                                 <p>
                                    {moment(current?.dateCreationVente).format(
                                       "DD/MM/yyyy"
                                    )}
                                 </p>
                              </Grid>
                              {user?.type !== 'CHARGE_CLIENT' && <>
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.courtier")}</p>
                              </Grid>
                              <Grid item xs={7}>
                                 <p>{vendeur}</p>
                              </Grid>
                              
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.responsableVendeur")}</p>
                              </Grid>
                              <Grid item xs={7}>
                                 <p>{responsableVendeur}</p>
                              </Grid>
                              </>
                              }
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.offer")}</p>
                              </Grid>
                              <Grid item xs={7}>
                                 <p>{current?.nomOffre}</p>
                              </Grid>
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.opttarif")}</p>
                              </Grid>
                              <Grid item xs={7}>
                                 <p>{current?.optionTarif}</p>
                              </Grid>
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.PS")}</p>
                              </Grid>
                              <Grid item xs={7}>
                                 <p>
                                    {
                                       ENUM_PUISSANCES.find(
                                          (p) =>
                                             p.enum === current?.puissancePDL
                                       )?.number
                                    }{" "}
                                    kVA
                                 </p>
                              </Grid>
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.estimatedCar")}</p>
                              </Grid>
                              <Grid item xs={7}>
                                 <p>{current?.estimationCar} kWh</p>
                              </Grid>
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.refPDL")}</p>
                              </Grid>
                              <Grid item xs={7}>
                                 <p>{current?.pdl}</p>
                              </Grid>
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.phone")}</p>
                              </Grid>
                              {modify ? (
                                 <Grid item xs={7}>
                                    <Controller
                                       name="telephone"
                                       control={control}
                                       defaultValue={current?.telephone}
                                       rules={{
                                          required: t("error.required"),
                                          validate: {
                                             validation: (v) =>
                                                formatErrorPhoneNumber(
                                                   v,
                                                   broker?.phone
                                                ),
                                          },
                                       }}
                                       render={({
                                          field: { onChange, value, onBlur },
                                       }) => (
                                          <PhoneInput
                                             value={value}
                                             label={"Mobile"}
                                             required={true}
                                             onChange={onChange}
                                             error={
                                                errors.telephone !== undefined
                                             }
                                             helperText={
                                                errors.telephone?.message
                                             }
                                             onBlur={(e) => {
                                                onBlur(e);
                                             }}
                                             autoComplete={autoCompleteAlea()}
                                             maxLength={10}
                                          />
                                       )}
                                    />
                                 </Grid>
                              ) : (
                                 <Grid item xs={7}>
                                    <p>{current?.telephone}</p>
                                 </Grid>
                              )}
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.client")}</p>
                              </Grid>

                              {modify ? (
                                 <>
                                    <Grid item xs={3}>
                                       <Controller
                                          name="nomClient"
                                          control={control}
                                          rules={{
                                             required: t("error.required"),
                                             validate: formatErrorNames,
                                          }}
                                          defaultValue={current.nomClient}
                                          render={({
                                             field: { value, onChange, onBlur },
                                          }) => (
                                             <TextInput
                                                className="adduser-input-text"
                                                value={value}
                                                label={t(
                                                   "adduser.labelFamilyName"
                                                )}
                                                onChange={onChange}
                                                required
                                                error={
                                                   errors.nomClient !==
                                                   undefined
                                                }
                                                helperText={
                                                   errors.nomClient?.message
                                                }
                                                onBlur={(e) => {
                                                   onBlur(e);
                                                }}
                                                autoComplete={autoCompleteAlea()}
                                             />
                                          )}
                                       />
                                    </Grid>
                                    <Grid item xs={3}>
                                       <Controller
                                          name="prenomClient"
                                          control={control}
                                          rules={{
                                             required: t("error.required"),
                                             validate: formatErrorNames,
                                          }}
                                          defaultValue={current.prenomClient}
                                          render={({
                                             field: { value, onChange, onBlur },
                                          }) => (
                                             <TextInput
                                                value={value}
                                                label="Prénom"
                                                onChange={onChange}
                                                required
                                                error={
                                                   errors.prenomClient !==
                                                   undefined
                                                }
                                                helperText={
                                                   errors.prenomClient?.message
                                                }
                                                onBlur={(e) => {
                                                   onBlur(e);
                                                }}
                                                autoComplete={autoCompleteAlea()}
                                             />
                                          )}
                                       />
                                    </Grid>
                                 </>
                              ) : (
                                 <Grid item xs={7}>
                                    <p>
                                       {current?.gender +
                                          " " +
                                          current?.prenomClient +
                                          " " +
                                          current?.nomClient}
                                    </p>
                                 </Grid>
                              )}

                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.birthdate")}</p>
                              </Grid>
                              <Grid item xs={7}>
                                 <p>
                                    {moment(current?.dateNaiss * 1).format(
                                       "DD/MM/yyyy"
                                    )}
                                 </p>
                              </Grid>
                              <Grid item xs={5} className="align">
                                 {t("detailvente.email")}
                              </Grid>
                              {modify ? (
                                 <Grid item xs={7}>
                                    <Controller
                                       name="email"
                                       control={control}
                                       defaultValue={current?.email}
                                       rules={{
                                          required: t("error.required"),
                                          validate: formatErrorEmail,
                                       }}
                                       render={({
                                          field: { onChange, value, onBlur },
                                       }) => (
                                          <TextInput
                                             value={value}
                                             type="email"
                                             label={"E-mail"}
                                             onChange={onChange}
                                             required={true}
                                             error={errors.email !== undefined}
                                             helperText={errors.email?.message}
                                             onBlur={(e) => {
                                                onBlur(e);
                                             }}
                                             autoComplete={autoCompleteAlea()}
                                          />
                                       )}
                                    />
                                 </Grid>
                              ) : (
                                 <Grid item xs={7}>
                                    <p>{current?.email}</p>
                                 </Grid>
                              )}
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.modefacturation")}</p>
                              </Grid>
                              <Grid item xs={7}>
                                 {t(`detailvente.${current?.modeFcturation}`)}
                              </Grid>
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.pdlAddress")}</p>
                              </Grid>
                              <Grid item xs={7}>
                                 <p>
                                    {current?.adressePDL?.additionnalAddress ===
                                       undefined
                                       ? current?.adressePDL?.street +
                                       " " +
                                       current?.codePostalPDL +
                                       " " +
                                       current?.villePDL
                                       : current?.adressePDL?.street +
                                       " " +
                                       current?.adressePDL
                                          ?.additionnalAddress +
                                       " " +
                                       current?.codePostalPDL +
                                       " " +
                                       current?.villePDL}
                                 </p>
                              </Grid>
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.facturAddress")}</p>
                              </Grid>
                              {!modify && (
                                 <Grid item xs={7}>
                                    <p>
                                       {current?.adresseFacturation
                                          ?.street1 +
                                          " " +
                                          current?.adresseFacturation
                                             ?.postCode +
                                          " " +
                                          current?.adresseFacturation?.city
                                          + " " +
                                          (current?.adresseFacturation
                                             ?.hamlet ?? "") +
                                          " " +
                                          (current?.adresseFacturation
                                             ?.supplement1 ?? "") +
                                          " " +
                                          (current?.adresseFacturation
                                             ?.supplement2 ?? "")
                                       }
                                    </p>
                                 </Grid>
                              )}
                              {modify && (
                                 <Grid item xs={7}>
                                    <Controller
                                       control={control}
                                       name="adressePDL"
                                       render={({
                                          field: { value, onChange, onBlur },
                                       }) => {
                                          return (
                                             <AddressInput
                                                value={value}
                                                label={t(
                                                   "souscription.facturation.address"
                                                )}
                                                onChange={(v) => {
                                                   onChange(v);
                                                   setValue("street1", v?.name);
                                                   setValue("street2", "");
                                                   setValue(
                                                      "city",
                                                      !!v?.city ? v.city : ""
                                                   );
                                                   setValue(
                                                      "postCode",
                                                      !!v?.zipCode
                                                         ? v.zipCode
                                                         : ""
                                                   );
                                                }}
                                             />
                                          );
                                       }}
                                    />
                                    <Grid container spacing={1}>
                                       <Grid item xs={12}>
                                          <Controller
                                             name="street1"
                                             control={control}
                                             defaultValue={
                                                current?.adresseFacturation
                                                   ?.street1
                                             }
                                             render={({
                                                field: {
                                                   onBlur,
                                                   onChange,
                                                   value,
                                                },
                                             }) => {
                                                return (
                                                   <TextInput
                                                      style={{
                                                         margin: "3px 0",
                                                      }}
                                                      value={value}
                                                      onChange={(e) => {
                                                         setFormatedAddress({
                                                            ...formatedAddress,
                                                            street1:
                                                               e.target.value,
                                                         });
                                                         onChange(
                                                            e.target.value
                                                         );
                                                      }}
                                                      onBlur={onBlur}
                                                      error={errors?.street1}
                                                      placeholder={t(
                                                         "souscription.facturation.placeholderHousenumberAndStreet"
                                                      )}
                                                   />
                                                );
                                             }}
                                          />
                                       </Grid>

                                       <Grid item xs={12}>
                                          <Controller
                                             name="hamlet"
                                             rules={{
                                             }}
                                             control={control}
                                             defaultValue={
                                                current?.adresseFacturation
                                                   ?.hamlet === 'None' ? undefined : current?.adresseFacturation
                                                   ?.hamlet
                                             }
                                             render={({
                                                field: {
                                                   onBlur,
                                                   onChange,
                                                   value,
                                                },
                                             }) => (
                                                <TextInput
                                                   required
                                                   style={{
                                                      margin: "3px 0",
                                                   }}
                                                   value={value}
                                                   onChange={(e) => {
                                                      onChange(e.target.value);
                                                      setFormatedAddress({
                                                         ...formatedAddress,
                                                         street2:
                                                            e.target.value,
                                                      });
                                                   }}
                                                   onBlur={onBlur}
                                                   error={errors?.street2}
                                                   placeholder={t(
                                                      "souscription.facturation.placeholderHamlet"
                                                   )}
                                                />
                                             )}
                                          />
                                       </Grid>

                                       <Grid item xs={12}>
                                          <Controller
                                             name="additionnalAddress1"
                                             rules={{
                                             }}
                                             control={control}
                                             defaultValue={
                                                current?.adresseFacturation
                                                   ?.supplement1 === 'None' ? undefined : current?.adresseFacturation
                                                   ?.supplement1
                                             }
                                             render={({
                                                field: {
                                                   onBlur,
                                                   onChange,
                                                   value,
                                                },
                                             }) => (
                                                <TextInput
                                                   required
                                                   style={{
                                                      margin: "3px 0",
                                                   }}
                                                   value={value}
                                                   onChange={(e) => {
                                                      onChange(e.target.value);
                                                      setFormatedAddress({
                                                         ...formatedAddress,
                                                         additionnalAddress1:
                                                            e.target.value,
                                                      });
                                                   }}
                                                   onBlur={onBlur}
                                                   error={errors?.additionnalAddress1}
                                                   placeholder={t(
                                                      "souscription.facturation.placeholderAdditionnalAddress1"
                                                   )}
                                                />
                                             )}
                                          />
                                       </Grid>

                                       <Grid item xs={12}>
                                          <Controller
                                             name="additionnalAddress2"
                                             rules={{
                                             }}
                                             control={control}
                                             defaultValue={
                                                current?.adresseFacturation
                                                   ?.supplement2 === 'None' ? undefined : current?.adresseFacturation
                                                   ?.supplement2
                                             }
                                             render={({
                                                field: {
                                                   onBlur,
                                                   onChange,
                                                   value,
                                                },
                                             }) => (
                                                <TextInput
                                                   required
                                                   style={{
                                                      margin: "3px 0",
                                                   }}
                                                   value={value}
                                                   onChange={(e) => {
                                                      onChange(e.target.value);
                                                      setFormatedAddress({
                                                         ...formatedAddress,
                                                         additionnalAddress2:
                                                            e.target.value,
                                                      });
                                                   }}
                                                   onBlur={onBlur}
                                                   error={errors?.additionnalAddress1}
                                                   placeholder={t(
                                                      "souscription.facturation.placeholderAdditionnalAddress2"
                                                   )}
                                                />
                                             )}
                                          />
                                       </Grid>

                                       <Grid item xs={12} sm={4}>
                                          <Controller
                                             name="postCode"
                                             rules={{
                                                required: t("error.required"),
                                             }}
                                             control={control}
                                             defaultValue={
                                                current?.adresseFacturation
                                                   ?.postCode
                                             }
                                             render={({
                                                field: {
                                                   onBlur,
                                                   onChange,
                                                   value,
                                                },
                                             }) => (
                                                <TextInput
                                                   required
                                                   maxLength={5}
                                                   style={{
                                                      margin: "3px 0",
                                                   }}
                                                   value={value}
                                                   onChange={(e) => {
                                                      onChange(e.target.value);
                                                      setFormatedAddress({
                                                         ...formatedAddress,
                                                         postCode:
                                                            e.target.value,
                                                      });
                                                   }}
                                                   onBlur={onBlur}
                                                   error={errors?.postCode}
                                                   placeholder={t(
                                                      "souscription.facturation.placeholderZip"
                                                   )}
                                                />
                                             )}
                                          />
                                       </Grid>

                                       <Grid item xs={12} sm={8}>
                                          <Controller
                                             name="city"
                                             rules={{
                                                required: t("error.required"),
                                             }}
                                             defaultValue={
                                                current?.adresseFacturation
                                                   ?.city
                                             }
                                             control={control}
                                             render={({
                                                field: {
                                                   onBlur,
                                                   onChange,
                                                   value,
                                                },
                                             }) => (
                                                <TextInput
                                                   required
                                                   name="city"
                                                   style={{
                                                      margin: "3px 0 16px",
                                                   }}
                                                   value={value}
                                                   onChange={(e) => {
                                                      onChange(e.target.value);
                                                      // dispatch();
                                                      setFormatedAddress({
                                                         ...formatedAddress,
                                                         city: e.target.value,
                                                      });
                                                   }}
                                                   onBlur={onBlur}
                                                   error={errors?.city}
                                                   placeholder={t(
                                                      "souscription.facturation.placeholderCity"
                                                   )}
                                                />
                                             )}
                                          />
                                       </Grid>
                                    </Grid>
                                    {/*  */}
                                    {/* </AddressDisplay> */}
                                 </Grid>
                              )}
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.contratstart")}</p>
                              </Grid>
                              <Grid item xs={7}>
                                 <p>
                                    {moment(
                                       current?.dateDebutContrat * 1
                                    ).format("DD/MM/yyyy")}
                                    {console.log(current)}
                                 </p>
                              </Grid>
                              {/* <Grid item xs={5} className="align">
                               <p>{t("detailvente.prlvdate")}</p>
                            </Grid>
                            {modify ? (
                               <Grid item xs={7}>
                                  <Controller
                                     control={control}
                                     rules={{
                                        required: t("error.required"),
                                     }}
                                     name="desirDate"
                                     render={({
                                        field: { onChange, onBlur, value },
                                     }) => (
                                        <ComboBox
                                           label={t(
                                              "souscription.paiement.inputDesirDate"
                                           )}
                                           onChange={onChange}
                                           value={value}
                                           list={debit.map((v, i) =>
                                              i === 0
                                                 ? v?.text + "er du mois"
                                                 : v?.text + " du mois"
                                           )}
                                           required
                                           helperText={t("error.required")}
                                           error={
                                              errors.desirDate !== undefined
                                           }
                                           onBlur={(v) => {
                                              onBlur(v);
                                           }}
                                           autoComplete={autoCompleteAlea()}
                                        />
                                     )}
                                  />
                               </Grid>
                            ) : (
                               <Grid item xs={7}>
                                  <p>{current?.prlvDate}</p>
                               </Grid>
                            )} */}
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.estmensuelle")}</p>
                              </Grid>
                              <Grid item xs={7}>
                                 <p>
                                    {replacePointByCommaInDecimals(
                                       Number(
                                          current?.estimationMensuelle
                                       ).toFixed(2)
                                    )}
                                    €
                                 </p>
                              </Grid>
                              <Grid item xs={5} className="align">
                                 {t("detailvente.opts")}
                              </Grid>
                              <Grid item xs={7}>
                                 <div style={{ display: "flex" }}>
                                    {current?.optionCommerciales?.join(", ")}
                                 </div>
                              </Grid>
                              {user?.type !== 'CHARGE_CLIENT' && <>
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.commission")}</p>
                              </Grid>
                              <Grid item xs={7}>
                                 <p>{current?.commissionVente}€</p>
                              </Grid>
                              </>
                              }
                              <Grid item xs={5} className="align">
                                 <p>{t("detailvente.denyRightOfWithdrawal")}</p>
                              </Grid>
                              <Grid item xs={7}>
                                 <p>{current?.denyRightOfWithdrawal ? "Oui" : "Non"}</p>
                              </Grid>
                              <Grid item xs={5} className="align">
                                 <p>{"Voltalis :"}</p>
                              </Grid>
                              <Grid item xs={7}>
                                 <p>{current?.voltalis ? "Oui" : "Non"}</p>
                              </Grid>
                              <PermissionWrapper
                                 requiredPermission={[
                                    "VALIDATE_SALE",
                                    "CANCEL_SALE",
                                 ]}
                              >
                                 {sortDates(current)[0]?.statut !== "ANNULE" &&
                                    !modify &&
                                    checkIfDisplayAnnuleBtn(user, current) && (
                                       <h3 style={{ textAlign: "center" }}>
                                          {t("detailvente.wantannulvente")}
                                       </h3>
                                    )}
                                 {sortDates(current)[0]?.statut !== "ANNULE" &&
                                    modify && (
                                       <h3 style={{ textAlign: "center" }}>
                                          {t("detailvente.wantconfirmvente")}
                                       </h3>
                                    )}
                              </PermissionWrapper>

                              {sortDates(current)[0]?.statut !== "ANNULE" && (
                                 <>
                                    <Grid
                                       container
                                       className="container_btn_popinDetail"
                                       style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          width: "100%",
                                       }}
                                    >
                                       {checkIfDisplayAnnuleBtn(
                                          user,
                                          current
                                       ) && (
                                             <PermissionWrapper
                                                requiredPermission={[
                                                   "CANCEL_SALE",
                                                ]}
                                             >
                                                <Grid item xs={12} sm={6}>
                                                   <Button
                                                      style={{ margin: "0 auto", background: "#991753" }}
                                                      onClick={() =>
                                                         annule(current.idVente)
                                                      }
                                                   >
                                                      <p
                                                         style={{
                                                            margin: "0 10px 0 0",
                                                            color: "white",
                                                         }}
                                                      >
                                                         {t(
                                                            "detailvente.annulvente"
                                                         )}
                                                      </p>
                                                      <InactiveSvg
                                                         fill={"#FFFFFF"}
                                                         style={{
                                                            marginLeft: "10px",
                                                         }}
                                                      />
                                                   </Button>
                                                </Grid>
                                             </PermissionWrapper>
                                          )}

                                       {/* {modify && ( */}
                                       {sortDates(current)[0]?.statut ===
                                          "A_CONFIRMER_COURTIER" &&
                                          !modify && (
                                             <>
                                                <PermissionWrapper
                                                   requiredPermission={[
                                                      "VALIDATE_SALE",
                                                   ]}
                                                >
                                                   <Grid item xs={12} sm={6}>
                                                      <Button
                                                         style={{
                                                            margin: "0 auto",
                                                            background: "#2896af"
                                                         }}
                                                         onClick={() => {
                                                            setModify(true);
                                                         }}
                                                      >
                                                         <p
                                                            style={{
                                                               margin:
                                                                  "0 10px 0 0",
                                                               color: "white",
                                                            }}
                                                         >
                                                            {t(
                                                               "detailvente.modifyvente"
                                                            )}
                                                         </p>
                                                         <ModifySvg
                                                            fill={"#FFFFFF"}
                                                            style={{
                                                               marginLeft:
                                                                  "10px",
                                                            }}
                                                         />
                                                      </Button>
                                                   </Grid>
                                                </PermissionWrapper>

                                                {loadingVente && (
                                                   <div
                                                      style={{
                                                         display: "flex",
                                                         justifyContent:
                                                            "center",
                                                         alignItems: "center",
                                                      }}
                                                   >
                                                      <Loader />
                                                   </div>
                                                )}

                                                {!loadingVente && (
                                                   <PermissionWrapper
                                                      requiredPermission={[
                                                         "VALIDATE_SALE",
                                                      ]}
                                                   >
                                                      <Grid item xs={12} sm={6}>
                                                         <Button
                                                            id="confirmation"
                                                            className="green"
                                                            style={{
                                                               margin: "0 auto",
                                                            }}
                                                            type="button"
                                                            onClick={() =>
                                                               confirmerVente(
                                                                  current
                                                               )
                                                            }
                                                         >
                                                            <p
                                                               style={{
                                                                  margin:
                                                                     "0 10px 0 0",
                                                                  color: "white",
                                                               }}
                                                            >
                                                               {t(
                                                                  "detailvente.confirmvente"
                                                               )}
                                                            </p>
                                                            <ActiveSvg
                                                               fill={"#FFFFFF"}
                                                               style={{
                                                                  marginLeft:
                                                                     "10px",
                                                               }}
                                                            />
                                                         </Button>
                                                      </Grid>
                                                   </PermissionWrapper>
                                                )}
                                             </>
                                          )}

                                       {sortDates(current)[0]?.statut ===
                                          "A_CONFIRMER_COURTIER" &&
                                          modify && (
                                             <>
                                                <PermissionWrapper
                                                   requiredPermission={[
                                                      "VALIDATE_SALE",
                                                   ]}
                                                >
                                                   <Grid item xs={12} sm={6}>
                                                      <Button
                                                         className="green"
                                                         style={{
                                                            margin: "0 auto",
                                                         }}
                                                         type="submit"
                                                      >
                                                         <p
                                                            style={{
                                                               margin:
                                                                  "0 10px 0 0",
                                                               color: "white",
                                                            }}
                                                         >
                                                            {t(
                                                               "detailvente.confirmodifyvente"
                                                            )}
                                                         </p>
                                                         <ActiveSvg
                                                            fill={"#FFFFFF"}
                                                            style={{
                                                               marginLeft:
                                                                  "10px",
                                                            }}
                                                         />
                                                      </Button>
                                                   </Grid>
                                                </PermissionWrapper>

                                                <Grid item xs={12} sm={6}>
                                                   <Button
                                                      className="green"
                                                      style={{
                                                         margin: "0 auto",
                                                      }}
                                                      type="button"
                                                      onClick={() =>
                                                         setModify(false)
                                                      }>
                                                      Retour
                                                   </Button>
                                                </Grid>
                                             </>
                                          )}
                                    </Grid>
                                 </>
                              )}
                           </Grid>
                        </div>
                     )}
                     {loading && (
                        <div
                           className="container_btn_popinDetail"
                           style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                           }}
                        >
                           {checkIfDisplayAnnuleBtn(user, current) && (
                              <PermissionWrapper
                                 requiredPermission={["CANCEL_SALE"]}
                              >
                                 <Button
                                    className="green"
                                    style={{ marginRight: "8px" }}
                                    onClick={() => annule(current.idVente)}
                                 >
                                    <p
                                       style={{
                                          margin: "0 10px 0 0",
                                          color: "white",
                                       }}
                                    >
                                       {t("detailvente.annulvente")}
                                    </p>
                                    <InactiveSvg
                                       fill={"#FFFFFF"}
                                       style={{ marginLeft: "10px" }}
                                    />
                                 </Button>
                              </PermissionWrapper>
                           )}

                           {modify && (
                              <Button
                                 className="green"
                                 style={{ marginLeft: "8px" }}
                                 type="submit"
                              >
                                 <p
                                    style={{
                                       margin: "0 10px 0 0",
                                       color: "white",
                                    }}
                                 >
                                    {t("detailvente.confirmvente")}
                                 </p>
                                 <ActiveSvg
                                    fill={"#FFFFFF"}
                                    style={{ marginLeft: "10px" }}
                                 />
                              </Button>
                           )}

                           {sortDates(current)[0]?.statut ===
                              "A_CONFIRMER_COURTIER" &&
                              !modify && (
                                 <PermissionWrapper
                                    requiredPermission={["VALIDATE_SALE"]}
                                 >
                                    <Button
                                       className="green"
                                       style={{
                                          marginLeft: "8px",
                                          marginBottom: "34px",
                                       }}
                                       onClick={() => {
                                          setModify(true);
                                       }}
                                    >
                                       <p
                                          style={{
                                             margin: "0 10px 0 0",
                                             color: "white",
                                          }}
                                       >
                                          {t("detailvente.modifyvente")}
                                       </p>
                                       <ModifySvg
                                          fill={"#FFFFFF"}
                                          style={{
                                             marginLeft: "10px",
                                          }}
                                       />
                                    </Button>
                                 </PermissionWrapper>
                              )}

                        </div>
                     )}
                     <AuthorizationLayout
                        current={user?.type}
                        authorized={["ADMIN_PRIMEO"]}
                     >
                        {!loading && (
                           <>
                              <h3 style={{ textAlign: "center" }}>{"Action d'administration"}</h3>
                              <Grid
                                 container
                                 className="container_btn_popinDetail"
                                 spacing={2}
                                 style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                 }}
                              >
                                 {sortDates(current)[0]?.statut !==
                                    "A_VALIDER_PRIMEO" &&
                                    <Grid item xs={12} sm={4} >
                                       <Button
                                          className="green"
                                          style={{
                                             margin: "0 auto",
                                          }}
                                          type="button"
                                          onClick={() =>
                                             confirmSale(current.idVente).then(() => onSuccess())
                                          }
                                       >
                                          Forcer confirmation
                                       </Button>
                                    </Grid>
                                 }
                                 {sortDates(current)[0]?.statut ===
                                    "A_VALIDER_PRIMEO" &&
                                    <Grid item xs={12} sm={4}>
                                       <Button
                                          className="green"
                                          style={{
                                             margin: "0 auto",
                                          }}
                                          type="button"
                                          onClick={() =>
                                             unconfirmSale(current.idVente).then(() => onSuccess())
                                          }
                                       >
                                          Déconfirmer
                                       </Button>
                                    </Grid>
                                 }
                                 {sortDates(current)[0]?.statut !==
                                    "ANNULE" &&
                                    <Grid item xs={12} sm={4}>
                                       <Button
                                          className="green"
                                          style={{
                                             margin: "0 auto",
                                          }}
                                          type="button"
                                          onClick={() =>
                                             cancelSale(current.idVente).then(() => onSuccess())
                                          }
                                       >
                                          Forcer l'annulation
                                       </Button>
                                    </Grid>
                                 }
                                 {sortDates(current)[0]?.statut !==
                                    "VALIDE" &&
                                    <Grid item xs={12} sm={4}>
                                       <Button
                                          className="green"
                                          style={{
                                             margin: "0 auto",
                                          }}
                                          type="button"
                                          onClick={() =>
                                             validateSale(current.idVente).then(() => onSuccess())
                                          }>
                                          Forcer la validation
                                       </Button>
                                    </Grid>
                                 }
                                 {sortDates(current)[0]?.statut ===
                                    "ANNULE" &&
                                    <Grid item xs={12} sm={4}>
                                       <Button
                                          className="green"
                                          style={{
                                             margin: "0",
                                             width: "100%",
                                          }}
                                          type="button"
                                          onClick={() =>
                                             uncancelSale(current.idVente).then(() => onSuccess())
                                          }
                                       >
                                          Désannuler
                                       </Button>
                                    </Grid>
                                 }
                                 <Grid item xs={12} sm={4}>
                                    <Button
                                       className="green"
                                       style={{
                                       }}
                                       type="button"
                                       onClick={handleClickOpen}
                                    >
                                       Modifier idMetier
                                    </Button>
                                 </Grid>
                              </Grid>
                              <br></br>
                              <div>
                                 <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                       component: 'form',

                                    }}
                                 >
                                    <DialogTitle>Modification référence métier</DialogTitle>
                                    <DialogContent>

                                       <TextInput
                                          value={current.idMetier}
                                          style={{ margin: "3px 0" }}
                                          onChange={(e) => setcurrent({ ...current, idMetier: e.target.value })}

                                       />
                                    </DialogContent>
                                    <DialogActions>
                                       <div style={{display: "flex", gap: "10px"}}>
                                          <Button onClick={handleClose} style={{width: "50%"}}>Annuler</Button>
                                      
                                          <Button
                                             className="green"
                                             style={{
                                                margin: "0",
                                                width: "50%",
                                             }}
                                             type="button"
                                             onClick={updateIdMetier}
                                          >
                                             Sauvegarder
                                          </Button>
                                          </div>
                                    </DialogActions>
                                 </Dialog>
                              </div>
                           </>)}
                     </AuthorizationLayout>
                     {loading && (
                        <div
                           style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                           }}
                        >
                           <Loader />
                        </div>
                     )}
                  </div>
               </form>
            </div>
         </>
      </div>
   );
};

export default PopindDetailVente;
