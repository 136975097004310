import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button/Button";

const Step4 = () => {
  const { t } = useTranslation();
  const history = useHistory();

  function goConnect() {
    history.push("/auth");
  }

  return (
    <div className="container_step">
    <h1>{t("password.step4")}</h1>
    <div className="container_btn">
      <Button className="green" style={{ margin: "0 auto" }} onClick={() => goConnect()}>
        {t("password.continue")}
      </Button>
    </div>
  </div>

  );
};

export default Step4;
