/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, forwardRef, useEffect } from "react";
import NumberFormat from "react-number-format";
import TextField from "@mui/material/TextField";
import "./textInput.scss";
import { ReactComponent as ErrorSvg } from "../../core/assets/picto/error.svg";

const NumberFormatCustom = forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange(values.floatValue);
      }}
      suffix="€"
    />
  );
});

export default function FormattedInputs({
  value,
  label,
  placeholder,
  error,
  required = false,
  disabled = false,
  margin = "normal",
  variant = "outlined",
  fullwidth = true,
  style,
  type,
  InputLabelProps,
  id,
  name,
  onChange,
  autoComplete,
  maxLength,
  percentage,
  className,
  currency,
  ...props
}) {
  const [values, setValues] = useState(value || "");

  const handleChange = (e) => {
    setValues(e);
    onChange(e);
  };

  useEffect(() => {
    if (value === 0) {
      setValues(0);
    }
  }, []);

  return (
    <div className={"input-text"}>
      <TextField
        className={className}
        value={values === 0 ? 0 : values || ""}
        onChange={handleChange}
        InputProps={{
          inputComponent: NumberFormatCustom,
          endAdornment: error === true && <ErrorSvg />,
        }}
        id={id}
        InputLabelProps={{ ...InputLabelProps }}
        label={label}
        placeholder={placeholder ?? label}
        margin={margin}
        required={required}
        variant={variant}
        error={!!error}
        helperText={error?.message ?? ""}
        disabled={disabled}
        fullWidth={fullwidth}
        style={{ ...style }}
        type={type}
        name={"noAutoFill"}
        autoComplete={autoComplete}
        {...props}
      />
    </div>
  );
}
