import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import "./textInput.scss";

const PhoneInput = ({
  value = "",
  label,
  placeholder,
  error,
  required = false,
  disabled = false,
  margin = "normal",
  variant = "outlined",
  fullwidth = true,
  style,
  InputLabelProps,
  id,
  name,
  onChange,
  autoComplete,
  maxLength,
  className,
  ...props
}) => {
  // Juste letters
  const isLetters = (str) => /^[a-zA-ZÀ-ÿ]*$/.test(str);
  //    //    const isNumber = (str) => /^[0-9]+$/.test(str);

  const onInputChange = (e) => {
    const text = e.target.value;
    if (!isLetters(text)) {
      return onChange(text);
    }
    if (text.length <= 0) {
      return onChange("");
    }
  };

  const validChars = ["+", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  const keyCodes = [8, 37, 38, 39, 40];

  useEffect(() => {
    let target = document.getElementsByClassName("phoneinput");

    target[0].addEventListener("keydown", function (e) {
      if (!validChars.includes(e.key) && !keyCodes.includes(e.keyCode)) {
        e.preventDefault();
      }
    });
  }, []);

  return (
    <div className={"input-text"}>
      <TextField
        className={"phoneinput"}
        id={id}
        InputLabelProps={{ ...InputLabelProps }}
        onChange={onInputChange}
        label={label}
        placeholder={placeholder ?? label}
        margin={margin}
        required={required}
        variant={variant}
        error={!!error}
        helperText={error?.message ?? ""}
        disabled={disabled}
        fullWidth={fullwidth}
        style={{ ...style }}
        type="tel"
        name={"noAutoFill"}
        autoComplete={autoComplete}
        value={value}
        InputProps={{
          inputProps: {
            maxLength: maxLength,
            pattern: "([/^+(33)(6|7)d{8}$/])",
          },
        }}
        {...props}
      />
    </div>
  );
};

export default PhoneInput;
