import { customRequest } from "../../../core/services/httpRequest/axios";

export function confirmSale(idSale) {
  return customRequest.put('/vente/'+idSale+'/set-status', { status : "CONFIRM" });
}

export function cancelSale(idSale) {
    return customRequest.put('/vente/'+idSale+'/set-status', { status : "CANCEL" });
}

export function validateSale(idSale) {
    return customRequest.put('/vente/'+idSale+'/set-status', { status : "VALID" });
}

export function unconfirmSale(idSale) {
    return customRequest.put('/vente/'+idSale+'/set-status', { status : "UNCONFIRM" });
}

export function uncancelSale(idSale) {
    return customRequest.put('/vente/'+idSale+'/set-status', { status : "UNCANCEL" });
}