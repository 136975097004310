import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";

const AuthorizationLayout = ({
   current,
   authorized,
   children,
   redirect = false,
   destination,
}) => {
   const history = useHistory();

   useEffect(() => {
      //
      if (redirect) {
         if (!_.includes(authorized, current)) {
            return history.push(destination);
         }
      }
   }, [current, authorized]);

   return <>{_.includes(authorized, current) ? children : <></>}</>;
};

export default AuthorizationLayout;
